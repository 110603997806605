import { DEFAULT_DIALOG_MESSAGES_PAGE_SIZE } from "config";
import { wsSndMessage } from "./wsActions";

export const POST_SET_POSTS = 'POST_SET_POSTS';
export const POST_GET_POSTS = 'POST_GET_POSTS';
export const POST_NEW_COMMENT = 'POST_NEW_COMMENT';
export const POST_NEW_REPLY = 'POST_NEW_REPLY';
export const POST_SET_REPLY_PERSONAL = 'POST_SET_REPLY_PERSONAL';

export const POST_SET_LOADING = 'POST_SET_LOADING';
export const POST_COMMENTS_SET_LOADING = 'COMMENTS_SET_LOADING';

export const POST_GET = 'POST_GET';
export const POST_ADD = 'POST_ADD';

export const POST_UPDATE_COMMENTS = 'POST_UPDATE_COMMENTS';

export const SET_TEXT = 'SET_TEXT';
export const POST_CLEAR_NEW_REPLY = 'POST_CLEAR_NEW_REPLY';
export const POST_SET_STORAGE_FILES = 'POST_SET_STORAGE_FILES';

export const SET_FILTER = 'SET_FILTER';

export const setFilter = (payload) => (dispatch) =>
  dispatch({
    type: SET_FILTER,
    payload,
  });

export const setStorageFiles = () => (dispatch) =>
  dispatch({
    type: POST_SET_STORAGE_FILES,
  });

export const clearNewReply = () => (dispatch) =>
  dispatch({
    type: POST_CLEAR_NEW_REPLY,
  });

export const setPostText = (payload) => (dispatch) =>
  dispatch({
    type: SET_TEXT,
    payload,
  });

export const setPosts = (payload) => (dispatch) =>
  dispatch({
    type: POST_SET_POSTS,
    payload,
  });

export const setCommentReplyPersonal = (payload) => (dispatch) =>
  dispatch({
    type: POST_SET_REPLY_PERSONAL,
    payload,
  });

export const setPostsLoading = ({ loading }) => (dispatch) =>
  dispatch({
    type: POST_SET_LOADING,
    payload: { loading },
  });

export const setCommentsLoading = (payload) => (dispatch) =>
  dispatch({
    type: POST_COMMENTS_SET_LOADING,
    payload,
  });

export const getPost = (payload) => (dispatch) => {
  dispatch(setPostsLoading({ loading: true }));
  dispatch(
    wsSndMessage({
      action: 'chat.post.get',
      data: payload,
    }),
  );
};

export const addPost = (payload) => (dispatch) => {
  dispatch({
    type: POST_ADD,
    payload,
  });
};

export const updatePostHistory = (payload) => (dispatch) =>
  dispatch({
    type: POST_UPDATE_COMMENTS,
    payload,
  });

export const newPostComment = (payload) => (dispatch, getState) => {
  const state = getState();
  const {
    ids: { uuidMessengerPost, uuidMessengerComment, uuidCompany },
  } = payload;

  if (state.session.selectedCompany === uuidCompany) {
    const currentPost = state.post.posts.find(
      ({ ids }) => ids.uuidMessengerPost === uuidMessengerPost,
    );

    if (!currentPost) {
      getPost({
        filters: {
          "ids.uuidMessengerPost": payload.ids.uuidMessengerPost,
        },
        historySize: DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
      })(dispatch);
    } else {
      const checkDouble = currentPost.comments.find(
        (m) => m.ids.uuidMessengerComment === uuidMessengerComment,
      );
      if (!checkDouble) {
        dispatch({
          type: POST_NEW_COMMENT,
          payload,
        });
      }
    }
  }
};

export const getChatPosts = (payload) => (dispatch) => {
  dispatch(setPostsLoading({ loading: true }));
  dispatch(
    wsSndMessage({
      action: 'chat.posts.get',
      data: payload,
    }),
  );
};

export const getPostComments = (payload) => (dispatch) => {
  const {
    uuidMessengerPost,
    page = 1,
    size = DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
  } = payload;

  wsSndMessage({
    action: 'chat.comments.get',
    data: {
      uuidMessengerPost,
      page,
      size,
    },
  })(dispatch);
};

export const sndPostComment = (payload) => (dispatch) => {
  const { text, ids, to, isReply, additional } = payload;
  dispatch(setCommentsLoading(true));
  wsSndMessage({
    action: 'post.comment.send',
    data: {
      isReply,
      data: {
        text,
        ...additional,
      },
      to,
      ids,
    },
  })(dispatch);
};

export const sndPostPersonalComment = (payload) => (dispatch) => {
  const { text, ids, to } = payload;
  wsSndMessage({
    action: 'post.comment.personal',
    data: {
      data: {
        text,
      },
      to,
      ids,
    },
  })(dispatch);
};

export const setPersonalReply = (payload) => (dispatch) => {
  const { uuidMessengerComment } = payload;
  wsSndMessage({
    action: 'post.comment.markPersonal',
    data: {
      uuidMessengerComment,
    },
  })(dispatch);
};