import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';

const Wrapper = styled.div`
    background-color: #403f3f3b;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 99999999999999;
    width: 100%;
    height: 100%;
    padding-top: ${props => !props.fullScreen ? 150 : 35}px;
    overflow: auto;

    .popup-header{
      h1{
        font-size: 21px;
      }
    }

    .popup-wrapper{
        border-radius: 6px;
        width: ${props => !props.fullScreen ? 94 : 98}%;
        max-width: 625px;
        margin: 0 auto;
        background-color: #ffffff;
        position: relative;
        margin-bottom: 50px;
        padding: 15px 60px 25px 30px;
    }

    @media (max-width: 480px){
      .popup-wrapper{
        padding: 15px 40px 25px 15px;
      }
      .popup-header h1{
          font-size: 18px;
      }
    }
`;


// ${(props) => (props.buttonBg ? `backgroundColor: green` : `backgroundColor: #215fdc`)};

const ModalWrapper = (props) => {
  const {
    title,
    onClose,
    children,
    noButtonBg,
    wrapperStyle,
    modalStyle,
    isExpand,
  } = props;

  const CloseButton = styled.div`
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
    .MuiSvgIcon-root {
        ${!noButtonBg ? `color: #215fdc` : `color: #000`};
    }
`;

  return (
    <Wrapper style={wrapperStyle} fullScreen={isExpand}>
      <div className="popup-wrapper" style={modalStyle}>
        <div className="popup-header">
          {title ? <h1>{title}</h1> : null}
          <CloseButton onClick={onClose}>{!noButtonBg ? <CancelIcon/> : <CloseIcon />}</CloseButton>
        </div>
        <div className="popup-content">
          {children}
        </div>
      </div>
    </Wrapper>
  );
};

ModalWrapper.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  noButtonBg: PropTypes.bool,
  children: PropTypes.any.isRequired,
  wrapperStyle: PropTypes.object,
  modalStyle: PropTypes.object,
  isExpand: PropTypes.bool,
};

ModalWrapper.defaultProps = {
  title: '',
  noButtonBg: false,
  wrapperStyle: {},
  modalStyle: {},
  isExpand: false,
};

export default ModalWrapper;

export const ActionButton = styled.button`
  ${(props) => (props.disabled ? 'opacity:0.6' : 'opacity:1')};
  ${(props) => (props.disabled ? 'cursor: not-allowed' : 'cursor: pointer')};
  border-radius: 20px;
  ${(props) => (props?.bgColor ? `background-color: ${props?.bgColor}` : `background-color: #006ae4`)};
  font-size: 15px;
  color: #fff;
  border: none;
  padding: 12px 17px;
  outline: none;
`;

export const CancelButton = styled.button`
  border-radius: 20px;
  background-color: #fff;
  font-size: 15px;
  color: #484c56;
  border: solid 1px #a0a8bb;
  padding: 12px 17px;
  outline: none;
  cursor: pointer;
`;
