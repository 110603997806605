import React from 'react';
import { Route } from 'react-router-dom';

import AuthLayout from '../layouts/Auth';
import LoginPage from '../views/Login';

const UnauthorizedRouter = () => (
  <AuthLayout>
    <Route exact path="/auth/login" component={LoginPage} />
  </AuthLayout>
);

export default UnauthorizedRouter;
