import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { PROVIDERS_CAN_ALL, PROVIDERS_CAN_REPLY } from 'config';

import { ReactComponent as DropdownIcon } from '../../../../../../../../assets/img/dropdown.svg';
import { ReactComponent as ReplyIcon } from '../../../../../../../../assets/img/reply.svg';
import { ReactComponent as ForwardIcon } from '../../../../../../../../assets/img/forward.svg';
import { ReactComponent as DeleteIcon } from '../../../../../../../../assets/img/trash.svg';
import { ReactComponent as SelectedIcon } from '../../../../../../../../assets/img/selected.svg';
import { ReactComponent as AiIcon } from '../../../../../../../../assets/img/ai-select.svg';

const Caret = styled.div`
  cursor: pointer;
  width: 15px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
  .MuiMenu-list {
    padding: 4px;
  }
  .MuiMenuItem-root {
    padding: 5px 8px 7px;
    border-radius: 6px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: auto;
    min-heigth: auto;
    padding-right: 10px;
  }
  .MuiListItemText-root {
    margin: 0;
    font-size: 14px;
  }
  .MuiTypography-body1 {
    line-height: 19px;
  }
`;

export default function ConversationMenu({
  providerName,
  handleSelect,
  handleReply,
  handleForward,
  handleDelete,
  handleAiSelect,
  isText,
}) {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleOption = (type) => {
    switch (type) {
      case 'select': {
        handleSelect();
        break;
      }
      case 'delete': {
        handleDelete();
        break;
      }
      case 'forward': {
        handleForward();
        break;
      }
      case 'reply': {
        handleReply();
        break;
      }
      case 'ai_select': {
        handleAiSelect();
        break;
      }
      default:
        break;
    }
    handleClose();
  }

  return (
    <div>
      <Caret onClick={handleClick}>
        <DropdownIcon />
      </Caret>
      <StyledMenu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={(handleClose)}
      >
        <StyledMenuItem
          disabled={!PROVIDERS_CAN_REPLY.includes(providerName)}
          onClick={() => handleOption('reply')}>
          <ListItemIcon>
            <ReplyIcon />
          </ListItemIcon>
          <ListItemText primary={t('common.reply')} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!PROVIDERS_CAN_ALL.includes(providerName)}
          onClick={() => handleOption('select')}>
          <ListItemIcon>
            <SelectedIcon />
          </ListItemIcon>
          <ListItemText primary={t('common.select')} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!PROVIDERS_CAN_ALL.includes(providerName)}
          onClick={() => handleOption('forward')}>
          <ListItemIcon>
            <ForwardIcon />
          </ListItemIcon>
          <ListItemText primary={t('common.forward')} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!PROVIDERS_CAN_ALL.includes(providerName)}
          onClick={() => handleOption('delete')}>
          <ListItemIcon>
            <DeleteIcon />
          </ListItemIcon>
          <ListItemText primary={t('common.delete')} />
        </StyledMenuItem>
        <StyledMenuItem
          disabled={!isText}
          onClick={() => handleOption('ai_select')}>
          <ListItemIcon>
            <AiIcon />
          </ListItemIcon>
          <ListItemText primary={t('ai.select')} />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}

ConversationMenu.propTypes = {
  handleReply: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  handleForward: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  providerName: PropTypes.string.isRequired,
  handleAiSelect: PropTypes.string.isRequired,
  isText: PropTypes.bool.isRequired,
};