import React from 'react';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import VolumeUpIcon from '@material-ui/icons/VolumeUp';
import VolumeOffIcon from '@material-ui/icons/VolumeOff';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsOffIcon from '@material-ui/icons/NotificationsOff';
import { Grid } from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import { useTranslation } from 'react-i18next';
import Fab from '@material-ui/core/Fab';
// import Brightness4Icon from '@material-ui/icons/Brightness4';
// import BrightnessHighIcon from '@material-ui/icons/BrightnessHigh';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import ChatIcon from '@material-ui/icons/Chat';
import SettingsIcon from '@material-ui/icons/Settings';
import { useHistory, useLocation } from 'react-router-dom';
import * as selector from 'store/selectors';

import {
  setSoundState,
  setNotificationState,
  logout,
  showModal,
  // enableDarkMode,
  // disableDarkMode,
} from 'store/actions';
import LanguageSwitcher from '../../../../components/LanguageSwitcher';

const useStyles = makeStyles(() => ({
  root: {
    width: 76,
  },
  paper: {
    overflow: 'hidden',
    width: 76,
    backgroundColor: '#fff',
    padding: 12,
    boxShadow: '2px 0 6px 0 rgba(59, 61, 65, 0.1)',
    zIndex: 9,
  },
}));

const StyledGrid = styled(Grid)`
  position: absolute;
  bottom: 10px;
`;
const SystemButton = styled(Fab)`
  width: 52px !important;
  height: 52px !important;
  cursor: pointer;
  background-color: #006ae4 !important;

  svg {
    fill: #fff;
  }
`;

const MessengersBar = (props) => {
  const { open } = props;
  const history = useHistory();
  const { search } = useLocation();
  const selectedDialog = useSelector(selector.selectCurrentDialog);
  const dispatch = useDispatch();
  const settings = useSelector((state) => state.settings);
  const { sound, notificationIsOn } = settings;


  const classes = useStyles();
  const handleLogout = () => {
    dispatch(logout()).then(() => {
      document.location.reload();
    });
  };

  // const handleEnableDarkMode = () => {
  //   if (settings.darkMode) {
  //     dispatch(disableDarkMode());
  //   } else {
  //     dispatch(enableDarkMode());
  //   }
  // };

  const handleToogleTaskWindow = () => {
    history.push(`/tasks${search}`);
  };
  const handleToogleAnalyticsWindow = () => {
    history.push(`/stat/general`);
  };
  const handleOpenChat = () => {
    if (selectedDialog !== null && selectedDialog !== undefined) {
      history.push(`/chat/${selectedDialog}${search}`);
    } else {
      history.push(`/chat${search}`);
    }
  };
  const handleToogleSettingsWindow = () => {
    history.push(`/settings`);
  };

  const handleToggleSound = () => {
    dispatch(setSoundState({ ...sound, isOn: !sound.isOn }));
  };

  const handleToggleNotification = () => {
    dispatch(setNotificationState(!notificationIsOn));
  };

  // const handleChangePassword = () => {
  //   dispatch(showModal('ChangePasswordDialog'));
  // };

  const { t } = useTranslation();

  return (
    <Drawer open={open} variant="persistent" anchor="left" classes={classes}>
      {React.useMemo(
        () => (
          <>
            <img src="/img/logo.svg" alt="intellect dialog logo" />
            <div style={{ paddingTop: '8px' }}>
              <LanguageSwitcher />
            </div>

            <StyledGrid
              container
              direction="column"
              className={classes.toolsGrid}
              spacing={1}
            >
              <Grid item>
                <Tooltip title={t('leftDrawer.chat')} placement="right">
                  <SystemButton onClick={handleOpenChat}>
                    <ChatIcon />
                  </SystemButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t('leftDrawer.task')} placement="right">
                  <SystemButton onClick={handleToogleTaskWindow}>
                    <DoneAllIcon />
                  </SystemButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t('leftDrawer.analytics')} placement="right">
                  <SystemButton onClick={handleToogleAnalyticsWindow}>
                    <EqualizerIcon />
                  </SystemButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip
                  title={t('leftDrawer.notifications')}
                  placement="right"
                >
                  <SystemButton onClick={handleToggleNotification}>
                    {notificationIsOn ? (
                      <NotificationsIcon />
                    ) : (
                      <NotificationsOffIcon />
                    )}
                  </SystemButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t('leftDrawer.sound')} placement="right">
                  <SystemButton onClick={handleToggleSound}>
                    {sound.isOn ? <VolumeUpIcon /> : <VolumeOffIcon />}
                  </SystemButton>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={t('leftDrawer.settings')} placement="right">
                  <SystemButton onClick={handleToogleSettingsWindow}>
                    <SettingsIcon />
                  </SystemButton>
                </Tooltip>
              </Grid>
              {/* <Grid item>
                <Tooltip title={t('leftDrawer.darkMode')} placement="right">
                  <SystemButton onClick={handleEnableDarkMode}>
                    {settings.darkMode ? (
                      <Brightness4Icon />
                    ) : (
                      <BrightnessHighIcon />
                    )}
                  </SystemButton>
                </Tooltip>
              </Grid> */}
              {/* <Grid item>
                <Tooltip
                  title={t('changePasswordDialog.title')}
                  placement="right"
                >
                  <SystemButton onClick={handleChangePassword}>
                    <Lock />
                  </SystemButton>
                </Tooltip>
              </Grid> */}
              {/* TODO: скрыта иконка настроек */}
              {/* <Grid item> */}
              {/*  <Tooltip title={t('leftDrawer.settings')} placement="right"> */}
              {/*    <SystemButton onClick={handleOpenSettings}> */}
              {/*      <Settings /> */}
              {/*    </SystemButton> */}
              {/*  </Tooltip> */}
              {/* </Grid> */}
              <Grid item>
                <Tooltip title={t('leftDrawer.logout')} placement="right">
                  <SystemButton onClick={handleLogout}>
                    <ExitToAppIcon />
                  </SystemButton>
                </Tooltip>
              </Grid>
            </StyledGrid>
          </>
        ),
        [sound, notificationIsOn, open, t],
      )}
    </Drawer>
  );
};

MessengersBar.propTypes = {
  open: PropTypes.bool,
};

MessengersBar.defaultProps = {
  open: false,
};

export default MessengersBar;
