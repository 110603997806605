import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { Pause } from '@material-ui/icons';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import { useStopwatch } from 'react-timer-hook';
import {
  pauseWorkingTime,
  setPauseWorkingStatus,
  setStartWorkingStatus,
  showModal,
  setWorkTimeTimer,
  unpauseWorkingTime,
} from 'store/actions';
import { differenceInSeconds } from 'date-fns';

const useStyles = makeStyles(() => ({
  container: {
    margiRight: '18px',
    padding: '4px 9px',
    height: '40px',
    borderRadius: '8px',
    color: '#263238',
    fontSize: '13px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    position: 'relative',
    backgroundColor: '#F3F4F5',
    cursor: 'pointer',
    border: '1px solid #fff',
  },
  height: {
    height: '24px',
  },
  startButton: {
    border: 0,
  },
  pauseButton: {
    border: 0,
  },
  continueButton: {
    border: 0,
  },
  endButton: {
    border: 0,
  },
  startHover: {
    '&:hover': {
      border: '1px solid #5ACC00',
      color: '#5ACC00',
    },
  },
  pauseHover: {
    '&:hover': {
      border: '1px solid #FFD029',
      color: '#FFD029',
    },
  },
  unpauseHover: {
    color: '#fff',
    backgroundColor: '#FFD029',
    '&:hover': {
      backgroundColor: '#fff',
      border: '1px solid #FFD029',
      color: '#FFD029',
    },
  },
  endHover: {
    '&:hover': {
      border: '1px solid #FF5F5F',
    },
  },
  green: {
    color: '#5ACC00',
  },
  red: {
    color: '#FF5F5F',
  },
  yellow: {
    color: '#FFD029',
  },
}));

const TrackingTimeItem = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const workTime = useSelector((state) => state.workTime);
  const startTime = useSelector((state) => state.workTime.isStarted);
  const pauseTime = useSelector((state) => state.workTime.isPaused);
  const [isHovered, setIsHovered] = useState(false);
  const begin = useSelector((state) => state.workTime.begin);
  const workSeconds = useSelector((state) => state.workTime.totalSeconds);
  const [now, setNow] = useState(new Date());
  const [initialOffsetTimestamp, setInitialOffsetTimestamp] = useState(0);

  const { minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
    offsetTimestamp: initialOffsetTimestamp,
  });

  const calculateTimeDifference = (starting, end) => {
    const started = new Date(starting);
    let endTime;
    if (end) {
      endTime = new Date(end);
    } else {
      endTime = new Date();
    }
    return differenceInSeconds(endTime, started);
  };
  const getWorkingTime = () => {
    const totalSeconds = workTime?.hours?.reduce((acc, workItem) => {
      const diffInSec = calculateTimeDifference(
        workItem.work?.start,
        workItem.work?.end,
      );
      return acc + diffInSec;
    }, 0);
    return totalSeconds;
  };

  const checkTrackingStatus = () => {
    const workTimehours = workTime?.hours;
    if (workTimehours && workTimehours?.length > 0) {
      const lastTime = workTimehours?.[workTimehours?.length - 1];
      const workStatus = Boolean(
        lastTime?.work?.start && !lastTime?.pause?.start,
      );
      const pauseStatus = Boolean(
        !lastTime?.pause?.end && lastTime?.pause?.start,
      );
      dispatch(setStartWorkingStatus(workStatus));
      dispatch(setPauseWorkingStatus(pauseStatus));
    }
  };
  function formatTime(value) {
    return value.toString().padStart(2, '0');
  }
  useEffect(() => {
    checkTrackingStatus();
  }, []);
  const handleStart = () => {
    dispatch(showModal('StartTimeTracker'));
  };
  const handlePause = () => {
    pause();
    dispatch(pauseWorkingTime());
  };
  const handleUnpause = () => {
    setNow(new Date());
    dispatch(unpauseWorkingTime());
  };
  const handleEnd = () => {
    dispatch(showModal('FinishTimeTracker'));
  };
  useEffect(() => {
    if (begin && (startTime || !pauseTime)) {
      const newOffset = now.setSeconds(now.getSeconds() + getWorkingTime());
      setInitialOffsetTimestamp(newOffset);
      reset(newOffset, false);
    }
    if (startTime && !pauseTime) {
      start();
    }
    if (pauseTime && !startTime) pause();
    if (!startTime && !pauseTime) {
      reset();
      pause();
    }
  }, [begin, startTime, pauseTime, now]);
  useEffect(() => {
    if (minutes * 60 > workSeconds) {
      dispatch(setWorkTimeTimer());
    }
  }, [minutes]);
  return (
    <>
      {!startTime && !pauseTime && (
        <div
          className={`${classes.container} ${classes.startHover}`}
          onClick={() => handleStart()}
        >
          <div className={classes.startButton}>Начать</div>
          <span className={classes.height}>
            <PlayArrowIcon className={classes.green} />
          </span>
        </div>
      )}
      {startTime && !pauseTime && (
        <div
          className={`${classes.container} ${classes.endHover}`}
          onClick={() => handleEnd()}
        >
          <span className={`${classes.height} ${classes.endButton}`}>
            <StopIcon className={classes.red} />
          </span>
        </div>
      )}
      {startTime && !pauseTime && (
        <div
          className={`${classes.container} ${classes.pauseHover}`}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => handlePause()}
        >
          <div style={{ fontSize: '10px' }}>
            <div>
              {!isHovered ? (
                <>
                  <span>{formatTime(hours)}</span>:
                  <span>{formatTime(minutes)}</span>
                </>
              ) : (
                'Пауза'
              )}
            </div>
          </div>
          <span className={`${classes.height} ${classes.pauseButton}`}>
            <Pause className={classes.yellow} />
          </span>
        </div>
      )}
      {!startTime && pauseTime && (
        <div
          className={`${classes.container} ${classes.unpauseHover}`}
          onClick={() => handleUnpause()}
        >
          <div type="button" className={classes.continueButton}>
            Продолжить
          </div>
          <span className={`${classes.height} ${classes.continueButton}`}>
            <PlayArrowIcon />
          </span>
        </div>
      )}
    </>
  );
};
export default TrackingTimeItem;
