const { createSelector } = require('reselect');

const selectCurrentCompany = (state) => state.session.selectedCompany;

const selectCurrentDepartment = (state) => state.session.departments;

/**
 * Selector: select current company
 * @description memorized
 * @returns  {string} company uuid
 */
export const selectCurrentCompanyMemorized = createSelector(selectCurrentCompany, (company) => company);
/**
 * Selector: select current company
 * @description memorized
 * @returns  {object} departments tree
 */
export const selectCurrentDepartmentMemorized = createSelector(selectCurrentDepartment, (departments) => departments);
