import React from 'react';
import moment from 'moment';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { Clear, ExpandLess, ExpandMore } from '@material-ui/icons';

import { useDispatch, useSelector } from 'react-redux';
import { removeDialogTag } from 'store/actions';

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import Confirm from './Confirm';

const Modal = styled.div`
  background-color: #403f3f3b;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .modal-content {
    background: #ffffff;
    border-radius: 12px;
    padding: 4px;
    height: calc(100% - 180px);
  }
`;

const Header = styled.div`
  padding: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .title {
    font-size: 18px;
    line-height: 25px;
  }
`;

const Body = styled.div`
  height: calc(100% - 76px);
  width: 471px;
  border-radius: 4px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 4px;
  }
  .tag-count {
    border-radius: 50%;
    border: 1px solid rgba(0, 0, 0, 0.15);
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
  }
  .date-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 10px;
    column-gap: 8px;
    padding: 10px 24px;
  }
  .date-list-item {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 4px;
    height: 22px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 26px;
    font-size: 13px;
    line-height: 18px;
    gap: 4px;
    background-color: #215fdc;
    border-color: #215fdc;
    color: #ffffff;
  }
  .date-list-remove {
    height: 15px;
    width: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
`;

const Icon = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #313131;
  }
`;

export default function TagDialog({ uuidDialog, onClose }) {
  const dispatch = useDispatch();
  const conversation = useSelector(
    (state) => state.chat.dialogs.find((d) => d.uuid === uuidDialog) || null,
  );
  const [tags, setTags] = React.useState([]);
  React.useEffect(() => {
    if (conversation && conversation.tags) {
      setTags(conversation.tags);
    }
  }, [JSON.stringify(conversation)]);
  const [list, setList] = React.useState([]);
  const [openedList, setOpenedList] = React.useState([]);
  const [removedTag, setRemovedTag] = React.useState(null);

  React.useEffect(() => {
    const uniqueTags = [];
    const sortedTags = tags.sort((a, b) => b.created - a.created);
    sortedTags.forEach((tag) => {
      if (!uniqueTags.find((uniqueTag) => tag.title === uniqueTag.title)) {
        uniqueTags.push({
          ...tag,
          items: sortedTags
            .filter(({ title }) => title === tag.title)
            .map(({ created }) => created),
        });
      }
    });
    setList(uniqueTags);
  }, [JSON.stringify(tags)]);

  const toDate = (second) => moment(second * 1000).format('hh:mm, DD.MM.YYYY');

  const handleOpen = (title) => {
    if (openedList.includes(title)) {
      setOpenedList(openedList.filter((openedItem) => openedItem !== title));
    } else {
      setOpenedList([...openedList, title]);
    }
  };

  const handleCloseConfirm = () => {
    setRemovedTag(null);
  };

  const handleRemoveTag = (title, created) => {
    dispatch(removeDialogTag(uuidDialog, title, created));
    handleCloseConfirm();
  };

  return (
    <Modal>
      <div className="modal-content">
        <Header>
          <div className="title">Все теги диалога</div>
          <Icon onClick={() => onClose()}>
            <CloseIcon />
          </Icon>
        </Header>
        <Body>
          <List>
            {list.map(({ title, created, items }, index) => (
              <div key={index}>
                <ListItem button onClick={() => handleOpen(title)}>
                  <ListItemIcon className="tag-count">
                    {items.length}
                  </ListItemIcon>
                  <ListItemText
                    primary={title}
                    secondary={`Последний раз: ${toDate(created)}`}
                  />
                  <div>
                    {openedList.includes(title) ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    )}
                  </div>
                </ListItem>
                <Collapse
                  in={openedList.includes(title)}
                  timeout="auto"
                  unmountOnExit
                >
                  <div className="date-list">
                    {items.map((date, index) => (
                      <div key={index} className="date-list-item">
                        {toDate(date)}
                        <Clear
                          onClick={() =>
                            setRemovedTag({ title, created: date })
                          }
                          className="date-list-remove"
                        />
                      </div>
                    ))}
                  </div>
                </Collapse>
              </div>
            ))}
          </List>
        </Body>
      </div>
      {removedTag !== null && (
        <Confirm
          handleConfirm={() =>
            handleRemoveTag(removedTag.title, removedTag.created)
          }
          handleClose={handleCloseConfirm}
          data={{
            title: 'Удалить тег?',
            subtitle: `${removedTag.title} ${toDate(removedTag.created)}`,
          }}
        />
      )}
    </Modal>
  );
}

TagDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  uuidDialog: PropTypes.string.isRequired,
};
