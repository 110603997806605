import React, { useState } from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CheckmarkIcon } from '../../../../../assets/img/checkmark.svg';
import { ReactComponent as ChevronIcon } from '../../../../../assets/img/chevron.svg';
import { SCRIPT_ACTION_OPTIONS } from '../index';
import HorizontalDivider from '../../../../../layouts/Modal/Settings/components/HorizontalDivider';

const useStyles = makeStyles(() => ({
  formControl: {
    '& .MuiInputBase-root': {
      border: '1px solid #DADCE0',
      borderRadius: '8px',
      justifyContent: 'center',
      backgroundColor: '#ffffff',
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: '0px',
    },
    '& .MuiSelect-select:focus': {
      backgroundColor: '#ffffff',
    },
  },
  paper: {
    borderRadius: 12,
    marginTop: 8,
    boxShadow: 'none !important',
  },
  list: {
    padding: '8px !important',
    backgroundColor: '#FFFFFF',
    '& li': {
      position: 'relative',
      fontWeight: 200,
      fontSize: '12px',
      padding: '8px',
      borderRadius: '4px',
      '&:not(:last-child)': {
        marginBottom: '9px',
      },
    },
    '& li:hover': {
      backgroundColor: '#F4F6F8',
    },
    '& li.Mui-selected': {
      color: 'white',
      backgroundColor: '#006AE40D',
      borderRadius: '4px',
    },
    '& li.Mui-selected:hover': {
      backgroundColor: '#006AE40D',
    },
  },
}));

const RoutingActionSelect = ({ activeAction, isDisabled, onChange }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const menuProps = {
    classes: {
      list: classes.list,
      paper: classes.paper,
    },
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'center',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'center',
    },
    getContentAnchorEl: null,
  };

  const [value, setValue] = useState(activeAction ?? '');
  const handleChange = (e) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <FormControl className={classes.formControl}>
      <Select
        MenuProps={menuProps}
        IconComponent={ChevronIcon}
        size="small"
        displayEmpty
        options={SCRIPT_ACTION_OPTIONS}
        value={t(value)}
        renderValue={(selected) => {
          const selectedOption = SCRIPT_ACTION_OPTIONS.find(
            (option) => option.value === selected,
          );

          return selectedOption ? (
            <span
              style={{ fontSize: '14px', lineHeight: '16px', color: '#393939' }}
            >
              {t(selectedOption.label)}
            </span>
          ) : (
            ''
          );
        }}
        SelectIconProps={{
          className: classes.selectIcon,
        }}
        disabled={isDisabled}
        onChange={handleChange}
      >
        {SCRIPT_ACTION_OPTIONS.map((option, index) => (
          <MenuItem
            key={option.value}
            value={option.value}
            disabled={option.isDisabled}
          >
            <Option>
              <p className={`title${option.value === value ? ' active' : ''}`}>
                {t(option.label)}
                {option.value === value && <CheckmarkIcon className="icon" />}
              </p>
              <p className="description">{t(option.description)}</p>
            </Option>

            {SCRIPT_ACTION_OPTIONS.length !== index + 1 && (
              <HorizontalDivider
                style={{
                  position: 'absolute',
                  bottom: '-5px',
                  left: 0,
                  right: 0,
                  pointerEvents: 'none',
                }}
                dividerColor="#DADCE0"
              />
            )}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

RoutingActionSelect.propTypes = {
  activeAction: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
};

export default RoutingActionSelect;

const Option = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  width: 100%;

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: 4px;
    font-size: 14px;
    line-height: 16px;

    &.active {
      color: #006ae4;
    }

    .icon path {
      fill: #006ae4;
    }
  }

  .description {
    font-size: 12px;
    line-height: 16px;
    color: #8b8c8d;
  }
`;
