import { makeStyles } from '@material-ui/styles';
import { get } from 'idb-keyval';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useLayoutEffect,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as filesActions from 'store/actions/filesActions';
import { getMsgOriginalIDForReply } from 'utils/getMsgOriginalID';
import { getStickerUnzip } from 'utils/getSticker';
import { getPersone } from 'utils/personeHelper';
import ConversationMessage from '../ConversationMessage';
import ConversationMessageButton from '../ConversationMessageButton';
import ConversationMessageEvent from '../ConversationMessageEvent';

export const msgListStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    flexGrow: 1,
    overflowY: 'auto',
    maxHeight: '100%',
    transform: 'none !important',
    flexShrink: 'initial !important',
    backgroundColor: '#ebeef3',
  },

  inner: {
    padding: theme.spacing(2),
  },
}));

const ConversationMessages = (props) => {
  const {
    messageList,
    conversationId,
    sortedMessages,
    pagination,
    scrollPosition,
    shouldGoTo,
    setShouldGoTo,
    handleScroll,
    handleClearNewMessagesState,
    participants,
    provider,
  } = props;
  // eslint-disable-next-line no-unused-vars
  const [personeParticipants, setParticipants] = useState([]);
  const [moveUuid, setMoveUuid] = useState(null);

  const dispatch = useDispatch();
  const filesStore = useSelector((state) => state.files.files);
  const [files, updateFiles] = useState({});
  const [didMount, setDidMount] = useState(false);
  const [firstScrollChange, setFirstScrollChange] = useState(false);
  const getFile = (uuidProvider, fileId, recipient) => filesActions.getMessengerFile({
    uuidProvider,
    fileId,
    recipient,
  })(dispatch);

  useEffect(() => {
    const {
      current: { scrollHeight },
    } = messageList;
    setFirstScrollChange(false);
    messageList.current.scrollTop = scrollHeight;
  }, [conversationId]);

  // useLayoutEffect(() => {
  //   const { current: { scrollHeight } } = messageList;
  //   messageList.current.scrollTop = firstScrollChange ? scrollHeight - scrollPosition.scrollHeight : scrollHeight;
  // }, [sortedMessages, files]);

  useLayoutEffect(() => {
    const {
      current: { scrollHeight },
    } = messageList;
    messageList.current.scrollTop = firstScrollChange
      ? scrollHeight - scrollPosition.scrollHeight
      : scrollHeight;
  }, [pagination.page]);

  useLayoutEffect(() => {
    if (shouldGoTo !== false) {
      if (shouldGoTo === null) {
        messageList.current.scrollTop = messageList.current.scrollHeight;
      } else {
        setTimeout(() => {
          const findMessageNode = document.getElementById(
            `chatMessage-${shouldGoTo}`,
          );
          if (findMessageNode) {
            messageList.current.scrollTop = findMessageNode.offsetTop - 148;
          }
        }, 500);
      }
      setShouldGoTo(false);
      handleClearNewMessagesState();
    }
  }, [shouldGoTo]);
  useEffect(() => {
    setDidMount(true);
  }, []);
  useEffect(() => {
    Promise.all(participants.map(getPersone)).then((personeArr) => {
      if (personeArr.length > 0) {
        setParticipants(personeArr.filter((x) => !!x));
      }
    });
  }, []);

  // Load persone & images data from cache
  useEffect(() => {
    if (!didMount) {
      (async function () {
        const promisesFiles = [];

        for (const message of sortedMessages) {
          // Load file from cache
          if (message.content.type === 'message.image') {
            const {
              content: {
                data: { sizes = [] },
              },
            } = message;
            for (const size of sizes) {
              if (size.storage !== 'messenger') continue;
              promisesFiles.push(
                new Promise((resolve) => {
                  // Get file link
                  get(`file:${size.fileId}`)
                    .then((file) => {
                      // Load file from messenger
                      const fileInStore = filesStore[size.fileId];
                      if (!file && !fileInStore) {
                        getFile(message.ids.uuidProvider, size.fileId, {});
                        resolve(null);
                        return;
                      }
                      resolve({ [`file:${size.fileId}`]: file });
                    })
                    .catch(() => resolve(null));
                }),
              );
            }
          }

          if (message.content.type === 'message.sticker') {
            const {
              content: { data = {} },
            } = message;

            if (
              data.storage !== 'messenger'
              && _.get(data, 'sizes.0.render.driver') !== 'lottie'
            ) {
              continue;
            }
            promisesFiles.push(
              new Promise((resolve) => {
                getStickerUnzip(_.get(data, 'sizes.0.fileUrl'), (buffer) => {
                  resolve({ [`sticker:${data.srcFiles[0].fileId}`]: buffer });
                });
              }),
            );
          }

          if (message.content.type === 'message.video') {
            const {
              content: {
                data: { thumb },
              },
            } = message;
            if (!thumb) continue;
            if (thumb.storage !== 'messenger') continue;
            /*
        promisesFiles.push(new Promise((resolve) => {
          // Get file link
          get(`file:${fileId}`).then((file) => {
            // Load file from messenger
            const fileInStore = filesStore[fileId];
            if (!file && !fileInStore) {
              getFile(message.ids.uuidProvider, fileId, {});
              resolve(null);
              return;
            }
            resolve({ [`file:${fileId}`]: file });
          }).catch(() => resolve(null));
        }));
        */
            promisesFiles.push(
              new Promise((resolve) => {
                // Get file link
                get(`file:${thumb.fileId}`)
                  .then((file) => {
                    // Load file from messenger
                    const fileInStore = filesStore[thumb.fileId];
                    if (!file && !fileInStore) {
                      getFile(message.ids.uuidProvider, thumb.fileId, {});
                      resolve(null);
                      return;
                    }
                    resolve({ [`file:${thumb.fileId}`]: file });
                  })
                  .catch(() => resolve(null));
              }),
            );
          }
        }

        // Run files promises
        const loadedFiles = await Promise.all(promisesFiles);

        let newFiles = {};
        loadedFiles.forEach((f) => {
          newFiles = { ...newFiles, ...f };
        });
        updateFiles({ ...files, ...newFiles });

        if (!firstScrollChange && pagination) {
          setFirstScrollChange(true);
        }
      }());
    }
  }, [sortedMessages, filesStore]);

  const classes = msgListStyles();
  const getForReplyUuid = (message) => {
    let result = null;
    if (message.reply) {
      const replyMsg = sortedMessages.find((msg) => {
        const originalID = getMsgOriginalIDForReply(msg);
        return originalID && originalID.includes(message.reply);
      });
      if (replyMsg) {
        result = replyMsg;
      }
      // sortedMessages.find((msg) => getMsgOriginalIDForReply (msg).includes(message.reply))
    }
    return result;
  }

  return (
    <div className={classes.root} ref={messageList} onScroll={handleScroll}>
      <div className={classes.inner}>
        {sortedMessages.map((message) => (message.type !== 'sys' ? (
          <ConversationMessage
            providerName={provider}
            key={message.uuid}
            message={message}
            id={message.uuid}
            reply={getForReplyUuid(message)}
            fileStorage={files}
            move={moveUuid === message.uuid}
            moveToReply={(uuid) => setMoveUuid(uuid)}
          />
        ) : (
          <ConversationMessageEvent message={message} key={message.uuid} />
        )))}
        <ConversationMessageButton uuidDialog={conversationId} />
      </div>
    </div>
  );
};
ConversationMessages.propTypes = {
  messageList: PropTypes.any.isRequired,
  setShouldGoTo: PropTypes.func.isRequired,
  shouldGoTo: PropTypes.bool.isRequired,
  handleClearNewMessagesState: PropTypes.func.isRequired,
  conversationId: PropTypes.string,
  pagination: PropTypes.any.isRequired,
  sortedMessages: PropTypes.array.isRequired,
  scrollPosition: PropTypes.object.isRequired,
  handleScroll: PropTypes.func.isRequired,
  participants: PropTypes.array.isRequired,
  provider: PropTypes.array.isRequired,
};

ConversationMessages.defaultProps = {
  conversationId: '',
};

export default ConversationMessages;
