import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  hours: [],
  stat: [],
  totalSeconds: 0,
  isStarted: false,
  isPaused: false,
  begin: ''
};

const workTimeReducer = (state = initialState, action) => {
  const { type } = action;
  const newDate = new Date();
  switch (type) {
    case actionTypes.SET_BEGIN_STOPWATCH:
      return {
        ...state,
        begin: action.payload
      }
    case actionTypes.SET_START_WORKING_STATUS:
      return {
        ...state,
        isStarted: action.payload
      };
    case actionTypes.SET_PAUSE_WORKING_STATUS:
      return {
        ...state,
        isPaused: action.payload
      };
    case actionTypes.START_WORKING_TIME:
      return {
        ...state,
        isStarted: true,
        hours: [
          ...state.hours,
          {
            work: {
              start: newDate,
              end: '',
            },
          },
        ],
      };
    case actionTypes.PAUSE_WORKING_TIME: {
      return update(state, {
        isStarted: {$set: false},
        isPaused: {$set: true},
        hours: {
          $apply: (hours) =>
            hours.map((hour) => {
              if (hour.work.start && !hour.work.end) {
                return {
                  ...hour,
                  work: { ...hour.work, end: newDate },
                  pause: { ...hour.pause, start: newDate },
                };
              }
              return hour;
            }),
        },
      });
    }
    case actionTypes.UNPAUSE_WORKING_TIME: {
      return update(state, {
        isStarted: {$set: true},
        isPaused: {$set: false},
        hours: {
          $apply: (hours) =>
            hours.map((hour) => {
              if (hour.pause.start && !hour.pause.end) {
                return {
                  ...hour,
                  work: { ...hour.work },
                  pause: { ...hour.pause, end: newDate },
                };
              }
              return hour;
            }),
          $push: [{ work: { start: newDate, end: '' } }],
        },
      });
    }
    case actionTypes.CLEAR_WORKING_TIME:
      return initialState;

    case actionTypes.FINISH_WORKING_TIME: {
      return update(state, {
        isStarted: {$set: false},
        isPaused: {$set: false},
        hours: {
          $apply: (hours) =>
            hours?.map((hour) => ({
              ...hour,
              work: { ...hour.work, end: new Date() },
            })),
        },
      });
    }
    case actionTypes.SET_WORKERS_STAT:
      return {...state, stat: action.payload?.data};
    case actionTypes.SET_WORKTIME_TIMER:
      return {...state, totalSeconds: state.totalSeconds + 60};
    default:
      return state;
  }
};

export default workTimeReducer;
