import { Box, styled, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import AppButton from 'components/AppButton/AppButton';
import AppIconButton from 'components/AppIconButton/AppIconButton';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Close } from '../../../../../assets/img/close.svg';
import { ReactComponent as TimeLine } from '../../../../../assets/img/time-line.svg';
import React from 'react';
import TimePicker from './Timepicker';
import { red } from '@mui/material/colors';

interface IDialogProps {
  handleClose: () => void;
  open: boolean;
  addInterval: (value: { from: number; to: number }) => void;
  lastSelectedTime?: { from: number; to: number };
}

function minutesTo24hFormat(minutes: number): string {
  let hours: string | number = Math.floor(minutes / 60);

  let remainingMinutes: string | number = minutes % 60;

  hours = hours < 10 ? `0${hours}` : hours;

  remainingMinutes = remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes;

  return `${hours}:${remainingMinutes}`;
}
function hoursToMinutes(timeString: string) {
  const [hours, minutes] = timeString.split(':');

  const hoursInMinutes = parseInt(hours) * 60;
  const minutesInMinutes = parseInt(minutes);

  return hoursInMinutes + minutesInMinutes;
}

const AddClockDialog = ({
  handleClose,
  open,
  addInterval,
  lastSelectedTime,
}: IDialogProps) => {
  const { t: translate } = useTranslation();

  const [timeFrom, setTimeFrom] = React.useState(lastSelectedTime?.from ?? 0);
  const [timeTo, setTimeTo] = React.useState(lastSelectedTime?.to ?? 1440);

  return (
    <DialogStyled
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent>
        <AppIconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '20px', top: '20px' }}
        >
          <Close />
        </AppIconButton>

        <Typography fontSize={16} fontWeight={500}>
          {translate('schedules.setTimeTitle')}
        </Typography>

        <StyledModalContent>
          <TimePicker
            minutes={timeFrom}
            setMinutes={setTimeFrom}
          />
          <TimeLine style={{ margin: 'auto 0' }} />
          <TimePicker
            minutes={timeTo}
            setMinutes={setTimeTo}
          />
        </StyledModalContent>

        {timeFrom >= timeTo && (
          <Typography sx={{fontSize: 12, color: red[300]}}>
            Неверный интевал
          </Typography>
        )}
        <AppButton
          type="common"
          size=""
          withIcon={false}
          onClick={(e: MouseEvent) => {
            e.stopPropagation();
            addInterval({from: timeFrom, to: timeTo});
          }}
          style={{ width: '100%' }}
          disabled={timeFrom >= timeTo}
        >
          {translate('calendar.btn1')}
        </AppButton>
      </DialogContent>
    </DialogStyled>
  );
};

export default AddClockDialog;

const DialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    maxWidth: '312px',
    width: '100%',
  },
  '& .MuiDialogContent-root': {},
}));

const StyledModalContent = styled(Box)({
  margin: '16px 0',
  display: 'flex',
  alignItems: 'stretch',
  justifyContent: 'space-between',
  gap: '6px',
  height: 192,
});
