import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
    label {
        font-size: 15px;
        color: #727887;
        margin-bottom: 10px;
    }

    input {
        border-radius: 3px;
        border: solid 1px #eeeff2;
        background-color: #f6f8fc;
        font-size: 15px;
        color: #2b2d33;
        padding: 10px 11px;
        outline: none;
    }
`;

const Input = (props) => {
  const {
    id,
    value,
    label,
    ...rest
  } = props;

  return (
    <Wrapper>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <input
        id={id}
        value={value}
        {...rest}
      />
    </Wrapper>
  );
};

Input.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,

};

Input.defaultProps = {
  id: '',
  value: '',
  placeholder: '',
  type: 'text',
  label: '',
  onChange: () => false,
};

export default Input;
