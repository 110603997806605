import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MuiRootDialog from '../../MuiRootDialog/MuiRootDialog';
import { changeRoutingScript, hideModal } from '../../../../store/actions';
import SelectionTargetEmployee from './components/SelectionTargetEmployee';
import AppSwitcher from '../../../../components/AppSwitcher/AppSwitcher';
import { PERMISSIONS } from '../../../../views/Settings';

const SelectVisibilityConditionDialog = ({ scriptIndex }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { settings, isAdmin } = useSelector((state) => state.session.user);
  const userPermissions = settings.permissions;
  const hasEditPermission =
    isAdmin || userPermissions.includes(PERMISSIONS.ROUTING.WRITE);

  const additionalScripts = useSelector(
    (state) => state.settings.scripts[scriptIndex].additional,
  );

  const { scripts } = useSelector((state) => state.settings);

  const [targets, setTargets] = useState(scripts[scriptIndex].visibility);
  const [isForAllUsers, setIsForAllUsers] = useState(targets.all ?? true);

  const handleModalHide = () => dispatch(hideModal());

  const { uuid: scriptUuid } = useSelector(
    (state) => state.settings.scripts[scriptIndex],
  );

  const changeScript = (visibility, selectedCompaniesForVisibility) => {
    dispatch(
      changeRoutingScript(
        {
          visibility,
          additional: {
            ...additionalScripts,
            selectedCompaniesForVisibility,
          },
        },
        scriptUuid,
      ),
    );
  };

  const confirmAction = () => {
    if (isForAllUsers) {
      return changeScript({ all: isForAllUsers, includes: [], excludes: [] });
    }
    return changeScript({ ...targets, all: isForAllUsers });
  };

  return (
    <MuiRootDialog
      PaperProps={{
        style: {
          maxWidth: 1078,
          width: '100%',
          backgroundColor: '#F5F5F6',
        },
      }}
      title={t('routingPage.visibilitySelectionCondition')}
      declineAction={handleModalHide}
      isConfirmDisabled={!hasEditPermission}
      confirmAction={() => confirmAction()}
      handleClose={handleModalHide}
    >
      <Body>
        <div className="mainCondition">
          <h4 className="title">{t('routingPage.isVisibleDialog')}</h4>
          <AppSwitcher
            className="toggler"
            isOn={isForAllUsers}
            onChange={() => setIsForAllUsers((prev) => !prev)}
          />
        </div>

        {!isForAllUsers && (
          <SelectionTargetEmployee targets={targets} setTargets={setTargets} />
        )}
      </Body>
    </MuiRootDialog>
  );
};

SelectVisibilityConditionDialog.propTypes = {
  scriptIndex: PropTypes.number.isRequired,
};

export default SelectVisibilityConditionDialog;

const Body = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
  padding: 16px 0;

  .mainCondition {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 24px;

    .title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
    }
  }

  .toggler {
    margin-right: 6px;
  }

  .errorText {
    color: tomato;
  }
`;
