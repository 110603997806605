import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DurationInputs from '../../components/DurationInputs';
import { ROUTING_RELATIONAL_OPERATOR_OPTIONS } from '../../constants';
import Select from '../../../../../components/Select/Select';
import AppMultipleAutocomplete from '../../../../../components/AppMultipleAutocomplete/AppMultipleAutocomplete';

const DialogTagsInputs = ({ orCondition }) => {
  const { tags } = useSelector((state) => state.chat);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: '100%',
        columnGap: '8px',
        rowGap: '12px',
      }}
    >
      <AppMultipleAutocomplete
        disablePortal
        options={tags}
        getOptionLabel={(option) => option.title}
        sx={{ minWidth: '200px', maxWidth: '300px', width: '100%' }}
        inputLabel="Название тега"
      />
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          columnGap: '8px',
          rowGap: '12px',
          width: '100%',
        }}
      >
        <Select
          isFullWidth
          sx={{ maxWidth: '180px' }}
          label="Прошло времени"
          options={ROUTING_RELATIONAL_OPERATOR_OPTIONS}
          defaultValue={ROUTING_RELATIONAL_OPERATOR_OPTIONS[0]}
        />
        <DurationInputs
          inputStyles={{ maxWidth: '120px' }}
          onChange={() => {}}
        />
      </div>
    </div>
  );
};
DialogTagsInputs.propTypes = {
  orCondition: PropTypes.object.isRequired,
};

export default DialogTagsInputs;
