import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledInput = styled.input`
    border-radius: 3px;
    border: solid 1px #eeeff2;
    background-color: #f6f8fc;
    font-size: 15px;
    color: #3f434c;
    padding: 10px;
    width: 100%;
    outline: none;
`;

const NumField = (props) => {
  const {
    id,
    value,
    onChange,
    placeholder,
  } = props;

  return (
    <StyledInput
      type="number"
      id={id}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
    />
  );
};

NumField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

NumField.defaultProps = {
  placeholder: 'Значение поля',
};

export default NumField;
