import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  tasks: [],
  dialogTasks: [],
  taskTotalCount: 0,
  dialogTaskTotalCount: 0,
  isFetching: false,
  errors: null,
};

const taskReducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case actionTypes.TASK_SET:
      return update(state, {
        tasks: {
          $set: [...state.tasks.filter((x) => x.uuid !== payload.uuid), payload],
        },
      });
    case actionTypes.TASK_REMOVE:
      return update(state, {
        tasks: {
          $apply: (d) => d.filter((task) => task.uuid !== payload.uuidTask),
        },
      });
    case actionTypes.TASK_UPDATE:
      return update(state, {
        tasks: {
          $apply: (d) => d.map((task) => {
            if (task !== payload.uuid) {
              return task;
            }
            return { ...task, ...payload };
          }),
        },
      });
    case actionTypes.TASK_LIST_SET: {
      return update(state, { tasks: { $set: payload } });
    }
    case actionTypes.TASK_LIST_COUNT_SET: {
      return update(state, { taskTotalCount: { $set: payload } });
    }
    case actionTypes.DIALOG_TASK_LIST_SET: {
      return update(state, { dialogTasks: { $set: payload } });
    }
    case actionTypes.DIALOG_TASK_LIST_COUNT_SET: {
      return update(state, { dialogTaskTotalCount: { $set: payload } });
    }
    default:
      return state;
  }
};
export default taskReducer;
