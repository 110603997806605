import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { wsConnect, wsDisconnect } from '../../store/actions/wsActions';
import { WSHOST } from '../../config';

const WebSocketConnection = (props) => {
  const { children } = props;
  const dispatch = useDispatch();
  const token = useSelector((state) => state.session.tokenData && state.session.tokenData.access.token);
  const connection = useSelector((state) => state.ws.connection);
  useEffect(() => {
    if (!token) {
      wsDisconnect()(dispatch);
    } else if (connection.status === 'disconnect') {
      wsConnect({ host: `${WSHOST}?access=${token}` })(dispatch);
    }
  }, [token, connection]);

  return (<>{children}</>);
};

WebSocketConnection.propTypes = {
  children: PropTypes.node,
};

WebSocketConnection.defaultProps = {
  children: null,
};

export default WebSocketConnection;
