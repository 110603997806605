import React, { useState } from 'react';
import styled from 'styled-components';
import Select from '@material-ui/core/Select';
import { MenuItem } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import * as flags from '../../assets/img/flags';

const FLAGS = [
  { path: flags.ru, text: 'ru' },
  { path: flags.en, text: 'en' },
  { path: flags.es, text: 'es' },
  { path: flags.de, text: 'de' },
  { path: flags.it, text: 'it' },
  { path: flags.pt, text: 'pt' },
];

const Wrap = styled(Select)`
  width: 60px;
  .MuiSelect-select.MuiSelect-select {
    display: flex;
    align-items: center;
    gap: 4px;
    border-radius: 6px;
    color: ${(props) => props.color};
  }
  .MuiSelect-icon {
    color: ${(props) => props.color};
  }
  &:after {
    border: none !important;
  }
  &:before {
    border: none !important;
  }
`;

const Flag = styled.img`
  width: 17px;
  height: 17px;
  border-radius: 50%;
`;

const Item = styled(MenuItem)`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const LanguageSwitcher = (props) => {
  const { color } = props;
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(
    FLAGS.map(({ text }) => text).includes(i18n.language.toLowerCase())
      ? i18n.language.toLowerCase()
      : 'en',
  );

  const handleChange = (event) => {
    const language = event.target.value;
    setSelectedLanguage(language);
    i18n.changeLanguage(language);
  };
  return (
    <Wrap color={color} value={selectedLanguage} onChange={handleChange}>
      {FLAGS.map(({ path, text }) => (
        <Item value={text} key={text}>
          <Flag src={path} alt={text} />
          {text.toUpperCase()}
        </Item>
      ))}
    </Wrap>
  );
};

LanguageSwitcher.propTypes = {
  color: PropTypes.string,
};

LanguageSwitcher.defaultProps = {
  color: '#000',
};

export default LanguageSwitcher;
