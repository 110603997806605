import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import { Emojione } from 'react-emoji-render';
import 'video-react/dist/video-react.css';
import { Player } from 'video-react';
import {
  Typography,
} from '@material-ui/core';

const VideoContent = ({
  src,
  caption,
  size,
  poster,
  isPost,
}) => {
  const Video = styled(Player)`
    max-width: 100% !important;
    display: block;
    border-radius: 4px;
    overflow: hidden;
    focus: none;
    margin-bottom: 5px;
    ${props => props.isPost ? css`
      padding-top: 100% !important;
      z-index: 100;
    ` : css`
      padding-top: 56.25%;
    `}

    .video-react-big-play-button {
      left: 0 !important;
      top: 0 !important;
      right: 0;
      bottom: 0;
      margin: auto;
      width: 1.5em !important;
      border-radius: 50% !important;
    }


  `;

  return (
    <Typography color="inherit" variant="body1">
      {src && (
        <Video
          isPost={isPost}
          playsInline
          poster={poster}
          src={src}
        />
      )}
      <p>
        {size ? `${Math.trunc(size / 10485.76) / 100} Mb` : null}
      </p>
      {caption && (
        <>
          <Emojione size={64} svg text={caption} />
        </>
      )}
    </Typography>
  );
};

VideoContent.propTypes = {
  src: PropTypes.string,
  caption: PropTypes.string,
  size: PropTypes.number,
  poster: PropTypes.string,
  isPost: PropTypes.bool,
};

VideoContent.defaultProps = {
  src: '',
  caption: '',
  poster: null,
  size: 0,
  isPost: false,
};

export default VideoContent;
