import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { FlexDiv } from './FlexDiv';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

const LineChart = ({
  title = '',
  statisticDesc1 = '',
  statisticDesc2 = '',
  statisticQuantity2 = '',
  statisticQuantity1 = '',
  desc = '',
  footer = false,
  data = {},
  question = '',
  question2 = '',
  propCallbacks = {},
}) => {
  const ttCallback = propCallbacks?.tooltipCallback ? propCallbacks.tooltipCallback : {}
  const yTickCallback = propCallbacks?.yTicksCallback ? propCallbacks.yTicksCallback : {}
  const tooltipCallbacks = {
    labelColor: () => ({
      borderRadius: 4,
      border: '1px solid #FFFFFF',
      backgroundColor: data.datasets[0].backgroundColor,
      width: '13px',
      height: '13px',
    }),
    ...ttCallback
    ,
  };
  const options = {
    maintainAspectRatio: false,
    responsive: true,

    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: '10px',
          },
          color: 'rgba(127, 127, 127, 0.7)',
        },
      },
      y: {
        ticks: {
          ...yTickCallback,
          color: 'rgba(127, 127, 127, 0.7)',
          font: {
            size: '10px',
          },
        },
      },
    },
    plugins: {
      tooltip: {
        position: 'average',
        titleFont: {
          size: 12,
          weight: 600,
          lineHeight: '17px',
          family: 'Helvetica',
        },
        titleMarginBottom: 3,
        // yAlign: 'bottom',
        backgroundColor: 'rgba(0,0,0, .5)',
        borderRadius: 4,
        fontWeight: 400,
        fontSize: '12px',
        callbacks: tooltipCallbacks,
      },
      legend: {
        display: false,
      },
    },
  };
  return (
    <>
      <div className="lineChart-wrapper">
        {title && <h5 className="graph-title">{title}</h5>}
        <FlexDiv justify="space-between" cGap="4px">
          {statisticDesc1 && (
            <FlexDiv
              style={{ width: 'calc(50%-5px)' }}
              direction="column"
              rGap="2px"
              margin="0 0 24px 0"
            >
              <span className="statistic-quantity">{statisticQuantity1}</span>
              <span className="statistic-desc">
                {statisticDesc1}
                {question && <span className="statistic-question-mark">
                  <p className="statistic-question">{question}</p>
                </span>}
              </span>
            </FlexDiv>
          )}
          {statisticDesc2 && (
            <FlexDiv direction="column" rGap="2px" margin="0 0 24px 0">
              <span className="statistic-quantity">{statisticQuantity2}</span>
              <span className="statistic-desc">
                {statisticDesc2}
                {question2 &&<span className="statistic-question-mark">
                   (
                    <p className="statistic-question statistic-question2">
                      {question2}
                    </p>
                  )
                </span>}
              </span>
            </FlexDiv>
          )}
        </FlexDiv>
        {desc && <p className="graph-desc">{desc}</p>}
      </div>

      <div>
        <div
          style={{
            height: '233px',
            display: 'flex',
            border: '1px solid rgba(0, 0, 0, 0.16)',
            borderRadius: '4px',
            padding: '12px',
          }}
        >
          <Line height={233} options={options} data={data} />
        </div>
        {footer && (
          <FooterGraphDiv squareColor={data?.datasets[0]?.backgroundColor}>
            <div />
            <span>{data?.datasets[0]?.label}</span>
          </FooterGraphDiv>
        )}
      </div>
    </>
  );
};

LineChart.propTypes = {
  title: PropTypes.string.isRequired,
  statisticDesc1: PropTypes.string.isRequired,
  statisticDesc2: PropTypes.string.isRequired,
  statisticQuantity1: PropTypes.string.isRequired,
  statisticQuantity2: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  footer: PropTypes.bool.isRequired,
  data: PropTypes.object.isRequired,
  question: PropTypes.string.isRequired,
  question2: PropTypes.string.isRequired,
  propCallbacks: PropTypes.object.isRequired,
};

const FooterGraphDiv = styled.div`
  display: flex;
  align-items: center;
  margin-top: 14px;
  margin-bottom: 32px;
  justify-content: center;
  div {
    background: ${(props) => props.squareColor};
    border-radius: 4px;
    width: 13px;
    height: 13px;
    margin-right: 4px;
  }
  span {
    font-size: 12px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export default LineChart;
