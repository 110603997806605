import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FlexDiv } from './FlexDiv';
import { ReactComponent as CalendarSvg } from '../../../assets/img/calendar.svg';
// import { ReactComponent as ChatSvg } from '../../../assets/img/chat_bubble.svg';
// import { ReactComponent as ArrowSvg } from '../../../assets/img/headerArrow.svg';
import CalendarModal, {
  dateTransform,
} from '../../../components/CalendarModal/CalendarModal';
import * as calendarActions from '../../../store/actions/calendarActions';

const StyledStatFilters = styled.div`
  position: relative;
`;

const FilterItem = styled.button`
  display: flex;
  column-gap: 7px;
  background: #f3f4f5;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 0;
  border-radius: 8px;
  padding: 4px 4px 4px 10px;
  span {
    padding: 5px 8px 5px 8px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #383838;
    background: #eaebec;
    border-radius: 6px;
  }
`;

const StatFilters = () => {
  const { t } = useTranslation();
  const calendarCtx = useSelector((state) => state.calendar);
  const dispatch = useDispatch();
  return (
    <StyledStatFilters>
      <FlexDiv wrap="wrap" cGap="18px" rGap="18px">
        <FilterItem
          onClick={() => {
            dispatch(calendarActions.handleToggleCalendar());
          }}
        >
          <CalendarSvg />
          <span>
            {!calendarCtx.mainDate
              ? t('profileFilters.filter1')
              : `${dateTransform(
                  calendarCtx.mainDate.dateBefore,
                )} - ${dateTransform(calendarCtx.mainDate.dateAfter)}`}
          </span>
        </FilterItem>
        {/* <FilterItem>
          <ChatSvg />
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              columnGap: '7px',
            }}
          >
            {t('profileFilters.filter5')}
            <ArrowSvg />
          </span>
        </FilterItem> */}
      </FlexDiv>
      {calendarCtx.calendarIsOpen && <CalendarModal />}
    </StyledStatFilters>
  );
};

export default StatFilters;
