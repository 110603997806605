import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AssignmentReturned,
  ExpandLess,
  ExpandMore,
  Group,
  PeopleOutlineOutlined
} from '@material-ui/icons';
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  Collapse,
} from '@material-ui/core';

import PropTypes from 'prop-types';
import { getInitials, stringToColor } from 'utils/userHelper';

import ClearButton from './components/ClearButton';
import SelectFilter from './components/SelectFilter';
import { useStyles } from './styles';

const getDepartmentUserName = (user) => {
  const { name, surname, lastname } = user;
  if (!name && !surname && !lastname) return 'noname';
  return `${name} ${surname} ${lastname}`;
};

export default function DepartmentFilter({
  department,
  handleClear,
  handleSelect
}) {
  const { t } = useTranslation();
  const classes = useStyles();

  const [opened, setOpened] = useState({});
  const [listOpen, setListOpen] = useState();
  const user = useSelector((state) => state.session.user);
  const departments = useSelector((state) => state.session.departments);
  const selectedCompany = useSelector((state) => state.session.selectedCompany);
  const currentData = departments[selectedCompany];

  const handleDepartmentAssigneeClick = (uuidAssignee, assigneeType) => {
    handleSelect(uuidAssignee, assigneeType === 'personal' ? 'employee' : 'department');
    setListOpen(false);
  }

  const handleOpenClose = (open) => {
    setListOpen(open);
  }

  const handleClick = (key) => {
    const newObj = { ...opened };
    newObj[key] = !opened[key];
    setOpened(newObj);
  };

  const handleDepartmentClick = (e, departmentUuid) => {
    e.stopPropagation();
    handleDepartmentAssigneeClick(departmentUuid, 'group');
  };

  const getAssigneeName = (assignee) => {
    if (assignee) {
      const assigneeDepartments = Object.values(
        _.get(departments, selectedCompany, {}),
      );

      if (assignee.type === 'employee') {
        if (assignee.assignee === user.uuid) {
          return user.name;
        }

        const usersInCompany = assigneeDepartments
          .map(({ users }) => users)
          .flat();

        const finded = usersInCompany.find(
          ({ uuid }) => uuid === assignee.assignee,
        );
        if (!finded) return '';
        return `${finded.surname} ${finded.name}`;
      }

      if (assignee.type === 'department') {
        return _.get(
          departments,
          `${selectedCompany}.${assignee.assignee}.name`,
          '',
        );
      }
    }

    return t('searchPanel.byAssign');
  };

  if (!currentData) return null;
  return (
    <View className={classes.filter}>
      <PeopleOutlineOutlined className={classes.filter_icon} />
      <SelectFilter text={getAssigneeName(department)} open={listOpen} handleOpenClose={handleOpenClose}>
        <ListItem onClick={() => handleDepartmentAssigneeClick(user.uuid, 'personal')}>
          <Avatar
            className={classes.avatar}
            style={{ backgroundColor: '#d84444' }}>
            <AssignmentReturned fontSize="small" />
          </Avatar>
          <ListItemText primary={t(`searchPanel.toolbar.assignee.toMe`)} />
        </ListItem>
        {Object.keys(currentData).map((key) => (
          <Fragment key={key}>
            <ListItem
              onClick={() => handleClick(key)} style={{ minWidth: '400px' }}>
              <Avatar
                className={classes.avatar}
                style={{ backgroundColor: stringToColor(currentData[key].name) }}>
                <Group fontSize="small" />
              </Avatar>
              <ListItemText
                primary={currentData[key].name}
                secondary={t(`searchPanel.toolbar.assignee.departmentSubtitle`, { count: currentData[key].users.length })} />
              {opened[key] ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={opened[key]} timeout="auto" unmountOnExit>
              <List>
                <ListItem onClick={(e) => handleDepartmentClick(e, key)}>
                  <Avatar
                    className={classes.avatar}
                    style={{ backgroundColor: stringToColor(currentData[key].name) }}>
                    <AssignmentReturned fontSize="small" />
                  </Avatar>
                  <ListItemText primary={t(`searchPanel.toolbar.assignee.toDepartament`)} />
                </ListItem>
                {currentData[key].users.map((userItem) => (
                  <ListItem key={userItem.uuid} onClick={() => handleDepartmentAssigneeClick(userItem.uuid, 'personal')}>
                    <Avatar
                      className={classes.avatar}
                      style={{ backgroundColor: stringToColor(getDepartmentUserName(userItem)) }}>
                      {getInitials(getDepartmentUserName(userItem))}
                    </Avatar>
                    <ListItemText
                      primary={getDepartmentUserName(userItem)} />
                  </ListItem>
                ))}
              </List>
            </Collapse>
          </Fragment>))}
      </SelectFilter>
      <ClearButton handleClear={handleClear} />
    </View >
  );
}

DepartmentFilter.propTypes = {
  handleClear: PropTypes.func.isRequired,
  handleSelect: PropTypes.func.isRequired,
  department: PropTypes.object.isRequired
}


const View = styled.div`
  display: flex;
  align-items: center;
  .by-assign-button {
    height: 30px;
    border-radius: 6px;
    &:hover {
      background-color: #DDDFE3;
    }
  }
  .by-assign-button.employee {
    background-color: #e7c1ff6e;
    height: 25lpx;
    cursor: pointer;
  }
  .by-assign-button.department {
    background-color: #8df77aab;
    height: 25px;
    cursor: pointer;
  }
  .by-assign-button.me {
    background-color: #ff9494c7;
    cursor: pointer;
    height: 25px;
  }
`;