import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import AppIconButton from 'components/AppIconButton/AppIconButton';
import { ReactComponent as TimeUpArrow } from '../../../../../assets/img/time-up-arrow.svg';
import { ReactComponent as TimeDownArrow } from '../../../../../assets/img/time-down-arrow.svg';

interface TimePickerProps {
  minutes: number;
  setMinutes: (value: number) => void;
}

const leadingZeroFormat = (num: number) => (num < 10 ? `0${num}` : num);

const MINUTES_STEP = 5;
const HOUR_STEP = 60;
const MIN_MINUTES_VALUE = 0;
const MAX_MINUTES_VALUE = 1440;
const MINUTE_OPTIONS = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55]
const HOUR_OPTIONS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23]

const TimePicker: React.FC<TimePickerProps> = ({ minutes, setMinutes }) => {
  const hoursRef = useRef<HTMLDivElement | null>(null);
  const minutesRef = useRef<HTMLDivElement | null>(null);

  const [isVisibleHourOptions, setIsVisibleHourOptions] = useState<boolean>(false);
  const [isVisibleMinuteOptions, setIsVisibleMinuteOptions] = useState<boolean>(false);
  const handleToggleVisibilityMinuteOptions = () => minutes < MAX_MINUTES_VALUE && setIsVisibleMinuteOptions(prev => !prev);
  const handleToggleVisibilityHourOptions = () => setIsVisibleHourOptions(prev => !prev);


  const isIncreaseHoursDisabled = minutes + HOUR_STEP > MAX_MINUTES_VALUE;
  const isDecreaseHoursDisabled = minutes - HOUR_STEP < MIN_MINUTES_VALUE;
  const isIncreaseMinutesDisabled = minutes + MINUTES_STEP > MAX_MINUTES_VALUE;
  const isDecreaseMinutesDisabled = minutes - MINUTES_STEP < MIN_MINUTES_VALUE;

  const increment = (step: number) => {
    if ((minutes + step) > MAX_MINUTES_VALUE) {
      return;
    }

    // @ts-expect-error
    setMinutes((prev: number) => prev + step);
  }
  const decrement = (step: number) => {
    if ((minutes - step) < MIN_MINUTES_VALUE) {
      return;
    }

    // @ts-expect-error
    setMinutes((prev: number) => prev - step);
  }

  const handleMinuteIncrement = () => increment(MINUTES_STEP);
  const handleHourIncrement = () => increment(HOUR_STEP);
  const handleMinuteDecrement = () => decrement(MINUTES_STEP);
  const handleHourDecrement = () => decrement(HOUR_STEP);

  const handleHourChange = (hour: number) => {
    const selectedTime = hour * 60;
    if (selectedTime === (minutes / 60)) {
      return;
    }

    // prev % 60 - остаток минут
    // @ts-expect-error
    setMinutes(prev => selectedTime + prev % 60);

    handleToggleVisibilityHourOptions();
  }
  const handleMinuteChange = (minute: number) => {
    if (minute === (minutes % 60)) {
      return;
    }

    const prevHours = Math.floor(minutes / 60);
    setMinutes((prevHours * 60) + minute);

    handleToggleVisibilityMinuteOptions();
  }

  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (hoursRef.current && !event.composedPath().includes(hoursRef.current) && isVisibleHourOptions) {
        setIsVisibleHourOptions(false);
      }
      if (minutesRef.current && !event.composedPath().includes(minutesRef.current) && isVisibleMinuteOptions) {
        setIsVisibleMinuteOptions(false);
      }
    }

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [hoursRef, isVisibleHourOptions, minutesRef, isVisibleMinuteOptions]);

  return (
    <Wrapper>
      <Dropdown>
        <AppIconButton
          disabled={isIncreaseHoursDisabled}
          onClick={handleHourIncrement}
        >
          <TimeUpArrow style={{opacity: isIncreaseHoursDisabled ? 0.2 : 1}} />
        </AppIconButton>

        <SelectedOption onClick={handleToggleVisibilityHourOptions}>
          {leadingZeroFormat(Math.floor(minutes / 60))}
        </SelectedOption>
        {isVisibleHourOptions && (
          <Options ref={hoursRef}>
            {HOUR_OPTIONS.map((hour, index) => (
              <Option
                key={`hour-${hour}`}
                onClick={() => handleHourChange(hour)}
              >
                {leadingZeroFormat(hour)}
              </Option>
            ))}
          </Options>
        )}

        <AppIconButton
          disabled={isDecreaseHoursDisabled}
          onClick={handleHourDecrement}
        >
          <TimeDownArrow style={{opacity: isDecreaseHoursDisabled ? 0.2 : 1}} />
        </AppIconButton>
      </Dropdown>

      <Separator>:</Separator>

      <Dropdown>
        <AppIconButton
          disabled={isIncreaseMinutesDisabled}
          onClick={handleMinuteIncrement}
        >
          <TimeUpArrow style={{opacity: isIncreaseMinutesDisabled ? 0.2 : 1}} />
        </AppIconButton>
        <SelectedOption
          onClick={handleToggleVisibilityMinuteOptions}
        >
          {leadingZeroFormat(minutes % 60)}
        </SelectedOption>
        {isVisibleMinuteOptions && (
          <Options ref={minutesRef}>
            {MINUTE_OPTIONS.map((minute) => (
              <Option key={`minute${minute}`} onClick={() => handleMinuteChange(minute)}>
                {leadingZeroFormat(minute)}
              </Option>
            ))}
          </Options>
        )}
        <AppIconButton
          disabled={isDecreaseMinutesDisabled}
          onClick={handleMinuteDecrement}
        >
          <TimeDownArrow style={{opacity: isDecreaseMinutesDisabled ? 0.2 : 1}} />
        </AppIconButton>
      </Dropdown>
    </Wrapper>
  );
};

export default TimePicker;

const Wrapper = styled.div`
  display: flex;
  background: #f4f6f8;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
`;

const Dropdown = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
`;

const SelectedOption = styled.div`
  cursor: pointer;
  padding: 8px 10px;
  border: 1px solid #ccc;
  border-radius: 6px;
  background: white;
  width: 38px;
`;

const Options = styled.div`
  display: block;
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 5px;
  list-style-type: none;
  z-index: 500;
  top: 70px;
  border-radius: 8px;
  height: 140px;
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Option = styled.button`
  cursor: pointer;
  padding: 2px 5px;
  text-align: center;
  border: 0;
  background-color: transparent;

  &[disabled] {
    cursor: default;
  }
`;

const Separator = styled.span`
  margin: 0 5px;
`;
