import React, { useMemo } from 'react';

import _ from 'lodash';

import { useTranslation } from 'react-i18next';

import { useSelector, useDispatch } from 'react-redux';
import { inWorkDialogAdd, inWorkDialogClose } from 'store/actions';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';
import { Button } from '@material-ui/core';

const StyledButton = styled(Button)`
  color: #215FDC;
  font-family: "Noto Sans";
  font-size: 14px!important;
  font-style: normal;
  font-weight: 400;
  line-height: 110%;
  border-radius: 20px !important;
  border: 1px solid #215FDC;
  display: flex;
  height: 28px !important;
  padding: 4px 8px !important;
  margin-right: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: lowercase !important;
  white-space: nowrap !important;
`

function InWorkButton() {
  const routerParams = useParams();
  const searchedDialogs = useSelector((state) => state.chat.searchedDialogs);
  const dialog =
    useSelector((state) => state.chat.dialogs.find((d) => d.uuid === routerParams.uuid) || null)
    || searchedDialogs.find((d) => d.uuid === routerParams.uuid) || null;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.session.user);

  const uuid = React.useMemo(() => _.get(currentUser, "uuid", null), [currentUser]);
  const name = React.useMemo(() => _.get(currentUser, "name", uuid), [currentUser, uuid]);

  const type = useMemo(() => {
    const inWork = _.get(dialog, "additional.inWork", []);

    if (_.isEmpty(inWork)) {
      return "add";
    }

    if (inWork?.some((inWorkUuid) => inWorkUuid === uuid)) {
      return "close";
    }

    if (inWork?.some((inWorkUuid) => inWorkUuid !== uuid)) {
      return "join";
    }

    return "add";
  }, [dialog, uuid]);

  const joinTheInWorkDialog = React.useCallback(() => {
    dispatch(inWorkDialogAdd({ dialog, user: {
      uuid,
      name,
    }}));
  }, [dispatch, dialog, uuid, name]);

  const takeIntoWorkDialog = React.useCallback(() => {
    dialog.additional.inWork = [uuid];

    dispatch(inWorkDialogAdd({ dialog, user: {
      uuid,
      name,
    }}));
  }, [dialog, uuid, dispatch, name]);

  const handleDialogInWork = React.useCallback(() => {
    if (dialog.additional?.inWork?.length > 0) {
      joinTheInWorkDialog();
    } else {
      takeIntoWorkDialog();
    }
  }, [dialog.additional?.inWork?.length, joinTheInWorkDialog, takeIntoWorkDialog]);

  const handleDialogClose = React.useCallback(() => {
    dispatch(inWorkDialogClose({ dialog, user: {
      uuid,
      name,
    }}));
  }, [dispatch, dialog, uuid, name]);

  const color = useMemo(() => {
    switch (type) {
      case "close":
        return "#188055ff";
      case "add":
        return "#a31111ff";
      default:
        return "#215FDC";
    }
  }, [type]);

  if (!uuid || !dialog) {
    return null;
  }

  return (
    <StyledButton
      loading
      variant="outlined"
      color="secondary"
      onClick={type === "close" ? handleDialogClose : handleDialogInWork}
      style={{borderColor: color, color}}
    >
      {t(`conversation.inWork.${type}`)}
    </StyledButton>
  );
}

export default InWorkButton;
