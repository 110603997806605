import React from 'react';
import styled from 'styled-components';

import _ from 'lodash';
import PropTypes from 'prop-types';

import moment from 'moment';
import {Emojione} from 'react-emoji-render';
import {useDispatch, useSelector} from 'react-redux';
import {useHistory, useLocation} from 'react-router-dom';
import {getName} from 'utils/personeHelper';

import {setCurrentDialog} from 'store/actions';

import {ConversationListItemWrapper} from '../../ConversationList/components/ConversationListItem/ConversationListItem';


export default function PostPreview ({
  post,
  isActive,
}) {
  const dispatch = useDispatch();
  const {search} = useLocation();
  const routerHistory = useHistory();

  const storageFiles = useSelector(state => state.post.storageFiles);

  const handleOpen = () => {
    routerHistory.push(`/chat/post/${post.uuid}${search}`);
    dispatch(setCurrentDialog(post.uuid));
  };

  const getVideo = (msg) => {
    const {
      content: {
        data: { thumb, files },
      },
    } = msg;
    // TODO: Something wrong here
    if (!files) {
      return {
        src: null,
        loading: true,
      };
    }
    const src = _.get(files, '0.storage') === 'messenger'
      ? storageFiles[`file:${_.get(files, '0.fileUrl')}`]
      : _.get(files, '0.fileUrl');
    const thumbSrc = _.get(thumb, 'sizes[0].storage') === 'messenger'
      ? storageFiles[`file:${_.get(thumb, 'sizes[0].fileId')}`]
      : _.get(thumb, 'sizes[0].fileUrl');
    return {
      src,
      thumb: thumbSrc,
      loading: false,
    };
  };

  const renderAvatar = () => {
    const {type, data: {url, thumbnailUrl, items}} = post.content;
    if (thumbnailUrl) {
      return <div className="user-avatar" style={{backgroundImage: `url(${thumbnailUrl})`}}/>
    }
    switch (type) {
      case 'IMAGE': {
        if (items && items.length) {
          return <div className="user-avatar" style={{backgroundImage: `url(${items[0]})`}}/>
        }
        if (url) {
          return <div className="user-avatar" style={{backgroundImage: `url(${url})`}}/>
        }
        const sizes = _.get(post, 'content.data.sizes');
        const imageSource = sizes ? _.get(
          sizes.sort(
            (left, right) => left.size - right.size,
          ),
          '0.fileUrl',
          undefined,
        ) : null;
        return <div className="user-avatar" style={{backgroundImage: `url(${imageSource})`}}/>
      }
      case 'VIDEO': {
        const video = getVideo(post, storageFiles);
        if (video.thumb) {
          return <div className="user-avatar" style={{backgroundImage: `url(${video.thumb})`}}/>;
        }
        return (
          <TgThumb className="user-avatar">
            tg
          </TgThumb>
        );
      }
      default:
        return <div className="user-avatar" style={{backgroundImage: `url(${url})`}}/>
    }
  }
  const getLastMessageDate = () => {
    const {
      date: {updated},
    } = post;
    const ts = moment.unix(updated)
    if (ts.isSame(new Date(), 'day')) {
      return ts.format('HH:mm');
    }
    return ts.format('DD.MM.YY');
  };
  return (
    <View onClick={handleOpen} className={`${isActive ? 'active' : ''}`}>
      <div className='conversation'>
        <div className="conversation-avatar">
          <div
            className={`conversation-messenger-badge ${post.provider.messenger}`}
          />
          <div className="user-avatar">
            {renderAvatar()}
          </div>
        </div>
        <div className="conversation-preview">
        <div className="conversation-name">{getName(post.persone, true)}</div>
          <div className="conversation-text">
            {post.content.data?.caption &&
                <Emojione svg text={post.content.data.caption} />}
            {post.content.data?.text &&
                <Emojione svg text={post.content.data.text} />}
          </div>
        </div>
        <div style={{marginLeft: 'auto', position: 'relative'}}>
          <div className="conversation-time">
            {getLastMessageDate()}
          </div>
        </div>
      </div>
    </View>
  );
}

const View = styled(ConversationListItemWrapper)`
  .conversation {
    flex: 1;
  }
  .conversation-avatar {
    border-radius: 25%;
    .user-avatar {
      border-radius: 25%;
    }
  }
  .conversation-preview {
    display: flex;
    flex: 1;
    flex-direction: column;
  }
`;

const TgThumb = styled.div`
  background-color: #0088cc !important;
  color: white !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
`;

PostPreview.propTypes = {
  post: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
};