import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';

const AppIconButton = ({
  borderRadius = '4px',
  hoverColor = '',
  disabled = false,
  onClick,
  children,
  ...rest
}) => {
  return (
    <IconButton
      {...rest}
      disabled={disabled}
      sx={{
        borderRadius,
        '&:hover': { backgroundColor: hoverColor },
      }}
      onClick={onClick}
    >
      {children}
    </IconButton>
  );
};

AppIconButton.propTypes = {
  borderRadius: PropTypes.string,
  hoverColor: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
};
AppIconButton.defaultProps = {
  borderRadius: '4px',
  hoverColor: '',
  disabled: false,
  onClick: null,
  children: null,
};

export default AppIconButton;
