import React from 'react';
import styled from 'styled-components';

import _, { get } from 'lodash';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { List, makeStyles } from '@material-ui/core';
import { getMessengerFile, setStorageFiles } from 'store/actions';

import { Spinner } from 'components';
import { getName } from 'utils/personeHelper';

import PostPreview from './PostPreview';
import { SpinnerWrapper } from '../../ConversationList/ConversationList';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    overflowY: 'auto',
    position: 'relative',
    borderRight: '2px solid #eaeaea !important',
  },
  searchInput: {
    flexGrow: 1,
  },
}));

export default function PostListPreview({ className, onLoadMore }) {
  const classes = useStyles();
  const { postsLoading, posts, postsPagination, dialogsLoading, filters } =
    useSelector((state) => state.post);

  const dispatch = useDispatch();
  const currentDialog = useSelector((state) => state.session.currentDialog);
  const filesStore = useSelector((state) => state.files.files);
  const storageFiles = useSelector((state) => state.post.storageFiles);

  const [didMount, setDidMount] = React.useState(false);

  const getFile = (uuidProvider, fileId, recipient) =>
    getMessengerFile({
      uuidProvider,
      fileId,
      recipient,
    })(dispatch);

  React.useEffect(() => {
    setDidMount(true);
  }, []);

  React.useEffect(() => {
    if (!didMount) {
      (async function () {
        const promisesFiles = [];

        for (const post of posts) {
          // Load file from cache
          let entities = [];
          switch (post.content.type) {
            case 'IMAGE': {
              const {
                content: {
                  data: { sizes },
                },
              } = post;
              if (sizes) {
                entities = sizes;
              }
              break;
            }
            case 'VIDEO': {
              const {
                content: {
                  data: { thumb },
                },
              } = post;
              if (!thumb) continue;
              entities.push(thumb);
              break;
            }
            default:
              break;
          }
          for (const entity of entities) {
            if (entity.storage !== 'messenger') continue;
            promisesFiles.push(
              new Promise((resolve) => {
                // Get file link
                get(`file:${entity.fileId}`)
                  .then((file) => {
                    // Load file from messenger
                    const fileInStore = filesStore[entity.fileId];
                    if (!file && !fileInStore) {
                      getFile(post.ids.uuidProvider, entity.fileId, {});
                      resolve(null);
                      return;
                    }
                    resolve({ [`file:${entity.fileId}`]: file });
                  })
                  .catch(() => resolve(null));
              }),
            );
          }

          // Run files promises
          /* eslint-disable no-await-in-loop */
          const loadedFiles = await Promise.all(promisesFiles);

          let newFiles = {};
          loadedFiles.forEach((f) => {
            newFiles = { ...newFiles, ...f };
          });
          dispatch(setStorageFiles({ ...storageFiles, ...newFiles }));
          /*
          if (!firstScrollChange && pagination) {
            setFirstScrollChange(true);
          }
          */
        }
      })();
    }
  }, [posts, filesStore]);

  const handleListScroll = (event) => {
    const isScrolled =
      event.target.scrollHeight - Math.ceil(event.target.scrollTop) <=
      event.target.clientHeight;
    const havePages =
      postsPagination.count > postsPagination.page * postsPagination.size;

    if (isScrolled && havePages && !dialogsLoading) {
      onLoadMore();
    }
  };
  const getFilteredPosts = () =>
    posts.filter((post) => {
      if (filters.provider && filters.provider !== post.provider.messenger) {
        return false;
      }
      if (filters.search) {
        const text = _.get(
          post,
          'content.data.text',
          _.get(post, 'content.data.caption'),
        );
        const title = getName(post.persone, true).toLowerCase();
        const search = filters.search.toLowerCase();
        if (text) {
          const postText = text.toLowerCase();
          if (
            !search.includes(postText) &&
            !postText.includes(search) &&
            !title.includes(search) &&
            !search.includes(title)
          ) {
            return false;
          }
        } else if (!title.includes(search) && !search.includes(title)) {
          return false;
        }
      }
      return true;
    });

  return (
    <div className={clsx(classes.root, className)} onScroll={handleListScroll}>
      <List disablePadding>
        {getFilteredPosts().map((post, index) => (
          <PostPreview
            key={index}
            post={post}
            isActive={post.uuid === currentDialog}
          />
        ))}
      </List>
      {postsLoading ? (
        <SpinnerWrapper>
          <Spinner show />
        </SpinnerWrapper>
      ) : null}
      {!postsLoading && posts.length === 0 && (
        <EmptyMsg>Нет существующих постов</EmptyMsg>
      )}
    </div>
  );
}

PostListPreview.propTypes = {
  className: PropTypes.string,
  onLoadMore: PropTypes.func.isRequired,
};

PostListPreview.defaultProps = {
  className: '',
};

const EmptyMsg = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 200px;
  color: #757575;
`;
