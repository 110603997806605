import React from 'react';
import Divider from '@mui/material/Divider';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const HorizontalDivider = ({ text, dividerColor, children, ...rest }) => {
  return (
    <DividerWrap className="divider-wrap" {...rest}>
      {(text || children) && (
        <>
          <Divider className="shortDivider" color={dividerColor || '#F0F0F0'} />
          {!children && <span className="text">{text}</span>}
          {children}
        </>
      )}
      <Divider className="divider" color={dividerColor || '#F0F0F0'} />
    </DividerWrap>
  );
};

HorizontalDivider.propTypes = {
  text: PropTypes.string,
  dividerColor: PropTypes.string,
  children: PropTypes.node,
};
HorizontalDivider.defaultProps = {
  text: '',
  dividerColor: '',
  children: null,
};

export default HorizontalDivider;

const DividerWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;

  .divider {
    flex: 1;
  }

  .shortDivider {
    max-width: 21px;
    width: 100%;
  }

  .text {
    font-size: 12px;
    line-height: 16px;
    padding: 0 16px;
  }
`;
