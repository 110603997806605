import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Lottie } from '@crello/react-lottie';
import { Emojione } from 'react-emoji-render';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import IconButton from '@material-ui/core/IconButton';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { useDispatch, useStore } from 'react-redux';
import { uploadChatAttachment } from 'store/actions';

const DownloadWrapper = styled.div`
  position: relative;

  .download {
    position: absolute;
    right: 0;
  }
`;

const PictureContent = ({
  src,
  caption,
  loading,
  onOpenGallery,
  downloadUrl,
  fullWidth,
}) => {
  const dispatch = useDispatch();
  const store = useStore();
  const theme = useTheme();

  const Picture = styled.img`
    margin-top: ${theme.spacing(2)};
    height: auto;
    width: ${props => props.fullWidth ? '100%': '380px'};
    max-width: 100%;
    display: block;
  `;

  const loaderOptions = {
    loop: true,
    path: '/lottiefiles/fileLoader.json',
  };

  const handleDownload = () => {
    axios
      .get(downloadUrl, {
        responseType: 'blob',
        headers: { 'User-Agent':'Axios 0.21.1' },
      })
      .then((res) => {
        fileDownload(res.data, 'image.png');
      })
      .catch(() => {
        uploadChatAttachment(downloadUrl)(
          dispatch,
          store.getState,
        );
      });
  };

  return React.useMemo(
    () => (
      <Typography color="inherit" variant="body1" component="div">
        {(!src || loading) && (
          <>
            <Lottie config={loaderOptions} height={150} width={150} />
            <Typography color="inherit" variant="caption">
              Loading image...
            </Typography>
          </>
        )}
        {downloadUrl && (
          <DownloadWrapper>
            <div className="download">
              <IconButton aria-label="delete" onClick={handleDownload}>
                <GetAppIcon />
              </IconButton>
            </div>
          </DownloadWrapper>
        )}

        {src && <Picture onClick={onOpenGallery} alt="Attachment" src={src} fullWidth={fullWidth}/>}

        {caption && (
          <Emojione
            size={64}
            svg
            text={caption}
            style={{ wordBreak: 'break-word' }}
          />
        )}
      </Typography>
    ),
    [loading, src, caption],
  );
};
PictureContent.propTypes = {
  src: PropTypes.string,
  caption: PropTypes.string,
  loading: PropTypes.bool,
  onOpenGallery: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string.isRequired,
  fullWidth: PropTypes.bool,
};

PictureContent.defaultProps = {
  src: '',
  caption: '',
  loading: false,
  fullWidth: false,
};

export default PictureContent;
