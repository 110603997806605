import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';

const CustomInput = styled(TextField)({
  minWidth: '100px',

  '& input': {
    maxHeight: '48px',
    padding: '12px 16px',
  },

  '& label': {
    fontSize: '14px',
  },

  '& .MuiFormLabel-asterisk': {
    color: '#FF3B30',
  },

  '& label.Mui-focused': {
    color: '#A0AAB4',
  },

  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    borderRadius: 8,

    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
});

const SettingsInput = ({ label, value, helper, onChange, ...rest }) => {
  return (
    <CustomInput
      {...rest}
      InputProps={
        helper && {
          endAdornment: (
            <InputAdornment position="end">{helper}</InputAdornment>
          ),
        }
      }
      variant="outlined"
      label={label}
      value={value}
      onChange={onChange}
    />
  );
};

SettingsInput.propTypes = {
  helper: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
SettingsInput.defaultProps = {
  helper: '',
  label: '',
};

export default SettingsInput;
