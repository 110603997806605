import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog, Grid, Typography, IconButton,
} from '@material-ui/core';
import styled from 'styled-components';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GetAppIcon from '@material-ui/icons/GetApp';
import moment from 'moment';
import { get } from 'idb-keyval';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { gallerySetCurrent, galleryClose } from 'store/actions';
import { getName } from 'utils/personeHelper';

const DialogWrapper = styled(Dialog)`
  z-index: 1000000;
  overflow: hidden;
  background-color: transparent;
`;

const ImageWrapper = styled.img`
  max-height: 100%;
  max-width: 100%;
  width: auto;
  height: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 50px;
`;

const GridPreviewWrapper = styled(Grid)`
  position: absolute;
  bottom: 0;
  padding: 0px 20px 10px 20px;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  z-index: 100;
  padding: 20px;
  top: 0;
  right: 0;
`;

const ImageButtonSlider = styled.div`
  position: absolute;
  display: flex;
  height: 100%;
  justify-content: ${(props) => (props.position === 'left' ? 'flex-end' : 'flex-start')};
  ${(props) => (props.position === 'left' ? 'right:0;' : 'left:0;')}
  align-items: center;

  padding: 20px;
`;

const ImageContainerWrapper = styled.div``;

function ConversationGallery() {
  const dispatch = useDispatch();
  const { isOpen, images, current } = useSelector((state) => state.gallery);

  const [user, setUser] = React.useState();

  // eslint-disable-next-line no-unused-vars
  const imgSrc = React.useMemo(() => {
    if (images && images.length > 0) {
      return images.find((x) => x.uuid === current);
    }
    return false;
  }, [images.length, current]);
  const hasNext = React.useMemo(() => imgSrc?.idx < images.length - 1, [
    images.length,
    current,
  ]);
  const hasPrev = React.useMemo(() => imgSrc?.idx > 0, [images.length, current]);

  const handleNext = () => {
    if (hasNext) {
      dispatch(gallerySetCurrent(images[imgSrc?.idx + 1].uuid));
    }
  };
  const handlePrev = () => {
    if (hasPrev) {
      dispatch(gallerySetCurrent(images[imgSrc?.idx - 1].uuid));
    }
  };
  React.useEffect(() => {
    if (imgSrc) {
      get(`persone:${imgSrc.source.ids.uuidPersone}`).then((data) => {
        setUser(data);
      });
    }
  }, [images.length, current]);

  const handleDownload = () => {
    axios
      .get(imgSrc.fileUrl, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, 'image.png');
      });
  };
  const handleKeyPress = (e) => {
    if (e.key === 'ArrowRight' && hasNext) {
      handleNext();
    }
    if (e.key === 'ArrowLeft' && hasPrev) {
      handlePrev();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [handleKeyPress]);
  return (
    <div>
      <DialogWrapper
        fullScreen
        open={isOpen}
        onClose={() => {
          dispatch(galleryClose());
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <CloseButtonWrapper
          onClick={() => {
            dispatch(galleryClose());
          }}
        >
          <IconButton aria-label="delete" color="primary">
            <CancelIcon fontSize="large" />
          </IconButton>
        </CloseButtonWrapper>
        <>
          <Grid container justify="space-between" alignItems="stretch">
            <Grid item xs={1}>
              {hasPrev && (
                <ImageButtonSlider position="right" onClick={handlePrev}>
                  <IconButton aria-label="delete" color="primary">
                    <ArrowBackIosIcon fontSize="large" />
                  </IconButton>
                </ImageButtonSlider>
              )}
            </Grid>

            <Grid item xs={10}>
              <ImageContainerWrapper>
                {imgSrc && <ImageWrapper src={imgSrc.fileUrl} alt="wait image..." />}
              </ImageContainerWrapper>
            </Grid>

            <Grid item xs={1}>
              {hasNext && (
                <ImageButtonSlider position="left" onClick={handleNext}>
                  <IconButton aria-label="delete" color="primary">
                    <ArrowForwardIosIcon fontSize="large" />
                  </IconButton>
                  {/* <Icon className="slide"  /> */}
                </ImageButtonSlider>
              )}
            </Grid>
          </Grid>

          <GridPreviewWrapper container direction="row" justify="space-between">
            <Grid item>
              <Grid container direction="column">
                <Grid item>
                  <Typography>
                    {` Фото ${imgSrc?.idx + 1} из ${images.length}`}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    {user && getName(user)}
                    {'  '}
                    {imgSrc && moment(imgSrc.source.date.created).calendar()}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <IconButton aria-label="delete" onClick={handleDownload} color="primary">
                <GetAppIcon fontSize="large" />
              </IconButton>
            </Grid>
          </GridPreviewWrapper>
        </>
      </DialogWrapper>
    </div>
  );
}

export default ConversationGallery;
