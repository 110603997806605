/* eslint-disable no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { Emojione } from 'react-emoji-render';
import {
  Link,
  Typography,
  Avatar,
  Grid,
  makeStyles,
  createStyles,
} from '@material-ui/core';
import axios from 'axios';
import fileDownload from 'js-file-download';
import styled from 'styled-components';
import { useDispatch, useStore } from 'react-redux';
import { getFileSize } from 'utils/getFileSize';
import { FILE_TYPES, getFileType } from 'utils/getFileType';

const TypeImage = styled.div`
  background-image: url(/img/fileTypes/file.svg);
  width: 32px;
  height: 32px;
`;

const useStyles = makeStyles((theme) =>
  createStyles({
    selector: {
      cursor: 'pointer',
    },
    large: {
      backgroundColor: theme.palette.primary.main,
      width: theme.spacing(5),
      height: theme.spacing(5),
    },
    image: {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  }),
);

const DocumentContent = ({
  src,
  caption,
  fileName,
  size,
  openGallery,
  useShortName,
}) => {
  const dispatch = useDispatch();
  const store = useStore();

  const handleDownload = () => {
    axios
      .get(src, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, fileName);
      });
  };
  const classes = useStyles();
  const memoType = React.useMemo(() => getFileType(fileName), [fileName]);
  const memoSize = React.useMemo(() => getFileSize(size), [size]);
  const sourceWrapper = React.useMemo(() => {
    switch (memoType) {
      // case 'png':
      //   return (
      //     <Avatar
      //       className={classes.image}
      //       src={src}
      //       alt={fileName}
      //       onClick={openGallery}
      //     />
      //   );
      default:
        return (
          <TypeImage
            type={FILE_TYPES.includes(memoType) ? memoType : 'flv'}
            alt={memoType}
          />
        );
    }
  }, [src]);
  const shortName = React.useMemo(() => {
    const parts = fileName.split('.')[0];
    if (parts.length > 10) {
      return `${parts.slice(0, 10)}.${memoType}`;
    }
    return fileName;
  }, [fileName]);
  return (
    <Grid className={classes.selector} container direction="column">
      <Grid container alignItems="center" spacing={2}>
        <Grid item>{sourceWrapper}</Grid>
        <Grid item>
          <Link onClick={handleDownload} color="inherit">
            <Typography variant="body1">
              {useShortName ? shortName : fileName}
            </Typography>
            <Typography>{memoSize}</Typography>
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

DocumentContent.propTypes = {
  src: PropTypes.string,
  fileName: PropTypes.string,
  caption: PropTypes.string,
  size: PropTypes.number,
  openGallery: PropTypes.func,
  useShortName: PropTypes.bool,
};

DocumentContent.defaultProps = {
  src: '',
  fileName: '',
  caption: '',
  size: 0,
  useShortName: false,
  openGallery: undefined,
};

export default DocumentContent;
