/* eslint-disable */
import React, { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as IconVK } from '../../../../assets/img/messengers/vk.svg';
import { ReactComponent as IconTG } from '../../../../assets/img/messengers/telegram.svg';
import { ReactComponent as IconInst } from '../../../../assets/img/messengers/instagram.svg';

import AccountList from './Account/AccountList';

import ConversationSearchPanel from '../ConversationSearchPanel';


const providerFilter = [
	{ icon: <IconInst /> },
	{ icon: <IconVK /> },
	{ icon: <IconTG /> },
];


export default function PostPage() {
	const [filters, setFilters] = useState({
		provider: 0,
	});
	const handleSelectProvider = (index) => {
		setFilters({
			...filters,
			provider: index,
		})
	}
	return (
		<Page>
			<Filters>
				<div className='search-filter'>
					<ConversationSearchPanel withFilters={false} />
				</div>
				<div className='provider-filter'>
					{providerFilter.map(({ icon }, index) =>
						<div
							className={`provider-filter-item ${index === filters.provider ? 'selected' : ''}`}
							onClick={() => handleSelectProvider(index)}>
							<div className='provider-icon'>
								{icon}
							</div>
						</div>
					)}
				</div>
			</Filters>
			<AccountList />
		</Page>
	);
}

const Filters = styled.div`
	display: flex;
	gap: 85px;
  border-bottom: 2px solid #eaeaea;
	.search-filter {
		max-width: 350px;
	}
	.provider-filter {
		display: flex;
		align-items: center;
		gap: 5px;
	}
	.provider-filter-item {
		cursor: pointer;
		padding: 5px;
		&:hover svg {
			filter: grayscale(0%);
		}
		border-bottom: 1px solid transparent;
	}
	.provider-filter-item.selected {
		border-bottom: 1px solid #d2d2e8;
		border-radius: 10px;
		svg {
			filter: grayscale(0%);
		}
	}
	.provider-icon {
		width: 25px;
		height: 25px;
		font-size: 25px;
		svg {
			width: 100%;
			height: 100%;
			filter: grayscale(80%);
		}
	}
`;

const Page = styled.div`
	width: 100%;
	height: 100%;
`;