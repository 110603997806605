import React, { Suspense, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { LinearProgress } from '@material-ui/core';
import { useLocation } from 'react-router-dom';

import { TopBar, FilterBar, ToolsBar } from './components';

import { WebSocket } from '../../components';

import Modal from '../Modal';
import { useDispatch, useSelector } from 'react-redux';
import { setIsTabCountOnState, setSoundState } from '../../store/actions';
import { useTranslation } from 'react-i18next';

const useStyles = (appWidth) =>
  makeStyles(() => ({
    root: {
      height: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
    },
    topBar: {
      zIndex: 2,
      position: 'relative',
    },
    container: {
      display: 'flex',
      flex: '1 1 auto',
      overflow: 'hidden',
    },
    navBar: {
      zIndex: 3,
      width: 256,
      minWidth: 256,
      flex: '0 0 auto',
    },
    content: {
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      overflowY: 'auto',
      msOverflowStyle: 'none',
      scrollbarWidth: 'none',
      background: '#fff',
      flex: '1 1 auto',
      marginBottom: appWidth <= 960 ? 39 : 0,
    },
  }));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Dashboard = (props) => {
  const { children } = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [openFilterBar, setOpenFilterBar] = useState(false);
  const [appWidth, setAppWidth] = useState(window.innerWidth);
  const classes = useStyles(appWidth)();
  const query = useQuery();
  const dialogView = query.get('dialogView');
  const messageCounters = useSelector((state) => state.chat.countStat);
  const { isTabCountOn, sound } = useSelector((state) => state.settings);

  if (typeof isTabCountOn === 'undefined') {
    dispatch(setIsTabCountOnState(true));
  }
  if (typeof sound === 'undefined') {
    dispatch(
      setSoundState({
        isOn: true,
        offTimeMinutes: null,
        offTimeInitiatedDate: null,
      }),
    );
  }

  // Включение звука уведомлений по таймеру
  useEffect(() => {
    if (sound.offTimeMinutes && sound.offTimeInitiatedDate) {
      const currentTime = Date.now();
      const deltaMilliseconds = sound.offTimeMinutes
        ? sound.offTimeMinutes * 60 * 1000
        : 0;

      const timer = setTimeout(() => {
        dispatch(
          setSoundState({
            isOn: true,
            offTimeMinutes: null,
            offTimeInitiatedDate: null,
          }),
        );
      }, sound.offTimeInitiatedDate + deltaMilliseconds - currentTime);

      return () => clearTimeout(timer);
    }
  }, [sound.offTimeInitiatedDate]);

  useEffect(() => {
    messageCounters.unread && isTabCountOn
      ? (document.title = `${t('filterBar.favoriteFilters.unread')} (${
          messageCounters.unread
        }) | IntellectDialog`)
      : (document.title = 'IntellectDialog');
  }, [messageCounters]);

  useEffect(() => {
    const handleResize = () => setAppWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  const handleToggleFilterBar = () => {
    setOpenFilterBar(!openFilterBar);
  };

  const handleCloseFilterBar = () => {
    setOpenFilterBar(false);
  };

  return (
    <WebSocket>
      <div className={classes.root}>
        <Modal />
        {appWidth < 960 ? (
          <TopBar
            className={classes.topBar}
            handleToggleFilterBar={handleToggleFilterBar}
          />
        ) : null}
        <div className={classes.container} id="test">
          {!dialogView && (
            <FilterBar
              adaptive={appWidth <= 960}
              open={openFilterBar}
              handleCloseFilterBar={handleCloseFilterBar}
            />
          )}
          {/* <NavBar
            className={classes.navBar}
            onMobileClose={handleNavBarMobileClose}
            openMobile={openNavBarMobile}
          /> */}
          <main className={classes.content}>
            <Suspense fallback={<LinearProgress />}>{children}</Suspense>
          </main>
          <ToolsBar appWidth={appWidth} />
        </div>
      </div>
    </WebSocket>
  );
};

Dashboard.propTypes = {
  children: PropTypes.element.isRequired,
};

export default Dashboard;
