import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select from '../../../../../components/Select/Select';
import {
  DEFAULT_DEPARTMENT_SCHEDULE,
  DEFAULT_EMPLOYEE_DIALOG_COUNT_CONDITIONS,
  ROUTING_TARGET_DEPARTMENT_MAIN_SELECT_OPTIONS,
  ROUTING_TARGET_DEPARTMENTS_VALUES,
  ROUTING_TARGET_EMPLOYEE_MAIN_SELECT_OPTIONS,
  ROUTING_TARGET_EMPLOYEE_VALUES,
} from '../constants';
import HorizontalDivider from '../../components/HorizontalDivider';
import { ReactComponent as TrashIcon } from '../../../../../assets/img/trash-outlined.svg';
import TargetEmployeeActiveInputs from './TargetEmployeeActiveInputs';
import TargetEmployeeInCloseInputs from './TargetEmployeeInCloseInputs';
import TargetEmployeeInWorkInputs from './TargetEmployeeInWorkInputs';
import TargetEmployeeScheduleInputs from './TargetEmployeeScheduleInputs';
import TargetEmployeeDialogCountInputs from './TargetEmployeeDialogCountInputs';
import TargetDepartmentsSchedule from './TargetDepartmentsSchedule';
import AppButton from '../../../../../components/AppButton/AppButton';
import AppIconButton from '../../../../../components/AppIconButton/AppIconButton';
import TargetEmployeeOnlineInputs from './TargetEmployeeOnlineInputs';
import TargetEmployeeDepartmentInputs from './TargetEmployeeDepartmentInputs';

const TargetAndGroupConditions = ({
  isDepartment,
  andConditions,
  setAndConditions,
}) => {
  const { t } = useTranslation();

  const handleAndConditionAdd = () =>
    setAndConditions((prevValues) => [
      ...prevValues,
      [
        isDepartment
          ? {
              ...DEFAULT_DEPARTMENT_SCHEDULE,
            }
          : {
              ...DEFAULT_EMPLOYEE_DIALOG_COUNT_CONDITIONS,
            },
      ],
    ]);

  const handleAndConditionDelete = (groupIndex) =>
    setAndConditions((prevValues) =>
      prevValues.filter((_, index) => index !== groupIndex),
    );

  const handleOrConditionAdd = (groupIndex) =>
    setAndConditions((prevValues) => {
      const updatedGroups = [...prevValues];
      updatedGroups[groupIndex] = [...updatedGroups[groupIndex]];
      updatedGroups[groupIndex].push(
        isDepartment
          ? {
              ...DEFAULT_DEPARTMENT_SCHEDULE,
            }
          : {
              ...DEFAULT_EMPLOYEE_DIALOG_COUNT_CONDITIONS,
            },
      );

      return updatedGroups;
    });

  const handleOrConditionDelete = (groupIndex, conditionIndex) =>
    setAndConditions((prevValues) =>
      prevValues.map((group, index) => {
        if (index === groupIndex) {
          return group.filter((_, idx) => idx !== conditionIndex);
        }
        return group;
      }),
    );

  const handleOptionKeyChange = (groupIndex, conditionIndex, key, value) => {
    setAndConditions((prevValues) => {
      const updatedGroups = [...prevValues];
      updatedGroups[groupIndex] = [...updatedGroups[groupIndex]];
      updatedGroups[groupIndex][conditionIndex] = {
        ...updatedGroups[groupIndex][conditionIndex],
        [key]: value ?? {},
      };
      const prevKey = Object.keys(
        updatedGroups[groupIndex][conditionIndex],
      ).find((k) => k !== 'mainOption' && k !== key);

      delete updatedGroups[groupIndex][conditionIndex][prevKey];

      return updatedGroups;
    });
  };

  const handleOptionValueChange = (groupIndex, conditionIndex, key, value) => {
    setAndConditions((prevValues) => {
      const updatedGroups = [...prevValues];
      const currentObject = updatedGroups[groupIndex][conditionIndex];
      const updatedObject = { ...currentObject };
      const currentObjectKey = Object.keys(updatedObject).find(
        (k) => k !== 'mainOption',
      );
      updatedObject[currentObjectKey] = {
        ...updatedObject[currentObjectKey],
        [key]: value,
      };
      updatedGroups[groupIndex][conditionIndex] = updatedObject;

      return updatedGroups;
    });
  };

  return (
    <Body>
      {andConditions.map((orConditions, groupIndex) => (
        <>
          <section key={groupIndex} className="group">
            <div className="group-header">
              <span className="group-title">
                {t('routingPage.conditionsGroup')}
              </span>

              <div className="actions">
                <AppButton
                  type="secondary"
                  size="small"
                  onClick={() => handleAndConditionDelete(groupIndex)}
                >
                  {t('routingPage.deleteConditionsGroup')}
                </AppButton>
              </div>
            </div>

            {orConditions.map((orCondition, conditionIndex) => (
              <div key={conditionIndex} className="conditionRow">
                <div className="conditionTitle">
                  <span
                    className={`indicator${
                      conditionIndex !== 0 ? ' dashedIndicator' : ''
                    }`}
                  >
                    {conditionIndex === 0
                      ? t('routingPage.if')
                      : t('routingPage.or')}
                  </span>
                  <span className="subtext">{t('routingPage.condition')}</span>
                  {!isDepartment ? (
                    <Select
                      isFullWidth
                      sx={{ maxWidth: '340px' }}
                      label={t('routingPage.condition')}
                      options={ROUTING_TARGET_EMPLOYEE_MAIN_SELECT_OPTIONS}
                      defaultValue={
                        ROUTING_TARGET_EMPLOYEE_MAIN_SELECT_OPTIONS.find(
                          (o) => o.value === Object.keys(orCondition)[0],
                        ) ?? ROUTING_TARGET_EMPLOYEE_MAIN_SELECT_OPTIONS[0]
                      }
                      onChange={(e) =>
                        handleOptionKeyChange(
                          groupIndex,
                          conditionIndex,
                          e.target.value.value,
                        )
                      }
                    />
                  ) : (
                    <Select
                      isFullWidth
                      sx={{ maxWidth: '160px' }}
                      label={t('routingPage.condition')}
                      options={ROUTING_TARGET_DEPARTMENT_MAIN_SELECT_OPTIONS}
                      defaultValue={
                        ROUTING_TARGET_DEPARTMENT_MAIN_SELECT_OPTIONS.find(
                          (o) => o.value === Object.keys(orCondition)[0],
                        ) ?? ROUTING_TARGET_DEPARTMENT_MAIN_SELECT_OPTIONS[0]
                      }
                      onChange={(e) =>
                        handleOptionKeyChange(
                          groupIndex,
                          conditionIndex,
                          e.target.value.value,
                        )
                      }
                    />
                  )}
                  {orConditions.length > 1 && (
                    <AppIconButton
                      className="removeAction"
                      onClick={() =>
                        handleOrConditionDelete(groupIndex, conditionIndex)
                      }
                    >
                      <TrashIcon color="#FF3B30" />
                    </AppIconButton>
                  )}
                </div>

                <div className="conditionInputs">
                  {!isDepartment ? (
                    <>
                      {Object.keys(orCondition)[0] ===
                        ROUTING_TARGET_EMPLOYEE_VALUES.ACTIVE && (
                        <TargetEmployeeActiveInputs
                          orCondition={orCondition}
                          onValueChange={(key, value) =>
                            handleOptionValueChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                        />
                      )}
                      {Object.keys(orCondition)[0] ===
                        ROUTING_TARGET_EMPLOYEE_VALUES.ONLINE && (
                        <TargetEmployeeOnlineInputs
                          orCondition={orCondition}
                          onKeyChange={(key, value) =>
                            handleOptionKeyChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                        />
                      )}
                      {Object.keys(orCondition)[0] ===
                        ROUTING_TARGET_EMPLOYEE_VALUES.AVERAGE_CLOSE && (
                        <TargetEmployeeInCloseInputs
                          orCondition={orCondition}
                          onValueChange={(key, value) =>
                            handleOptionValueChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                        />
                      )}
                      {Object.keys(orCondition)[0] ===
                        ROUTING_TARGET_EMPLOYEE_VALUES.AVERAGE_IN_WORK && (
                        <TargetEmployeeInWorkInputs
                          orCondition={orCondition}
                          onValueChange={(key, value) =>
                            handleOptionValueChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                        />
                      )}
                      {Object.keys(orCondition)[0] ===
                        ROUTING_TARGET_EMPLOYEE_VALUES.SCHEDULE && (
                        <TargetEmployeeScheduleInputs
                          orCondition={orCondition}
                          onKeyChange={(key, value) =>
                            handleOptionKeyChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                          onValueChange={(key, value) =>
                            handleOptionValueChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                        />
                      )}
                      {Object.keys(orCondition)[0] ===
                        ROUTING_TARGET_EMPLOYEE_VALUES.DIALOG_COUNT && (
                        <TargetEmployeeDialogCountInputs
                          orCondition={orCondition}
                          onValueChange={(key, value) =>
                            handleOptionValueChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                        />
                      )}
                      {Object.keys(orCondition)[0] ===
                        ROUTING_TARGET_EMPLOYEE_VALUES.DEPARTMENT && (
                        <TargetEmployeeDepartmentInputs
                          orCondition={orCondition}
                          onValueChange={(key, value) =>
                            handleOptionValueChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                        />
                      )}
                    </>
                  ) : (
                    <>
                      {Object.keys(orCondition)[0] ===
                        ROUTING_TARGET_DEPARTMENTS_VALUES.SCHEDULE && (
                        <TargetDepartmentsSchedule
                          orCondition={orCondition}
                          onKeyChange={(key, value) =>
                            handleOptionKeyChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                          onValueChange={(key, value) =>
                            handleOptionValueChange(
                              groupIndex,
                              conditionIndex,
                              key,
                              value,
                            )
                          }
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            ))}

            <AppButton onClick={() => handleOrConditionAdd(groupIndex)}>
              {t('routingPage.addCondition')}
            </AppButton>
          </section>

          {andConditions.length > 0 &&
            andConditions.length !== groupIndex + 1 && (
              <HorizontalDivider text={t('routingPage.and')}>
                <span className="dividerBox">{t('routingPage.and')}</span>
              </HorizontalDivider>
            )}
        </>
      ))}

      <AppButton type="dashed" onClick={handleAndConditionAdd}>
        {t('routingPage.addConditionsGroup')}
      </AppButton>
    </Body>
  );
};

TargetAndGroupConditions.propTypes = {
  isDepartment: PropTypes.bool.isRequired,
  andConditions: PropTypes.object.isRequired,
  setAndConditions: PropTypes.func.isRequired,
};

export default TargetAndGroupConditions;

const Body = styled.section`
  padding: 16px 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;

  .dividerBox {
    color: #215fdc;
    padding: 6px 12px;
    border: 1px solid #dbdbdb;
    border-radius: 8px;
    min-width: 55px;
    text-align: center;
    font-size: 14px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 12px;
    border: 1px solid #dbdbdb;
    border-radius: 14px;
    padding: 6px;
  }

  .group-header {
    display: flex;
    align-items: center;
    column-gap: 4px;
    row-gap: 6px;
    flex-wrap: wrap;
    width: 100%;

    .actions {
      display: flex;
      align-items: center;
      column-gap: 12px;
      margin-left: auto;
    }
  }

  .group-title {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    color: #8b8c8d;
    padding: 6px 12px 0 12px;
  }

  .conditions {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 8px;
  }

  .conditionRow {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    column-gap: 4px;
    row-gap: 12px;
    width: 100%;
    border-radius: 10px;
    background-color: #ffffff;
    padding: 12px;

    .conditionTitle {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      column-gap: 20px;
      row-gap: 10px;
      margin-bottom: 6px;

      .indicator {
        padding: 12px;
        color: #ffffff;
        background-color: #006ae4;
        border-radius: 8px;
        font-size: 14px;
        line-height: 16px;
        text-transform: uppercase;
        text-align: center;
      }
      .dashedIndicator {
        border: 1px dashed #006ae4;
        background-color: transparent;
        color: #006ae4;
      }

      .subtext {
        font-size: 14px;
        line-height: 16px;
        color: #006ae4;
      }

      .removeAction {
        margin-left: auto;

        svg path {
          fill: #ff3b30;
        }
      }
    }

    .conditionInputs {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      column-gap: 10px;
      row-gap: 12px;
      width: 100%;
    }
  }
`;
