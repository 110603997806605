import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  dateRange: {from: new Date(Date.now() - 604800000), to: new Date()},
  provider: '',
  assigned: { assignee: null, type: 'all' },
  dialogs: [],
  filteredDialogs: [],
  searchedSubstring: '',
  company: null,
};
const statReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.TAG_FILTER_SET_DATE: {
      return update(state, {
        dateRange: { $set: payload },
      });
    }
    case actionTypes.TAG_FILTER_SET_PROVIDER: {
      return update(state, {
        provider: { $set: payload },
      });
    }
    case actionTypes.TAG_FILTER_SET_ASSIGNED: {
      return update(state, {
        assigned: { $set: payload },
      });
    }
    case actionTypes.TAG_STAT_GET_DIALOGS: {
      return update(state, {
        dialogs: { $set: payload },
      });
    }
    case actionTypes.TAG_STAT_SEARCH: {
      return update(state, {
        searchedSubstring: { $set: payload },
      });
    }
    case actionTypes.TAG_FILTER_SET_COMPANY: {
      return update(state, {
        company: { $set: payload },
      });
    }
    default: {
      return state;
    }
  }
};

export default statReducer;
