import React from 'react';
import PropTypes from 'prop-types';
import { ROUTING_RELATIONAL_OPERATOR_OPTIONS } from '../../constants';
import Select from '../../../../../components/Select/Select';
import DurationInputs from '../../components/DurationInputs';

const TargetEmployeeInWorkInputs = ({ orCondition, onValueChange }) => {
  const values = Object.values(orCondition)[0]

  return (
    <>
      <Select
        isFullWidth
        sx={{ maxWidth: '140px' }}
        label="Прошло времени"
        options={ROUTING_RELATIONAL_OPERATOR_OPTIONS}
        defaultValue={ROUTING_RELATIONAL_OPERATOR_OPTIONS.find(o => o.value === values.operator) ?? ROUTING_RELATIONAL_OPERATOR_OPTIONS[0]}
        onChange={(e) => onValueChange('operator', e.target.value.value)}
      />
       <DurationInputs
        inputStyles={{ maxWidth: '110px', minWidth: '80px' }}
        duration={values.duration}
        onChange={(duration) => onValueChange(duration.key, duration.value)}
       />
    </>
  );
};
TargetEmployeeInWorkInputs.propTypes = {
  orCondition: PropTypes.object,
  onValueChange: PropTypes.func,
};
TargetEmployeeInWorkInputs.defaultProps = {
  orCondition: {},
  onValueChange: null,
};
export default TargetEmployeeInWorkInputs;
