/* eslint-disable no-unused-vars */
import React from 'react';

import uuid from 'uuid';
import PropTypes from 'prop-types';
import { Tooltip, Menu, MenuItem } from '@material-ui/core';

function DropdownMenu({
  component: Component,
  idControl,
  startIcon,
  buttonText,
  menuItems,
}) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <Tooltip title={buttonText}>
        <Component
          aria-controls={idControl}
          aria-haspopup="true"
          startIcon={startIcon}
          onClick={handleClick}
        >
          {buttonText}
        </Component>
      </Tooltip>
      <Menu
        id={idControl}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {menuItems
          && menuItems.map((menuItem) => (
            <MenuItem
              onClick={(e) => {
                menuItems.onClick(e);
                handleClose(e);
              }}
            >
              {menuItem.text}
            </MenuItem>
          ))}
      </Menu>
    </>
  );
}
DropdownMenu.propTypes = {
  component: PropTypes.node.isRequired,
  menuItems: PropTypes.array,
  startIcon: PropTypes.node,
  idControl: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
};
DropdownMenu.defaultProps = {
  menuItems: [],
  startIcon: null,
  idControl: uuid.v4(),
};
export default DropdownMenu;
