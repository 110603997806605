import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import MuiRootDialog from './MuiRootDialog/MuiRootDialog';
import { hideModal } from '../../store/actions';
import HorizontalDivider from './Settings/components/HorizontalDivider';
import AppButton from '../../components/AppButton/AppButton';
import { ReactComponent as TrashIcon } from '../../assets/img/trash-outlined.svg';

const ConfirmRemoveDialog = ({ data }) => {
  const { t } = useTranslation();

  const { title, text, onConfirm } = data;

  const dispatch = useDispatch();

  const handleModalClose = () => {
    dispatch(hideModal());
  };

  return (
    <MuiRootDialog
      PaperProps={{
        style: {
          maxWidth: 320,
          width: '100%',
        },
      }}
      confirmButtonText={t('common.confirm')}
      handleClose={handleModalClose}
    >
      <Wrapper>
        <h4 className="title">{title}</h4>
        <p className="text">{text}</p>

        <HorizontalDivider style={{ marginBottom: '8px' }} />

        <div className="actions">
          <AppButton type="secondary" onClick={handleModalClose}>
            {t('common.cancel')}
          </AppButton>
          <AppButton className="removeButton withIcon" onClick={onConfirm}>
            <TrashIcon className="trashIcon" />
            <span>{t('common.delete')}</span>
          </AppButton>
        </div>
      </Wrapper>
    </MuiRootDialog>
  );
};

ConfirmRemoveDialog.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ConfirmRemoveDialog;

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  column-gap: 4px;

  .title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: #8b8c8d;
    margin-bottom: 12px;
  }

  .actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    margin-left: auto;
  }

  .trashIcon path {
    fill: #ffffff;
  }

  .removeButton {
    background-color: #ff3b30;
    border: 1px solid #ff3b30;
    color: #ffffff;
    padding-left: 20px;
  }
`;
