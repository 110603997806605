import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ChatIcon from '@material-ui/icons/Chat';
import { setPostMode } from 'store/actions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexGrow: 1,
  },
  inner: {
    textAlign: 'center',
  },
  title: {
    margin: theme.spacing(4, 0, 1, 0),
  },
}));

const ConversationPlaceholder = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const dialogsLoading = useSelector((state) => state.chat.dialogsLoading);

  const dispatch = useDispatch();
  const postMode = useSelector(state => state.settings.postMode);

  React.useEffect(() => {
    if (postMode) {
      dispatch(setPostMode(false));
    }
  }, [])

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        <ChatIcon fontSize="large" color="primary" />
        <Typography className={classes.title} variant="h4" style={{ fontWeight: 500 }}>
          {!dialogsLoading ? t('chatPlaceholder.title') : t('chatPlaceholder.loading')}
        </Typography>
        {!dialogsLoading
          ? (
            <Typography variant="subtitle1">
              {t('chatPlaceholder.description')}
            </Typography>
          )
          : null}
      </div>
    </div>
  );
};

export default ConversationPlaceholder;
