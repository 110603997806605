import React from 'react';
import ReactJson from 'react-json-view';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

function DebugView({ src, title }) {
  return (
    <div style={{ overflowY: 'scroll' }}>
      <Typography variant="h6" component="span">
        {title}
      </Typography>
      <ReactJson src={src} />

    </div>
  );
}
DebugView.propTypes = {
  src: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
};

export default DebugView;
