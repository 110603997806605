import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AddCircleIcon from '@material-ui/icons/AddCircle';

const AddListItem = styled.div`
    display: flex;
    align-items: center;
    color: #006ae4 !important;
    cursor: pointer;
    .MuiSvgIcon-root{
        color: #006ae4 !important;
        margin-right: 5px;
    }
`;

const AddButton = (props) => {
  const { t } = useTranslation();
  const {
    text = t('common.addButton'),
    style,
    onClick = () => false,
  } = props;
  return (
    <AddListItem button dense style={style} onClick={onClick}>
      <AddCircleIcon />
      {text}
    </AddListItem>
  );
};

AddButton.propTypes = {
  text: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
};

AddButton.defaultProps = {
  text: '',
  style: {},
  onClick: () => false,
};

export default AddButton;
