export { default } from './SettingsRouting';

export const SCRIPT_ACTION_OPTIONS = [
  {
    value: 'assignDialog',
    label: 'routingPage.assignToDialog', // Назначить на диалог
    description: 'routingPage.assignToDialogSubText',
    isDisabled: false,
  },
  {
    value: 'addToDialog',
    label: 'routingPage.addToDialog', // Добавить к диалогу
    description: 'routingPage.addToDialogSubtext',
    isDisabled: true,
  },
  {
    value: 'manageDialogAccess',
    label: 'routingPage.closeAccessToDialog', // Закрыть доступ к диалогу
    description: 'routingPage.closeAccessToDialogSubText',
    isDisabled: true,
  },
];
