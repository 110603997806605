/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import AccountListItem from './AccountListItem';
import { Posts } from '../Posts';


export default function AccountList() {
	const { posts } = useSelector(state => state.post);

	return (
		<Parent>
			<Accounts>
				{posts.map(({ provider, persone, date }) =>
					<AccountListItem
						date={date}
						persone={persone}
						provider={provider} />)}
			</Accounts>
			<Preview>
				<Posts />
			</Preview>
		</Parent>
	);
}

const Preview = styled.div`
	flex: 1;
`;

const Accounts = styled.div`
	width: 300px;
  border-right: 2px solid #eaeaea;
`;

const Parent = styled.div`
	display: flex;
`;