import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';

import { useDispatch, useSelector } from 'react-redux';
import { InsertComment } from '@material-ui/icons';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import {
  TasksTool, ProfileTool, ChannelsTool, TemplatesTool, AiTool,
} from './Tools';
// import TemplatesIcon from '@material-ui/icons/AddComment';
import { ReactComponent as PersonIcon } from '../../../../assets/img/persons.svg';
import { ReactComponent as ChannelsIcon } from '../../../../assets/img/phone.svg';
import { ReactComponent as AiIcon } from '../../../../assets/img/ai.svg';
import {
  handleOpenRightPanel,
  handleHideRightPanel,
  handleCloseRightPanel,
} from '../../../../store/actions';
import RightBar from './RightBar';
import BottomBar from './BottomBar';
import PopupBar from './PopupBar';

export const getMenuItems = (t) => {
  const menuItems = [
    {
      id: 'profile',
      icon: PersonIcon,
      component: ProfileTool,
    },
    {
      id: 'templates',
      icon: InsertComment,
      component: TemplatesTool,
    },
    // { id: 'subscriptions', icon: SubscriptionIcon },
    // { id: 'description', icon: TasksIcon },
    {
      id: 'channels',
      icon: ChannelsIcon,
      component: ChannelsTool,
    },
    {
      id: 'tasks',
      icon: DoneAllIcon,
      component: TasksTool,
    },
    {
      id: 'ai',
      icon: AiIcon,
      component: AiTool,
    },
    // { id: 'orders', icon: OrdersIcon },
    // { id: 'settings', icon: SettingsIcon },
  ];
  return menuItems.map((item, index) => ({
    idx: index,
    ...item,
    title: t(`toolsBar.${item.id}`),
  }));
};

const ToolsBar = (props) => {
  const { appWidth } = props;
  const { t } = useTranslation();
  const [open, setOpenState] = useState(false);

  // const [open,setOpenState] = useState(true);
  const currentItem = useSelector(state => state.rightPanel.item);
  const chatOpenId = useSelector((state) => state.ws.responseStatus?.['chat.dialogs.open']);
  const chatCloseId = useSelector((state) => state.ws.responseStatus?.['chat.dialogs.close']);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  // const [currentItem,setCurrentItem] = useState(getMenuItems(t)[0]);
  // TODO: refactor code because it's shit
  const menuItems = getMenuItems(t);
  const dispatch = useDispatch();
  // TODO: it's need to refactor code
  const handleMenuItemClick = (itemId) => {
    if (!open) {
      if (currentTimeout) {
        clearTimeout(currentTimeout);
        setCurrentTimeout(null);
      }
      setOpenState(true);
    }
    dispatch(handleOpenRightPanel(itemId));
  };

  useEffect(() => {
    if (!_.isEmpty(currentItem)) {
      handleMenuItemClick(currentItem);
    }
  }, [currentItem])

  const handleClosePopup = () => {
    setOpenState(false);
    if (currentItem?.id) {
      const timeout = setTimeout(() => {
        dispatch(handleCloseRightPanel());
      }, 1000);
      setCurrentTimeout(timeout);
      dispatch(handleHideRightPanel());
    }
  };
  useEffect(() => {
    if (chatCloseId || chatOpenId) handleClosePopup();
  }, [chatCloseId, chatOpenId])
  return (
    <>
      {appWidth > 960 ? (
        <RightBar
          open={open}
          menuItems={menuItems}
          currentItem={currentItem}
          handleMenuItemClick={handleMenuItemClick}
        />
      ) : (
        <BottomBar
          open={open}
          menuItems={menuItems}
          currentItem={currentItem}
          handleMenuItemClick={handleMenuItemClick}
        />
      )}
      <PopupBar
        appWidth={appWidth}
        open={open}
        content={currentItem.component || null}
        handleClosePopup={handleClosePopup}
      >
        {currentItem.component ? (
          <currentItem.component key={currentItem.idx} />
        ) : null}
      </PopupBar>
    </>
  );
};

ToolsBar.propTypes = {
  appWidth: PropTypes.number,
};

ToolsBar.defaultProps = {
  appWidth: 0,
};

export default ToolsBar;
