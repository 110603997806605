import React from "react";
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Confirm from "./Confirm";

const Modal = styled.div`
  background-color: #403f3f3b;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  overflow: auto;
  @media (max-width: 960px) {
    padding: 40px 24px;
    padding-top: 0px;
    .expand-icon {
      display: none;
    }
  }
  .error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: rgba(0, 0, 0, 0.3);
  }
`;

export default function ConfirmDialog({
    data,
    onClose,
}) {
    return (
        <Modal>
            <Confirm
                handleConfirm={data.handleConfirm}
                handleClose={onClose}
                data={data}/>
        </Modal>
    );
}

ConfirmDialog.propTypes = {
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object.isRequired,
};