import { useState } from 'react';
import styled, { css } from 'styled-components';

import PropTypes from 'prop-types';
import moment from 'moment';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { Tooltip } from '@material-ui/core';
import {
  Add,
  CallMade,
  CheckCircle,
  Close,
  Done,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { showModal } from 'store/actions';
import Tasks from './components/Tasks';

import { ReactComponent as TextIcon } from '../../../../../../assets/img/text.svg';
import { ReactComponent as RubIcon } from '../../../../../../assets/img/rub.svg';

export default function ContactsLeadsAccordion({
  contacts,
  leads,
  tasks,
  amoBaseUrl,
  phone,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [parentHover, setParentHover] = useState(true);
  const [opened, setOpened] = useState([]);
  const [leadOpened, setLeadOpened] = useState([]);
  const [openedCreate, setOpenedCreate] = useState([]);
  const [newLeads, setNewLeads] = useState([]);

  const getContactLeads = (contactLeadIds) => {
    const contactLeads = leads.filter((lead) =>
      contactLeadIds.includes(lead.id),
    );
    return contactLeads;
  };

  const handleHover = () => {
    setParentHover(false);
  };

  const handleMouseLeave = () => {
    setParentHover(true);
  };

  const handleOpen = (index) => {
    const i = opened.indexOf(index);
    if (i !== -1) {
      const newOpened = [...opened];
      newOpened.splice(i, 1);
      setOpened(newOpened);
    } else {
      setOpened([...opened, index]);
    }
  };
  const handleOpenLead = (index) => {
    const i = leadOpened.indexOf(index);
    if (i !== -1) {
      const newLeadOpened = [...leadOpened];
      newLeadOpened.splice(i, 1);
      setLeadOpened(newLeadOpened);
    } else {
      setLeadOpened([...leadOpened, index]);
    }
  };

  const handleOpenCreateForm = (index) => {
    if (!openedCreate.includes(index)) {
      setOpenedCreate([...openedCreate, index]);
    }
  };

  const handleCloseCreateForm = (index) => {
    const i = openedCreate.indexOf(index);
    if (i !== -1) {
      const newOpenedCreate = [...openedCreate];
      newOpenedCreate.splice(i, 1);
      setOpenedCreate(newOpenedCreate);
    }
  };

  const handleOpenInNewTab = (e, additional, id) => {
    window.open(`${amoBaseUrl}/${additional}/detail/${id}`, '_blank');
    e.stopPropagation();
  };

  const handleSetLead = (index, key, value) => {
    const newLeadIndex = newLeads.findIndex((lead) => lead.index === index);
    const newNewLeads = newLeads;
    if (newLeadIndex === -1) {
      newNewLeads.push({ index, [key]: value });
    } else {
      newNewLeads[newLeadIndex][key] = value;
    }
    setNewLeads(newNewLeads);
  };

  const handleCreateLead = (index, contactId) => {
    let data = newLeads.find((lead) => lead.index === index);
    if (data && data.name) {
      data = { ...data };
      delete data.index;
      window.parent.postMessage(
        {
          action: 'createLead',
          data: {
            data,
            phone,
            contactId,
          },
        },
        amoBaseUrl,
      );
    }
  };

  const handleCreateTask = (contactPhone, text, leadId, date) => {
    window.parent.postMessage(
      {
        action: 'createTask',
        data: {
          phone: contactPhone,
          text,
          leadId,
          date,
        },
      },
      amoBaseUrl,
    );
  };

  const handleCompleteTask = (contactPhone, id) => {
    dispatch(
      showModal('ConfirmDialog', {
        title: t('amo.confirm'),
        input: t('amo.result'),
        handleConfirm: (result) => {
          window.parent.postMessage(
            {
              action: 'completeTask',
              data: {
                phone: contactPhone,
                id,
                result,
              },
            },
            amoBaseUrl,
          );
        },
      }),
    );
  };

  return (
    <Wrapper>
      <div className="title">
        {t('amo.title')}
        <div className="amocrm">{t('amo.crm')}</div>
      </div>
      <div className="contacts">
        {contacts.map((contact, index) => (
          <Contact
            key={index}
            isHover={parentHover}
            isOpen={opened.includes(index)}
          >
            <div className="top" onClick={() => handleOpen(index)}>
              <div
                onClick={(e) => handleOpenInNewTab(e, 'contacts', contact.id)}
                className="info"
                onMouseEnter={handleHover}
                onMouseLeave={handleMouseLeave}
              >
                <div className="contact-name">{contact.name}</div>
                <CallMade className="open-icon" />
              </div>
              {opened.includes(index) ? (
                <ExpandLess className="expand-icon" />
              ) : (
                <ExpandMore className="expand-icon" />
              )}
            </div>
            <div className="animated-body">
              <div className="body">
                <div className="divider" />
                <div className="leads">
                  {getContactLeads(contact.leads).map((lead, i) => (
                    <Lead
                      key={i}
                      isHover={parentHover}
                      isOpen={leadOpened.includes(i)}
                    >
                      <div className="lead" onClick={() => handleOpenLead(i)}>
                        <div className="lead-header">
                          <div className="lead-status">
                            {!lead.open ? (
                              <Tooltip title={t('common.complete')}>
                                <CheckCircle className="complete-icon" />
                              </Tooltip>
                            ) : (
                              <div className="no-complete">•</div>
                            )}
                          </div>
                          <div
                            onMouseEnter={handleHover}
                            onMouseLeave={handleMouseLeave}
                            className="lead-info"
                            onClick={(e) =>
                              handleOpenInNewTab(e, 'leads', lead.id)
                            }
                          >
                            <div className="lead-info-main">
                              <text className="lead-name">
                                <text className="lead-price">
                                  {`${lead.price} ₽`}
                                </text>
                                <text className="point">•</text>
                                <text className="lead-text">{lead.name}</text>
                              </text>
                              <CallMade className="open-icon" />
                            </div>
                            <div className="date">
                              {moment(lead.date * 1000).format(
                                'HH:mm, D MMMM, YYYY',
                              )}
                            </div>
                          </div>
                        </div>
                        {leadOpened.includes(i) ? (
                          <ExpandLess className="expand-icon" />
                        ) : (
                          <ExpandMore className="expand-icon" />
                        )}
                      </div>
                      <div className="tasks">
                        <Tasks
                          phone={phone}
                          handleCreateTask={handleCreateTask}
                          handleCompleteTask={handleCompleteTask}
                          leadId={lead.id}
                          items={tasks.filter(
                            ({ leadId }) => leadId === lead.id,
                          )}
                        />
                      </div>
                    </Lead>
                  ))}
                </div>
                <CreateLead isOpen={openedCreate.includes(index)}>
                  <div className="create-lead">
                    <div className="create-lead-input">
                      <TextIcon />
                      <input
                        onChange={(e) =>
                          handleSetLead(index, 'name', e.target.value)
                        }
                        placeholder={t('amo.leadName')}
                      />
                    </div>
                    <div className="create-lead-input">
                      <RubIcon />
                      <input
                        type="number"
                        min="0"
                        onChange={(e) =>
                          handleSetLead(
                            index,
                            'price',
                            parseInt(e.target.value, 10) || 0,
                          )
                        }
                        placeholder={t('common.price')}
                      />
                    </div>
                    <div className="create-lead-actions">
                      <Close
                        className="clear-icon icon"
                        onClick={() => handleCloseCreateForm(index)}
                      />
                      <Done
                        className="done-icon icon"
                        onClick={() => handleCreateLead(index, contact.id)}
                      />
                    </div>
                  </div>
                </CreateLead>
                {!openedCreate.includes(index) && (
                  <div
                    onClick={() => handleOpenCreateForm(index)}
                    className="create-button"
                  >
                    <Add className="add-icon icon" />
                    {t('amo.createLead')}
                  </div>
                )}
              </div>
            </div>
          </Contact>
        ))}
      </div>
    </Wrapper>
  );
}

const CreateLead = styled.div`
  .create-lead-input {
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 6px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  input {
    outline: none;
    border: none;
    width: 100%;
  }
  .create-lead-actions {
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .done-icon {
    color: #006ae4;
  }
  .clear-icon {
    color: #7f7f7f;
  }
  .add-icon {
    color: #454545;
  }
  .create-lead {
    padding: 8px;
    margin-top: 6px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 6px;
    background: #ffffff;
  }
  overflow: hidden;
  transition: max-height 0.3s ease;
  ${({ isOpen }) =>
    isOpen
      ? css`
          max-height: 1000px;
        `
      : css`
          max-height: 0;
        `};
`;

const Lead = styled.div`
  .tasks {
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(0, 0.2, 0.4, 1);
    ${({ isOpen }) =>
      isOpen
        ? css`
            max-height: 1000px;
          `
        : css`
            max-height: 0;
          `};
  }
  .lead-info-main {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 6.6px;
  }
  .lead-status {
    width: 13.33px;
  }
  .complete-icon {
    margin-top: 1px;
    color: #03a000;
    height: 13.33px;
    width: 13.33px;
  }
  .no-complete {
    display: flex;
    justify-content: center;
  }
  .date {
    padding-top: 2px;
    font-size: 12px;
    line-height: 17px;
    color: #7f7f7f;
  }
  .lead-text {
    overflow: hidden;
  }
  .lead-name {
    overflow: hidden;
    font-size: 14px;
  }
  .lead-info {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  .point {
    color: #a6a6a6;
    padding: 0px 4px;
  }
  .lead-price {
    white-space: nowrap;
    font-weight: bold;
    font-size: 14px;
  }
  .lead-header {
    overflow: hidden;
    display: flex;
    gap: 7.33px;
    border-radius: 6px;
    padding: 4px 6px;
    &:hover {
      background: #e4e6ea;
    }
  }
  .lead {
    display: flex;
    justify-content: space-between;
    border-radius: 6px;
    padding: 6px 4px 4px 6px;
    &:hover {
      ${(props) => props.isHover && 'background: #E4E6EA'};
    }
  }
  gap: 6px;
  cursor: pointer;
`;

const Contact = styled.div`
  .divider {
    width: 100%;
    height: 1px;
    background-color: #e6e7e8;
    margin-bottom: 4px;
  }
  .icon {
    font-size: 14px;
    cursor: pointer;
  }
  .create-button {
    cursor: pointer;
    margin-top: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 9.33px;
    padding: 6px;
    background: #ffffff;
    border-radius: 6px;
    font-size: 14px;
    line-height: 19px;
  }
  .expand-icon {
    font-size: 14px;
    color: #7f7f7f;
  }
  .open-icon {
    color: #a4aabb;
    font-size: 12px;
    height: 17px;
    display: flex;
    align-items: center;
  }
  .contact-name {
    overflow: hidden;
  }
  .info {
    overflow: hidden;
    display: flex;
    align-items: center;
    gap: 6.6px;
    padding: 4px 6px;
    border-radius: 6px;
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    color: #202020;
    &:hover {
      background: #e4e6ea;
    }
  }
  .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px;
    border-radius: inherit;
    &:hover {
      ${(props) => props.isHover && 'background: #E4E6EA'};
    }
  }
  .animated-body {
    overflow: hidden;
    transition: max-height 0.4s cubic-bezier(0, 0.2, 0.4, 1);
    ${({ isOpen }) =>
      isOpen
        ? css`
            max-height: 1000px;
          `
        : css`
            max-height: 0;
          `};
  }
  .body {
    padding: 4px;
  }
  .leads {
    padding-right: 2px;
    max-height: 300px;
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(124, 124, 124, 0.5);
      border-radius: 4px;
    }
  }
  background: #f3f4f5;
  border-radius: 10px;
  overflow: hidden;
`;

const Wrapper = styled.div`
  cursor: default !important;
  .contacts {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  .title {
    font-weight: bold;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    gap: 12px;
  }
  .amocrm {
    padding: 3px 6px;
    gap: 10px;
    color: #fff;
    background: #3ecf8e;
    border-radius: 6px;
    font-size: 12px;
  }
`;

ContactsLeadsAccordion.propTypes = {
  contacts: PropTypes.array.isRequired,
  leads: PropTypes.array.isRequired,
  tasks: PropTypes.array.isRequired,
  amoBaseUrl: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
};
