import { makeStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) => ({
  filter: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px',
  },
  filter_icon: {
    color: '#a4aabb'
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
  avatarImg: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    fontSize: '1rem',
  }
}));