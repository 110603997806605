import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';

import { Field, Form } from 'react-final-form';
import ModalWrapper, { ActionButton, CancelButton } from '../layout';

import { TextField } from '../../../components/Fields';
import TextLargeField from './Fields/TextLargeField';
import NumField from './Fields/NumField';
import MonetaryField from './Fields/MonetaryField';
import PhoneField from './Fields/PhoneField';
import DateField from './Fields/DateField';
import TimeField from './Fields/TimeField';

// TODO перенести все в components/Fields

import {
  addNewPersonInfo,
  updatePersonInfo,
} from '../../../store/actions/chatActions';

const Wrapper = styled.form`
  font-size: 15px;
  .form-input {
    margin-top: 25px;
    label {
      display: block;
      margin-bottom: 10px;
      color: #7d8392;
    }
    .react-select__control {
      border-radius: 3px;
      border: solid 1px #eeeff2;
      background-color: #f6f8fc;
      color: #3f434c;
    }
  }
  .modal-error {
    color: red;
    margin: 15px 0;
  }
  .modal-buttons {
    margin-top: 25px;
    button:first-child {
      margin-right: 15px;
    }
  }
`;

// const validationSchema = () => yup.lazy((values) => {
//   const valObj = {
//     field: yup.string().min(1).required(),
//     type: yup.string().required(),
//     value: yup.string().min(1).required(),
//   };

//   if (values.type === 'email') {
//     valObj.value = yup.string().email().required();
//   }

//   if (values.type === 'phone') {
//     valObj.value = yup.string().min(11).required();
//   }

//   return yup.object().shape(valObj);
// });

const AddNewField = (props) => {
  const { data, onClose } = props;
  const {t} = useTranslation();

  const mockDataTypes = [
    { label: t('dataTypes.text'), value: 'text' },
    { label: t('dataTypes.largeText'), value: 'textLarge' },
    { label: t('dataTypes.number'), value: 'num' },
    { label: t('dataTypes.monetary'), value: 'monetary' },
    { label: t('dataTypes.email'), value: 'email' },
    { label: t('dataTypes.phone'), value: 'phone' },
    { label: t('dataTypes.date'), value: 'date' },
    { label: t('dataTypes.time'), value: 'time' },
  ];

  const dispatch = useDispatch();

  const handleSaveNote = (values) => {
    const action = !data.currentData ? addNewPersonInfo : updatePersonInfo;
    action({
      uuid: data.persone.uuid,
      field: values.field,
      type: values.type,
      value: values.value,
    })(dispatch);
    onClose();
  };

  // const formik = useFormik({
  //   initialValues: {
  //     field: data.currentData ? data.currentData.field : '',
  //     type: data.currentData ? data.currentData.type : mockDataTypes[0].value,
  //     value: data.currentData ? data.currentData.value : '',
  //   },
  //   onSubmit: handleSaveNote,
  //   validationSchema,
  // });

  // const {
  //   values, isValid, submitCount, handleSubmit, setFieldValue,
  // } = formik;

  const mapper = {
    text: TextField,
    emal: TextField,
    textLarge: TextLargeField,
    num: NumField,
    monetary: MonetaryField,
    phone: PhoneField,
    date: DateField,
    time: TimeField,
  };

  // const handleChangeType = (typeData) => {
  //   setFieldValue('value', '');
  //   setFieldValue('type', typeData.value);
  // };

  return (
    <ModalWrapper onClose={() => onClose()} title={t('newField.title')}>
      <Form
        initialValues={
          data.currentData
            ? data.currentData
            : { field: '', type: mockDataTypes[0].value, value: '' }
        }
        onSubmit={handleSaveNote}
        render={({ handleSubmit, invalid, values }) => (
          <Wrapper onSubmit={handleSubmit}>
            <Field name="field">
              {({ input }) => (
                <TextField
                  id="field"
                  label={t('newField.fieldName')}
                  {...input}
                  placeholder={t('newField.fieldName')}
                />
              )}
            </Field>

            <div className="form-input">
              <label htmlFor="dataType">{t('newField.dataType')}</label>
              <Field name="type">
                {({ input }) => (
                  <Select
                    classNamePrefix="react-select"
                    id="type"
                    options={mockDataTypes}
                    value={mockDataTypes.find((value) => value.value === input.value)}
                    onChange={(e) => input.onChange(e.value)}
                  />
                )}
              </Field>
            </div>
            <Field name="value">
              {({ input }) => (
                <>
                  {values.type === 'text' || values.type === 'email' ? (
                    React.createElement(mapper[values.type], {
                      id: 'newUserFieldValue',
                      placeholder: t('newField.fieldValue'),
                      label: t('newField.fieldValue'),
                      ...input,
                    })
                  ) : (
                    <div className="form-input">
                      <label htmlFor="value">{t('newField.fieldValue')}</label>
                      {React.createElement(mapper[values.type], {
                        id: 'newUserFieldValue',
                        ...input,
                      })}
                    </div>
                  )}
                </>
              )}
            </Field>

            {invalid ? (
              <div className="modal-error">Форма заполнена невалидно</div>
            ) : null}
            <div className="modal-buttons">
              <ActionButton type="submit" disabled={invalid}>
                {t('common.save')}
              </ActionButton>
              <CancelButton type="button" onClick={() => onClose()}>
                {t('common.cancel')}
              </CancelButton>
            </div>
          </Wrapper>
        )}
      />
    </ModalWrapper>
  );
};

AddNewField.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddNewField;
