import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const AppButton = ({
  type,
  size = '',
  withIcon = false,
  onClick,
  children,
  buttonType = 'button',
  ...rest
}) => {
  return (
    <StyledButton
      className={`${type} ${size || ''} ${withIcon ? 'withIcon' : ''}`}
      type={buttonType}
      {...rest}
      onClick={onClick}
    >
      {children}
    </StyledButton>
  );
};

AppButton.propTypes = {
  type: PropTypes.string,
  size: PropTypes.string,
  withIcon: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
  buttonType: PropTypes.string,
  rest: PropTypes.object,
};
AppButton.defaultProps = {
  type: 'common' || 'secondary' || 'dashed',
  size: '', // || 'small'
  withIcon: false,
  onClick: null,
  children: null,
  buttonType: 'button',
  rest: {},
};
export default AppButton;

const StyledButton = styled.button`
  border: 1px solid;
  padding: 11px 24px;
  border-radius: 8px;
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  cursor: pointer;

  &.common {
    background-color: #006ae4;
    color: #ffffff;
    border-color: #006ae4;

    &:disabled {
      background-color: #c2c2c2;
      border-color: transparent;
    }
  }

  &.secondary {
    color: #18191b;
    background-color: #ffffff;
    border-color: #dadce0;
  }

  &.dashed {
    background-color: transparent;
    color: #006ae4;
    border: 1px dashed #006ae4;
  }

  &.small {
    padding: 6px 12px;
    border-radius: 10px;
  }

  &.withIcon {
    display: flex;
    align-items: center;
    column-gap: 8px;
    padding-left: 12px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  @media (hover: hover) {
    &:hover:not(:disabled) {
      opacity: 0.7;
    }
  }

  @media (hover: none) {
    &:active:not(:disabled) {
      opacity: 0.7;
    }
  }
`;
