import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AppMultipleAutocomplete from '../../../../../components/AppMultipleAutocomplete/AppMultipleAutocomplete';

const DialogUserTagsInputs = ({ orCondition, onKeyChange }) => {
  const { t } = useTranslation();

  const { personTags } = useSelector((state) => state.chat);
  const [tags, setTags] = useState(orCondition.tags ?? []);

  useEffect(() => {
    onKeyChange('tags', tags);
  }, [tags]);

  return (
    <AppMultipleAutocomplete
      options={personTags}
      value={tags}
      defaultValue={orCondition.tags}
      inputLabel={t('routingPage.tags')}
      inputPlaceholder={t('routingPage.searchByTags')}
      onChange={(e, value) => setTags(value)}
    />
  );
};
DialogUserTagsInputs.propTypes = {
  orCondition: PropTypes.object,
  onKeyChange: PropTypes.func,
};
DialogUserTagsInputs.defaultProps = {
  orCondition: {},
  onKeyChange: null,
};
export default DialogUserTagsInputs;
