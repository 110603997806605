import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

const StatisticsLoading = () => {
  const { t } = useTranslation();
  const statisticsData = useSelector((state) => state.statistics);
  const {isLoading, dialogsCount} = statisticsData
  return (
    <>
      {isLoading && (
        <StatisticsLoadingDiv>
          <p>
            {t('graphInfo.loading', { dial: dialogsCount })}
          </p>
        </StatisticsLoadingDiv>
      )}
    </>
  );
};

const StatisticsLoadingDiv = styled.div`
  background: #f7f7f7;
  border-radius: 6px;
  padding: 7px 0;
  margin-bottom: 32px;
  p {
    text-align: center;
    font-size: 14px;
    color: #a6a6a6;
    font-family: 'Helvetica';
  }
`;

export default StatisticsLoading;
