import React from 'react';
import {
  Grid,
  Avatar,
  Paper,
  makeStyles,
  createStyles,
  IconButton,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import { chatUploadFileRemove } from 'store/actions';
import { FILE_IMAGE_TYPES } from 'utils/getFileType';
import * as selector from 'store/selectors';

const ImagePreviewContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  margin: 10px;
  padding-bottom: 50px;
  padding-right: 30px;
`;
const useStyles = makeStyles((theme) =>
  createStyles({
    large: {
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    grid: {
      position: 'relative',
    },
    fabProgress: {
      position: 'absolute',
      top: 4,
      left: 4,
      zIndex: 1,
    },
  }),
);

const TypeImage = styled.div`
  background-image: url(/img/fileTypes/${(props) => props.type}.svg);
  width: 32px;
  height: 32px;
`;

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

const typeMapper = (fileName) => fileName.split('.').reverse()[0];

const ConversationPreviewItem = ({ file, onDelete }) => {
  const classes = useStyles();

  const memoSource = React.useMemo(() => {
    if (file.src.type.indexOf('image') !== -1) {
      return URL.createObjectURL(file.src);
    }
    return '/';
  }, [file.uuid]);
  const memoType = React.useMemo(
    () => typeMapper(file.src.name),
    [file.src.name],
  );
  const memoSize = React.useMemo(() => formatBytes(file.src.size));
  const sourceWrapper = React.useMemo(() => {
    switch (file.src.type) {
      case 'image/png':
        return (
          <Avatar className={classes.large} src={memoSource} alt={memoType} />
        );
      default:
        return (
          <TypeImage
            type={FILE_IMAGE_TYPES.includes(memoType) ? memoType : 'file'}
            alt={memoType}
          />
        );
    }
  }, [file.uuid]);
  return (
    <Grid
      container
      alignItems="center"
      spacing={2}
      component={Paper}
      elevation={4}
    >
      <Grid item className={classes.grid}>
        {sourceWrapper}
      </Grid>
      <Grid item>
        <Grid container alignItems="center">
          <Grid item>
            <Typography variant="body1">
              {file.src.name.length > 25
                ? `${file.src.name.substring(0, 25)}...${memoType}`
                : file.src.name}
            </Typography>
            <Typography>{memoSize}</Typography>
          </Grid>
          <Grid item>
            <IconButton aria-label="delete" onClick={() => onDelete(file.uuid)}>
              <DeleteIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

ConversationPreviewItem.propTypes = {
  file: PropTypes.object.isRequired,
  onDelete: PropTypes.func.isRequired,
};

function ConversationPreview() {
  const selectedDialog = useSelector(selector.selectCurrentDialog);
  const dispatch = useDispatch();

  const handleRemoveFile = (file) => {
    dispatch(chatUploadFileRemove(selectedDialog, file));
  };
  const files = useSelector(
    (state) =>
      _.get(
        state.chat.dialogs.find((x) => x.uuid === selectedDialog),
        'files',
        [],
      ),
    (prev, next) => prev.length !== next.length,
  );

  return (
    <>
      {files && files.length > 0 && (
        <ImagePreviewContainer>
          <Grid container spacing={3} direction="column">
            {files &&
              files.length > 0 &&
              files.slice(0, 5).map((file, index) => (
                <Grid key={index} item>
                  <ConversationPreviewItem
                    file={file}
                    key={file.uuid}
                    onDelete={handleRemoveFile}
                  />
                </Grid>
              ))}
          </Grid>
        </ImagePreviewContainer>
      )}
    </>
  );
}

export default ConversationPreview;
