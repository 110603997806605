/* eslint-disable no-unused-vars */
import React from 'react';
import { Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import styled from 'styled-components';
import {
  postCreateTask, postDeleteTask, postUpdateTask,
  fetchTaskListCompany,
} from 'store/actions';
import * as selector from 'store/selectors';
import TaskTable from '../TaskTable';
import TaskDetailView from '../TaskDetailView';
import TaskToolBar from '../TaskToolBar';

const TaskDetailWrapper = styled.div`
width:100%;
background-color:#fff;
`;
export default function TaskDetails() {
  const [activeTask, setActiveTask] = React.useState(undefined);
  const [currentPage, setCurrentPage] = React.useState(0);
  const handleSelectTask = (task) => {
    setActiveTask(task);
  };
  const dispatch = useDispatch();
  // const handleTaskCreate = (task) => {
  //   dispatch(postCreateTask(task));
  // };
  const handleTaskUpdateStatus = (task, status) => {
    dispatch(postUpdateTask(
      {
        ...task,
        status: status === 'todo' ? 'done' : 'todo',
      },

    ));
  };
  const handleTaskUpdate = (task, status) => {
    if (status) {
      handleTaskUpdateStatus(task, status);
    } else {
      dispatch(postUpdateTask(task));
    }
    setActiveTask(undefined);
  };

  const handleTaskDelete = (task) => {
    dispatch(postDeleteTask(task));
    setActiveTask(undefined);
  };
  const selectedCompany = useSelector(selector.selectCurrentCompanyMemorized);
  const tasks = useSelector(selector.selectTaskListSelector);
  const taskTotalCount = useSelector(selector.selectTaskTotalCountSelector);
  const departments = useSelector(selector.selectCurrentDepartmentMemorized);

  const { t } = useTranslation();
  const handleCreateTask = (task, status) => {
    dispatch(postCreateTask({
      ...task,
      ids: { uuidCompany: selectedCompany },
    }));
    setActiveTask(undefined);
  };
  const handleChangePage = (event, newPage) => {
    dispatch(fetchTaskListCompany(selectedCompany, newPage + 1));
    setCurrentPage(newPage);
  };
  const handleChangePerPage = (perPage) => {
    dispatch(fetchTaskListCompany(selectedCompany, currentPage + 1, perPage));
  }
  React.useEffect(() => {
    dispatch(fetchTaskListCompany(selectedCompany, 1));
  }, [selectedCompany]);
  const { user } = useSelector((state) => state.session);

  const getDepartmentName = (assignee) => {
    if (assignee) {
      const assigneeDepartments = Object.values(
        _.get(departments, selectedCompany, {}),
      );
      if (assignee.type === 'employee') {
        // Get personal name

        const dep = assigneeDepartments.find((x) => x.users.map((usr) => usr.uuid).includes(assignee.uuid));
        if (dep) {
          return dep.name;
        }
      }
      if (assignee.type === 'department') {
        return _.get(
          departments,
          `${selectedCompany}.${assignee.uuid}.name`,
          '',
        );
      }
    }
    return null;
  };
  const getAssigneeName = (assignee) => {
    if (assignee) {
      const assigneeDepartments = Object.values(
        _.get(departments, selectedCompany, {}),
      );
      if (assignee.type === 'employee') {
        // Get personal name
        if (assignee.uuid === user.uuid) {
          return user.name;
        }
        // TODO: get users from store or api

        const usersInCompany = assigneeDepartments
          .map(({ users }) => users)
          .flat();

        const finded = usersInCompany.find(
          ({ uuid }) => uuid === assignee.uuid,
        );
        if (!finded) return '';
        return `${finded.surname} ${finded.name}`;
      }

      if (assignee.type === 'department') {
        return _.get(
          departments,
          `${selectedCompany}.${assignee.uuid}.name`,
          '',
        );
      }
    }

    return '';
  };
  return (
    <TaskDetailWrapper>
      <TaskTable
        currentPage={currentPage}
        onChangePage={handleChangePage}
        onChangePerPage={handleChangePerPage}
        getAssigneeDepartment={getDepartmentName}
        onSelectTask={handleSelectTask}
        onUpdateStatus={handleTaskUpdate}
        user={user}
        tasks={tasks}
        taskTotalCount={taskTotalCount}
        onDeleteTask={handleTaskDelete}
        getAssigneeName={getAssigneeName}
        departments={Object.values(
          _.get(departments, selectedCompany, {}),
        )}
      />

      {activeTask && (
        <TaskDetailView
          departments={departments}
          task={activeTask}
          onCreate={handleCreateTask}
          onUpdate={handleTaskUpdate}
          selectedCompany={selectedCompany}
          getAssigneeName={getAssigneeName}
          onClose={() => setActiveTask(undefined)}
          onCancel={() => setActiveTask(undefined)}
          onDelete={handleTaskDelete}
        />
      )}

    </TaskDetailWrapper>
  );
}
