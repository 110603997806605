import update from 'immutability-helper';
import * as modalTypes from '../actions';

const initialState = {
  show: false,
  type: null,
  data: null,
};

const modal = (state = initialState, action) => {
  switch (action.type) {
    case modalTypes.SHOW_MODAL:
      return update(state, {
        show: { $set: true },
        type: { $set: action.modalType },
        data: { $set: action.data },
      });

    case modalTypes.HIDE_MODAL:
      return update(state, {
        show: { $set: false },
        type: { $set: null },
        data: { $set: null },
      });

    default:
      return state;
  }
};

export default modal;
