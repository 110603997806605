import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
    width: inherit;

    label {
        font-size: 15px;
        color: #727887;
        margin-bottom: 10px;
    }

    textarea {
        font-family: "Roboto","Helvetica","Arial",sans-serif;
        min-height: 100px;
        border-radius: 3px;
        border: solid 1px #eeeff2;
        background-color: #f6f8fc;
        font-size: 15px;
        color: #2b2d33;
        padding: 10px 11px;
        outline: none;
        width: 100%;
    }
`;

const Textarea = (props) => {
  const { id, label, ...otherProps } = props;
  return (
    <Wrapper>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <textarea
        {...otherProps}
        id={id}
      />
    </Wrapper>
  );
};

Textarea.propTypes = {
  label: PropTypes.string,
  id: PropTypes.string,
};
Textarea.defaultProps = {
  label: undefined,
  id: 'textarea-uuid',
};

export default Textarea;
