import * as actionTypes from '../actions';

const initialState = {
  open: false,
  item: {},
};

const rightPanel = (state = initialState, action) => {
  const {type, payload} = action;
  switch (type) {
    case actionTypes.HANDLE_OPEN_RIGHT_PANEL:
      return { ...state, open: true, item: payload };
    case actionTypes.HANDLE_CLOSE_RIGHT_PANEL:
      return { ...state, item: {} };
    case actionTypes.HANDLE_HIDE_RIGHT_PANEL:
      return { ...state, open: false, item: {} };
    default:
      return state;
  }
};

export default rightPanel;
