import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Avatar,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
} from '@material-ui/core';
import { AccountCircleRounded } from '@material-ui/icons';
import * as selector from 'store/selectors';
import { getName } from 'utils/personeHelper';
import { FORBIDDEN_TAGS } from 'config';
import { Switcher, AddButton, Input, Textarea, Button } from 'components';
import {
  addNewPersonTag,
  removePersonTag,
  addNewPersonInfo,
  updatePersonInfo,
  closeDialog,
} from 'store/actions/chatActions';
import { showModal } from 'store/actions/modalActions';
import {
  addUserToSpamList,
  addUserToStopList,
  deleteUserFromSpamList,
  deleteUserFromStopList,
  getUsetInStoplist,
  getUsetInSpamlist,
} from 'store/actions';
import copy from 'copy-to-clipboard';
import GroupTool from './GroupTool';
import { ReactComponent as BanIcon } from '../../../../../assets/img/ban.svg';
import { ReactComponent as UnsubscribeOutlined } from '../../../../../assets/img/markSpam.svg';
import ContactsLeadsAccordion from '../components/ContactsDealsAccordion/ContactsLeadsAccordion';
import _ from 'lodash';

const Wrapper = styled.div`
  font-size: 15px;
  color: #26282d;
  padding: 32px 15px 64px 15px;
  .user-avatar {
    position: relative;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    background-color: #bdbdbd;
    background-image: url(${(props) => (props.image ? props.image : 'none')});
    background-size: contain;
    background-position: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiSvgIcon-root {
      width: 55%;
      height: 55%;
      color: #fff;
    }
  }
  .user-name {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    max-width: 75%;
    margin: 0 auto;
    margin-top: 24px;
  }

  .user-info {
    margin-top: 24px;
    > div {
      border-bottom: 1px solid #eeeff2;
      padding-bottom: 15px;
      margin-top: 15px;
      cursor: pointer;
      &:first-child {
        margin-top: 0;
      }
      div.label {
        display: block;
        font-weight: bold;
        margin-bottom: 5px;
        display: flex;
        .edit-button {
          width: 14px;
          height: 14px;
          background-image: url(/img/pencil.svg);
          background-repeat: no-repeat;
          background-size: contain;
          background-repeat: no-repeat;
          margin-left: 10px;
          display: none;
        }
      }
      &:hover {
        .edit-button {
          display: block !important;
        }
      }
    }
  }
  .bottom-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    > div {
      cursor: pointer;
    }
  }
  .remove-tag-button {
    color: red;
    margin-bottom: -5px;
    margin-left: 5px;
    cursor: pointer;
  }
  .profile-tag-list {
    display: flex;
    flex-wrap: wrap;
    row-gap: 5px;
  }
  .profile-tag {
    margin-right: 5px;
  }
  .user-button {
    display: flex;
    align-items: center;
    color: #ff0000;
    cursor: pointer;
    cursor: pointer;
    gap: 5px;
  }
  .mark-spam {
    color: #ff0000;
  }
  .user-button-icon {
    width: 21.5px;
    height: 21.5px;
  }
  .crm-button {
    color: blueviolet;
  }
  .info-val {
    overflow: hidden;
  }
  .copy-block {
    position: relative;
  }
  .copied {
    position: absolute;
    border-radius: 10px;
    top: 100%;
    padding: 5px 10px;
    left: 50%;
    color: #fff;
    z-index: 2;
  }
`;

const getInfoFieldString = (value, type) => {
  switch (type) {
    case 'date':
      return moment.unix(value).format('DD.MM.YYYY');
    case 'time':
      return moment.unix(value).format('HH:mm');
    default:
      return value;
  }
};

const NoteBlock = (props) => {
  const { t } = useTranslation();
  const { newNote, note, setAddNewNote, handleSaveNote, handleCancelNote } =
    props;
  if (newNote !== null) {
    return (
      <>
        <Textarea
          value={newNote}
          placeholder={t('profileTool.addNote')}
          onChange={(e) => setAddNewNote(e.target.value)}
        />
        <div>
          <Button
            type="button"
            className="primary"
            style={{ margin: '10px 10px 10px 0' }}
            disabled={!newNote}
            onClick={handleSaveNote}
          >
            {t('common.save')}
          </Button>
          <Button
            type="button"
            className="secondary"
            onClick={handleCancelNote}
          >
            {t('common.cancel')}
          </Button>
        </div>
      </>
    );
  }
  if (note) {
    return (
      <div style={{ cursor: 'pointer' }} onClick={() => setAddNewNote(note)}>
        <div className="label">{t('profileTool.note')}</div>
        {note}
      </div>
    );
  }

  return (
    <AddButton
      text={t('profileTool.addNewNote')}
      onClick={() => setAddNewNote('')}
    />
  );
};

NoteBlock.propTypes = {
  newNote: PropTypes.string,
  note: PropTypes.string,
  setAddNewNote: PropTypes.func,
  handleSaveNote: PropTypes.func,
  handleCancelNote: PropTypes.func,
};

NoteBlock.defaultProps = {
  newNote: '',
  note: '',
  setAddNewNote: () => false,
  handleSaveNote: () => false,
  handleCancelNote: () => false,
};

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const ProfileTool = () => {
  const query = useQuery();

  // crm params
  const isCRM = query.get('isCRM');
  const CRMUserId = query.get('CRMUserId');

  const isAmoCRM = query.get('isAmoCRM');
  const buttons = query.get('buttons');
  const { t } = useTranslation();
  const dialogs = useSelector((state) => state.chat.dialogs);
  const amoBaseUrl = useSelector((state) => state.chat.amoBaseUrl);
  const posts = useSelector((state) => state.post.posts);
  const selectedDialog = useSelector(selector.selectCurrentDialog);
  const userInStopList = useSelector((state) => state.person.inStopList);
  const userInSpamList = useSelector((state) => state.person.inSpamList);
  const postMode = useSelector((state) => state.settings.postMode);
  const [addTagMode, setAddTagMode] = useState(false);
  const [newTagValue, setNewTagValue] = useState('');
  const [crmButtons, setCrmButtons] = useState([]);
  const [newNote, setAddNewNote] = useState(null);
  const [confirm, setConfirm] = useState({ show: false });
  const [amoData, setAmoData] = useState({
    contacts: [],
    leads: [],
    tasks: [],
  });
  const [phone, setPhone] = useState();
  const [selectedKey, setSelectedKey] = useState('');

  const dispatch = useDispatch();

  const uuidUser = useMemo(() => {
    const dialog = dialogs.find((o) => o.uuid === selectedDialog);

    if (dialog) {
      const info = _.get(dialog, "persone.info");

      if (info) {
        const uuid = info.find((i) => i.key === "uuidUser");
  
        if (uuid) {
          return uuid;
        }
      }
    }

    return null;
  }, [dialogs, selectedDialog])

  // Функция для получения всех CRM полей
  const CRMFields = useMemo(() => {
    if (isCRM && CRMUserId && uuidUser && CRMUserId === uuidUser.val) {
      const fields = [];
      let index = 0;

      // Поиск всех параметров, начинающихся с CRMField
      while (query.has(`CRMField[${index}][name]`)) {
        fields.push({
          name: query.get(`CRMField[${index}][name]`),
          value: query.get(`CRMField[${index}][value]`),
        });
        index++;
      }

      return fields;
    }
    return null;
  }, [CRMUserId, isCRM, query, uuidUser]);

  useEffect(() => {
    if (isCRM && buttons && buttons.length) {
      const buttonList = [];
      buttons.split(';').forEach((params) => {
        const param = params.split(',');
        let button = {};
        if (param.length > 0) {
          button = { name: param[0], link: '' };
          if (param.length > 1) {
            button = { ...button, link: param[1] };
          }
          buttonList.push(button);
        }
      });
      setCrmButtons(buttonList);
    }
  }, [isCRM, buttons]);

  useEffect(() => {
    if (dialogs && selectedDialog) {
      const dialog = dialogs.find((o) => o.uuid === selectedDialog);
      if (dialog) {
        const {
          persone,
          type,
          ids: { uuidMessengerUser, uuidMessengerChat },
        } = dialog;
        if (persone) {
          if (uuidMessengerUser && type !== 'group') {
            dispatch(
              getUsetInStoplist(uuidMessengerUser, persone.ids.uuidCompany),
            );
            dispatch(
              getUsetInSpamlist(uuidMessengerUser, persone.ids.uuidCompany),
            );
          } else if (uuidMessengerChat && type === 'group') {
            dispatch(
              getUsetInStoplist(uuidMessengerChat, persone.ids.uuidCompany),
            );
            dispatch(
              getUsetInSpamlist(uuidMessengerChat, persone.ids.uuidCompany),
            );
          }
        }
      }
    }
  }, [selectedDialog]);

  if (!selectedDialog || !dialogs) return '';
  const dialog = !postMode
    ? dialogs.find((o) => o.uuid === selectedDialog)
    : posts.find((o) => o.uuid === selectedDialog);
  if (!dialog) return '';
  const { persone } = dialog;

  const { val: avatar = '/img/avatar-default.jpg' } =
    persone.info.find((o) => o.key === 'avatar') || {};
  const { val: note = '' } = persone.info.find((o) => o.key === 'note') || {};

  const AvatarPersone = styled(Avatar)`
    width: 155px !important;
    height: 155px !important;
  `;

  const handleNewTagCancel = () => {
    setAddTagMode(false);
    setNewTagValue('');
  };

  const handleNewTagAdd = () => {
    const tag = newTagValue;
    const containsForbiddenTag = FORBIDDEN_TAGS.some((forbiddenTag) =>
      tag.toLocaleLowerCase().includes(forbiddenTag),
    );
    if (containsForbiddenTag) {
      dispatch(closeDialog({ uuid: selectedDialog }));
    }
    addNewPersonTag({
      uuid: persone.uuid,
      tag,
    })(dispatch);
    handleNewTagCancel();
  };

  const handleRemoveTag = (tag) => {
    removePersonTag({
      uuid: persone.uuid,
      tag,
    })(dispatch);
  };

  const handleSaveNote = () => {
    const value = newNote;
    const action = !note ? addNewPersonInfo : updatePersonInfo;
    action({
      uuid: persone.uuid,
      field: 'note',
      type: 'text',
      value,
    })(dispatch);
    setAddNewNote(null);
  };

  const handleCancelNote = () => {
    setAddNewNote(null);
  };

  const handleAddNewField = () => {
    dispatch(showModal('AddNewField', { persone }));
  };

  const handleOpenCRM = (link) => {
    dispatch(showModal('CRM', { link }));
  };

  const handleEditField = (data) => {
    dispatch(showModal('AddNewField', { persone, currentData: data }));
  };

  const handleOpenStopConfirm = () => {
    setConfirm({
      show: true,
      title: userInStopList
        ? t('profileTool.deleteFromStopList')
        : t('profileTool.addToStopList'),
      callback: () => {
        if (dialog) {
          const {
            type,
            ids: { uuidMessengerUser, uuidMessengerChat },
          } = dialog;
          if (uuidMessengerUser && type !== 'group') {
            if (userInStopList) {
              dispatch(
                deleteUserFromStopList(
                  uuidMessengerUser,
                  persone.ids.uuidCompany,
                ),
              );
            } else {
              dispatch(
                addUserToStopList(uuidMessengerUser, persone.ids.uuidCompany),
              );
            }
          } else if (uuidMessengerChat && type === 'group') {
            if (userInStopList) {
              dispatch(
                deleteUserFromStopList(
                  uuidMessengerChat,
                  persone.ids.uuidCompany,
                ),
              );
            } else {
              dispatch(
                addUserToStopList(uuidMessengerChat, persone.ids.uuidCompany),
              );
            }
          }
        }
        setConfirm({ show: false });
      },
    });
  };
  const handleOpenSpamConfirm = () => {
    setConfirm({
      show: true,
      title: userInSpamList ? t('spamlist.delete') : t('spamlist.add'),
      callback: () => {
        if (dialog) {
          const {
            ids: { uuidMessengerUser },
          } = dialog;
          if (uuidMessengerUser) {
            if (userInSpamList) {
              dispatch(
                deleteUserFromSpamList(
                  uuidMessengerUser,
                  persone.ids.uuidCompany,
                ),
              );
            } else {
              dispatch(
                addUserToSpamList(uuidMessengerUser, persone.ids.uuidCompany),
              );
            }
          }
        }
        setConfirm({ show: false });
      },
    });
  };
  /*
  const handleUpdatePersoneName = (name) => {
    if (persone && persone.info) {
      const info = {
        uuid: persone.uuid,
        field: 'name',
        type: 'text',
        value: name,
      }
      const personeName = persone.info.find(({ key }) => key === 'name')
      if (personeName) {
        if (personeName.val !== name) {
          dispatch(updatePersoneName(info));
        }
      } else {
        dispatch(addPersoneName(info));
      }
    }
  }
  */

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isAmoCRM && amoBaseUrl && persone && persone.info) {
      const phoneObj = persone.info.find(({ key }) => key === 'phone');
      if (phoneObj && phoneObj.val) {
        setPhone(phoneObj.val);
        window.parent.postMessage(
          {
            action: 'searchContactsAndLeads',
            phone: phoneObj.val,
          },
          amoBaseUrl,
        );

        // Обработчик события message на iframe
        const handleMessage = (event) => {
          // Проверяем источник сообщения
          if (event.origin !== amoBaseUrl) {
            return;
          }
          // Проверяем, что это ответ на наш запрос
          if (event.data.action === 'searchContactsAndLeadsResponse') {
            // Обрабатываем полученные данные
            if (event.data.data) {
              const {
                data: {
                  contacts: responseContacts,
                  leads: responseLeads,
                  tasks: responseTasks,
                },
              } = event.data;
              if (responseContacts && responseLeads && responseTasks) {
                responseContacts.sort((a, b) => b.date - a.date);
                responseLeads.sort((a, b) => b.date - a.date);
                responseTasks.sort((a, b) => a.date - b.date);

                setAmoData({
                  contacts: responseContacts,
                  leads: responseLeads,
                  tasks: responseTasks,
                });

                if (responseContacts.length > 0) {
                  // handleUpdatePersoneName(responseContacts[responseContacts.length - 1].name);
                }
              }
            }
          }
        };

        window.addEventListener('message', handleMessage);

        // очистка события после размонтирования компонента
        return () => {
          window.removeEventListener('message', handleMessage);
        };
      }
    }
    return () => {};
  }, [selectedDialog]);
  const handleCopyClick = (key, val) => {
    copy(val);
    setSelectedKey(key);
    setTimeout(() => {
      setSelectedKey('');
    }, 1000);
  };
  const memorizedDialogType = dialog.type === 'group';

  const mainInfo = persone.info.filter((item) =>
    ['name', 'username', 'phone', 'uuidUser'].includes(item.key),
  );
  const secondaryInfo = persone.info.filter(
    (item) =>
      !['avatar', 'note', 'name', 'username', 'phone', 'uuidUser'].includes(
        item.key,
      ),
  );

  return memorizedDialogType ? (
    <GroupTool
      userInSpamList={userInSpamList}
      userInStopList={userInStopList}
      handleOpenSpamConfirm={handleOpenSpamConfirm}
      handleOpenStopConfirm={handleOpenStopConfirm}
      confirm={confirm}
      setConfirm={setConfirm}
    />
  ) : (
    <Wrapper>
      <Switcher
        backgroundImgForOn="/img/bot-switcher.svg"
        style={{ position: 'absolute', top: '20px' }}
      />
      <div className="user-avatar">
        <AvatarPersone src={avatar} />
      </div>
      <div className="user-name">{getName(persone)}</div>
      <div className="user-info">
        {mainInfo.map(({ key, val, type }) => (
          <div key={key}>
            <div className="label">
              {t(`profileTool.${key}`, key)}
              <span
                className="edit-button"
                onClick={() =>
                  handleEditField({ field: key, value: val, type })
                }
              />
            </div>
            <span
              className="copy-block"
              onClick={() => handleCopyClick(key, val)}
            >
              {selectedKey === key && (
                <span className="copied" style={{ backgroundColor: '#008000' }}>
                  {t('templatesTool.copied')}
                </span>
              )}
              {getInfoFieldString(val, type)}
            </span>
          </div>
        ))}
        {amoData.contacts && amoData.contacts.length > 0 && phone && (
          <ContactsLeadsAccordion
            phone={phone}
            amoBaseUrl={amoBaseUrl}
            contacts={amoData.contacts}
            tasks={amoData.tasks}
            leads={amoData.leads}
          />
        )}
        {secondaryInfo.map(({ key, val, type }, index) => (
          <div key={key}>
            <div className="label">
              {t(`profileTool.${key}`, key)}
              <span
                className="edit-button"
                onClick={() =>
                  handleEditField({ field: key, value: val, type })
                }
              />
            </div>
            <span
              className="copy-block info-val"
              onClick={() => handleCopyClick(key, val)}
            >
              {selectedKey === key && (
                <span className="copied" style={{ backgroundColor: '#008000' }}>
                  Скопировано
                </span>
              )}
              {getInfoFieldString(val, type)}
            </span>
          </div>
        ))}

        {!!CRMFields && CRMFields.length > 0 &&
          <div>
            <div className="label">Данные из CRM:</div>
            <div style={{display: "flex", flexDirection: "column", alignItems: "start"}}>
              {CRMFields.filter((crmField) => crmField.name && crmField.value).map((crmField, index) =>
                {
                  const key = `CRMFields.${index}.${crmField.name || ""}`;
                  return (
                    <div onClick={() => handleCopyClick(key, crmField.value)} key={key} style={{ display: "flex", gap: 3, position: 'relative' }}>
                      <p style={{ color: "gray", textWrap: "wrap", wordBreak: "break-all" }}>{crmField.name}:</p>
                      <p style={{ textWrap: "wrap", wordBreak: "break-all" }}>{crmField.value}</p>
                      {selectedKey === key && (
                        <span className="copied" style={{ backgroundColor: '#008000' }}>
                          Скопировано
                        </span>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        }
        <div>
          <div style={{ display: 'flex' }}>
            <div className="label">{t('profileTool.tags')}</div>
            {!addTagMode ? (
              <div style={{ marginLeft: 'auto' }}>
                <AddButton onClick={() => setAddTagMode(true)} />
              </div>
            ) : null}
          </div>
          {addTagMode ? (
            <>
              <Input
                value={newTagValue}
                placeholder={t('profileTool.addNewTag')}
                onChange={(e) =>
                  setNewTagValue(e.target.value?.replace(/[\/# ]/g, ''))
                }
              />
              <div>
                <Button
                  type="button"
                  className="primary"
                  style={{ margin: '10px 10px 10px 0' }}
                  disabled={!newTagValue}
                  onClick={handleNewTagAdd}
                >
                  {t('common.save')}
                </Button>
                <Button
                  type="button"
                  className="secondary"
                  onClick={handleNewTagCancel}
                >
                  {t('common.cancel')}
                </Button>
              </div>
            </>
          ) : null}

          <div className="profile-tag-list">
            {persone.tags.map((tag) => (
              <Chip
                key={tag}
                className="profile-tag"
                label={`#${tag}`}
                onDelete={() => handleRemoveTag(tag)}
              />
            ))}
          </div>
        </div>
        <div>
          <NoteBlock
            newNote={newNote}
            note={note}
            setAddNewNote={setAddNewNote}
            handleSaveNote={handleSaveNote}
            handleCancelNote={handleCancelNote}
          />
        </div>
        <div>
          <AddButton
            text={t('profileTool.addNewField')}
            onClick={handleAddNewField}
          />
        </div>
        {crmButtons.map(({ name, link }) => (
          <div
            key={name}
            className="user-button crm-button"
            onClick={() => handleOpenCRM(link)}
          >
            <AccountCircleRounded className="user-button-icon" />
            {name}
          </div>
        ))}
        <div className="user-button" onClick={handleOpenStopConfirm}>
          <BanIcon className="user-button-icon" />
          {userInStopList
            ? t('profileTool.deleteFromStopList')
            : t('profileTool.addToStopList')}
        </div>
        <div className="user-button mark-spam" onClick={handleOpenSpamConfirm}>
          <UnsubscribeOutlined className="user-button-icon" />
          {!userInSpamList ? t('spamlist.add') : t('spamlist.delete')}
        </div>
        <Dialog
          open={confirm.show}
          onClose={() => setConfirm({ show: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle id="alert-dialog-title">{confirm.title}</DialogTitle>
          <DialogActions>
            <Button
              type="button"
              className="primary"
              style={{ margin: '10px 10px 10px 0' }}
              onClick={() => confirm.callback()}
            >
              {t('common.confirm')}
            </Button>

            <Button
              type="button"
              className="secondary"
              onClick={() => setConfirm({ show: false })}
            >
              {t('templatesTool.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Wrapper>
  );
};

export default ProfileTool;
