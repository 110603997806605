import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { HighlightOff } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import PropTypes from 'prop-types';


export default function ClearButton({
  handleClear
}) {
  const { t } = useTranslation();
  return (
    <Tooltip
      title={t('common.clear')}>
      <View onClick={handleClear}>
        <div className="icon">
          <HighlightOff
            className="clear-icon"
            color="inherit" />
        </div>
      </View >
    </Tooltip>
  );
}

ClearButton.propTypes = {
  handleClear: PropTypes.func.isRequired
}

const View = styled.div`
  cursor: pointer;
  background-color: #e6e7e8;
  border-radius: 6px;
  padding: 6.67px;
  .icon {
    height: 16.67px;
    width: 16.67px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .clear-icon {
    color: #a4aaa4;
  }
`;