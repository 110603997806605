import axios from 'axios';
import { fetchRefreshToken, logout } from '../store/actions/sessionActions';

const createReq = ({
  method, url, body, formData,
}, tokens) => {
  const config = {
    method,
    url,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  if (body) config.data = JSON.stringify(body);

  if (formData) {
    config.data = formData;
    config.headers['Content-Type'] = 'multipart/form-data';
  }

  if (tokens) config.headers.Authorization = `Bearer ${tokens.access.token}`;

  return axios(config);
};

const apiFetch = ({
  dispatch, url, method, body, formData, callback, error, tokens,
}) => {
  const req = createReq({
    method, url, body, formData,
  }, tokens);
  return req.then((res) => {
    if (callback) callback(res.data);
  }).catch((err) => {
    if (err.response && err.response.request.status === 401) {
      // Update token if expired
      if (err.config.headers.Authorization) {
        dispatch(fetchRefreshToken());
      }
      // Logout if token failed to update
      if (body && body.grantBy === 'refresh') {
        dispatch(logout());
      }
    }

    if (error) error(err.response);
  });
};

export const apiAll = ({
  data, callback, error, tokens,
}) => {
  const reqs = data.map((elem) => createReq(elem, tokens));

  axios.all(reqs)
    .then(axios.spread((...result) => {
      if (callback) callback(result);
    })).catch((err) => {
      if (error) error(err.response && err.response.data && err.response.data.description);
    });
};

export const apiPost = (data) => apiFetch({ ...data, method: 'POST' });

export const apiGet = (data) => apiFetch({ ...data, method: 'GET' });

export const apiPut = (data) => apiFetch({ ...data, method: 'PUT' });

export const apiPatch = (data) => apiFetch({ ...data, method: 'PATCH' });

export const apiDelete = (data) => apiFetch({ ...data, method: 'DELETE' });
