import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import _ from 'lodash';
import styled from 'styled-components';
import moment from 'moment';
import { Tooltip, Typography } from '@material-ui/core';
import { isToday } from 'utils/dateHelper';

const MessageEventWrapper = styled.div`
  display: flex;
  justify-content: center;

  .content {
    margin-top: 10px;
    display: flex;
    div {
      font-size: 0.8rem;
    }
    flex-direction: column;

    span {
      text-align: right;
    }
  }
`;

const ConversationMessageEvent = ({ message }) => {
  const { t } = useTranslation();
  const eventType = _.get(message, 'content.data.type');

  const key = `dialog.${message.content.type}.${
    eventType === 'assignee' ? 'assignee.default' : eventType
  }`;
  if (_.get(message, 'content.data.params.uuidUser') === 'bot') {
    _.set(message, 'content.data.params.userName', 'bot');
  }

  const memorizedDate = React.useMemo(
    () => (isToday(message.date.created)
      ? `${t('common.date.today')} ${moment(message.date.created).format(
        'HH:mm',
      )}`
      : moment(message.date.created).format('HH:mm DD.MM')),
    [message.date.created],
  );

  return (
    <>
      <MessageEventWrapper>
        <div className="content">
          <Typography
            variant="body1"
            component="div"
          >
            { t(key, _.get(message, 'content.data.params')) }
          </Typography>
          <Tooltip title={moment(_.get(message, 'date.created')).fromNow()}>
            <Typography
              component="span"
              variant="body2"
            >
              {memorizedDate}
            </Typography>
          </Tooltip>

        </div>
      </MessageEventWrapper>
    </>
  );
};
ConversationMessageEvent.propTypes = {
  message: PropTypes.object.isRequired,
};
export default ConversationMessageEvent;
