import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Page } from 'components';
import { fetchCompanyProviders } from 'store/actions';
import { ReactComponent as FileDownloadSvg } from '../../assets/img/file_download.svg';
import Filters from './components/Filters';
import StatNav from './components/StatNav';
import { Wrap } from './StatPage';

const OperatorsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uuidCompany = useSelector((state) => state.session.selectedCompany);
  const company = useSelector((state) => state.stat.company);
  const [selectedCompany, setSelectedCompany] = useState(
    company || uuidCompany,
  );

  useEffect(() => {
    if (company) {
      setSelectedCompany(company);
      dispatch(fetchCompanyProviders({ uuidCompany: company }));
    }
  }, [company]);

  const testData = [
    {
      id: 1,
      operatorName: 'asdasdasdasdfjhasdkfjhaksdfhakdhjsfkajdhsfkjadhsf',
      averageAssessment: 4.67,
      workTime: t('time.timeMins', { min: 9, sec: 12 }),
      notWorkTime: t('time.timeHours', { hour: 24, min: 60, sec: 14 }),
      inQuestions: 629,
      solvedQuestions: 617,
    },
    {
      id: 2,
      operatorName: 'asdasdasdasdfjhasdkfjhaksdfhakdhjsfkajdhsfkjadhsf',
      averageAssessment: 0,
      workTime: t('time.timeMins', { min: 9, sec: 12 }),
      notWorkTime: t('time.timeHours', { hour: 24, min: 60, sec: 14 }),
      inQuestions: 629,
      solvedQuestions: 617,
    },
    {
      id: 3,
      operatorName: 'asdasdasdasdfjhasdkfjhaksdfhakdhjsfkajdhsfkjadhsf',
      averageAssessment: 3,
      workTime: t('time.timeMins', { min: 9, sec: 12 }),
      notWorkTime: t('time.timeHours', { hour: 24, min: 60, sec: 14 }),
      inQuestions: 629,
      solvedQuestions: 617,
    },
  ];

  return (
    <>
      <Page>
        <StatNav />
        <Wrap>
          <Filters selectedCompany={selectedCompany} />
          <SectionTitle>{t('operatorsPage.title')}</SectionTitle>
          <OperatorsList>
            <div className="list-header">
              <span>{t('operatorsPage.listHeader.col1')}</span>
              <span>{t('operatorsPage.listHeader.col2')}</span>
              <span>{t('operatorsPage.listHeader.col3')}</span>
              <span>{t('operatorsPage.listHeader.col4')}</span>
              <span>{t('operatorsPage.listHeader.col5')}</span>
              <span>{t('operatorsPage.listHeader.col6')}</span>
              <div className="svg">
                <FileDownloadSvg />
              </div>
            </div>
            {testData.map((data) => (
              <div className="row" key={data.id}>
                <span>{data.operatorName}</span>
                <span
                  className={
                    data.averageAssessment >= 4.01 &&
                    data.averageAssessment <= 5
                      ? 'green'
                      : data.averageAssessment >= 0 &&
                        data.averageAssessment <= 2
                      ? 'red'
                      : ''
                  }
                >
                  {data.averageAssessment}
                </span>
                <span>{data.workTime}</span>
                <span>{data.notWorkTime}</span>
                <span>{data.inQuestions}</span>
                <span>{data.solvedQuestions}</span>
                <span className="file-type">тип файла</span>
              </div>
            ))}
          </OperatorsList>
        </Wrap>
      </Page>
    </>
  );
};

const SectionTitle = styled.h4`
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-top: 36px;
  margin-bottom: 35px;
  line-height: 25px;
`;

const OperatorsList = styled.div`
  font-weight: 400;
  margin-top: 32px;
  .row,
  .list-header {
    display: grid;
    grid-template-columns: 2fr repeat(6, minmax(0, 1fr));
    grid-column-gap: 16px;
  }
  .row {
    padding: 11px 0;
    .file-type {
      padding: 5px 6px 7px 8px;
      background: #f3f4f5;
      border-radius: 8px;
      text-align: center;
      &:hover {
        background: #eaebec;
      }
    }
    span {
      color: #000000;
      font-size: 14px;
      line-height: 19px;
    }
    span:first-of-type {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span.green {
      color: #03a000;
    }

    span.red {
      color: #f23131;
    }

    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .list-header {
    font-size: 12px;
    line-height: 17px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 11px 0;
    color: rgba(0, 0, 0, 0.5);
  }

  .svg {
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
  }
`;

export default OperatorsPage;
