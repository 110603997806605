import _ from 'lodash';
import {
  apiDelete, apiGet, apiPost, apiPut,
} from '../../api';
import { HOST, TASK_SIZE } from '../../config';
import { dialogTaskListHasTodoSet } from './chatActions';

export const TASK_FETCHING = 'TASK_FETCHING';

export const TASK_CREATE_REQUEST_SUCCESS = 'TASK_CREATE_REQUEST_SUCCESS';
export const TASK_UPDATE_REQUEST_SUCCESS = 'TASK_UPDATE_REQUEST_SUCCESS';
export const TASK_DELETE_REQUEST_SUCCESS = 'TASK_DELETE_REQUEST_SUCCESS';

export const TASK_CREATE_REQUEST_FAILURE = 'TASK_CREATE_REQUEST_FAILURE';
export const TASK_UPDATE_REQUEST_FAILURE = 'TASK_UPDATE_REQUEST_FAILURE';
export const TASK_DELETE_REQUEST_FAILURE = 'TASK_DELETE_REQUEST_FAILURE';

export const TASK_LIST_SET = 'TASK_LIST_SET';
export const TASK_LIST_COUNT_SET = 'TASK_LIST_COUNT_SET';

export const DIALOG_TASK_LIST_SET = 'DIALOG_TASK_LIST_SET';
export const DIALOG_TASK_LIST_COUNT_SET = 'DIALOG_TASK_LIST_COUNT_SET'

export const TASK_SET = 'TASK_SET';
export const TASK_REMOVE = 'TASK_REMOVE';
export const TASK_UPDATE = 'TASK_UPDATE';

export const taskListCountSet = (payload) => ({
  type: TASK_LIST_COUNT_SET,
  payload,
});

export const dialogTaskListCountSet = (payload) => ({
  type: DIALOG_TASK_LIST_COUNT_SET,
  payload,
});

export const taskListSet = (payload) => ({
  type: TASK_LIST_SET,
  payload,
});

export const dialogTaskListSet = (payload) => ({
  type: DIALOG_TASK_LIST_SET,
  payload,
});

export const taskSet = (payload) => ({
  type: TASK_SET,
  payload,
});

export const taskRemove = (uuidTask) => ({
  type: TASK_REMOVE,
  payload: { uuidTask },
});

export const taskFetching = (fetchingState) => ({
  type: TASK_FETCHING,
  payload: { fetchingState },
});

export const taskUpdate = (payload) => ({
  type: TASK_UPDATE,
  payload,
});

/**
 * get task list of selected company
 * @param {string} uuidCompany  uuid of company
 * @returns
 */
export const fetchTaskListCompany = (uuidCompany, page, size) => async (
  dispatch,
  getState,
) => {
  apiPost({
    dispatch,
    url: `${HOST}/chat/tasks/company/${uuidCompany}`,
    tokens: getState().session.tokenData,
    body: { status: ['todo', 'done'], page, size: size || TASK_SIZE },
    callback: (res) => {
      dispatch(taskListSet(res.rows));
      dispatch(taskListCountSet(res.totalCount));
      dispatch(taskFetching(false));
    },
  });
};

export const fetchTaskByUuid = (uuidTask) => async (dispatch, getState) => {
  dispatch(taskFetching(true));
  apiGet({
    dispatch,
    url: `${HOST}/chat/tasks/${uuidTask}`,
    tokens: getState().session.tokenData,

    callback: (res) => {
      dispatch(taskSet(res));
      dispatch(taskFetching(false));
    },
  });
};

export const fetchTaskListDialog = (uuidDialog, page) => async (
  dispatch,
  getState,
) => {
  dispatch(taskFetching(true));
  apiPost({
    dispatch,
    url: `${HOST}/chat/tasks/dialog/${uuidDialog}`,
    tokens: getState().session.tokenData,
    body: { status: ['todo', 'done'], page, size: TASK_SIZE },
    callback: (res) => {
      dispatch(dialogTaskListSet(res.rows));
      dispatch(dialogTaskListCountSet(res.totalCount));
      dispatch(dialogTaskListCountSet(res.totalCount));
      dispatch(dialogTaskListHasTodoSet({
        uuidDialog,
        hasTodoTask: res.rows.some(item => item.status === "todo"),
      }));
      dispatch(taskFetching(false));
    },
  });
};

export const postCreateTask = (task) => async (dispatch, getState) => {
  dispatch(taskFetching(true));
  apiPost({
    dispatch,
    url: `${HOST}/chat/tasks`,
    tokens: getState().session.tokenData,
    body: task,
    callback: (res) => {
      dispatch(taskSet(res));
    },
  });
};

export const postDeleteTask = (task) => async (dispatch, getState) => {
  dispatch(taskFetching(true));
  apiDelete({
    dispatch,
    url: `${HOST}/chat/tasks/${task.uuid}`,
    tokens: getState().session.tokenData,
    body: {
      uuidCompany: _.get(task, 'ids.uuidCompany'),
      uuidAssignee: _.get(task, 'assignee.uuid'),
    },
    callback: () => {
      dispatch(taskRemove(task.uuid));
    },
  });
};

export const postUpdateTask = (task) => async (dispatch, getState) => {
  dispatch(taskFetching(true));
  apiPut({
    dispatch,
    url: `${HOST}/chat/tasks/${task.uuid}`,
    tokens: getState().session.tokenData,
    body: task,
    callback: (res) => {
      dispatch(fetchTaskByUuid(res.uuid));
    },
  });
};
