import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import SettingsInput from './SettingsInput';
import { validateAsPositiveFloatNumber } from '../../../../utils/inputValidators';

function calculateTimeFromMinutes(totalMinutes) {
  const days = Math.floor(totalMinutes / (24 * 60));
  const remainingMinutes = totalMinutes - days * 24 * 60;
  const hours = Math.floor(remainingMinutes / 60);
  const minutes = remainingMinutes % 60;

  return {
    days,
    hours,
    minutes,
  };
}

const DurationInputs = ({ inputStyles, duration, onChange }) => {
  const { t } = useTranslation();

  const {
    days: daysFromMinutes,
    hours: hoursFromMinutes,
    minutes: remainingMinutes,
  } = calculateTimeFromMinutes(duration);

  const [days, setDays] = useState(daysFromMinutes ?? 0);
  const [hours, setHours] = useState(hoursFromMinutes ?? 0);
  const [minutes, setMinutes] = useState(remainingMinutes ?? 0);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === 'day') {
      validateAsPositiveFloatNumber(value, setDays);
    } else if (name === 'hour') {
      validateAsPositiveFloatNumber(value, setHours);
    } else if (name === 'minute') {
      validateAsPositiveFloatNumber(value, setMinutes);
    }
  };

  useEffect(() => {
    const durationInMinutes = +days * 24 * 60 + +hours * 60 + +minutes;

    onChange({ key: 'duration', value: durationInMinutes });
  }, [days, hours, minutes]);

  return (
    <Wrapper>
      <SettingsInput
        sx={inputStyles}
        name="day"
        helper={t('routingPage.dayShort')}
        value={days}
        onChange={handleInputChange}
      />
      <SettingsInput
        sx={inputStyles}
        name="hour"
        helper={t('routingPage.hourShort')}
        value={hours}
        onChange={handleInputChange}
      />
      <SettingsInput
        sx={inputStyles}
        name="minute"
        helper={t('routingPage.minuteShort')}
        value={minutes}
        onChange={handleInputChange}
      />
    </Wrapper>
  );
};

DurationInputs.propTypes = {
  inputStyles: PropTypes.object,
  duration: PropTypes.number,
  onChange: PropTypes.func.isRequired,
};
DurationInputs.defaultProps = {
  inputStyles: {},
  duration: 0,
};

export default DurationInputs;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
`;
