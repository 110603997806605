import { Howl, Howler } from 'howler';

const sound = new Howl({
  src: ['/sounds/notify.mp3'],
});
Howler.volume(0.9);

export const play = () => {
  sound.play();
};
