import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import _ from 'lodash';
import { get } from 'idb-keyval';
import { useDispatch, useSelector } from 'react-redux';
import { Avatar } from '@material-ui/core';
import { setChatMessage } from 'store/actions';
import genAvatar from 'utils/genAvatar';
import { getName } from 'utils/personeHelper';

export default function UsersTag({ setMentionUsers, conversation }) {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.chat.message);
  const [users, setUsers] = React.useState([]);
  React.useEffect(() => {
    Promise.all(
      _.get(conversation, 'participants', []).map((user) =>
        get(`persone:${user}`),
      ),
    ).then((data) => {
      const mentionUsers = [];
      data.forEach((user) => {
        if (user) {
          let id = user.info.find((o) => o.key === 'uuidUser');
          if (id) {
            id = id.val;
            const notUnique = mentionUsers.find(
              (addedUser) => addedUser.id === id,
            );
            if (!notUnique) {
              mentionUsers.push({
                avatar: _.get(user, 'info.4.val', genAvatar(user.uuid)),
                id,
                name: getName(user),
              });
            }
          }
        }
      });
      setMentionUsers(mentionUsers);
      setUsers(mentionUsers);
    });
  }, [conversation]);

  const handleMention = (name) => {
    dispatch(setChatMessage(`${message}${name} `));
  };

  return (
    <User>
      <div className="users">
        {users &&
          users.map(({ avatar, name }, index) => (
            <div
              key={index}
              className="user"
              onClick={() => handleMention(name)}
            >
              <Avatar className="avatar" alt={name} src={avatar} />
              {name}
            </div>
          ))}
      </div>
    </User>
  );
}

const User = styled.div`
  position: relative;
  .users {
    position: absolute;
    bottom: 0px;
    z-index: 2;
    width: 100%;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 4px;
    }
    max-height: 120px;
    overflow-y: auto;
    background-color: #fff;
  }
  .user {
    padding: 6px 20px;
    display: flex;
    gap: 20px;
    align-items: center;
    color: #757575;
    cursor: pointer;
    border-bottom: 1px solid #eaeaea;
  }
  .avatar {
    width: 24px;
    height: 24px;
    .MuiAvatar-root {
      font-size: 16px;
    }
  }
`;

UsersTag.propTypes = {
  conversation: PropTypes.object.isRequired,
  setMentionUsers: PropTypes.func.isRequired,
};
