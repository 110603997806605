
import React, { useEffect, useState } from 'react';

import styled from 'styled-components';
import PropTypes from 'prop-types';

import { Chip, List, Popover } from '@material-ui/core';


export default function SelectFilter({
  open,
  handleOpenClose,
  text,
  children
}) {
  const [menu, setMenu] = useState(null);
  const handleClose = () => {
    setMenu(null);
    handleOpenClose(false);
  };
  useEffect(() => {
    if (!open) {
      handleClose();
    }
  }, [open]);
  const isOpen = Boolean(menu);
  const id = isOpen ? 'transfer-popover' : undefined;

  const handleOpen = (event) => {
    setMenu(event.currentTarget);
    handleOpenClose(true);
  };

  return (
    <View>
      <Chip
        className="by-assign-button"
        label={text}
        onClick={handleOpen}
      />
      <Popover
        id={id}
        open={isOpen}
        anchorEl={menu}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <ListStyled>
          {children}
        </ListStyled>
      </Popover>
    </View>
  );
}

SelectFilter.propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
  handleOpenClose: PropTypes.func.isRequired,
};

SelectFilter.defaultProps = {
  open: false,
};

const ListStyled = styled(List)`
  .MuiListItem-root{
    cursor: pointer;
    &:hover{
      background-color: rgba(0, 0, 0, 0.08);
    }
  }
`;

const View = styled.div`
  .by-assign-button {
    height: 30px;
    border-radius: 6px;
    &:hover {
      background-color: #DDDFE3;
    }
  }
`;