import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from '@mui/material/ToggleButtonGroup';
import { useTranslation } from 'react-i18next';
import Select from '../../../../../components/Select/Select';
import { ROUTING_SCHEDULE_OPTIONS } from '../constants';
import AppMultipleAutocomplete from '../../../../../components/AppMultipleAutocomplete/AppMultipleAutocomplete';

const CustomToggleButtonGroup = styled(ToggleButtonGroup)(() => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    border: 0,
  },
}));

const SelectDayMonthInputs = ({ orCondition, onKeyChange, onValueChange }) => {
  const { t } = useTranslation();

  const week = [
    { value: 0, label: 'пн' },
    { value: 1, label: 'вт' },
    { value: 2, label: 'ср' },
    { value: 3, label: 'чт' },
    { value: 4, label: 'пт' },
    { value: 5, label: 'сб' },
    { value: 6, label: 'вс' },
  ];
  const isMonthDays = orCondition[Object.keys(orCondition)[0]]?.monthDays;

  const [selectedWeekDays, setSelectedWeekDays] = useState(
    orCondition.schedule?.weekDays ?? [],
  );
  const [selectedMonthDays, setSelectedMonthDays] = useState(
    orCondition.schedule?.monthDays ?? [],
  );

  const handleDaysToggle = (event, day) => {
    setSelectedWeekDays(day);
  };
  useEffect(() => {
    if (!isMonthDays) {
      onValueChange('weekDays', selectedWeekDays);
    } else {
      onValueChange('monthDays', selectedMonthDays);
    }
  }, [selectedWeekDays, selectedMonthDays]);

  return (
    <>
      <Select
        isFullWidth
        sx={{ maxWidth: '220px' }}
        label="Временной интервал"
        options={ROUTING_SCHEDULE_OPTIONS}
        defaultValue={
          ROUTING_SCHEDULE_OPTIONS.find(
            (o) => o.value === Object.values(orCondition)[0],
          ) ?? ROUTING_SCHEDULE_OPTIONS[0]
        }
        onChange={(e) =>
          onKeyChange('schedule', { [e.target.value.value]: [] })
        }
      />

      {!isMonthDays && (
        <div
          style={{
            border: '1px solid #DADCE0',
            borderRadius: '8px',
            overflow: 'auto',
            margin: 'auto 0',
          }}
        >
          <CustomToggleButtonGroup
            color="primary"
            value={selectedWeekDays}
            onChange={handleDaysToggle}
            aria-label="Platform"
          >
            {week.map((day, index) => (
              <ToggleButton
                sx={{
                  position: 'relative',
                  padding: '12px 18px',
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  lineHeight: '16px',
                  ...(index !== week.length - 1 && {
                    ':after': {
                      content: "''",
                      position: 'absolute',
                      right: 0,
                      top: '50%',
                      transform: 'translateY(-50%)',
                      height: '20px',
                      width: '1px',
                      backgroundColor: '#DADCE0',
                    },
                  }),
                }}
                key={day.value}
                value={day.value}
              >
                {t(`graphInfo.weekdays.${day.label}`)}
              </ToggleButton>
            ))}
          </CustomToggleButtonGroup>
        </div>
      )}

      {isMonthDays && (
        <AppMultipleAutocomplete
          sx={{ width: 'auto' }}
          limitTags={4}
          options={new Array(31).fill(0).map((_, index) => index + 1)}
          defaultValue={selectedMonthDays}
          inputLabel={t('dataTypes.number')}
          value={selectedMonthDays}
          onChange={(event, value) => setSelectedMonthDays(value)}
        />
      )}
    </>
  );
};
SelectDayMonthInputs.propTypes = {
  orCondition: PropTypes.object,
  onKeyChange: PropTypes.func,
  onValueChange: PropTypes.func,
};
SelectDayMonthInputs.defaultProps = {
  orCondition: {},
  onKeyChange: null,
  onValueChange: null,
};
export default SelectDayMonthInputs;
