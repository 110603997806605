import { play as soundNotify } from './soundNotify';
// import isVisibly from './visibility';
import { getName } from './personeHelper';

export const notify = (title, options) => {
  let notification;
  if (!('Notification' in window)) return false;

  if (Notification.permission === 'granted') {
    notification = new Notification(title, options);
  } else if (Notification.permission !== 'denied') {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        notification = new Notification(title, options);
      }
    });
  }
  return notification;
};

export const notifyNewMessage = ({
  payload, currentDialog, soundIsOn, notificationIsOn, 
}) => {
  const { type, content } = payload;
  if (type === 'in') {
    if (notificationIsOn) {
      if (content && content.data) {
        const options = {};
        switch (content.type) {
          case 'message.text':
            options.body = content.data.text;
            break;
          case 'message.photo':
          case 'message.image':
            options.body = '🖼️ Изображение';
            break;
          case 'message.choice':
            options.body = `▶️ ${content.data.answer}`;
            break;
          case 'message.video':
            options.body = '🎬 Видео';
            break;
          case 'message.location':
            options.body = '🌍 Геолокация';
            break;
          case 'message.sticker':
            options.body = '👾 Стикер';
            break;
          case 'message.file':
            options.body = '📄 Файл';
            break;
          default:
            options.body = '...';
        }
        options.icon = '';
        options.image = '';
        options.timestamp = Math.floor(Date.now());
        if (currentDialog && currentDialog.persone !== undefined) {
          const person = getName(currentDialog.persone);
          const findedAvatar = currentDialog && currentDialog.persone.info.find((i) => i.key === 'avatar');
          options.icon = (findedAvatar && findedAvatar.val) || undefined;

          notify(`Новое сообщение от ${person}`, options);
        } else {
          notify('Новое сообщение', options);
        }
      }
    }
    if (soundIsOn) {
      soundNotify();
    }
  }
};
