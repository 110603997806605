import uuidGen from 'uuid/v1';
import { rcvEvent } from './chatActions';

export const WS_SET_STATUS = 'CHAT_WS_STATUS';
export const WS_CONNECT = 'WS_CONNECT';
export const WS_CONNECTING = 'WS_CONNECTING';
export const WS_CONNECTED = 'WS_CONNECTED';
export const WS_DISCONNECT = 'WS_DISCONNECT';
export const WS_DISCONNECTED = 'WS_DISCONNECTED';
export const WS_RCV_MESSAGE = 'WS_RCV_MESSAGE';
export const WS_SND_MESSAGE = 'WS_SND_MESSAGE';

export const setConnectionStatus = ({ status, error = null }) => (dispatch) => dispatch({
  type: WS_SET_STATUS,
  payload: { status, error },
});

export const wsSndMessage = (payload) => (dispatch) => {
  dispatch({
    type: WS_SND_MESSAGE,
    payload: { uuid: uuidGen(), ...payload },
  });
};
export const wsRcvMessage = (payload) => (dispatch, getState) => {
  dispatch({
    type: WS_RCV_MESSAGE,
    payload,
  });
  rcvEvent(payload)(dispatch, getState);
};
export const wsConnect = ({ host }) => (dispatch) => {
  dispatch({
    type: WS_CONNECT,
    host,
  });
};
export const wsConnecting = ({ host }) => (dispatch) => {
  setConnectionStatus({ status: 'connecting' })(dispatch);
  dispatch({
    type: WS_CONNECTING,
    host,
  });
};
export const wsConnected = ({ host }) => (dispatch) => {
  setConnectionStatus({ status: 'connected' })(dispatch);
  dispatch({
    type: WS_CONNECTED,
    host,
  });
};
export const wsDisconnect = () => (dispatch) => dispatch({
  type: WS_DISCONNECT,
});
export const wsDisconnected = () => (dispatch) => {
  setConnectionStatus({ status: 'disconnect' })(dispatch);
  dispatch({
    type: WS_DISCONNECTED,
  });
};
