import { HOST } from '../../config';
import { apiGet, apiPost, apiDelete } from '../../api';

export const SET_USER_IN_STOP_LIST = 'SET_USER_IN_STOP_LIST';
export const SET_USER_IN_SPAM_LIST = 'SET_USER_IN_SPAM_LIST';
export const SET_CHANGE_PASSWORD_RESULT = 'SET_CHANGE_PASSWORD_RESULT';

const setUserInStopList = (payload) => ({
  type: SET_USER_IN_STOP_LIST,
  payload,
});

const setUserInSpamList = (payload) => ({
  type: SET_USER_IN_SPAM_LIST,
  payload,
});

export const setChangePasswordResult = (payload) => ({
  type: SET_CHANGE_PASSWORD_RESULT,
  payload
});

export const getUsetInStoplist = (phone, uuidOrganization) => async (dispatch, getState) => {
  apiGet({
    dispatch,
    url: `${HOST}/legacy/persones/stoplist/${phone.split('@')[0]}/${uuidOrganization}`,
    tokens: getState().session.tokenData,
    callback: (res) => {
      dispatch(setUserInStopList(res.result));
    }
  });
};

export const addUserToStopList = (phone, uuidOrganization) => async (dispatch, getState) => {
  apiPost({
    dispatch,
    url: `${HOST}/legacy/persones/stoplist/${phone.split('@')[0]}/${uuidOrganization}`,
    tokens: getState().session.tokenData,
    callback: (res) => {
      if (res.result) {
        dispatch(setUserInStopList(true));
      }
    }
  });
};

export const getUsetInSpamlist = (phone, uuidOrganization) => async (dispatch, getState) => {
  const id = phone.split('@')[0];
  apiGet({
    dispatch,
    url: `${HOST}/legacy/persones/spamlist/${uuidOrganization}/${id}`,
    tokens: getState().session.tokenData,
    callback: (res) => {
      dispatch(setUserInSpamList(res.result));
    }
  });
};

export const addUserToSpamList = (phone, uuidOrganization) => async (dispatch, getState) => {
  const id = phone.split('@')[0];
  apiPost({
    dispatch,
    url: `${HOST}/legacy/persones/spamlist/${uuidOrganization}/${id}`,
    tokens: getState().session.tokenData,
    callback: () => {
      dispatch(getUsetInSpamlist(id, uuidOrganization));
    }
  });
};

export const deleteUserFromSpamList = (phone, uuidOrganization)=> async (dispatch, getState) => {
  const id = phone.split('@')[0];
  apiDelete({
    dispatch,
    url: `${HOST}/legacy/persones/spamlist/${uuidOrganization}/${id}`,
    tokens: getState().session.tokenData,
    callback: () => {
      dispatch(getUsetInSpamlist(id, uuidOrganization));
    }
  });
};

export const deleteUserFromStopList = (phone, uuidOrganization) => async (dispatch, getState) => {
  apiDelete({
    dispatch,
    url: `${HOST}/legacy/persones/stoplist/${phone.split('@')[0]}/${uuidOrganization}`,
    tokens: getState().session.tokenData,
    callback: (res) => {
      if (res.result) {
        dispatch(setUserInStopList(false));
      }
    }
  });
};

export const changeUserPassword = (newPassword, confirmPassword) => async (dispatch, getState) => {
  apiPost({
    dispatch,
    url: `${HOST}/legacy/password`,
    body: { password: newPassword, confirm: confirmPassword },
    tokens: getState().session.tokenData,
    callback: (res) => {
      if (res.success) {
        dispatch(setChangePasswordResult(true));
      } else {
        dispatch(setChangePasswordResult(false));
      }
    },
    error: () => {
      dispatch(setChangePasswordResult(false));
    }
  });
};