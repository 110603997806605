import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  images: [],
  isOpen: false,
  current: null,
};

const galleryReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GALLERY_OPEN:
      return update(state, {
        current: { $set: action.payload },
        isOpen: { $set: true },
      });

    case actionTypes.GALLERY_SET_IMAGES: {
      return update(state, {
        images: { $set: action.payload },
      });
    }
    case actionTypes.GALLERY_SET_IMAGE: {
      const { payload } = action;

      return update(state, {
        current: { $set: payload },
      });
    }
    case actionTypes.GALLERY_CLOSE: {
      return update(state, {
        isOpen: { $set: false },
      });
    }
    default: {
      return state;
    }
  }
};
export default galleryReducer;
