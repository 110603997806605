import React from 'react';
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setFilter } from 'store/actions';

import { ReactComponent as IconVK } from '../../../../../assets/img/messengers/vk.svg';
import { ReactComponent as IconTG } from '../../../../../assets/img/messengers/telegram.svg';
import { ReactComponent as IconInst } from '../../../../../assets/img/messengers/instagram.svg';

const providerFilter = [
  { icon: <IconInst />, name: 'instagramOriginal' },
  { icon: <IconVK />, name: 'vk' },
  { icon: <IconTG />, name: 'telegram' },
];

export default function PostSearchPanel() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.post.filters);

  const handleSelectProvider = (index) => {
    const provider = providerFilter[index];
    if (provider.name === filters.provider) {
      dispatch(
        setFilter({
          provider: null,
        }),
      );
    } else {
      dispatch(
        setFilter({
          provider: providerFilter[index].name,
        }),
      );
    }
  };

  const handleSearch = (event) => {
    dispatch(
      setFilter({
        search: event.target.value,
      }),
    );
  };

  return (
    <Parent>
      <div className="search-filter">
        <div className="input">
          <input
            value={filters.search}
            onChange={handleSearch}
            type="text"
            placeholder={t('post.searchPanel')}
          />
        </div>
      </div>
      <div className="provider-filter">
        {providerFilter.map(({ icon, name }, index) => (
          <div
            key={index}
            className={`provider-filter-item ${
              name === filters?.provider ? 'selected' : ''
            }`}
            onClick={() => handleSelectProvider(index)}
          >
            <div className="provider-icon">{icon}</div>
          </div>
        ))}
      </div>
    </Parent>
  );
}

const Parent = styled.div`
  display: flex;
  border-bottom: 2px solid #eaeaea;
  .input {
    flex: 2;
    background-image: url(/img/main-search.svg);
    background-repeat: no-repeat;
    background-position: 21px 21px;
    background-color: #fff;
  }
  input {
    height: 65px;
    border: none;
    margin-left: 64px;
    outline: none;
    font-size: 15px;
  }
  .search-filter {
    width: 300px;
  }
  .provider-filter {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .provider-filter-item {
    cursor: pointer;
    padding: 5px;
    &:hover svg {
      filter: grayscale(0%);
    }
    border-bottom: 1px solid transparent;
  }
  .provider-filter-item.selected {
    border-bottom: 1px solid #d2d2e8;
    border-radius: 10px;
    svg {
      filter: grayscale(0%);
    }
  }
  .provider-icon {
    width: 25px;
    height: 25px;
    font-size: 25px;
    svg {
      width: 100%;
      height: 100%;
      filter: grayscale(80%);
    }
  }
`;
