import { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import styled from 'styled-components';
import { Route } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Drawer from '@mui/material/Drawer';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components';
import SettingsSchedule from './components/SettingsSchedule';
import SettingsRouting from './components/SettingsRouting';
import SettingsPassword from './components/SettingsPassword';
import SettingsNotifications from './components/SettingsNotifications';
import SettingsAccess from './components/SettingsAccess';
import SettingsNavBar from './components/SettingsNavBar';
import {
  SETTINGS_ROUTES,
  ROLES,
  SETTINGS_NAV_LINKS,
  PERMISSIONS,
} from './index';
import { fetchRoutingScripts } from '../../store/actions';
import useWindowSize from '../../hooks/useWindowSize';
import AppIconButton from '../../components/AppIconButton/AppIconButton';
import { ReactComponent as BurgerIcon } from '../../assets/img/burger.svg';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    minHeight: '100%',
  },
}));

export default function SettingsPage() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { windowWidth } = useWindowSize();
  const location = useLocation();
  const {
    isAdmin,
    group: userRole,
    settings,
  } = useSelector((state) => state.session.user);
  const userPermissions = settings.permissions;
  const isAbleToSee =
    [ROLES.USER, ROLES.DEVELOPER].includes(userRole) || isAdmin;

  const [isDrawerOpen, setIsDrawerOpen] = useState(true);

  const linkByPath = useMemo(() => {
    for (const section of SETTINGS_NAV_LINKS) {
      const link = section.links.find((l) => l.path === location.pathname);
      if (link) {
        return link;
      }
    }
    return null;
  }, [location.pathname]);

  const fetchScripts = () => {
    dispatch(fetchRoutingScripts());
  };

  useEffect(() => {
    fetchScripts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page
      className={clsx({
        [classes.root]: true,
      })}
      title="IntellectDialog | Settings"
    >
      {windowWidth > 900 ? (
        <SettingsNavBar />
      ) : (
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              backgroundColor: '#f6f8fc',
            },
          }}
          open={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
        >
          <SettingsNavBar />
        </Drawer>
      )}

      <Wrapper>
        <Header>
          <p className="breadcrumbs">
            <span className="text">{t('settingsDrawer.title')}</span>
            {linkByPath?.sectionBy && (
              <>
                /{' '}
                <span className="text">
                  {t(`settingsDrawer.${linkByPath.sectionBy}`)}
                </span>
              </>
            )}
            {linkByPath?.name && (
              <>
                /{' '}
                <span className="text">
                  {t(`settingsDrawer.${linkByPath.name}`)}
                </span>
              </>
            )}
          </p>
          {linkByPath?.name && (
            <h3 className="title">{t(`settingsDrawer.${linkByPath?.name}`)}</h3>
          )}
          {windowWidth <= 900 && (
            <AppIconButton
              className="burgerButton"
              onClick={() => setIsDrawerOpen(true)}
            >
              <BurgerIcon />
            </AppIconButton>
          )}
        </Header>

        <Body>
          <Route
            exact
            path={SETTINGS_ROUTES.SCHEDULE}
            component={SettingsSchedule}
          />
          {isAbleToSee ||
          userPermissions.includes(
            PERMISSIONS.ROUTING.READ || PERMISSIONS.ROUTING.WRITE,
          ) ? (
            <Route
              exact
              path={SETTINGS_ROUTES.ROUTING}
              component={SettingsRouting}
            />
          ) : (
            <Redirect to="/settings" />
          )}

          <Route
            exact
            path={SETTINGS_ROUTES.PASSWORD}
            component={SettingsPassword}
          />
          <Route
            exact
            path={SETTINGS_ROUTES.NOTIFICATIONS}
            component={SettingsNotifications}
          />
          <Route
            exact
            path={SETTINGS_ROUTES.ACCESS_SETTINGS}
            component={SettingsAccess}
          />
        </Body>
      </Wrapper>
    </Page>
  );
}

const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Body = styled.section`
  position: relative;
  flex: 1;
  padding: 48px 40px;
  background-color: #eceff2;

  @media (max-width: 480px) {
    padding: 24px 20px;
  }
`;

const Header = styled.header`
  position: relative;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 2px;
  width: 100%;
  padding: 24px;
  max-height: 98px;
  height: 100%;
  border-bottom: 1px solid #dadce0;

  .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 8px;
    row-gap: 6px;
    font-size: 12px;
    line-height: 16px;
    color: #18191b;

    .text {
      color: #8b8c8d;
    }

    .title {
      font-size: 20px;
      line-height: 32;
      font-weight: 700;
      color: #18191b;
    }
  }

  .burgerButton {
    position: absolute;
    right: 28px;
    top: 12px;
  }
`;
