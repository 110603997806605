import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import AppIconButton from '../../../../../components/AppIconButton/AppIconButton';
import { ReactComponent as MinusIcon } from '../../../../../assets/img/minus.svg';
import { ReactComponent as PlusIcon } from '../../../../../assets/img/plus.svg';
import { ReactComponent as CrossIcon } from '../../../../../assets/img/cross-rounded.svg';

const SelectionDepartmentOrUserActions = ({
  uuid,
  status,
  onInclude,
  onExclude,
  onRemove,
  onSwap,
}) => {
  const handleInclude = (e) => {
    e.stopPropagation();

    onInclude(uuid);
  };
  const handleExclude = (e) => {
    e.stopPropagation();

    onExclude(uuid);
  };
  const handleSwap = (e) => {
    e.stopPropagation();

    onSwap(uuid, status);
  };
  const handleRemove = (e) => {
    e.stopPropagation();

    onRemove(uuid, status);
  };

  return (
    <Wrapper>
      <AppIconButton
        style={{
          backgroundColor:
            status === 'excludes' ? 'rgba(255, 59, 48, 0.1)' : 'inherit',
        }}
        hoverColor="rgba(255, 59, 48, 0.1)"
        disabled={status === 'excludes'}
        onClick={status === 'includes' ? handleSwap : handleExclude}
      >
        <CrossIcon className="icon cross" />
      </AppIconButton>

      <AppIconButton
        style={{
          backgroundColor: !status ? 'rgba(57, 57, 57, 0.1)' : 'inherit',
        }}
        hoverColor="rgba(57, 57, 57, 0.1)"
        disabled={!status}
        onClick={handleRemove}
      >
        <MinusIcon className="icon minus" />
      </AppIconButton>

      <AppIconButton
        style={{
          backgroundColor:
            status === 'includes' ? 'rgba(0, 106, 228, 0.1)' : 'inherit',
        }}
        hoverColor="rgba(0, 106, 228, 0.1)"
        disabled={status === 'includes'}
        onClick={status === 'excludes' ? handleSwap : handleInclude}
      >
        <PlusIcon className="icon plus" />
      </AppIconButton>
    </Wrapper>
  );
};

SelectionDepartmentOrUserActions.propTypes = {
  uuid: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  onInclude: PropTypes.func.isRequired,
  onExclude: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onSwap: PropTypes.func.isRequired,
};

export default SelectionDepartmentOrUserActions;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-left: auto;

  .icon {
    width: 16px;
    height: 16px;
  }

  .cross path {
    fill: #ff3b30;
  }
  .plus path {
    fill: #006ae4;
  }
  .minus path {
    fill: #393939;
  }
`;
