import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import moment from 'moment';
import { getName } from 'utils/personeHelper';


export default function AccountListItem({
	persone,
	provider,
	date,
}) {
	const avatar = useMemo(() => {
		const photo = persone.info.find((o) => o.key === 'avatar');
		return (photo && photo.val) || '/img/avatar-default.jpg'
	}, [persone]);

	const datetime = () => {
		const { updated } = date;
		if (moment(updated).isSame(new Date(), 'day')) {
			return moment(updated).format('HH:mm');
		}
		return moment(updated).format('DD.MM.YY');
	};

	return (
		<Profile>
			<div className='about'>
				<Avatar>
					<div
						className={`conversation-messenger-badge ${provider.messenger}`}
					/>
					<div
						className="user-avatar"
						style={{ backgroundImage: `url(${avatar})` }}
					/>
					<div className="conversation-messenger-info">
						<span>10</span>
					</div>
				</Avatar>
				{getName(persone, true)}
			</div>
			<div className="last-time">{datetime()}</div>
		</Profile>
	);
}

const Avatar = styled.div`
	flex-shrink: 0;
	position: relative;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background-repeat: no-repeat;
	background-size: cover;
	background-color: #ebeef3;
	.user-avatar {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-size: cover;
		background-color: transparent;
		border: 2px solid #fff;
	}
	.conversation-messenger-badge {
		position: absolute;
		width: 18px;
		height: 18px;
		top: 0px;
		left: -3px;
		border-radius: 50%;
		background-repeat: no-repeat;
		background-position: center center;
		border: 2px solid #fff;
		&.avito {
		background-color: #f1f3f4;
		background-image: url(/img/messengers/badges/avito.svg);
		}
		&.telegram {
		background-color: #0088cc;
		background-image: url(/img/messengers/badges/telegram.svg);
		}
		&.telegramOriginal {
		background-color: #0088cc;
		background-image: url(/img/messengers/badges/telegram.svg);
		}
		&.whatsapp {
		background-color: #25d366;
		background-image: url(/img/messengers/badges/whatsapp.svg);
		}
		&.waba {
		background-color: #25d366;
		background-image: url(/img/messengers/badges/whatsapp.svg);
		}
		&.facebook {
		background-color: #3b5998;
		background-image: url(/img/messengers/badges/facebook.svg);
		}
		&.vk {
		background-color: #45668e;
		background-image: url(/img/messengers/badges/vk.svg);
		}
		&.viber {
		background-color: #fff;
		background-image: url(/img/messengers/badges/viber.svg);
		background-size: cover;
		}
		&.i2crm {
		background-color: #fff;
		background-image: url(/img/messengers/badges/i2crm.svg);
		background-size: cover;
		}
		&.instagram {
		background-color: #fff;
		background-image: url(/img/messengers/badges/instagram.svg);
		background-size: cover;
		}
		&.instagramOriginal {
		background-color: #fff;
		background-image: url(/img/messengers/badges/instagram.svg);
		background-size: cover;
		}
	}
	.conversation-messenger-info {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		border: 2px solid #fff;
		width: 18px;
		height: 18px;
		bottom: 0px;
		right: -3px;
		border-radius: 11px;
		background-repeat: no-repeat;
		background-position: center center;
		font-size: 8px;
		color: #fff;
		background-color: #006ae4;
	}
`;

const Profile = styled.div`
	padding: 10px 10px 3px 12px;
	border-bottom: 1px solid rgb(238, 239, 242);
	cursor: pointer;
	display: flex;
	justify-content: space-between;
	gap: 10px;
	.about {
		display: flex;
		gap: 10px;
		align-items: center;
	}
	.last-time {
    font-size: 13px;
    color: #727887;
	}
`;

AccountListItem.propTypes = {
	persone: PropTypes.object.isRequired,
	provider: PropTypes.object.isRequired,
	date: PropTypes.object.isRequired,
};