import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import { changeUserPassword, setChangePasswordResult } from 'store/actions';

import { SecretInput } from 'components';
import ModalWrapper, { ActionButton, CancelButton } from './layout';
import SuccessPasswordChangeDialog from './SuccessPasswordChangeDialog';

const Wrapper = styled.div`
  .line {
    margin-top: 25px;
    label {
      display: block;
      margin-bottom: 10px;
      color: #7d8392;
    }
  }
  .modal-buttons {
    display: flex;
    margin-top: 25px;
    button:first-child {
      margin-right: 15px;
    }
  }
  .modal-error {
    color: red;
    margin: 15px 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  button {
    flex: 1;
  }
`;

const ChangePasswordDialog = (props) => {
  const dispatch = useDispatch();
  const changePasswordSuccess = useSelector(
    (state) => state.person.changePasswordSuccess,
  );

  const { onClose } = props;
  const { t: translate } = useTranslation();

  const [visible, setVisible] = useState(false);

  const [invalid, setInvalid] = useState([]);

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  const [success, setSuccess] = useState(false);

  useEffect(() => {
    if (changePasswordSuccess === true) {
      setSuccess(true);
    } else if (changePasswordSuccess === false) {
      setInvalid([translate('errors:changePasswordDialog.unprocessed')]);
    }
  }, [changePasswordSuccess]);

  const handleConfirm = () => {
    if (newPassword === '' || confirmPassword === '') {
      setInvalid([translate('errors:changePasswordDialog.empty')]);
    } else if (newPassword !== confirmPassword) {
      setInvalid([translate('errors:changePasswordDialog.match')]);
    } else {
      dispatch(changeUserPassword(newPassword, confirmPassword));
    }
  };

  const handleClose = () => {
    dispatch(setChangePasswordResult(null));
    onClose();
  };

  const handleVisibilityToggle = () => {
    setVisible(!visible);
  };

  return (
    <ModalWrapper
      modalStyle={{
        width: '300px',
        paddingRight: '30px',
      }}
      onClose={handleClose}
      title={translate('changePasswordDialog.title')}
    >
      {!success ? (
        <Wrapper>
          <div className="line">
            <SecretInput
              handleToggle={handleVisibilityToggle}
              show={visible}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              label={translate('changePasswordDialog.newPassword')}
            />
          </div>
          <div className="line">
            <SecretInput
              handleToggle={handleVisibilityToggle}
              show={visible}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              label={translate('changePasswordDialog.confirmPassword')}
            />
          </div>
          {invalid.length > 0 && (
            <div className="modal-error">
              {invalid.map((errorText, index) => (
                <div key={index}>{errorText}</div>
              ))}
            </div>
          )}
          <div className="modal-buttons">
            <ActionButton onClick={handleConfirm} type="save" disabled={false}>
              {translate('common.save')}
            </ActionButton>
            <CancelButton type="button" onClick={handleClose}>
              {translate('common.cancel')}
            </CancelButton>
          </div>
        </Wrapper>
      ) : (
        <SuccessPasswordChangeDialog />
      )}
    </ModalWrapper>
  );
};

ChangePasswordDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default ChangePasswordDialog;
