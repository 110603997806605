/**
 *
 * Generation image by uuid value and creature type
 * Example: https://robohash.org/swagCatt?set=set4
 * @param uuid
 * @param {number} type:  2 - is Monster , 3 - is Robot head, 4 - is Cat
 * @returns {string}
 */
export default function genAvatar(uuid, type = 0) {
  return `https://robohash.org/${uuid}${type !== 0 ? `?set=set${type}` : ''}`;
}
