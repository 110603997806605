import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Select from '../../../../../components/Select/Select';
import { ROUTING_RELATIONAL_OPERATOR_OPTIONS } from '../../constants';
import SettingsInput from '../../components/SettingsInput';

const TargetEmployeeDialogCountInputs = ({ orCondition, onValueChange }) => {
  const { t } = useTranslation();

  const values = Object.values(orCondition);

  return (
    <Wrapper>
      <Select
        sx={{ minWidth: '220px' }}
        label="Знак"
        options={ROUTING_RELATIONAL_OPERATOR_OPTIONS}
        defaultValue={
          ROUTING_RELATIONAL_OPERATOR_OPTIONS.find(
            (o) => o.value === values.operator,
          ) ?? ROUTING_RELATIONAL_OPERATOR_OPTIONS[0]
        }
        onChange={(e) => onValueChange('operator', e.target.value.value)}
      />
      <SettingsInput
        label={t('routingPage.value')}
        value={values.value}
        onChange={(e) => onValueChange('value', e.target.value)}
      />
    </Wrapper>
  );
};
TargetEmployeeDialogCountInputs.propTypes = {
  orCondition: PropTypes.object,
  onValueChange: PropTypes.func,
};
TargetEmployeeDialogCountInputs.defaultProps = {
  orCondition: {},
  onValueChange: null,
};
export default TargetEmployeeDialogCountInputs;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 10px;
  row-gap: 12px;
`;
