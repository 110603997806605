import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import Select from 'react-select';
import PhoneInput from 'react-phone-input-2';
import { Close } from '@material-ui/icons';

import { Field, Form } from 'react-final-form';
import _ from 'lodash';
import { ReactComponent as AttachIcon } from '../../assets/img/attach.svg';
import ModalWrapper, { ActionButton, CancelButton } from './layout';
import { Textarea, Spinner } from '../../components';

// eslint-disable-next-line no-unused-vars
import {
  sndStartDialog,
  postChatAttachmentThroughBody,
  clearPostChatAttachment,
} from '../../store/actions/chatActions';
import { PROVIDERS_CAN_START } from '../../config';
import { setPreferredCompanyProvider } from '../../store/actions';

const Wrapper = styled.form`
  font-size: 15px;
  .form-input {
    margin-top: 25px;
    label {
      display: block;
      margin-bottom: 10px;
      color: #7d8392;
    }
    .react-select__control {
      border-radius: 3px;
      border: solid 1px #eeeff2;
      background-color: #f6f8fc;
      color: #3f434c;
    }
  }
  .react-tel-input .form-control {
    width: 100%;
    border-radius: 3px;
    border: solid 1px #eeeff2;
    background-color: #f6f8fc;
    font-size: 15px;
    color: #2b2d33;
    padding: 10px 11px 10px 40px;
    outline: none;
  }
  .react-tel-input .flag-dropdown {
    background-color: transparent;
    border: none;
  }
  .modal-error {
    color: red;
    margin: 15px 0;
  }
  .modal-buttons {
    margin-top: 25px;
    button:first-child {
      margin-right: 15px;
    }
  }
  .branch-chat button:not(:first-child) {
    margin-left: 15px;
  }
  .attach {
    display: flex;
    align-items: center;
    gap: 5px;
  }
  .attach_label {
    margin-bottom: 0px !important;
  }
  .attach_file {
    display: flex;
    align-items: center;
    gap: 3px;
  }
  .attach_file_icon {
    cursor: pointer;
  }
  .attach_clear_icon {
    height: 15px;
    height: 15px;
    cursor: pointer;
    color: #a6a6a6;
    &:hover {
      color: #000;
      transform: scale(1.2);
    }
  }
`;
const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CRMItem = styled.div`
  margin-top: 25px;
  display: block;
  margin-bottom: 10px;

  .user-name {
    font-size: 16px;
  }

  .user-status {
    color: #7d8392;
  }

  .user-id {
    color: black;
  }
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StartDialog = (props) => {
  const { onClose, data: dialogData } = props;
  const isExpand = dialogData.isExpand || false;
  const { t: translate } = useTranslation();
  const store = useStore();
  const isFetchingTemplate = useSelector((state) => state.templates.isFetching);
  const query = useQuery();
  const CRMUserName = query.get('CRMUserName');
  const CRMUserId = query.get('CRMUserId');
  const CRMUserStatus = query.get('CRMUserStatus');
  const isCRM = query.get('isCRM') || query.get('isAmoCRM');
  const fileInput = useRef();
  const providers = useSelector((state) =>
    state.chat.companyProviders.map((p) => ({
      label: p.name,
      value: p.uuid,
      data: p,
      isDisabled: !PROVIDERS_CAN_START.includes(p.provider),
    })),
  );
  const newAttachedFile = useSelector((state) => state.chat.attachedFile);
  const [attachedFile, setAttachedFile] = React.useState();
  const { preferredProvider } = useSelector((state) => state.settings);

  React.useEffect(() => {
    setAttachedFile(newAttachedFile);
  }, [newAttachedFile]);

  const clearPhone = (phoneString = '') => {
    if (typeof phoneString !== 'string') return phoneString;
    const phone = decodeURIComponent(phoneString)
      .replace(/(\s|\t|\+|\-|\(|\))/g, '')
      .trim();
    const isPhone = /^\d+$/.test(phone);
    if (isPhone) {
      return phone[0] === '8' && phone.length === 11
        ? `7${phone.slice(-10)}`
        : phone;
    }
    return phoneString;
  };

  const templates = useSelector((state) =>
    state.templates.data.map((t) => {
      const result = {
        label: t.template,
        value: t.template,
      };
      if (t.file) {
        result.file = t.file;
      }
      return result;
    }),
  );
  const uuidCompany = useSelector((state) => state.session.selectedCompany);

  const dispatch = useDispatch();

  const clearAttach = () => {
    fileInput.current.value = '';
    setAttachedFile(null);
    dispatch(clearPostChatAttachment());
  };

  const handleClose = () => {
    onClose();
    if (attachedFile) {
      dispatch(clearPostChatAttachment());
    }
  };

  const handleStartDialog = (values) => {
    let data = {
      uuidMessengerUser: !CRMUserId ? values.phone : clearPhone(CRMUserId),
      uuidConnection: values.provider.value,
      name: CRMUserName || '',
      status: CRMUserStatus || '',
    };
    if (attachedFile) {
      let fileData = {
        files: [
          {
            fileUrl: attachedFile.url,
            storage: 'link',
            title: attachedFile.originName,
            size: attachedFile.size,
          },
        ],
      };

      let cmd = 'send.file';
      if (['image/jpeg', 'image/png'].includes(attachedFile.mimeType)) {
        fileData = {
          sizes: [
            {
              storage: 'link',
              fileUrl: attachedFile.url,
            },
          ],
        };
        cmd = 'send.image';
      }
      if (values.message && values.message.length > 0) {
        fileData = { ...fileData, caption: values.message };
      }
      data = { ...data, cmd, data: fileData };
    } else {
      data = {
        ...data,
        cmd: 'send.text',
        data: {
          text: values.message,
        },
      };
    }

    dispatch(setPreferredCompanyProvider(data.uuidConnection));
    sndStartDialog(data)(dispatch);
    clearAttach();
    handleClose();
  };

  const handleAttachClick = () => {
    fileInput.current.click();
  };

  const handleAttachClear = (e) => {
    e.preventDefault();
    clearAttach();
  };

  const handleAttach = (evt) => {
    const file = evt.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      postChatAttachmentThroughBody(
        file.name,
        formData,
        uuidCompany,
      )(dispatch, store.getState);
    }
  };

  const handleValidate = (values) => {
    const errors = {};
    const phone = _.get(values, 'phone', '');
    const message = _.get(values, 'message', '');
    if (!_.get(values, 'provider.value')) {
      _.set(errors, 'provider', 'Provider error');
    }
    if ((!phone || phone.length < 11) && !clearPhone(CRMUserId)) {
      _.set(errors, 'phone', 'Phone error');
    }
    if ((!message || message.length < 1) && !attachedFile) {
      _.set(errors, 'phone', 'Empty content');
    }
    return errors;
  };

  let mock = [];

  // TODO: Remove hack
  if (uuidCompany === 'a4476f2a-e5c3-4e11-8d5e-a2f68f627577') {
    mock = [
      {
        action: 'first-message-msb-site',
        uuidBot: '6fc1db26-4985-4413-921b-b983314f8b75',
        title: 'Вы оставили заявку',
      },
      {
        action: 'first-message-msb-req',
        uuidBot: '6fc1db26-4985-4413-921b-b983314f8b75',
        title: 'Вы просили направить',
      },
    ];
  }

  return (
    <ModalWrapper
      isExpand={isExpand}
      onClose={() => handleClose()}
      title={translate('startDialog.title')}
      style={{ border: '2px solid red' }}
    >
      <Form
        keepDirtyOnReinitialize
        validate={handleValidate}
        onSubmit={handleStartDialog}
        initialValues={{
          provider: providers.find(
            (p) =>
              (p.value === preferredProvider && !p.isDisabled) ?? !p.isDisabled,
          ),
        }}
        mutators={{
          setScenario: ([scenario], state, { changeValue }) => {
            changeValue(
              state,
              'message',
              () => `action:${scenario.action}:${scenario.uuidBot}`,
            );
          },
          setTemplate: ([template], state, { changeValue }) => {
            changeValue(state, 'message', () => `${template.value}`);
            if (template.file) {
              setAttachedFile(template.file);
            } else {
              setAttachedFile(null);
            }
          },
        }}
        render={({ form, handleSubmit, invalid }) => (
          <Wrapper onSubmit={handleSubmit}>
            <div className="form-input">
              <label htmlFor="provider">
                {translate('startDialog.provider')}
              </label>
              <Field name="provider">
                {({ input }) => (
                  <Select
                    required
                    classNamePrefix="react-select"
                    id="provider"
                    options={providers}
                    value={input.value}
                    onChange={(p) => input.onChange(p)}
                  />
                )}
              </Field>
            </div>
            <div className="form-input">
              <label htmlFor="phone">{translate('startDialog.account')}</label>
              <Field name="phone">
                {({ input }) =>
                  isCRM && CRMUserId ? (
                    <PhoneInput
                      id="phone"
                      country="ru"
                      {...input}
                      value={clearPhone(CRMUserId)}
                    />
                  ) : (
                    <PhoneInput id="phone" country="ru" {...input} />
                  )
                }
              </Field>
            </div>
            {isCRM && CRMUserId && (
              <CRMItem>
                <b>
                  <span className="user-name">{CRMUserName}</span>
                </b>
                {CRMUserId && (
                  <span className="user-id"> +{clearPhone(CRMUserId)}</span>
                )}
                {CRMUserStatus && (
                  <span className="user-status"> ({CRMUserStatus})</span>
                )}
              </CRMItem>
            )}
            <div className="form-input">
              <label htmlFor="message">
                {translate('startDialog.writeMessage')}
              </label>
              <Field name="message">
                {({ input }) => <Textarea id="message" {...input} />}
              </Field>
            </div>
            <div className="form-input">
              <Field name="file">
                {() => (
                  <div className="attach">
                    <input
                      style={{ display: 'none' }}
                      type="file"
                      ref={fileInput}
                      onChange={handleAttach}
                    />
                    {attachedFile ? (
                      <div className="attach_file">
                        {attachedFile.originName}
                        <Close
                          className="attach_clear_icon"
                          onClick={handleAttachClear}
                        />
                      </div>
                    ) : (
                      <label htmlFor="message" className="attach_label">
                        {translate('startDialog.attachFile')}
                      </label>
                    )}
                    <AttachIcon
                      onClick={handleAttachClick}
                      className="attach_file_icon"
                    />
                  </div>
                )}
              </Field>
            </div>
            <SpinnerWrapper>
              <Spinner show={isFetchingTemplate} />
            </SpinnerWrapper>
            {!isFetchingTemplate && (
              <div className="form-input">
                <label htmlFor="template">
                  {translate('startDialog.insertTemplate')}
                </label>
                <Field name="template">
                  {({ input }) => (
                    <Select
                      classNamePrefix="react-select"
                      id="template"
                      options={templates}
                      value={input.value}
                      onChange={(t) => {
                        input.onChange(t);
                        form.mutators.setTemplate(t);
                      }}
                    />
                  )}
                </Field>
              </div>
            )}
            {invalid && (
              <div className="modal-error">
                {translate('startDialog.invalidForm')}
              </div>
            )}

            <div className="branch-chat">
              {!!mock &&
                mock.map((item) => (
                  <ActionButton
                    key={`scenario-${item.action}`}
                    type="button"
                    onClick={() => form.mutators.setScenario(item)}
                  >
                    {item.title}
                  </ActionButton>
                ))}
            </div>

            <div className="modal-buttons">
              <ActionButton type="submit" disabled={invalid}>
                {translate('common.send')}
              </ActionButton>
              <CancelButton type="button" onClick={() => handleClose()}>
                {translate('common.cancel')}
              </CancelButton>
            </div>
          </Wrapper>
        )}
      />
    </ModalWrapper>
  );
};

StartDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  data: PropTypes.object,
};

StartDialog.defaultProps = {
  data: {},
};

export default StartDialog;
