import { get } from 'idb-keyval';
import _ from 'lodash';

export const getUser = (uuidUser, callback, error) => {
  get(`user:${uuidUser}`)
    .then((user) => (user ? callback(user) : error()));
};

export const stringToColor = (str) => {
  let hash = 0;
  // eslint-disable-next-line
  for (let i = 0; i < str.length; i++) {
    // eslint-disable-next-line no-bitwise
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }

  let hex = '';
  // eslint-disable-next-line
  for (let i = 0; i < 3; i++) {
    // eslint-disable-next-line no-bitwise
    const value = (hash >> (i * 8)) & 0xFF;
    hex += (`00${value.toString(16)}`).substr(-2);
  }

  let c = hex.substring(1).split('');
  if (c.length === 3) {
    c = [c[0], c[0], c[1], c[1], c[2], c[2]];
  }

  c = `0x${c.join('')}`;
  // eslint-disable-next-line no-bitwise
  return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')}, 0.65)`;
};

export const getInitials = (name) => {
  const words = name.trim().split(' ');
  if (words.length > 1) {
    return words[0][0] + words[1][0];
  }
  return name.substr(0, 2);
};

export const getImage = (msg, fileStorage) => {
  const { content: { data: { sizes = [] } = {} } = {} } = msg;
  if (!sizes.length) {
    return {
      src: null,
      loading: true,
    };
  }
  const thumb = sizes[sizes.length - 1];
  if (thumb.storage === 'messenger') {
    return (
      { src: fileStorage[`file:${thumb.fileId}`] } || {
        src: null,
        loading: true,
      }
    );
  }
  return (
    { src: thumb.fileUrl || thumb.fileSrc } || {
      src: null,
      loading: true,
    }
  );
};

export const getVideo = (msg, fileStorage) => {
  const {
    content: {
      data: { thumb, files },
    },
  } = msg;
  // TODO: Something wrong here
  if (!files) {
    return {
      src: null,
      loading: true,
    };
  }

  const src = _.get(files, '0.storage') === 'messenger'
    ? fileStorage[`file:${_.get(files, '0.fileUrl')}`]
    : _.get(files, '0.fileUrl');
  const thumbSrc = _.get(thumb, 'sizes[0].storage') === 'messenger'
    ? fileStorage[`file:${_.get(thumb, 'sizes[0].fileId')}`]
    : _.get(thumb, 'sizes[0].fileUrl');
  return {
    src,
    thumb: thumbSrc,
    loading: false,
  };
};