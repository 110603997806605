/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Emojione } from 'react-emoji-render';
import {
  Typography,
  IconButton,
  LinearProgress,
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import GetApp from '@material-ui/icons/GetApp';

import StopIcon from '@material-ui/icons/Stop';
import moment from 'moment';

import axios from 'axios';
import fileDownload from 'js-file-download';
import { getFileSize } from '../../../../../../../../utils/getFileSize';

// const BorderLinearProgress = withStyles((theme) => ({
//   root: {
//     height: 10,
//     borderRadius: 5,
//   },
//   colorPrimary: {
//     backgroundColor:
//       theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
//   },
//   bar: {
//     borderRadius: 5,
//     backgroundColor: '#1a90ff',
//   },
// }))(LinearProgress);

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '250px',
    background: 'white',
    borderRadius: '4px',
    padding: '10px',
    margin: '10px 0 5px',
  },

  audioWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    flexBasis: '100%',
    alignContent: 'flex-end',
    justifyContent: 'space-between',
  },
  progressBar: {
    flex: '1 0 100%',
    background: '#f5f5f5',
    height: '8px',
    borderRadius: '4px',
    display: 'flex',
    marginBottom: '8px',
  },
  progress: {
    width: 200,
    height: 8,

    borderRadius: '4px',
  },
  bottom: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  download: {
    cursor: 'pointer',
    width: '15px',
    color: '#8c8e92',
  },
}));

function useAudioPlayer(ref) {
  const [durationTime, setDuration] = useState();
  const [curTime, setCurTime] = useState();
  const [playing, setPlaying] = useState(false);
  const [clickedTime, setClickedTime] = useState();

  React.useEffect(() => {
    const audio = ref.current;

    const setAudioData = () => {
      setDuration(audio.duration);
      setCurTime(audio.currentTime);
    };

    const setAudioTime = () => setCurTime(audio.currentTime);

    audio.addEventListener('loadeddata', setAudioData);

    audio.addEventListener('timeupdate', setAudioTime);

    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime;
      setClickedTime(null);
    }

    return () => {
      audio.removeEventListener('loadeddata', setAudioData);
      audio.removeEventListener('timeupdate', setAudioTime);
    };
  });

  return {
    curTime,
    durationTime,
    playing,
    setPlaying,
    setClickedTime,
  };
}

const AudioContent = ({ fileName, src, duration, size, caption }) => {
  const audioEl = useRef();
  const progressRef = useRef();

  const { curTime, durationTime, playing, setPlaying, setClickedTime } =
    useAudioPlayer(audioEl);
  const styles = useStyles();

  const formatSecondsAsTime = (secs, format) => {
    const hr = Math.floor(secs / 3600);
    let min = Math.floor((secs - hr * 3600) / 60);
    let sec = Math.floor(secs - hr * 3600 - min * 60);

    if (min < 10) {
      min = `0${min}`;
    }
    if (sec < 10) {
      sec = `0${sec}`;
    }

    return `${min}:${sec}`;
  };
  const memoSize = React.useMemo(() => getFileSize(size));
  const memoDuration = React.useMemo(
    () => formatSecondsAsTime(durationTime),
    [durationTime],
  );

  const memoTime = React.useMemo(() => formatSecondsAsTime(curTime), [curTime]);
  const curPercentage = (curTime / duration) * 100;

  function calcClickedTime(e) {
    const clickPositionInPage = e.pageX;
    const bar = progressRef.current;
    const barStart = bar.getBoundingClientRect().left + window.scrollX;
    const barWidth = bar.offsetWidth;
    const clickPositionInBar = clickPositionInPage - barStart;
    const timePerPixel = duration / barWidth;
    return timePerPixel * clickPositionInBar;
  }

  function handleTimeDrag(e) {
    setClickedTime(calcClickedTime(e));
    const updateTimeOnMove = (eMove) => {
      setClickedTime(calcClickedTime(eMove));
    };

    document.addEventListener('mousemove', updateTimeOnMove);

    document.addEventListener('mouseup', () => {
      document.removeEventListener('mousemove', updateTimeOnMove);
    });
  }
  const handleDownload = () => {
    axios
      .get(src, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, 'audio.mp3');
      });
  };
  React.useEffect(() => {
    const audio = audioEl.current;
    const handleAudioEnded = () => {
      setPlaying(false);
    };
    audio.addEventListener('ended', handleAudioEnded);

    return () => {
      audio.removeEventListener('ended', handleAudioEnded);
    };
  }, [setPlaying]);

  return (
    <Grid container direction="column">
      <Grid container alignItems="center">
        <Grid item>
          <IconButton aria-label="delete" onClick={() => setPlaying(!playing)}>
            {playing ? (
              <StopIcon fontSize="large" />
            ) : (
              <PlayCircleFilledIcon fontSize="large" />
            )}
          </IconButton>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="body1">{fileName}</Typography>
          <div>
            {/* eslint-disable */}
            {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
            <audio ref={audioEl}>
              <source src={src} />
            </audio>
            {/* eslint-disable */}
            <LinearProgress
              ref={progressRef}
              className={styles.progress}
              variant="determinate"
              value={curPercentage}
              onMouseDown={handleTimeDrag}
            />
            {/* eslint-enable */}
            <Typography variant="body1" className={styles.bottom}>
              <div>
                {curPercentage > 0
                  ? `${memoTime} / ${memoDuration} `
                  : `${memoDuration} ${memoSize}`}
              </div>
              {/*
              <a target="_blank" href={src} download="music" rel="noreferrer">
                <GetApp className={styles.download}/>
              </a>
              */}
              <GetApp onClick={handleDownload} className={styles.download} />
            </Typography>
          </div>
        </Grid>
      </Grid>
      {caption && (
        <Typography color="inherit" variant="body1">
          <Emojione size={64} svg text={caption} />
        </Typography>
      )}
    </Grid>
    // <Typography color="inherit" variant="body1">
    //   {caption && <Emojione size={64} svg text={caption} />}
    //   <div className={styles.root}>
    //     <IconButton aria-label="delete" size="large" onClick={onPlayClick}>
    //       {playing ? (
    //         <StopIcon fontSize="large" />
    //       ) : (
    //         <PlayCircleFilledIcon fontSize="large" />
    //       )}
    //     </IconButton>

    //     <div className={styles.audioWrapper}>
    //       <p>{fileName}</p>

    //       <LinearProgress variant="determinate" value={20} />

    //       {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
    //       <audio ref={audioEl} onPlay={handlePlay}>
    //         <source src={src} />
    //       </audio>
    //       <p>{getFileSize(size)}</p>
    //       <p>
    //         {`${Math.trunc(duration / 60)}:${`00${duration % 60}`.slice(-2)}`}
    //       </p>
    //     </div>
    //   </div>
    // </Typography>
  );
};

AudioContent.propTypes = {
  fileName: PropTypes.string,
  src: PropTypes.string,
  caption: PropTypes.string,
  duration: PropTypes.number,
  size: PropTypes.number,
};

AudioContent.defaultProps = {
  src: '',
  duration: 0,
  caption: '',
  size: 0,
  fileName: '',
};

export default AudioContent;
