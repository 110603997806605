import React, { useState } from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Add } from '@material-ui/icons';
import { ReactComponent as CheckBoxOn } from '../../../../../../../assets/img/checkbox-on.svg';
import { ReactComponent as CheckBoxOff } from '../../../../../../../assets/img/checkbox-off.svg';

export default function Tasks({
  phone,
  leadId,
  items,
  handleCreateTask,
  handleCompleteTask,
}) {
  const { t } = useTranslation();
  const [text, setText] = useState('');
  const [date, setDate] = useState(moment().add(5, 'minutes'));

  const onCreateTask = () => {
    handleCreateTask(phone, text, leadId, date.unix());
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      onCreateTask();
    }
  };
  const handleChange = (event) => {
    setText(event.target.value);
  };
  const handleChangeDate = (event) => {
    const selected = moment(event.target.value);
    const minDateTime = moment().add(5, 'minutes');
    if (selected.isBefore(minDateTime)) {
      setDate(minDateTime);
    } else {
      setDate(selected);
    }
  };
  return (
    <Wrapper>
      {items.map((item, index) => (
        <Task key={index} resolved={!item.open}>
          {item.open ? (
            <CheckBoxOff onClick={() => handleCompleteTask(phone, item.id)} />
          ) : (
            <CheckBoxOn />
          )}
          <div className="text">{item.text || t('common.noname')}</div>
          <div className="date">
            {moment(item.date * 1000).format('DD.MM.YYYY HH:mm')}
          </div>
        </Task>
      ))}
      <Task>
        <input
          className="input"
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          placeholder={`${t('tasksTool.create')}...`}
        />
        <input
          min={moment().add(5, 'minutes').format('YYYY-MM-DDTHH:mm')}
          className="date"
          color="secondary"
          onChange={handleChangeDate}
          value={date.format('YYYY-MM-DDTHH:mm')}
          type="datetime-local"
        />
        <Add className="create-icon" onClick={onCreateTask} />
      </Task>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin-top: 3px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

const Task = styled.div`
  .create-icon {
    color: #7e7f83;
    font-size: 18px;
    &:hover {
      color: #000;
    }
  }
  .text {
    flex: 1;
    font-size: 14px;
    overflow: hidden;
  }
  input {
    border: none;
    outline: none;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  }
  .input {
    flex: 1;
  }
  ${(props) => props.resolved && 'text-decoration: line-through'};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 6.75px;
  padding: 8.75px;
  background: #ffffff;
  border-radius: 6px;
`;

Tasks.propTypes = {
  phone: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  leadId: PropTypes.number.isRequired,
  handleCreateTask: PropTypes.func.isRequired,
  handleCompleteTask: PropTypes.func.isRequired,
};
