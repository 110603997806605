import {
  UPDATE_STATISTICS,
  STATISTICS_LOADING_SET,
  STATISTICS_LOADING_UNSET,
  STATISTICS_SUBSCRIBED,
} from 'store/actions';
import {
  STATISTICS_SET_CURRENT_DIALOG_TAGS,
  STATISTICS_SET_SELECTED_COMPANY,
  STATISTICS_SET_TAGS,
  STATISTICS_SET_SELECTED_PROVIDER,
  STATISTICS_SET_SELECTED_DEPARTMENT,
} from 'store/actions/statisticsActions';

const initialState = {
  statistics: {},
  isLoading: false,
  isSubscribed: false,
  dialogsCount: 0,
  tags: {total: 0, rows: []},
  selectedCompany: null,
  selectedProviders: null,
  selectedDepartment: { assignee: null, type: 'all' },
  currentDialogTags: [],
};

const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case STATISTICS_SET_CURRENT_DIALOG_TAGS:
      return { ...state, currentDialogTags: action.payload };
    case STATISTICS_SET_SELECTED_COMPANY:
      return { ...state, selectedCompany: action.payload };
    case STATISTICS_SET_SELECTED_PROVIDER:
      return { ...state, selectedProviders: action.payload };
    case STATISTICS_SET_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartment: action.payload };
    case UPDATE_STATISTICS:
      return { ...state, statistics: action.payload };
    case STATISTICS_SET_TAGS:
      return { ...state, tags: action.payload };
    case STATISTICS_LOADING_SET:
      return { ...state, isLoading: true, dialogsCount: action.payload };
    case STATISTICS_LOADING_UNSET:
      return { ...state, isLoading: false, dialogsCount: 0 };
    case STATISTICS_SUBSCRIBED:
      return { ...state, isSubscribed: true };
    default:
      return state;
  }
};

export default statisticsReducer;
