import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';

const Wrapper = styled.div`
    display: flex;
    .monetary-value {
        border-radius: 4px 0 0 4px;
        border: solid 1px #eeeff2;
        background-color: #f6f8fc;
        font-size: 15px;
        color: #3f434c;
        padding: 10px;
        width: 100%;
        outline: none;
        border-right: none;
    }
    .monetary-select__control {
        border-radius: 3px;
        border: solid 1px #eeeff2;
        background-color: #f6f8fc;
        font-size: 15px;
        color: #3f434c;
        width: 110px;
        border-radius: 0 4px 4px 0;
        height: 40px;
    }
`;

const mockMonataryTypes = [
  { label: 'RUB', value: 'RUB' },
  { label: 'USD', value: 'USD' },
  { label: 'EUR', value: 'EUR' },
  { label: 'RSD', value: 'RSD' },
];

const MonetaryField = (props) => {
  const {
    id,
    value,
    onChange,
    placeholder,
  } = props;

  const [inputValue, setInputValue] = useState(value.substring(0, value.length - 4));
  const [selectValue, setSelectValue] = useState(value ? value.substring(value.length - 4) : mockMonataryTypes[0].value);

  const handleChangeInput = (v) => {
    if (/^-?\d+\.?\d*$/.test(v)) {
      setInputValue(v);
      const newValue = `${v}${selectValue}`;
      onChange(newValue);
    }
  };

  const handleChangeSelect = (v) => {
    setSelectValue(v.value);
    const newValue = `${inputValue}${v.value}`;
    onChange(newValue);
  };

  return (
    <Wrapper>
      <input
        id={id}
        className="monetary-value"
        value={inputValue}
        onChange={(e) => handleChangeInput(e.target.value)}
        placeholder={placeholder}
      />
      <Select
        classNamePrefix="monetary-select"
        id="monetaryType"
        options={mockMonataryTypes}
        value={{ label: selectValue, value: selectValue }}
        onChange={handleChangeSelect}
      />
    </Wrapper>
  );
};

MonetaryField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

MonetaryField.defaultProps = {
  placeholder: 'Значение поля',
};

export default MonetaryField;
