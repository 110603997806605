import React, { useState } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { IconButton } from '@material-ui/core';
import { Close, Code, Favorite } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { getPersone, getName } from 'utils/personeHelper';
import { getImage, getVideo } from 'utils/userHelper';

import { fetchPersoneByUuid } from 'store/actions';

import VideoContent from 'views/Chat/components/ConversationDetails/components/ConversationMessage/components/Video';
import {
  MessageWrapper,
  useMsgStyles,
} from 'views/Chat/components/ConversationDetails/components/ConversationMessage/ConversationMessage';
import PictureContent from 'views/Chat/components/ConversationDetails/components/ConversationMessage/components/Picture';
import { DebugView } from 'components';

export default function Post({ post }) {
  const { t } = useTranslation();
  const classes = useMsgStyles();

  const dispatch = useDispatch();
  const storageFiles = useSelector((state) => state.post.storageFiles);
  const currentUser = useSelector((state) => state.session.user);

  const [hideText, setHideText] = useState(true);
  const [username, setUsername] = useState(undefined);
  const [isDebug, setIsDebug] = useState(false);

  React.useEffect(() => {
    if (post.ids.uuidPersone) {
      getPersone(post.ids.uuidPersone).then((personeData) => {
        if (_.get(personeData, 'info')) {
          setUsername(getName(personeData, true));
        } else {
          dispatch(fetchPersoneByUuid(post.ids.uuidPersone));
        }
      });
    }
  }, [post]);

  const switchDebug = () => {
    setIsDebug(!isDebug);
  };

  const renderContent = () => {
    const {
      type,
      data: { url, thumbnailUrl, items },
    } = post.content;
    switch (type) {
      case 'IMAGE': {
        if (['instagramOriginal', 'vk'].includes(post.provider.messenger)) {
          if (items) {
            return (
              <div className="images">
                {items.map((item, index) => (
                  <PictureContent
                    key={index}
                    fullWidth
                    alt="Attachment"
                    src={item}
                  />
                ))}
              </div>
            );
          }
          return <PictureContent fullWidth alt="Attachment" src={url} />;
        }
        const image = getImage(post, storageFiles);
        const sizes = _.get(post, 'content.data.sizes');
        const imageSource = sizes
          ? _.get(
              sizes.sort((left, right) => left.size - right.size),
              '0.fileUrl',
              undefined,
            )
          : null;
        return <PictureContent src={image.src} downloadUrl={imageSource} />;
      }
      case 'VIDEO': {
        if (post.provider.messenger === 'instagramOriginal') {
          return <VideoContent src={url} poster={thumbnailUrl} isPost />;
        }
        const video = getVideo(post, storageFiles);
        return <VideoContent src={video.src} />;
      }
      default:
        return (
          <div
            className="user-avatar"
            style={{ backgroundImage: `url(${url})` }}
          />
        );
    }
  };

  const content = React.useMemo(() => renderContent(), [post]);
  const isDeveloper = _.get(currentUser, 'group', '') === 'developer';

  return (
    <div>
      {isDeveloper && (
        <DebugIcon>
          <IconButton aria-label="delete" size="small" onClick={switchDebug}>
            {!isDebug ? (
              <Code fontSize="inherit" />
            ) : (
              <Close fontSize="inherit" />
            )}
          </IconButton>
        </DebugIcon>
      )}
      <View className={classes.root}>
        {!isDebug && (
          <div className={classes.msgBody}>
            <div className={`${classes.body} message-body`}>
              <Content className={classes.content}>{content}</Content>
              <div className="author">{username || <span>&nbsp;</span>}</div>
              <div className={hideText ? `caption hide` : `caption`}>
                {post.content.data?.caption || post.content.data?.text}
              </div>
              <div className="hide-icon" onClick={() => setHideText(!hideText)}>
                {hideText ? t('common.show') : t('common.hide')}
              </div>
              <div className="footer">
                <div className="like-count">
                  <div className="like-count-icon">
                    <Favorite className="like-icon" />
                  </div>
                  <div className="like-count-text">
                    {post.additional?.countLike}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </View>
      {isDebug && (
        <div>
          <DebugView src={{ ...post }} title="Debug post" />
        </div>
      )}
    </div>
  );
}

const Content = styled.div`
  border-radius: 4px;
  overflow: hidden;
`;

const DebugIcon = styled.div`
  position: sticky;
  float: right;
  top: 0;
`;

const View = styled(MessageWrapper)`
  max-width: 75%;
  overflow: hidden;
  padding: 10px;
  .images {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: auto;
  }
  .images div:nth-child(1) {
    grid-column-start: 1;
    grid-column-end: 4;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  .images div:nth-child(2) {
    grid-column-start: 4;
    grid-column-end: 7;
    grid-row-start: 1;
    grid-row-end: 6;
  }
  .reply-to-post {
    cursor: pointer;
  }
  .footer {
    padding-top: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .hide-icon {
    padding-top: 2px;
    font-size: 12px;
    line-height: 17px;
    color: #006ae4;
    cursor: pointer;
    user-select: none;
  }
  .author {
    padding-top: 7px;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #000000;
  }
  .caption {
    padding-top: 2px;
    overflow: hidden;
    word-break: break-word;
  }
  .caption.hide {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  .like-count {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  .like-count-text {
    font-size: 12px;
    line-height: 15px;
    color: #a6a6a6;
  }
  .like-count-icon {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .like-icon {
    font-size: 15px;
    color: #a6a6a6;
  }
`;

Post.propTypes = {
  post: PropTypes.object.isRequired,
};

Post.defaultProps = {};
