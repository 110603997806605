import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  width: 46px;
  height: 24px;
  border-radius: 12px;
  background-color: ${(props) => (props.isOn ? '#006ae4' : '#bdbdbd')};
  background-image: ${(props) =>
    props.isOn && props.backgroundImgForOn
      ? `url(${props.backgroundImgForOn})`
      : 'none'};
  background-repeat: no-repeat;
  background-position: 9px 6px;
  position: relative;
  cursor: pointer;
  .button-oval {
    width: 18px;
    height: 18px;
    box-shadow: -1px 1px 3px 0 rgba(0, 0, 0, 0.25);
    background-color: #ffffff;
    border-radius: 50%;
    position: absolute;
    left: ${(props) => (!props.isOn ? '3px' : '25px')};
    top: 3px;
    transition: left 0.2s;
  }
`;

const Switcher = ({ checked, onChange, ...props }) => (
  <Wrapper
    {...props}
    isOn={checked}
    onClick={(event) => onChange(!checked, event)}
  >
    <div className="button-oval" />
  </Wrapper>
);
Switcher.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
};
Switcher.defaultProps = {
  onChange: () => {},
  checked: false,
};

export default Switcher;
