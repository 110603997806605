import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSwipeable } from 'react-swipeable';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/styles';

import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = (appWidth) => makeStyles(() => ({
  root: {

  },
  paper: {
    width: appWidth > 960 ? 333 : '100%',
    padding: 0,
  },
}));

const CloseButton = styled.div`
    position: absolute;
    top: 18px;
    right: 15px;
    cursor: pointer;
    .MuiSvgIcon-root{
        color: #215fdc;
    }
`;

const PopupBar = (props) => {
  const {
    appWidth,
    open,
    children,
    handleClosePopup,
  } = props;
  const { t } = useTranslation();
  const classes = useStyles(appWidth)();
  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Right') {
        handleClosePopup();
      }
    },
  });
  return (
    <Drawer
      open={open}
      classes={classes}
      anchor="right"
      variant="persistent"
      {...swipeHandlers}
    >
      {children || <h2>{t('popupBar.underDevelopment')}</h2>}
      <CloseButton onClick={handleClosePopup}><CancelIcon /></CloseButton>
    </Drawer>
  );
};

PopupBar.propTypes = {
  appWidth: PropTypes.number,
  open: PropTypes.bool,
  children: PropTypes.node,
  handleClosePopup: PropTypes.func,
};

PopupBar.defaultProps = {
  appWidth: 0,
  open: false,
  children: null,
  handleClosePopup: () => false,
};

export default PopupBar;
