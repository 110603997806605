import { wsSndMessage } from './wsActions';
import { apiDelete, apiGet, apiPost, apiPut } from '../../api';
import { HOST } from '../../config';

export const SET_IS_TAB_COUNT_STATE = 'SET_IS_TAB_COUNT_STATE';
export const SET_SOUND_STATE = 'SET_SOUND_STATE';
export const SET_NOTIFICATION_STATE = 'SET_NOTIFICATION_STATE';
export const SET_LANGUAGE = 'SET_LANGUAGE';
export const SET_SELECTED_PROVIDERS = 'SET_SELECTED_PROVIDERS';
export const SET_DISABLED_PROVIDERS = 'SET_DISABLED_PROVIDERS';
export const SET_ASSIGNEE_FILTER = 'SET_ASSIGNEE_FILTER';
export const SET_POST_MODE = 'SET_POST_MODE';
export const SET_CUSTOM_FILTER = 'SET_CUSTOM_FILTER';
export const SET_DIALOG_STATUS_FILTER = 'SET_DIALOG_STATUS_FILTER';

export const TOGGLE_FILTER_BLOCK = 'TOGGLE_FILTER_BLOCK';
export const TOGGLE_PROVIDERS_BLOCK = 'TOGGLE_PROVIDERS_BLOCK';
export const TOGGLE_DISABLED_PROVIDERS_BLOCK =
  'TOGGLE_DISABLED_PROVIDERS_BLOCK';
export const SET_GROUP_FILTER = 'SET_GROUP_FILTER';

export const ENABLE_CHAT_NOTIFICATION = 'ENABLE_CHAT_NOTIFICATION';
export const DISABLE_CHAT_NOTIFICATION = 'DISABLE_CHAT_NOTIFICATION';

export const ENABLE_DARK_MODE = 'ENABLE_DARK_MODE';
export const DISABLE_DARK_MODE = 'DISABLE_DARK_MODE';

export const SET_EXACT_SEARCH = 'SET_EXACT_SEARCH';

export const enableDarkMode = () => ({ type: ENABLE_DARK_MODE });
export const disableDarkMode = () => ({ type: DISABLE_DARK_MODE });

export const SET_FILTER_STATE_DATE = 'SET_FILTER_STATE_DATE';

export const SET_PREFERRED_COMPANY_PROVIDER = 'SET_PREFERRED_COMPANY_PROVIDER';

export const SET_ROUTING_SCRIPTS = 'SET_ROUTING_SCRIPTS';

export const SET_ACCESS_TO_SETTINGS = 'SET_ACCESS_TO_SETTINGS';

export const setExactSearch = (payload) => ({
  type: SET_EXACT_SEARCH,
  payload,
});

export const setFilterStateDate = (date) => ({
  type: SET_FILTER_STATE_DATE,
  payload: date,
});

export const disableChatNotification = (uuidDialog) => ({
  type: DISABLE_CHAT_NOTIFICATION,
  payload: { uuidDialog },
});

export const enableChatNotification = (uuidDialog) => ({
  type: ENABLE_CHAT_NOTIFICATION,
  payload: { uuidDialog },
});
export const setGroupFilter = () => ({
  type: SET_GROUP_FILTER,
});

export const setIsTabCountOnState = (isTabCountStateOn) => ({
  type: SET_IS_TAB_COUNT_STATE,
  isTabCountStateOn,
});

export const setSoundState = (soundState) => ({
  type: SET_SOUND_STATE,
  soundState,
});

export const setNotificationState = (notificationState) => ({
  type: SET_NOTIFICATION_STATE,
  notificationState,
});

export const setLanguage = (language) => ({
  type: SET_LANGUAGE,
  language,
});

export const setSelectedProviders = (data) => ({
  type: SET_SELECTED_PROVIDERS,
  data,
});

export const setDisabledProviders = (data) => ({
  type: SET_DISABLED_PROVIDERS,
  data,
});

export const setAssigneeFilter = (payload) => ({
  type: SET_ASSIGNEE_FILTER,
  payload,
});

export const setPostMode = (payload) => ({
  type: SET_POST_MODE,
  payload,
});

export const setCustomFilter = (filter) => ({
  type: SET_CUSTOM_FILTER,
  filter,
});

export const setDialogStatusFilter = (status) => ({
  type: SET_DIALOG_STATUS_FILTER,
  status,
});

export const toggleFilterBlock = (status) => ({
  type: TOGGLE_FILTER_BLOCK,
  status,
});

export const toggleProvidersBlock = (status) => ({
  type: TOGGLE_PROVIDERS_BLOCK,
  status,
});

export const toggleDisabledProvidersBlock = (status) => ({
  type: TOGGLE_DISABLED_PROVIDERS_BLOCK,
  status,
});

export const sendCustomerFilter = (filterStatus) => async (dispatch) => {
  wsSndMessage({
    action: 'SET_CUSTOM_FILTER',
    data: {
      filter: {
        favoritesFilter: filterStatus,
      },
    },
  })(dispatch);
};

export const setPreferredCompanyProvider = (uuid) => ({
  type: SET_PREFERRED_COMPANY_PROVIDER,
  uuid,
});

export const setRoutingScripts = (scripts) => ({
  type: SET_ROUTING_SCRIPTS,
  scripts,
});

export const setAccessesToSettings = (accessesToSettings) => {
  const groupedUsersByDepartment = {};

  for (const person of accessesToSettings.company.users) {
    const departmentName = person.department_name;

    if (!groupedUsersByDepartment[departmentName]) {
      groupedUsersByDepartment[departmentName] = [];
    }

    groupedUsersByDepartment[departmentName].push(person);
  }

  return {
    type: SET_ACCESS_TO_SETTINGS,
    accessesToSettings: {
      ...accessesToSettings,
      groupedUsersByDepartment,
      departments: Object.keys(groupedUsersByDepartment),
    },
  };
};

export const fetchRoutingScripts = () => (dispatch, getState) =>
  apiGet({
    dispatch,
    url: `${HOST}/chat/history/dialog-rotes/${
      getState().session.selectedCompany
    }/list`,
    tokens: getState().session.tokenData,
    callback: (scripts) => {
      dispatch(setRoutingScripts(scripts));
    },
  });

export const createRoutingScript = (body) => (dispatch, getState) =>
  apiPost({
    dispatch,
    url: `${HOST}/chat/history/dialog-rotes/${
      getState().session.selectedCompany
    }`,
    tokens: getState().session.tokenData,
    body,
    callback: () => {
      dispatch(fetchRoutingScripts(getState().session.selectedCompany));
    },
  });

export const changeRoutingScript =
  (bodyFragment, routeUuid) => (dispatch, getState) =>
    apiPut({
      dispatch,
      url: `${HOST}/chat/history/dialog-rotes/${
        getState().session.selectedCompany
      }/${routeUuid}`,
      tokens: getState().session.tokenData,
      body: bodyFragment,
      callback: () => {
        dispatch(fetchRoutingScripts(getState().session.selectedCompany));
      },
    });

export const deleteRoutingScript = (routeUuid) => (dispatch, getState) =>
  apiDelete({
    dispatch,
    url: `${HOST}/chat/history/dialog-rotes/${
      getState().session.selectedCompany
    }/${routeUuid}`,
    tokens: getState().session.tokenData,
    callback: () => {
      dispatch(fetchRoutingScripts(getState().session.selectedCompany));
    },
  });

export const fetchAccessToRoutingSettings = () => (dispatch, getState) =>
  apiGet({
    dispatch,
    url: `${HOST}/company/${getState().session.selectedCompany}/access/routing`,
    tokens: getState().session.tokenData,
    callback: (accesses) => {
      dispatch(setAccessesToSettings(accesses.data));
    },
  });

export const changeAccessToRoutingSettings = (data) => (dispatch, getState) =>
  apiPut({
    dispatch,
    url: `${HOST}/company/${getState().session.selectedCompany}/access/routing`,
    tokens: getState().session.tokenData,
    body: data,
    callback: () => {
      dispatch(fetchAccessToRoutingSettings());
    },
  });
