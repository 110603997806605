import React from 'react';
import PropTypes from 'prop-types';
import { Lottie } from '@crello/react-lottie';
import { Emojione } from 'react-emoji-render';
import { Typography } from '@material-ui/core';
import axios from 'axios';
import fileDownload from 'js-file-download';

import styled from 'styled-components';
import IconButton from '@material-ui/core/IconButton';
import GetAppIcon from '@material-ui/icons/GetApp';

const StyledGridContainer = styled.div`
  line-height: 0;
  column-count: 2;
  column-gap: 0;
  img{
  width: 100% !important;
  height: auto !important;
  }
`;

function randomInteger(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const DownloadWrapper = styled.div`
  position: relative;

  .download {
    position: absolute;
    right: 0;
  }
`;

const PictureGroup = ({
  src,
  caption,
  loading,
  onOpenGallery,
  downloadUrl,
}) => {
  const loaderOptions = {
    loop: true,
    path: '/lottiefiles/fileLoader.json',
  };

  const handleDownload = () => {
    onOpenGallery();
    axios
      .get(downloadUrl, {
        responseType: 'blob',
      })
      .then((res) => {
        fileDownload(res.data, 'image.png');
      });
  };
  // eslint-disable-next-line no-console

  return React.useMemo(
    () => (
      <Typography color="inherit" variant="body1" component="div">
        {(!src || loading) && (
          <>
            <Lottie config={loaderOptions} height={150} width={150} />
            <Typography color="inherit" variant="caption">
              Loading image...
            </Typography>
          </>
        )}

        {src && (
          <StyledGridContainer>
            {new Array(6).fill(undefined)
              .map(() => ({
                cols: randomInteger(1, 2),
                img: `https://picsum.photos/400/400?random=${randomInteger(1, 120)}`,
              }))
              .map((tile) => (
                <>
                  {downloadUrl && (
                    <DownloadWrapper>
                      <div className="download">
                        <IconButton aria-label="delete" onClick={handleDownload}>
                          <GetAppIcon />
                        </IconButton>
                      </div>
                    </DownloadWrapper>
                  )}
                  <img src={tile.img} alt={tile.title} />

                </>

              ))}
          </StyledGridContainer>
        )}

        {caption && (
          <Emojione
            size={64}
            svg
            text={caption}
            style={{ wordBreak: 'break-word' }}
          />
        )}
      </Typography>
    ),
    [loading, src, caption],
  );
};
PictureGroup.propTypes = {
  src: PropTypes.string,
  caption: PropTypes.string,
  loading: PropTypes.bool,
  onOpenGallery: PropTypes.func.isRequired,
  downloadUrl: PropTypes.string.isRequired,
};

PictureGroup.defaultProps = {
  src: '',
  caption: '',
  loading: false,
};

export default PictureGroup;
