import update from 'immutability-helper';
import { set } from 'idb-keyval';
import loadFile from '../../utils/loadFileB64';
import * as actionTypes from '../actions';

const initialState = {
  files: {},
};

const filesReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CHAT_UPDATE_FILE: {
      const { uuid, data } = payload;
      const file = Object.values(state.files).find((o) => o.requestUuid === uuid);
      if (!file) return state;
      // TODO: MOVE TO ACTIONS
      if (data.type === 'file.src') {
        loadFile(data.value.fileSrc).then((b64) => {
          set(`file:${file.fileId}`, b64);
        });
      } else if (data.type === 'file.base64') {
        set(`file:${file.fileId}`, data.value.file);
      }
      return update(state, {
        files: {
          $set: {
            ...state.files,
            [file.fileId]: {
              fileSize: data.value.fileSize || null,
              status: 'loaded',
            },
          },
        },
      });
    }

    case actionTypes.CHAT_WAIT_FILE: {
      const { uuid, data: { fileId } } = payload;
      return update(state, {
        files: {
          $set: {
            ...state.files,
            [fileId]: { fileId, requestUuid: uuid, status: 'wait' },
          },
        },
      });
    }

    default: {
      return state;
    }
  }
};

export default filesReducer;
