export const getMsgOriginalID = (msg) => {
  let uuid = null;
  if (msg.type === 'in') {
    uuid = msg.uuid;
  }
  if (msg.type === 'out') {
    if (msg && msg.ids && msg.ids.uuidMessage) {
      uuid = msg.ids.uuidMessage;
    }
  }
  if (uuid) {
    uuid = uuid.split(':');
    if (uuid.length === 2) {
      return uuid[1];
    }
  }
  return null;
};

export const getMsgOriginalIDForReply = (msg) => {
  let uuid = null;
  if (msg.type === 'in') {
    uuid = msg.uuid;
  }
  if (msg.type === 'out') {
    if (msg && msg.ids && msg.ids.uuidMessage) {
      uuid = msg.ids.uuidMessage;
    }
  }
  if (uuid) {
    uuid = uuid.split(':');
    if (uuid.length === 2) {
      return uuid[1];
    }
    if (uuid.length === 1) {
      return uuid[0];
    }
  }
  return null;
};
