import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { FlexDiv } from './FlexDiv';

function classCalc(quantity) {
  if (quantity > 70) {
    return ' big';
  }
  if (quantity > 20) {
    return ' medium';
  }
  if (quantity > 0) {
    return ' small';
  }
  return '';
}

const TableChart = (props) => {
  const { t } = useTranslation();
  const [hoveredItem, setHoveredItem] = useState(null);
  // const yData = [
  //   '0:00-3:00',
  //   '3:00-6:00',
  //   '6:00-9:00',
  //   '9:00-12:00',
  //   '12:00-15:00',
  //   '15:00-18:00',
  //   '18:00-21:00',
  //   '21:00-00:00',
  // ];

  // const xData = [
  //   { label: '20.09', dataset: [10, 10, 10, 100, 100, 10, 10, 10] },
  //   { label: '21.09', dataset: [10, 10, 10, 10, 10, 10, 10, 10] },
  //   { label: '22.09', dataset: [10, 0, 10, 50, 50, 10, 10, 10] },
  //   { label: '23.09', dataset: [10, 10, 0, 50, 50, 10, 10, 10] },
  //   { label: '24.09', dataset: [10, 10, 10, 10, 10, 0, 0, 10] },
  //   { label: '25.09', dataset: [10, 0, 10, 10, 10, 10, 50, 10] },
  // ];

  const mouseOverHandler = (e) => {
    const { target } = e;
    const coords = target.getBoundingClientRect();
    setHoveredItem({
      time: target.dataset.time,
      value: target.dataset.value,
      x:
        target.offsetLeft +
        coords.width / 2 -
        document?.querySelector('.item-info')?.clientWidth / 2,
      y: target.offsetTop - coords.height * 2.3,
    });
  };

  document.addEventListener('mousemove', () => {
    setHoveredItem(null);
  });

  return (
    <>
      <div className="lineChart-wrapper">
        {props.title && <h5 className="graph-title">{props.title}</h5>}
        <FlexDiv justify="space-between">
          {props.statisticDesc1 && (
            <FlexDiv
              style={{ width: 'calc(50%-5px)' }}
              direction="column"
              rGap="2px"
              margin="0 0 24px 0"
            >
              <span className="statistic-quantity">
                {props.statisticQuantity1}
              </span>
              <span className="statistic-desc">
                {props.statisticDesc1}
                {props.question &&  <span className="statistic-question-mark">
                  <p className="statistic-question">{props.question}</p>
                </span>}
              </span>
            </FlexDiv>
          )}
          {props.statisticDesc2 && (
            <FlexDiv direction="column" rGap="2px" margin="0 0 24px 0">
              <span className="statistic-quantity">
                {props.statisticQuantity2}
              </span>
              <span className="statistic-desc">{props.statisticDesc2}</span>
            </FlexDiv>
          )}
        </FlexDiv>
        {props.desc && <p className="graph-desc">{props.desc}</p>}
      </div>
      <StyledDivChart col={props?.data?.y ? props.data.y.length + 1 : 0}>
        <div className="chart-wrapper">
          <div className="label-y">
            {props?.data?.y
              ? props.data.y.map((data) => <span key={data}>{data}</span>)
              : []}
          </div>
          <StyledChart col={props?.data?.x ? props.data.x.length : 0}>
            <div
              className="chart-items"
              onMouseOut={() => {
                setHoveredItem(null);
              }}
              onBlur={() => {
                setHoveredItem(null);
              }}
            >
              {props?.data?.x
                ? props.data.x.map((d) => (
                    <div
                      key={d.label}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                        rowGap: '2px',
                      }}
                    >
                      {d?.dataset
                        ? d.dataset.map((data, index) => (
                            <div
                              onMouseOver={mouseOverHandler}
                              onFocus={mouseOverHandler}
                              onMouseOut={() => {
                                setHoveredItem(null);
                              }}
                              onBlur={() => {
                                setHoveredItem(null);
                              }}
                              data-time={props.data.y[index]}
                              data-value={data}
                              key={Math.random()}
                              className={`chart-item${classCalc(data)}`}
                            />
                          ))
                        : []}
                    </div>
                  ))
                : []}

              <StyledItemInfo
                style={{ opacity: `${hoveredItem ? 1 : 0}` }}
                onMouseOver={(e) => {
                  e.stopPropagation();
                }}
                top={hoveredItem ? hoveredItem.y : 50}
                left={hoveredItem ? hoveredItem.x : -1000}
                className="item-info"
              >
                <span>{hoveredItem?.time}</span>
                <div>
                  <div
                    className={`indicator${classCalc(hoveredItem?.value)}`}
                  />
                  <span>
                    {t('graphInfo.graphTitle3')}:{hoveredItem?.value}
                  </span>
                </div>
              </StyledItemInfo>
            </div>
            <div className="label-x">
              {props?.data?.x ? props.data.x.map((data) => (
                <span key={Math.random()}>{props.data.key === 'weekdays' ? t(`graphInfo.weekdays.${data.label}`) : data.label}</span>
              )): []}
            </div>
          </StyledChart>
        </div>
      </StyledDivChart>
    </>
  );
};

const StyledDivChart = styled.div`
  padding: 12px 12px 19px 12px;
  background: #ffffff;
  min-height: 233px;
  border: 1px solid rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  .chart-wrapper {
    display: grid;
    grid-template-columns: 1fr 8fr;
    column-gap: 8px;
    @media (max-width: 1575px) {
      grid-template-columns: 1.5fr 8fr;
    }
    @media (max-width: 1275px) {
      grid-template-columns: 1.8fr 8fr;
    }
    @media (max-width: 1150px) {
      grid-template-columns: 2.1fr 8fr;
    }
    @media (max-width: 1056px) {
      grid-template-columns: 1.4fr 8fr;
    }
    @media (max-width: 925px) {
      grid-template-columns: 1.8fr 8fr;
    }
  }

  .label-y {
    display: grid;
    grid-template-rows: repeat(${props => props.col}, 1fr);
    grid-gap: 2px;
    color: rgba(127, 127, 127, 0.7);
    font-weight: 400;
    justify-content: right;
    font-size: 10px;
    line-height: 14px;
    font-family: 'Helvetica';
    span {
      height: 24px;
      display: flex;
      justify-content: right;
      align-items: center;
    }
  }
`;

const StyledChart = styled.div`
  .chart {
    display: flex;
    flex-direction: column;
  }
  .label-x {
    margin-top: 3px;
    display: grid;
    grid-template-columns: repeat(
      ${(props) => (props.col ? props.col : '0')},
      1fr
    );
    span {
      font-weight: 400;
      font-size: 10px;
      line-height: 14px;
      text-align: center;
      color: rgba(127, 127, 127, 0.7);
    }
  }
  .chart-items {
    position: relative;
    display: grid;
    grid-gap: 2px;
    grid-template-columns: repeat(
      ${(props) => (props.col ? props.col : '0')},
      1fr
    );

    .chart-item {
      width: 100%;
      height: 24px;
      background: #f0f0f0;
      border-radius: 2px;
    }
    .chart-item.big {
      background: #f5692d;
    }
    .chart-item.medium {
      background: linear-gradient(
          0deg,
          rgba(27, 149, 17, 0.2),
          rgba(27, 149, 17, 0.2)
        ),
        #c7ffd6;
    }
    .chart-item.small {
      background: #c7ffd6;
    }
  }
`;

const StyledItemInfo = styled.div`
  position: absolute;
  max-height: 58px;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: #ffffff;
  row-gap: 3px;
  user-select: none;
  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: calc(50% - 5.5px);
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 9px solid rgba(0, 0, 0, 0.5);
  }

  span {
    white-space: nowrap;
    padding-left: 3px;
    font-size: 12px;
    font-weight: 400;
  }

  div {
    margin-bottom: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .indicator {
    min-width: 13px;
    max-width: 13px;
    height: 13px;
    background: #f0f0f0;
    border: 1px solid #ffffff;
    border-radius: 4px;
    &.big {
      background: #f5692d;
    }
    &.small {
      background: #c7ffd6;
    }
    &.medium {
      background: linear-gradient(
          0deg,
          rgba(27, 149, 17, 0.2),
          rgba(27, 149, 17, 0.2)
        ),
        #c7ffd6;
    }
  }
`;

TableChart.propTypes = {
  title: PropTypes.string.isRequired,
  statisticDesc1: PropTypes.string.isRequired,
  statisticQuantity1: PropTypes.string.isRequired,
  statisticDesc2: PropTypes.string.isRequired,
  statisticQuantity2: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  data: PropTypes.object.isRequired,
  question: PropTypes.string.isRequired,
};

export default TableChart;
