import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import moment from 'moment';
import {isToday} from 'date-fns';
import _ from 'lodash';

import {useDispatch, useSelector} from 'react-redux';
import {
  Avatar, Grid, Tooltip, Typography,
} from '@material-ui/core';
import {chatReplyMessage, fetchPersoneByUuid} from 'store/actions';
import {getAvatar, getName, getPersone} from 'utils/personeHelper';
import genAvatar from 'utils/genAvatar';

import {MessageWrapper, useMsgStyles} from 'views/Chat/components/ConversationDetails/components/ConversationMessage/ConversationMessage';
import { ReactComponent as Reply } from '../../../../../../../assets/img/reply.svg';
import { ReactComponent as ReplyPersonally } from '../../../../../../../assets/img/reply_personaly.svg';
import { ReactComponent as ReplyPersonalComplete } from '../../../../../../../assets/img/reply_personal_complete.svg';
import { ReactComponent as ReplyComplete } from '../../../../../../../assets/img/reply_complete.svg';

import Replies from '../Replies';


export default function PostComment ({
  comment,
  postAuthor,
  provider,
}) {
  const {t} = useTranslation();
  const classes = useMsgStyles();
  const dispatch = useDispatch();

  const self = React.useRef();
  const newReply = useSelector(chat => chat.post.newReply);

  const [expandReplies, setExpandReplies] = React.useState(false);
  const [participantPersone, setParticipantPersone] = React.useState(undefined);
  const [username, setUserName] = React.useState(undefined);
  const [avatarSrc, setAvatarSrc] = React.useState(undefined);
  const memorizedDate = React.useMemo(
    () => (isToday(comment.date.created*1000)
      ? `${t('common.date.today')} ${moment(comment.date.created*1000).format(
        'HH:mm',
      )}`
      : moment(comment.date.created*1000).format('HH:mm DD.MM')),
    [comment.date.created],
  );

  React.useEffect(() => {
    if (participantPersone) {
      setUserName(getName(participantPersone, true));
      setAvatarSrc(getAvatar(participantPersone) || genAvatar(comment.ids.uuidPersone));
    }
  }, [participantPersone]);
  React.useEffect(() => {
    if (comment.ids.uuidPersone) {
      getPersone(comment.ids.uuidPersone).then((personeData) => {
        if (_.get(personeData, 'info')) {
          setParticipantPersone(personeData);
        } else {
          dispatch(fetchPersoneByUuid(comment.ids.uuidPersone));
        }
      });
    }
  }, [comment]);
  React.useEffect(() => {
    if (newReply.ids && newReply.ids.uuidMessengerParent === comment.ids.uuidMessengerComment) {
      setExpandReplies(true);
      self.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
    if (self && newReply.ids && newReply.ids.uuidMessengerComment === comment.ids.uuidMessengerComment) {
      self.current.scrollIntoView({
        behavior: 'smooth',
        block: newReply.ids.uuidMessengerParent ? 'center' : 'end',
      });
    }
  }, [newReply]);
  const handleReply = (commentData, name, content, personally) => {
    dispatch(chatReplyMessage({comment: commentData, username: name, content, personally}));
  }

  const isPostAuthor = comment.ids.uuidMessengerUser === postAuthor;

  const getText = () => {
    const {
      content: {
        data: {
          text,
        }
      },
    } = comment;
    if (provider.messenger === 'vk') {
      const pattern = /\[id(\d+)\|(\w+)\]/;
      const replacement = "@$2";
      return text.replace(pattern, replacement);
    }
    return text;
  }

  return (
    <MessageWrapper className={classes.root}>
      <View className={classes.inner}>
        <Avatar className={classes.avatar} src={avatarSrc}/>
        <div className={classes.msgBody} ref={self}>
          <div style={{display: 'flex'}}>
            <div style={{display: 'flex', flexDirection: 'column'}}>
              <div className={`${classes.body} message-body`}>
                <div className={`${classes.head} head`}>
                  {username || <span>&nbsp;</span>}
                  {!comment.isReply && !isPostAuthor &&
                    <div className="reply_menu">
                      <Tooltip
                        title={t('post.reply')}
                        className="reply_menu_icon"
                        onClick={() => handleReply(comment, username, comment.content.data.text)}>
                        <Reply/>
                      </Tooltip>
                      {provider.messenger === 'instagramOriginal' &&
                      <Tooltip
                        title={t('post.replyPerson')}
                        className="reply_menu_icon"
                        onClick={() => handleReply(comment, username, comment.content.data.text, true)}>
                        <ReplyPersonally/>
                      </Tooltip>}
                    </div>
                  }
                </div>
                <div className={classes.content}>
                  {getText()}
                </div>
              </div>
              <div className={classes.footer}>
                <Grid container alignItems="center" justify="flex-start">
                  <Grid item>
                    <Tooltip title={moment(comment.date.created*1000).fromNow()}>
                      <Typography className={classes.time} variant="body2">
                        {memorizedDate}
                      </Typography>
                    </Tooltip>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
          {!comment.isReply && !isPostAuthor && (
            <div className='reply_marks_wrap'>
              {(comment?.additional?.personalReply || (comment.replies && comment.replies.length > 0)) ?
                <div className='reply_marks'>
                  {t('post.answered')}
                  {comment?.additional?.personalReply &&
                  provider.messenger === 'instagramOriginal' &&
                      <ReplyPersonalComplete/>}
                  {(comment.replies && comment.replies.length > 0) &&
                    <ReplyComplete/>}
              </div>:
              <div className='reply_marks error'>
                {t('post.notAnswered')}
              </div>}
            </div>)}
          {comment.replies && comment.replies.length > 0 &&
            <Replies
              isExpand={expandReplies}
              replies={comment.replies}/>
          }
        </div>
      </View>
    </MessageWrapper>
  );
}

const View = styled.div`
  .reply_marks_wrap {
    display: flex;
  }
  .reply_marks {
    display: flex;
    flex-direction: row;
    padding: 3px 3px 3px 8px;
    gap: 3px;
    background: #03A000;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 28px;
    font-size: 13px;
    line-height: 18px;
    color: #FFFFFF;
  }
  .reply_marks.error {
    background: #F23131;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  .message-body {
    background-color: #fff;
  }
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .reply_menu {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .reply_menu_icon {
    cursor: pointer;
    color: #454545;
  }
  display: flex;
`;

PostComment.propTypes = {
  comment: PropTypes.object.isRequired,
  postAuthor: PropTypes.string,
  provider: PropTypes.string.isRequired,
};

PostComment.defaultProps = {
  postAuthor: '',
};