import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ListItemIcon, ListItemText, Menu, MenuItem } from '@material-ui/core';
import { ArchiveOutlined, StarBorderOutlined } from '@material-ui/icons';
import { closeDialog, reopenDialog } from 'store/actions';

export default function ContextMenu({
  item,
  handleClose,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [conversation, setConversation] = useState(null);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  useEffect(() => {
    if (item) {
      setAnchorEl(item.anchorEl);
      setConversation(item.conversation);
    } else {
      setAnchorEl(null);
    }
  }, [item]);

  const handleContextMenu = (event) => {
    event.preventDefault();
    handleClose();
  }

  const handleArchive = () => {
    if (conversation) {
      if (conversation.status !== 'closed') {
        dispatch(closeDialog({ uuid: conversation.uuid }));
      } else {
        dispatch(reopenDialog({ uuid: conversation.uuid }));
      }
    }
    handleClose();
  }

  return (
    <StyledMenu
      onContextMenu={handleContextMenu}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      id="customized-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={(handleClose)}
      autoFocus={false}
    >
      <StyledMenuItem onClick={handleArchive}>
        <ListItemIcon>
          <ArchiveOutlined />
        </ListItemIcon>
        <ListItemText primary={conversation?.status !== 'closed' ?
          t('chatToolbar.archiveDialog') : t('chatToolbar.unarchiveDialog')} />
      </StyledMenuItem>
      <StyledMenuItem disabled>
        <ListItemIcon>
          <StarBorderOutlined />
        </ListItemIcon>
        <ListItemText primary={t('common.pin')} />
      </StyledMenuItem>
    </StyledMenu>
  );
}

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
  .MuiMenu-list {
    padding: 3px;
  }
  .MuiMenuItem-root {
    padding: 3px 6px 5px;
    border-radius: 6px;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  .MuiListItemIcon-root {
    min-width: auto;
    min-heigth: auto;
    padding-right: 5px;
  }
  .MuiSvgIcon-root {
    font-size: 18px;
  }
  .MuiListItemText-root {
    margin: 0;
    font-size: 14px;
  }
  .MuiTypography-body1 {
    line-height: 17px;
  }
`;

ContextMenu.propTypes = {
  item: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
};
