import { Box, Typography } from '@mui/material';
import AppSwitcher from 'components/AppSwitcher/AppSwitcher';
import { styled } from '@mui/material/styles';
import { ReactComponent as MessageSquareCode } from '../../../../assets/img/message-square-code.svg';
import { ReactComponent as Table2 } from '../../../../assets/img/table-2.svg';

import { useTranslation } from 'react-i18next';
import {
  setIsTabCountOnState,
  setNotificationState,
} from '../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import SettingsSoundCard from './components/SettingsSoundCard';

export const SettingsCardStyled = styled(Box)(({ theme }) => ({
  borderRadius: '8px',
  padding: '24px',
  background: '#ffff',
  minHeight: '230px',
  boxSizing: 'border-box',
  '.header-container': {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '16px',
    minHeight: '32px',
  },
  '.card-title': {
    fontSize: '16px',
    fontWeight: 500,
    lineHeight: '24px',
    marginBottom: '4px',
  },
  '.card-text': {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '20px',
    color: '#8B8C8D',
    marginBottom: '20px',
  },
}));
const SettingsNotifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    isTabCountOn,
    notificationIsOn: isNotificationsOn,
    // @ts-expect-error
  } = useSelector((state) => state.settings);

  const handleIsTabCountOnToggle = () => {
    dispatch(setIsTabCountOnState(!isTabCountOn));
  };
  const handleNotificationsToggle = () => {
    dispatch(setNotificationState(!isNotificationsOn));
  };

  return (
    <Box
      sx={{
        maxWidth: 800,
        mx: 'auto',
        mt: 10,
        display: 'grid',
        height: 'fit-content',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
        },
        flex: 1,
      }}
    >
      <SettingsCardStyled>
        <Box className="header-container">
          <MessageSquareCode />

          <AppSwitcher
            onChange={handleNotificationsToggle}
            isOn={isNotificationsOn}
            size=""
          />
        </Box>
        <Typography className="card-title">
          {t('notification.desktopAlertTitle')}
        </Typography>
        <Typography className="card-text">
          {t('notification.desktopAlertText')}
        </Typography>
      </SettingsCardStyled>

      <SettingsSoundCard />

      <SettingsCardStyled>
        <Box className="header-container">
          <Table2 />
          <AppSwitcher
            onChange={handleIsTabCountOnToggle}
            isOn={isTabCountOn}
            size=""
          />
        </Box>
        <Typography className="card-title">
          {t('notification.notificatioBrowserTitle')}
        </Typography>
        <Typography className="card-text">
          {t('notification.notificatioBrowserText')}
        </Typography>
      </SettingsCardStyled>
    </Box>
  );
};

export default SettingsNotifications;
