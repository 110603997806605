import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import FieldWrapper from './layout';

const StyledInput = styled.input`
    border-radius: 3px;
    border: solid 1px #eeeff2;
    background-color: #f6f8fc;
    font-size: 15px;
    color: #3f434c;
    padding: 10px;
    width: 100%;
    outline: none;
    &:disabled {
      background-color: #ececec;
    }
`;

const TextField = React.forwardRef((props, ref) => {
  const {
    id,
    label,
    value,
    onChange,
    placeholder,
    disabled,
    wrapperStyle,
    labelStyle,
  } = props;

  return (
    <FieldWrapper id={id} label={label} style={wrapperStyle} labelStyle={labelStyle}>
      <StyledInput
        id={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        disabled={disabled}
        ref={ref}
      />
    </FieldWrapper>
  );
});

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  wrapperStyle: PropTypes.object,
  labelStyle: PropTypes.object,
};

TextField.defaultProps = {
  label: '',
  placeholder: 'Значение поля',
  disabled: false,
  wrapperStyle: {},
  labelStyle: {},
};

export default TextField;
