/* eslint-disable */
import { Avatar, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PermMediaIcon from '@material-ui/icons/PermMedia';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import InfoIcon from '@material-ui/icons/Info';
import { Button, Switcher } from 'components';
import { get } from 'idb-keyval';
import _ from 'lodash';
import moment from 'moment';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { disableChatNotification } from 'store/actions';
import styled from 'styled-components';
import { getName } from 'utils/personeHelper';
import genAvatar from '../../../../../utils/genAvatar';
import * as settingsActions from 'store/actions';
import { ReactComponent as BanIcon } from '../../../../../assets/img/ban.svg';
import { ReactComponent as UnsubscribeOutlined } from '../../../../../assets/img/markSpam.svg';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
  font-size: 15px;
  color: #26282d;
  padding: 32px 15px 64px 15px;
  .user-avatar {
    position: relative;
    width: 155px;
    height: 155px;
    border-radius: 50%;
    background-color: #bdbdbd;
    background-image: url(${(props) => (props.image ? props.image : 'none')});
    background-size: contain;
    background-position: center;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    .MuiSvgIcon-root {
      width: 55%;
      height: 55%;
      color: #fff;
    }
  }
  .user-name {
    font-size: 19px;
    font-weight: bold;
    text-align: center;
    max-width: 75%;
    margin: 0 auto;
    margin-top: 24px;
  }
  .user-status {
    font-size: 15px;
    text-align: center;
    color: #727887;
    max-width: 75%;
    margin: 0 auto;
    margin-top: 5px;
  }
  .user-info {
    > div {
      border-bottom: 1px solid #eeeff2;
      padding: 17px 20px;
      cursor: pointer;
    }
  }
  .bottom-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-around;
    > div {
      cursor: pointer;
    }
  }
  .remove-tag-button {
    color: red;
    margin-bottom: -5px;
    margin-left: 5px;
    cursor: pointer;
  }
  .profile-tag {
    margin-right: 5px;
  }
  .user-button {
    display: flex;
    align-items: center;
    color: #ff0000;
    cursor: pointer;
    cursor: pointer;
    gap: 5px;
  }
  .mark-spam {
    color: #ff0000;
  }
  .user-button-icon {
    width: 21.5px;
    height: 21.5px;
  }
  .crm-button {
    color: blueviolet;
  }
  .info-val {
    overflow: hidden;
  }
  .nav-pb {
    padding-bottom: 0;
  }
`;

const getInfoFieldString = (value, type) => {
  switch (type) {
    case 'date':
      return moment.unix(value).format('DD.MM.YYYY');
    case 'time':
      return moment.unix(value).format('HH:mm');
    default:
      return value;
  }
};
const AvatarPersone = styled(Avatar)`
  width: 155px !important;
  height: 155px !important;
`;
const GroupTool = ({
  userInStopList,
  userInSpamList,
  handleOpenSpamConfirm,
  handleOpenStopConfirm,
  confirm,
  setConfirm,
}) => {
  const { t } = useTranslation();
  const [users, setUsers] = React.useState([]);

  const isNotified = useSelector((state) =>
    state.settings.chatNotifications.find(
      (uuidDialog) => uuidDialog === _.get(state, 'session.currentDialog', ''),
    ),
  );
  const dialog = useSelector((state) =>
    state.chat.dialogs.find(
      (x) => x.uuid === _.get(state, 'session.currentDialog', ''),
    ),
  );
  const handleChangeNotification = (checkedValue) => {
    if (!checkedValue) {
      dispatch(settingsActions.disableChatNotification(dialog.uuid));
    } else {
      dispatch(settingsActions.enableChatNotification(dialog.uuid));
    }
  };
  const groupInfo = _.get(dialog, 'additional.group');
  const groupId = _.get(dialog, 'ids.uuidMessengerChat');
  const getDialogAvatar = React.useMemo(
    () => _.get(groupInfo, 'avatar.0.fileUrl', '/img/group.png'),
    [dialog],
  );

  const userName = _.get(groupInfo, 'title');

  const dispatch = useDispatch();

  if (!dialog) return '';
  const { persone } = dialog;
  React.useEffect(() => {
    Promise.all(
      _.get(dialog, 'participants', []).map((user) => get(`persone:${user}`)),
    ).then((data) => {
      setUsers(
        data
          .filter((x) => x)
          .map((p) => ({
            avatar: _.get(p, 'info.4.val', genAvatar(p.uuid)),
            name: getName(p),
          })),
      );
    });
  }, [dialog]);
  const userCount = _.get(groupInfo, 'membersCount');
  const activeUsers = users.length;

  return (
    <Wrapper>
      <div className="user-avatar">
        <AvatarPersone src={getDialogAvatar} />
      </div>
      <div className="user-name">{userName}</div>
      <div className="user-status">{t("groupTool.groupChannel")}</div>

      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t("groupTool.channelInfo")}
          </ListSubheader>
        }
      >
        <ListItem>
          <ListItemIcon>
            <InfoIcon />
          </ListItemIcon>
          <ListItemText primary="ID канала" />
          {groupId}
        </ListItem>
      </List>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t("groupTool.settings")}
          </ListSubheader>
        }
      >
        <ListItem button>
          <ListItemIcon>
            <NotificationsIcon />
          </ListItemIcon>
          <ListItemText primary="Уведомления" />
          <Switcher checked={!isNotified} onChange={handleChangeNotification} />
        </ListItem>
      </List>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t("groupTool.media")}
          </ListSubheader>
        }
      >
        <ListItem button>
          <ListItemIcon>
            <PermMediaIcon />
          </ListItemIcon>
          <ListItemText primary="Изображения" />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemIcon>
            <VideoLibraryIcon />
          </ListItemIcon>
          <ListItemText primary="Видео" />
        </ListItem>
        <Divider />
        <ListItem button>
          <ListItemIcon>
            <InsertDriveFileIcon />
          </ListItemIcon>
          <ListItemText primary="Файлы" />
        </ListItem>
        <Divider />
      </List>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className="nav-pb"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            {t("groupTool.participants")} {activeUsers} / {userCount}
          </ListSubheader>
        }
      >
        {users &&
          users.map(({ avatar, name }, index) => (
            <Fragment key={index}>
              <ListItem button>
                <ListItemAvatar>
                  <Avatar alt={name} src={avatar} />
                </ListItemAvatar>
                <ListItemText primary={name} />
              </ListItem>
              <Divider />
            </Fragment>
          ))}
      </List>
      <div className="user-info">
        <div className="user-button" onClick={handleOpenStopConfirm}>
          <BanIcon className="user-button-icon" />
          {userInStopList
            ? t('profileTool.deleteFromStopList')
            : t('profileTool.addToStopList')}
        </div>
        <div className="user-button mark-spam" onClick={handleOpenSpamConfirm}>
          <UnsubscribeOutlined className="user-button-icon" />
          {!userInSpamList ? t('spamlist.add') : t('spamlist.delete')}
        </div>
        <Dialog
          open={confirm.show}
          onClose={() => setConfirm({ show: false })}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <DialogTitle id="alert-dialog-title">{confirm.title}</DialogTitle>
          <DialogActions>
            <Button
              type="button"
              className="primary"
              style={{ margin: '10px 10px 10px 0' }}
              onClick={() => confirm.callback()}
            >
              {t('common.confirm')}
            </Button>

            <Button
              type="button"
              className="secondary"
              onClick={() => setConfirm({ show: false })}
            >
              {t('templatesTool.cancel')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {/*
      <div className="bottom-buttons">
        <div style={{ color: '#727887' }}>{t('profileTool.moveToArchive')}</div>
        <div style={{ color: '#ff5d5d' }}>{t('profileTool.deleteAccount')}</div>
      </div>
      */}
    </Wrapper>
  );
};
GroupTool.propTypes = {
  userInStopList: PropTypes.boolean,
  userInSpamList: PropTypes.boolean,
  handleOpenSpamConfirm: PropTypes.func,
  handleOpenStopConfirm: PropTypes.func,
  confirm: PropTypes.object,
  setConfirm: PropTypes.func,
};

GroupTool.defaultProps = {
  userInStopList: false,
  userInSpamList: false,
  handleOpenSpamConfirm: () => false,
  handleOpenStopConfirm: () => false,
  confirm: {},
  setConfirm: () => false,
};
export default GroupTool;
