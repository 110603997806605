import { get } from 'idb-keyval';
import _ from 'lodash';

export const getName = (persone, isPost=false) => {
  const username = persone.info.find((o) => o.key === (isPost ? 'username' : 'name'));
  const phone = persone.info.find((o) => o.key === 'phone');
  const uuidUser = persone.info.find((o) => o.key === 'uuidUser');
  if (username) return username.val;
  if (phone) return phone.val;
  if (uuidUser) return `ID:${uuidUser.val}`;
  const contactName = _.get(persone, 'contacts.0.data.id');
  if (contactName) {
    return contactName.split('@')[0];
  }
  return 'NONAME';
};
export const getAvatar = (persone) => {
  if (persone) {
    const avatar = persone.info.find((o) => o.key === 'avatar');
    if (avatar) {
      return avatar.val;
    }
  }

  return undefined;
};

export const getPersone = (uuidUser) => new Promise((resolve) => {
  get(`persone:${uuidUser}`).then((user) => resolve(user));
});
