import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Checkbox,
  Tooltip,
  IconButton,
} from '@material-ui/core';

import _ from 'lodash';
import {
  RadioButtonUncheckedOutlined as RadioButtonUncheckedOutlinedIcon,
  CheckCircle as CheckBoxIcon,
  Schedule as TimeIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,

  // Undo as UndoIcon,
} from '@material-ui/icons';

import moment from 'moment';
import PropTypes from 'prop-types';
import DocumentContent from 'views/Chat/components/ConversationDetails/components/ConversationMessage/components/Document';

const TaskItemCard = styled.div.attrs({ props: { status: false } })`
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px;
  `}
  ${(props) => `
  opacity:${props.status ? 0.6 : 1.0}
  `}
  .MuiCard-root {
    background-color: #eeeff2;
    box-shadow: none;
  }

  .Mui-checked {
    color: #5ec359 !important;
  }
  .MuiCardContent-root {
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .MuiCardContent-root:last-child {
    padding-bottom: 8px;
  }
  .MuiCardHeader-title {
    font-size: 1rem;
    color: #777d8b;
  }
  .task-time {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;
    color: #777d8b;
    svg {
      height: 16px;
      width: 16px;
    }
  }
`;

const DescriptionWrapper = styled(Typography)`
  overflow-wrap: break-word;
  hyphens: auto;
`;

const CardContentWrapper = styled(Grid)`
  padding: 0 24px;
`;

const TaskItem = ({
  task, onUpdate, renderForm, onDelete,
}) => {
  const [editState, setEditState] = React.useState(false);
  const { t } = useTranslation();

  // TODO: переводы toolips
  const taskItemContent = (
    <CardContentWrapper container>
      <Grid item>
        <DescriptionWrapper variant="body2" component="p" noWrap={false}>
          {task.description}
        </DescriptionWrapper>
      </Grid>

      <Grid item xs={12} md={12}>
        {task.attachments
          && task.attachments.length > 0
          && task.attachments.filter((file) => file.url).map((item) => (

            <DocumentContent
              key={item.uuid}
              fileName={_.get(item, 'title')}
              src={_.get(item, 'url')}
              size={1000000}
              useShortName
            />

          ))}
        <br />
      </Grid>
      <Grid item xs={12} md={12}>
        <Typography variant="body1" component="span" className="task-time">
          <Tooltip title={task.due ? t('task.due') : t('task.date')}>
            <TimeIcon fontSize="small" color={task.due ? 'error' : 'inherit'} />
          </Tooltip>
          {moment.unix(task.date.created).format('DD.MM.YY HH:MM')}
          {task.date.due && (
            <>
              <span> - </span>
              {moment.unix(task.date.due).format('DD.MM.YY HH:MM')}
            </>
          )}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Grid container>
          <Grid item>
            <Tooltip title={t('common.edit')}>
              <IconButton
                disabled={task.status !== 'todo'}
                onClick={() => setEditState((state) => !state)}
              >
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item>
            <Tooltip title={t('common.delete')}>
              <IconButton onClick={() => onDelete(task)}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </CardContentWrapper>
  );
  const getAssigneeName = useSelector((state) => {
    if (!task.assignee) {
      return '';
    }
    const {
      session: { selectedCompany, departments, user },
    } = state;
    if (task.assignee.type === 'employee') {
      // Get personal name
      if (task.assignee.uuid === user.uuid) {
        return user.name;
      }
      // TODO: get users from store or api
      const usersInCompany = Object.values(
        _.get(departments, selectedCompany, {}),
      )
        .map(({ users }) => users)
        .flat();
      const finded = usersInCompany.find(
        ({ uuid }) => uuid === task.assignee.uuid,
      );
      if (!finded) return 'undefined';
      return `${finded.surname} ${finded.name}`;
    }
    if (task.assignee.type === 'department') {
      if (!state.session.departments) return 'undefined'; // TODO:
      return `${t('chatAssigneDepartment')} ${_.get(
        state.session.departments,
        `${state.session.selectedCompany}.${task.assignee.uuid}.name`,
      )}`;
    }
    return 'undefined';
  });
  return (
    <TaskItemCard status={task.status !== 'todo'}>
      <Card>
        <CardHeader
          title={task.title}
          titleTypographyProps={{ variant: 'h6' }}
          subheader={getAssigneeName}
          action={(
            <Checkbox
              checked={task.status !== 'todo'}
              icon={<RadioButtonUncheckedOutlinedIcon fontSize="default" />}
              checkedIcon={<CheckBoxIcon fontSize="default" />}
              name="checkedI"
              onChange={() => onUpdate(task, task.status)}
            />
          )}
        />
        <CardContent>
          <Grid container direction="row">
            <Grid item xs={12} container direction="column" />
            {!editState ? taskItemContent : renderForm(task, setEditState)}
          </Grid>
        </CardContent>
      </Card>
    </TaskItemCard>
  );
};
TaskItem.propTypes = {
  task: PropTypes.shape({
    status: PropTypes.string,
    description: PropTypes.string,
    assignee: PropTypes.object,
    title: PropTypes.string,
    date: PropTypes.shape({ created: PropTypes.number, due: PropTypes.number }),
    attachments: PropTypes.array,
    due: PropTypes.bool
  }).isRequired,
  onUpdate: PropTypes.func.isRequired,
  renderForm: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TaskItem;
