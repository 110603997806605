import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';

const Wrapper = styled.div`
    > div {
        display: flex;
        font-size: 14px;
        margin-bottom: 16px;
        cursor: pointer;
        .search-message-avatar {
            margin-right: 16px;
            width: 50px;
            height: 50px;
            display: flex;
            overflow: hidden;
            position: relative;
            font-size: 1.25rem;
            align-items: center;
            flex-shrink: 0;
            line-height: 1;
            user-select: none;
            border-radius: 50%;
            justify-content: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(/img/avatar-default.jpg);
            > div {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: cover;
            }
        }
        .search-message-wrapper {
            color: #263238;
            padding: 8px 16px;
            border-radius: 4px;
            background-color: rgb(235, 238, 243);
            .search-message-persone {
                font-weight: 600;
                line-height: 20px;
            }
            .search-message-content {
                margin-top: 8px;
                white-space: pre-wrap;
                line-height: 21px;
                letter-spacing: -0.05px;
            }
        }
    }
`;

const MessageItem = (props) => {
  const {
    message,
    handleOpenMessage,
  } = props;

  const avatar = message.persone.info.find((i) => i.key === 'avatar') || null;
  const name = message.persone.info.find((i) => i.key === 'name') || '';

  return (
    <div onClick={() => handleOpenMessage(message)}>
      <div className="search-message-avatar"><div style={{ backgroundImage: `url(${avatar ? avatar.val : '/img/avatar-default.jpg'})` }} /></div>
      <div className="search-message-wrapper">
        <div className="search-message-persone">{name.val}</div>
        <div className="search-message-date">{moment(message.date.created).fromNow()}</div>
        <div className="search-message-content">{message.content.data.text}</div>
      </div>
    </div>
  );
};

MessageItem.propTypes = {
  message: PropTypes.object.isRequired,
  handleOpenMessage: PropTypes.func.isRequired,
};

const MessagesTab = (props) => {
  const {
    messages,
    handleOpenMessage,
  } = props;

  return (
    <Wrapper>
      {messages.filter(message => message.persone !== null).map((message) => (
        <MessageItem key={message.uuid} message={message} handleOpenMessage={handleOpenMessage} />
      ))}
    </Wrapper>
  );
};

MessagesTab.propTypes = {
  messages: PropTypes.array.isRequired,
  handleOpenMessage: PropTypes.func.isRequired,
};

export default MessagesTab;
