import { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAccessToRoutingSettings } from '../../../../store/actions';
import RoutingAccesses from './RoutingAccesses';

const SettingsAccess = () => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();

  useEffect(() => {
    dispatch(fetchAccessToRoutingSettings());
  }, []);

  return (
    <Wrapper>
      <RoutingAccesses />
    </Wrapper>
  );
};

export default SettingsAccess;

const Wrapper = styled.div`
  max-width: 800px;
  margin: 0 auto;
`;

const AccordionBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: transparent;
`;
