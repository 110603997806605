import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import moment from 'moment';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ListItemText, Menu, MenuItem, Tooltip } from '@material-ui/core';
import { DateTimePicker } from '@material-ui/pickers';
import { DateRange } from '@material-ui/icons';
import { showModal, posponeDialog, unposponeDialog } from 'store/actions';

import { ReactComponent as PostponeIcon } from '../../../../../../../assets/img/conversation/toolbar/postpone.svg';

export default function PostponeMenu({ uuid }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customFilter = useSelector((state) => state.settings.customFilter);

  const [anchorEl, setAnchorEl] = useState(null);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const handleOpen = (event) => {
    if (_.has(customFilter, 'postponed')) {
      dispatch(
        unposponeDialog({
          uuid,
        }),
      );
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenCalendar = () => {
    setCalendarOpen(true);
  };

  const handleCloseCalendar = () => {
    setCalendarOpen(false);
  };

  const selectDateTime = (datetime) => {
    let totalDateTime = datetime;
    if (datetime.diff(moment(), 'minutes') <= 2) {
      totalDateTime = moment().add(2, 'minutes');
    }
    dispatch(
      showModal('ConfirmDialog', {
        title: t('postpone.modal.title'),
        subtitle: t('postpone.modal.subtitle', {
          datetime: totalDateTime.format('DD.MM.YYYY HH:mm'),
        }),
        handleConfirm: () => {
          dispatch(
            posponeDialog({
              uuid,
              datetime: totalDateTime.unix(),
            }),
          );
        },
      }),
    );
  };

  const handleSelectOwnDateTime = (datetime) => {
    selectDateTime(datetime);
  };

  const handleSelectDateTime = (datetime) => {
    selectDateTime(datetime);
  };

  const datetimes = [
    {
      text: 'laterToday',
      value: moment().add(2, 'hours'),
      format: 'ddd, HH:mm',
    },
    {
      text: 'tomorrow',
      value: moment().add(1, 'day').startOf('day').hours(9),
      format: 'ddd, HH:mm',
    },
    {
      text: 'laterThisWeek',
      value: moment().add(2, 'day').startOf('day').hours(9),
      format: 'ddd, HH:mm',
    },
    {
      text: 'thisWeekend',
      value: moment().startOf('week').add(5, 'days').hours(9),
      format: 'ddd, HH:mm',
    },
    {
      text: 'nextWeek',
      value: moment().add(1, 'weeks').hours(9),
      format: 'DD.MM, HH:mm',
    },
  ];

  return (
    <>
      <Tooltip
        title={
          _.has(customFilter, 'postponed')
            ? t('chatToolbar.unpostponeDialog')
            : t('chatToolbar.postponeDialog')
        }
      >
        <PostponeIcon onClick={handleOpen} />
      </Tooltip>
      <StyledMenu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {datetimes.map(({ text, value, format }, index) => (
          <StyledMenuItem
            key={index}
            onClick={() => handleSelectDateTime(value)}
          >
            <ListItemText
              secondary={value.format(format)}
              primary={t(`postpone.${text}`)}
            />
          </StyledMenuItem>
        ))}
        <StyledMenuItem onClick={handleOpenCalendar}>
          <ListItemText
            primary={t('postpone.chooseDateAndTime')}
            secondary={<DateRange className="calendar-icon" />}
          />
        </StyledMenuItem>
        <DateTimePicker
          minDate={moment().add(2, 'minutes')}
          onChange={handleSelectOwnDateTime}
          onClose={handleCloseCalendar}
          className="datetime-picker"
          open={calendarOpen}
        />
      </StyledMenu>
    </>
  );
}

const StyledMenuItem = styled(MenuItem)`
  label {
    border: 1px solid red;
  }
  .MuiTypography-colorTextSecondary {
    color: #7f7f7f;
  }
  .MuiListItemIcon-root {
    min-width: auto;
    min-height: auto;
    padding-right: 10px;
  }
  .MuiListItemText-root {
    margin: 0;
    font-size: 14px;
    flex-direction: row;
    display: flex;
    gap: 4px;
    justify-content: space-between;
  }
  .MuiTypography-body1 {
    line-height: 19px;
  }
`;

const StyledMenu = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0px 12px 24px rgba(0, 0, 0, 0.12);
    border-radius: 8px;
  }
  .MuiMenu-list {
    padding: 4px;
  }
  .MuiMenuItem-root {
    padding: 5px 8px 7px;
    border-radius: 6px;
  }
  .calendar-icon {
    height: 100%;
    width: 15px;
  }
  .datetime-picker {
    display: none;
  }
`;

PostponeMenu.propTypes = {
  uuid: PropTypes.string.isRequired,
};
