import React from 'react';
import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Emojione } from 'react-emoji-render';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Chip, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { useTranslation } from 'react-i18next';
import { Schedule } from '@material-ui/icons';

import { setCurrentDialog } from '../../../../../../store/actions/sessionActions';
import { clearSelectedMsg } from '../../../../../../store/actions/chatActions';
import { getName } from '../../../../../../utils/personeHelper';

const useStyles = makeStyles({
  personal: {
    backgroundColor: '#e7c1ff6e',
    height: '20px',
    cursor: 'pointer',
  },
  group: {
    backgroundColor: '#8df77aab',
    height: '20px',
    cursor: 'pointer',
  },
  me: {
    backgroundColor: '#ff9494c7',
    height: '20px',
    cursor: 'pointer',
  },
});
// #e7c1ff6e

export const ConversationListItemWrapper = styled.div`
  position: relative;
  padding: 10px 10px 3px 12px;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #eeeff2;
  cursor: pointer;
  .conversation-wrapper {
    flex: 1;
    width: calc(100% - 20px);
  }
  .right {
    width: 20px;
  }
  .conversation {
    display: flex;
    position: relative;
    padding-bottom: 3px;
  }
  .archive {
    height: 20px;
  }
  .banned {
    background-color: #1e1e1e8a;
    color: #fff;
    height: 20px;
  }
  .conversation-avatar {
    flex-shrink: 0;
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ebeef3;
    .user-avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;
      border: 2px solid #fff;
    }
    .conversation-messenger-badge {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 0px;
      left: -3px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center center;
      border: 2px solid #fff;
      &.avito {
        background-color: #f1f3f4;
        background-image: url(/img/messengers/badges/avito.svg);
      }
      &.telegram {
        background-color: #0088cc;
        background-image: url(/img/messengers/badges/telegram.svg);
      }
      &.telegramOriginal {
        background-color: #0088cc;
        background-image: url(/img/messengers/badges/telegram.svg);
      }
      &.whatsapp {
        background-color: #25d366;
        background-image: url(/img/messengers/badges/whatsapp.svg);
      }
      &.waba {
        background-color: #25d366;
        background-image: url(/img/messengers/badges/whatsapp.svg);
      }
      &.facebook {
        background-color: #3b5998;
        background-image: url(/img/messengers/badges/facebook.svg);
      }
      &.vk {
        background-color: #45668e;
        background-image: url(/img/messengers/badges/vk.svg);
      }
      &.viber {
        background-color: #fff;
        background-image: url(/img/messengers/badges/viber.svg);
        background-size: cover;
      }
      &.i2crm {
        background-color: #fff;
        background-image: url(/img/messengers/badges/i2crm.svg);
        background-size: cover;
      }
      &.instagram {
        background-color: #fff;
        background-image: url(/img/messengers/badges/instagram.svg);
        background-size: cover;
      }
      &.instagramOriginal {
        background-color: #fff;
        background-image: url(/img/messengers/badges/instagram.svg);
        background-size: cover;
      }
    }
    .conversation-messenger-info {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border: 2px solid #fff;
      width: 18px;
      height: 18px;
      bottom: 0px;
      right: -3px;
      border-radius: 11px;
      background-repeat: no-repeat;
      background-position: center center;
      font-size: 8px;
      color: #fff;
      background-color: #006ae4;
    }
  }

  .conversation-preview {
    margin-left: 10px;
    width: 130px;

    .conversation-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      font-weight: bold;
      color: #26282d;
    }
    .conversation-text {
      max-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      line-height: 1.23;
      color: #727887;
    }
  }

  .conversation-time {
    font-size: 13px;
    text-align: right;
    color: #727887;
  }

  .conversation-due-task {
    position: absolute;
    right: 0px;
    font-size: 18px;
  }

  .conversation-todo-task {
    position: absolute;
    right: 0px;
    font-size: 18px;
    color: #e48a00;
  }

  .conversation-notification {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin-top: 9px;
    margin-left: auto;
    border-radius: 50%;
    opacity: 0;
    &.conversation-unread {
      background-color: #f4ab00;
      opacity: 1;
    }
    &.conversation-newassign {
      background-color: red;
      opacity: 1;
    }
  }

  .closed-badge {
    position: absolute;
    bottom: 0;
    right: 4px;
  }

  &.closed {
    background: #f5f5f5;
    opacity: 0.8;
  }

  &.active {
    box-shadow: inset 4px 0px 0px #006ae4;
    background-color: #ebeef3;
  }
  &.active-assigned {
    box-shadow: inset 4px 0px 0px #006ae4;
    background-color: #ef44448a;
    .conversation-messenger-badge {
      border: 1px solid #fff;
    }
    .conversation-name {
      color: #fff;
    }
    .conversation-text {
      color: rgba(255, 255, 255, 0.6);
    }
    .conversation-time {
      color: rgba(255, 255, 255, 0.6);
    }
    .conversation-unread {
      background-color: #fff;
      color: #000;
    }
  }
  .tag-container {
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 3px;
    column-gap: 1px;
    max-height: 72px;
    overflow-y: auto;
    width: 100%;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #d9d9d9;
      border-radius: 4px;
    }
  }
  .tag {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 26px;
    font-size: 13px;
    line-height: 18px;
  }
  .postpone-time {
    font-size: 13px;
    text-align: right;
    color: #382eda;
  }
`;

const ConversationBottomWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  .task-icon {
    position: absolute;
    right: 0;
    bottom: 3px;
    svg {
      fill: #fff;
      width: 16px;
      height: 16px;
    }
    padding: 5px 5px 2px 5px;

    margin-left: 10px;
    border-radius: 50%;
    background-color: red;
  }
`;

const ConversationNotification = (props) => {
  const { unread, isNewAssign } = props;
  let className = 'conversation-notification';
  if (unread > 0) className += ' conversation-unread';
  if (isNewAssign) className += ' conversation-newassign';
  return <div className={className}>{unread > 0 ? unread : null}</div>;
};

ConversationNotification.propTypes = {
  unread: PropTypes.number,
  isNewAssign: PropTypes.bool,
};

ConversationNotification.defaultProps = {
  unread: 0,
  isNewAssign: false,
};

const ConversationListItem = (props) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { isActive, conversation, mini = false, handleContextMenu } = props;
  const { persone } = conversation;
  const { countUnread, postpone } = conversation.additional || {
    countUnread: 0,
    postpone: null,
  };

  const isClosed = conversation.status === 'closed';
  const { history = [] } = conversation;
  const { tags = [] } = conversation;
  const lastMessage = history.length > 0 ? history[history.length - 1] : null;

  const groupInfo = React.useMemo(
    () => _.get(conversation, 'additional.group'),
    [conversation.additional],
  );

  const dispatch = useDispatch();
  const routerHistory = useHistory();
  const { search } = useLocation();

  const getDialogAvatar = () =>
    _.get(groupInfo, 'avatar.0.fileUrl', '/img/group.png');

  const getAvatar = () => {
    const avatar = persone.info.find((o) => o.key === 'avatar');
    return (avatar && avatar.val) || `https://robohash.org/${persone.uuid}`;
  };

  const getPostpone = () => {
    const now = moment();
    const postponeTime = moment.unix(postpone);

    // Вычисляем разницу между текущим временем и отложенным временем
    const diff = postponeTime.diff(now);

    let result;
    // Проверяем, больше ли разница чем 24 часа
    if (moment.duration(diff).asDays() > 1) {
      // Если да, выводим количество дней и часов
      const days = Math.floor(moment.duration(diff).asDays());
      const hours = moment.duration(diff).hours();
      result = `${days} д ${hours} ч`;
    } else {
      // Если меньше 24 часов, выводим количество часов и минут
      const hours = moment.duration(diff).hours();
      const minutes = moment.duration(diff).minutes();
      result = hours ? `${hours} ч ${minutes} мин` : `${minutes} мин`;
    }
    return result;
  };

  const typing = useSelector((state) => state.chat.typing);
  const I = useSelector((state) => state.session.user);
  const typingUsers =
    conversation && conversation.uuid
      ? typing.filter(
          ({ uuidDialog, uuidUser }) =>
            conversation.uuid === uuidDialog && uuidUser !== I?.uuid,
        )
      : [];

  const handleRightClick = (event) => {
    handleContextMenu(event, {
      uuid: conversation.uuid,
      status: conversation.status,
    });
  };

  const getLastMessageLine = () => {
    if (typingUsers && typingUsers.length > 0) {
      return `✏️ ${typingUsers.map(({ name }) => name).join(', ')} ${t(
        'chatMessages.typing',
      )}`;
    }
    const msg = lastMessage;
    if (!msg) return 'empty';
    const name = msg.type === 'in' ? '' : 'You: ';
    let line = 'undefined';
    switch (msg.content.type) {
      case 'message.text':
        line = _.get(msg, 'content.data.text', '');
        break;
      case 'message.photo':
      case 'message.image':
        line = '🖼️ Image';
        break;
      case 'message.choice':
        line = `▶️ ${msg.content.data.answerText || msg.content.data.answer}`;
        break;
      case 'message.video':
        line = '🎬 Video';
        break;
      case 'message.location':
        line = '🌍 Location';
        break;
      case 'message.sticker':
        line = '👾 Sticket';
        break;
      case 'message.file':
        line = '📄 File';
        break;
      default:
        line = '...';
    }

    const message = `${name}${line}`;

    return <Emojione svg text={message} />;
  };

  const getLastMessageDate = () => {
    const {
      date: { lastMessage: lastMessageHuman },
      additional,
    } = conversation;
    let ts = lastMessageHuman;
    if (
      additional &&
      additional.lastMessageBot &&
      additional.lastMessageBot > lastMessageHuman
    ) {
      ts = additional.lastMessageBot;
    }
    if (moment(ts).isSame(new Date(), 'day')) {
      return moment(ts).format('HH:mm');
    }
    return moment(ts).format('DD.MM.YY');
  };

  const handleConversationOpen = () => {
    if (!mini) {
      dispatch(setCurrentDialog(conversation.uuid));
      dispatch(clearSelectedMsg());
      routerHistory.push(`/chat/${conversation.uuid}${search}`);
    }
  };

  const assigneeForMe = useSelector((state) => {
    if (!conversation.assignee) {
      return false;
    }
    const {
      session: { user },
    } = state;
    const { assignee } = conversation;
    if (assignee.type === 'personal' && assignee.uuid === user.uuid) {
      return true;
    }
    return false;
  });

  const getAssigneeName = useSelector((state) => {
    if (!conversation.assignee) {
      return 'none';
    }
    const {
      session: { selectedCompany, departments, user },
    } = state;
    if (conversation.assignee.type === 'personal') {
      // Get personal name
      if (conversation.assignee.uuid === user.uuid) {
        return user.name;
      }
      // TODO: get users from store or api
      const usersInCompany = Object.values(
        _.get(departments, selectedCompany, {}),
      )
        .map(({ users }) => users)
        .flat();
      const finded = usersInCompany.find(
        ({ uuid }) => uuid === conversation.assignee.uuid,
      );
      if (!finded) return 'undefined';
      return `${finded.surname} ${finded.name}`;
    }
    if (conversation.assignee.type === 'group') {
      if (!state.session.departments) return 'undefined'; // TODO:
      return `${t('chatAssigneDepartment')} ${_.get(
        state.session.departments,
        `${state.session.selectedCompany}.${conversation.assignee.uuid}.name`,
      )}`;
    }
    return 'undefined';
  });
  const due = false;

  return React.useMemo(() => {
    const avatarUrl =
      conversation.type === 'group' ? getDialogAvatar() : getAvatar();
    const userName = _.get(groupInfo, 'title', getName(persone));
    const lastLine = getLastMessageLine();
    const time = getLastMessageDate();

    return (
      <ConversationListItemWrapper
        onContextMenu={handleRightClick}
        className={`${isActive ? 'active' : ''} ${isClosed ? 'closed' : ''}`}
        onClick={handleConversationOpen}
      >
        <div className="conversation-wrapper">
          <div className="conversation">
            <div className="conversation-avatar">
              <div
                className={`conversation-messenger-badge ${conversation.provider.messenger}`}
              />
              <div
                className="user-avatar"
                style={{ backgroundImage: `url(${avatarUrl})` }}
              />
              {countUnread > 0 && (
                <div className="conversation-messenger-info">
                  <span> {countUnread > 100 ? '•••' : countUnread}</span>
                </div>
              )}
            </div>
            <div className="conversation-preview">
              <div className="conversation-name">{userName}</div>
              {!mini && (
                <>
                  <div className="conversation-text">{lastLine}</div>
                  <ConversationBottomWrapper>
                    {getAssigneeName !== 'undefined' &&
                      conversation.assignee !== 'undefined' &&
                      conversation.assignee &&
                      conversation.status !== 'closed' && (
                        <Chip
                          classes={{
                            root: classes[
                              assigneeForMe ? 'me' : conversation.assignee.type
                            ],
                          }}
                          size="small"
                          label={getAssigneeName}
                          variant="outlined"
                        />
                      )}
                    {conversation.tasks && conversation.tasks.length > 0 && (
                      <div className="task-icon">
                        <LocalOfferIcon fontSize="small" />
                      </div>
                    )}
                    {conversation.status === 'closed' && (
                      <Chip
                        className="archive"
                        size="small"
                        label={t('chatToolbar.archived')}
                        variant="outlined"
                      />
                    )}
                  </ConversationBottomWrapper>
                </>
              )}
            </div>
            <div style={{ marginLeft: 'auto', position: 'relative' }}>
              <div className="conversation-time">{time}</div>
              {postpone && <div className="postpone-time">{getPostpone()}</div>}
              {due && (
                <Tooltip title={t('task.due')}>
                  <Schedule
                    size="small"
                    color="error"
                    className="conversation-due-task"
                  />
                </Tooltip>
              )}
              {conversation.hasTodoTask && (
                <Tooltip title={t('task.todo')}>
                  <Schedule size="small" className="conversation-todo-task" />
                </Tooltip>
              )}
            </div>
          </div>
          {!mini && tags && tags.length > 0 && (
            <div className="tag-container">
              {tags
                .map(({ title }) => title)
                .filter((val, index, self) => self.indexOf(val) === index)
                .map((title) => (
                  <Tooltip key={title} title={title}>
                    <div className="tag">{title}</div>
                  </Tooltip>
                ))}
            </div>
          )}
        </div>
      </ConversationListItemWrapper>
    );
  }, [conversation, isActive, countUnread, getAssigneeName, t, typingUsers]);
};

ConversationListItem.propTypes = {
  isActive: PropTypes.bool.isRequired,
  conversation: PropTypes.object.isRequired,
  handleContextMenu: PropTypes.func.isRequired,
};
export default ConversationListItem;
