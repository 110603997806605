/* eslint-disable no-unused-vars */
import React from 'react';
import {
  makeStyles,
  Checkbox,
  Chip,
  SwipeableDrawer,
  Button as MuiButton,
  Grid,
  Typography,
  Tooltip,
  IconButton,
  Popover,

} from '@material-ui/core';
import uuidGen from 'uuid';

import CheckIcon from '@material-ui/icons/Check';
import { useTranslation } from 'react-i18next';
import CancelIcon from '@material-ui/icons/Cancel';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Form, Field } from 'react-final-form';
import { useDispatch, useSelector } from 'react-redux';
import _ from 'lodash';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import {
  AssignmentReturn as AssignmentReturnIcon,
  // Undo as UndoIcon,

  CheckCircle as CheckBoxIcon,
  RadioButtonUncheckedOutlined as RadioButtonUncheckedOutlinedIcon,
} from '@material-ui/icons';
import styled from 'styled-components';
import { Input, Textarea, Button } from 'components';
import { postUploadFile } from 'store/actions';
import DocumentContent from 'views/Chat/components/ConversationDetails/components/ConversationMessage/components/Document';
import { DepartmentsList } from 'views/Chat/components/ConversationDetails/components/ConversationToolbar/components';
import { getFileTypeEnum } from 'utils/getFileType';

const TaskStyledDrawer = styled(SwipeableDrawer)`
  .MuiDrawer-paper {
    width:450px;
    overflow: hidden;
  }
`;
const TaskDetailViewContainer = styled.div`
  padding: 20px;
  width: 100%;
  .form-input {
    margin-top: 25px;
    input{
      width:100%;
    }
    label {
      display: block;
      margin-bottom: 10px;
      color: #7d8392;
    }
    .react-select__control {
      border-radius: 3px;
      border: solid 1px #eeeff2;
      background-color: #f6f8fc;
      color: #3f434c;
    }
  }
`;
const useStyles = makeStyles({
  table: {
    minWidth: 300,
  },
  employee: {
    marginRight: '5px',
    backgroundColor: '#e7c1ff6e',

    cursor: 'pointer',
  },
  department: {
    marginRight: '5px',
    backgroundColor: '#8df77aab',

    cursor: 'pointer',
  },
  me: {
    marginRight: '5px',
    backgroundColor: '#ff9494c7',
    cursor: 'pointer',
  },
});

export const StyledButton = styled(Button)`
 background-color:${(props) => (props.completed ? 'green' : ' #006ae4')};
opacity: ${(props) => (props.completed ? '0.6' : '1')};
`;

export default function TaskDetailView({
  task, departments, selectedCompany, onUpdate, onCreate, onClose, getAssigneeName, onCancel, onDelete,
}) {
  const { t } = useTranslation();
  const [anchorTransferMenu, setAnchorTransferMenu] = React.useState(null);
  const transferOpen = Boolean(anchorTransferMenu);
  const [files, setFiles] = React.useState(task.attachments || []);
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.session);
  const transferId = transferOpen ? 'transfer-popover' : undefined;
  const handleTransfer = (event) => {
    setAnchorTransferMenu(event.currentTarget);
  };
  React.useEffect(() => {
    setFiles(task.attachments || []);
  }, [task.uuid]);

  const handleTransferClose = () => {
    setAnchorTransferMenu(null);
  };
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(postUploadFile(file)).then((fileData) => {
        setFiles([...files, {
          src: file,
          uuid: uuidGen.v4(),
          title: fileData.originName,
          type: getFileTypeEnum(file.name),
          author: user.name,
          url: fileData.url,
        }]);
      });
    }
  };
  return (
    <TaskStyledDrawer
      anchor="right"
      open={!!task}
      onClose={() => { }}
      onOpen={() => { }}
      variant="persistent"
    >
      <Form
        onSubmit={(values) => {
          if (values.uuid) { onUpdate({ ...values, attachments: files }); } else {
            onCreate({ ...values, attachments: files });
          }
        }}
        validate={(values) => {
          _.debounce(() => onUpdate(values), 1000);
        }}
        initialValues={task}
        render={({ handleSubmit, values, reset }) => (
          <form onSubmit={handleSubmit}>
            <TaskDetailViewContainer>
              <Grid container direction="row" spacing={10} alignItems="center">
                <Grid item>
                  <Field name="status">
                    {({ input }) => (
                      <Grid container alignItems="center">
                        <Grid item>
                          <IconButton size="small">
                            <Checkbox
                              checked={input.value === 'done'}
                              icon={<RadioButtonUncheckedOutlinedIcon fontSize="default" />}
                              checkedIcon={<CheckBoxIcon fontSize="default" />}
                              name="checkedI"
                              onChange={(e) => {
                                input.onChange(e.target.checked ? 'done' : 'todo');
                              }}
                            />
                          </IconButton>

                        </Grid>
                        <Grid item>
                          <Typography variant="h5">
                            {values.title}
                          </Typography>
                        </Grid>
                      </Grid>

                    )}
                  </Field>
                </Grid>
              </Grid>
              <Grid container direction="column" justify="flex-end" spacing={1}>
                <Grid item xs={10}>
                  <div className="form-input">
                    <label htmlFor="taskName">{t('taskPage.header.title')}</label>
                    <Field name="title">
                      {({ input }) => (
                        <Input
                          id="taskName"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                </Grid>

                <Grid item xs={10}>
                  <div className="form-input">
                    <label htmlFor="description">{t('taskPage.header.description')}</label>
                    <Field name="description">
                      {({ input }) => (
                        <Textarea
                          id="description"
                          {...input}
                        />
                      )}
                    </Field>
                  </div>
                </Grid>

                <Grid
                  container
                  item
                  justify="flex-start"
                  spacing={6}
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography>{t('taskPage.header.assignee')}</Typography>
                  </Grid>
                  <Grid item>
                    <Grid
                      item
                      xs={12}
                      container
                      direction="row"
                      alignItems="center"
                      justify="space-between"
                    >
                      <Grid item>
                        <Typography component="span">
                          {getAssigneeName(values.assignee).length > 0 && (
                          <Chip
                            classes={{
                              root:
                      classes[
                        values.assignee
                          ? user.uuid === values.assignee.uuid
                            ? 'me'
                            : values.assignee.type
                          : 'me'
                      ],
                            }}
                            label={`${getAssigneeName(values.assignee)}`}
                          />
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Tooltip title={t('tasksTool.form.assignee')}>
                          <IconButton
                            color="primary"
                            aria-describedby={transferId}
                            onClick={handleTransfer}
                          >
                            <AssignmentReturnIcon />
                          </IconButton>
                        </Tooltip>
                      </Grid>

                      <Popover
                        id={transferId}
                        open={transferOpen}
                        anchorEl={anchorTransferMenu}
                        onClose={handleTransferClose}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'center',
                        }}
                      >
                        <Field name="assignee.uuid">
                          {(assigneeUuidInput) => (
                            <Field name="assignee.type">
                              {(assigneeTypeInput) => (
                                <DepartmentsList
                                  departments={departments}
                                  selectedCompany={selectedCompany}
                                  handleDepartmentAssigneeClick={(uuidAssignee, assigneeType) => {
                                    assigneeUuidInput.input.onChange(uuidAssignee);
                                    assigneeTypeInput.input.onChange(assigneeType === 'personal' ? 'employee' : 'department');
                                    handleTransferClose();
                                  }}
                                />
                              )}
                            </Field>

                          )}
                        </Field>

                      </Popover>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  item
                  justify="flex-start"
                  spacing={6}
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography>{t('taskPage.header.date')}</Typography>
                  </Grid>
                  <Grid item>
                    <Typography>{moment.unix(_.get(task, 'date.created')).format('DD.MM.YY HH:MM')}</Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  item
                  justify="flex-start"
                  spacing={6}
                  alignItems="center"
                >
                  <Grid item xs={3}>
                    <Typography>{t('taskPage.header.attachments')}</Typography>
                  </Grid>
                  <Grid item>

                    <input
                      type="file"
                      style={{ display: 'none' }}
                      id="file-attach"
                      onChange={handleUpload}
                    />
                    <label htmlFor="file-attach">
                      <IconButton color="primary" component="span">
                        <AttachFileIcon />
                      </IconButton>
                    </label>

                  </Grid>

                </Grid>
                <Grid
                  container
                  item
                  justify="flex-start"
                  spacing={6}
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    {files
                        && files.length > 0
                        && files.filter((file) => file.url).map((item) => (
                          <Grid key={item.uuid} container alignItems="center" spacing={5}>
                            <Grid item>
                              <DocumentContent
                                fileName={_.get(item, 'title')}
                                src={_.get(item, 'url')}
                                size={1000000}
                                useShortName
                              />
                            </Grid>
                            <Grid item>
                              <IconButton onClick={() => setFiles(files.filter((x) => x.uuid !== item.uuid))}>
                                <CancelIcon />
                              </IconButton>
                            </Grid>
                          </Grid>

                        ))}
                  </Grid>
                </Grid>

                <Grid
                  item
                  spacing={3}
                  container
                  direction="row"
                  alignItems="center"
                >
                  <Grid item>
                    <Button type="submit" className="primary">
                      {t('common.save')}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      className="secondary"
                      onClick={(e) => {
                        e.preventDefault();
                        onCancel();
                      }}
                    >
                      {t('common.cancel')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </TaskDetailViewContainer>
          </form>
        )}
      />
    </TaskStyledDrawer>

  );
}

TaskDetailView.propTypes = {
  task: PropTypes.shape({
    uuid: PropTypes.string,
    status: PropTypes.string,
    description: PropTypes.string,
    assignee: PropTypes.object,
    title: PropTypes.string,
    date: PropTypes.shape({ created: PropTypes.number }),
    attachments: PropTypes.array,
  }).isRequired,
  onCreate: PropTypes.func.isRequired,
  departments: PropTypes.object.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  getAssigneeName: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};
