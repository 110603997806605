import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@mui/material/Dialog';
import styled from 'styled-components';
import IconButton from '@mui/material/IconButton';
import { useTranslation } from 'react-i18next';
import { ReactComponent as CrossIcon } from '../../../assets/img/cross-rounded.svg';
import AppButton from '../../../components/AppButton/AppButton';
import HorizontalDivider from '../Settings/components/HorizontalDivider';

/**
 * Данный компонент позволяет легко и аккуратно создавать модальные окна,
 * используя следующие свойства:
 * - title: заголовок модального окна (опционально)
 * - confirmAction: функция, выполняемая при подтверждении (опционально)
 * - declineAction: функция, выполняемая при отмене (опционально)
 * - handleClose: функция закрытия модального окна
 * - children: содержимое модального окна
 *
 * Пример использования:
 * ```jsx
 * <MuiRootDialog
 *   title="Заголовок модального окна"
 *   confirmAction={() => console.log('Подтверждено')}
 *   declineAction={() => console.log('Отменено')}
 *   handleClose={() => console.log('Модальное окно закрыто')}
 * >
 *   Содержимое модального окна
 * </MuiRootDialog>
 * ```
 */
const MuiRootDialog = ({
  title,
  confirmButtonText,
  confirmAction,
  isConfirmDisabled,
  declineAction,
  handleClose,
  children,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Dialog open onClose={handleClose} {...rest}>
      <Wrapper>
        <IconButton
          sx={{ borderRadius: '4px' }}
          className="closeButton"
          onClick={handleClose}
        >
          <CrossIcon />
        </IconButton>

        {title && (
          <Header>
            <h3 className="title">{title}</h3>
            <HorizontalDivider dividerColor="#DADCE0" />
          </Header>
        )}

        {children}

        {(confirmAction || declineAction) && (
          <Footer>
            <HorizontalDivider dividerColor="#DADCE0" />

            <div className="actions">
              {!!declineAction && (
                <AppButton
                  style={{ minWidth: '144px' }}
                  type="secondary"
                  onClick={declineAction}
                >
                  {t('common.cancel')}
                </AppButton>
              )}
              {!!confirmAction && (
                <AppButton
                  style={{ minWidth: '144px' }}
                  disabled={isConfirmDisabled}
                  onClick={confirmAction}
                >
                  {confirmButtonText || t('common.save')}
                </AppButton>
              )}
            </div>
          </Footer>
        )}
      </Wrapper>
    </Dialog>
  );
};

MuiRootDialog.propTypes = {
  title: PropTypes.string,
  confirmButtonText: PropTypes.string,
  confirmAction: PropTypes.func,
  isConfirmDisabled: PropTypes.bool,
  declineAction: PropTypes.func,
  children: PropTypes.node,
  handleClose: PropTypes.func.isRequired,
};
MuiRootDialog.defaultProps = {
  title: '',
  confirmButtonText: '',
  isConfirmDisabled: false,
  children: null,
  confirmAction: null,
  declineAction: null,
};

export default MuiRootDialog;

const Wrapper = styled.div`
  position: relative;
  padding: 24px;

  .closeButton {
    position: absolute;
    right: 8px;
    top: 8px;
  }
`;

const Header = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;

  .title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
  }
`;

const Footer = styled.section`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  .actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 10px;
  }

  .decline-button {
    background-color: transparent;
    color: #565656;
    border-color: #d1d1d1;
  }
`;
