import React from 'react';
import styled from 'styled-components';

import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import { LinearProgress } from '@material-ui/core';
import {AttachFile, Clear} from '@material-ui/icons';


export default function AttachFileForm ({
  onAttach,
  onClear,
  onClose,
  attachedFile,
}) {
  const {t} = useTranslation();
  const fileInput = React.useRef();
  const [loading, setLoading] = React.useState(false);

  const attachisImg = () => attachedFile.mimeType.startsWith('image/');
  const clearAttach = () => {
    fileInput.current.value = '';
    onClear();
  }

  React.useEffect(() => () => onClose(), []);

  React.useEffect(() => {
    setLoading(false);
  }, [attachedFile]);

  const handleAttach = (evt) => {
    const file = evt.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('file', file);
      onAttach(file.name, formData);
      setLoading(true);
    }
  }
  const handleAttachClick = () => {
    fileInput.current.click();
  };
  const handleAttachClear = (e) => {
    e.preventDefault();
    clearAttach();
  }

  return (
    <View>
      { loading && <LinearProgress className='attach-loading'/> }
      { !loading && attachedFile &&
        <div className='attach-file-wrap'>
          {
            !attachisImg() ?
              <div className='attach-file'>
                <AttachFile size="small"/>
                {attachedFile.originName}
              </div> :
              <img
                className="attach-img"
                alt={attachedFile.originName}
                src={attachedFile.url}/>
          }
          <div className='attach-file-clear' onClick={handleAttachClear}>
            <Clear size="small"/>
          </div>
        </div>}
        <input style={{ display: 'none' }} type="file" ref={fileInput} onChange={handleAttach} />
        <div className='attach-form' onClick={handleAttachClick}>
          <AttachFile className='attach-form-icon'/>
          {t('startDialog.attachFile')}
        </div>
        <div className="divider"/>
    </View>
  );
}


const View = styled.div`
  .attach-form {
    cursor: pointer;
    margin-top: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 5px 8px;
    background: rgba(0, 106, 228, 0.1);
    border-radius: 6px;
    color: #006AE4;
  }
  .attach-form-icon {
    color: #006AE4;
  }

  .divider {
    margin-top: 24px;
    border-top: 1px solid #E6E7E8;
    width: 100%;
  }
  
  .attach-file-wrap {
    display: flex;
    gap: 10px;
    padding-top: 12px;
  }
  .attach-file {
    padding-top: 12px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: #F4F5F6;
    border-radius: 3px;
    padding: 6px 8px;
    color: #7F7F7F;
  }
  .attach-file-clear {
    margin-top: 5px;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #C1C1C1;
    border-radius: 2px;
    color: #7F7F7F;
    cursor: pointer;
  }
  .attach-img {
    height: auto;
    width: 150px;
    max-width: 100%;
    border-radius: 3px;
  }
  .attach-loading {
    margin-bottom: 8px;
    color: #006ae4;
  }
`;


AttachFileForm.propTypes = {
  onAttach: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
  attachedFile: PropTypes.object.isRequired,
  onClose: PropTypes.func,
};

AttachFileForm.defaultProps = {
  onClose: () => false,
};