import _ from 'lodash';
import uuidGen from 'uuid/v1';
import { apiPost } from 'api';
import { HOST } from 'config';
import { wsSndMessage } from './wsActions';

export const CHAT_WAIT_FILE = 'CHAT_WAIT_FILE';
export const CHAT_UPDATE_FILE = 'CHAT_UPDATE_FILE';
export const UPLOAD_FILE = 'UPLOAD_FILE';
export const UPLOAD_FILE_CACHE = 'UPLOAD_FILE_CACHE';

export const getMessengerFile = (payload) => (dispatch) => {
  const uuid = uuidGen();
  const message = { uuid, action: 'chat.message.file.get', data: payload };
  wsSndMessage(message)(dispatch);
  dispatch({
    type: CHAT_WAIT_FILE,
    payload: message,
  });
};

export const saveFileCache = () => {
  /**
   * mimeType: "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
originName: "Задачи RegEx_.docx"
size: 994161
url: "https://static.intellectdialog.com/0fe8f996-86ab-455c-8c1c-00c22cda1287/chat/59f7bdd5-bdab-4f1f-b0ad-1eb7d255d23c.docx"
   */

};

/**
 * post upload file
 * @param {string} uuidCompany
 * @param {{src:Blob,uuid:string}} file
 */
export const postUploadFile = (file) => async (dispatch, getState) => {
  const uuidCompany = _.get(getState(), 'session.selectedCompany');
  const formData = new FormData();
  formData.append('file', file);
  return new Promise((resolve) => {
    apiPost({
      dispatch,
      url: `${HOST}/chat/upload/${uuidCompany}`,
      formData,
      tokens: getState().session.tokenData,
      callback: (res) => {
        resolve(res);
      },

    });
  });
};

export const updateFile = (payload) => (dispatch) => {
  // const {data} = payload;
  // set(`file:${}`, )
  dispatch({
    type: CHAT_UPDATE_FILE,
    payload,
  });
};
