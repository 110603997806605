import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
  border-radius: 3px;
  border: solid 1px #006ae4;
  font-size: 15px;
  color: #fff;
  padding: 11px 15px;
  cursor: pointer;
  &.primary {
    background-color: #006ae4;
  }
  &.secondary {
    background-color: #fff;
    border-color: #a0a8bb;
    color: #373b42;
  }
  &:disabled {
    opacity: 0.6;
  }
`;

const Button = (props) => <Wrapper {...props} />;

export default Button;
