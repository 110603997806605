import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';

import interfaceEN from './assets/locales/en/interface.json';
import interfaceRU from './assets/locales/ru/interface.json';
import interfaceES from './assets/locales/es/interface.json';
import interfaceDE from './assets/locales/de/interface.json';
import interfaceIT from './assets/locales/it/interface.json';
import interfacePT from './assets/locales/pt/interface.json';
import errorsEN from './assets/locales/en/errors.json';
import errorsRU from './assets/locales/ru/errors.json';
import errorsES from './assets/locales/es/errors.json';
import errorsDE from './assets/locales/de/errors.json';
import errorsIT from './assets/locales/it/errors.json';
import errorsPT from './assets/locales/pt/errors.json';


// eslint-disable-next-line no-unused-vars
const configureI18n = (language) => {
  i18n
    .use(detector)
    .init({
      resources: {
        en: {
          interface: interfaceEN,
          errors: errorsEN,
        },
        ru: {
          interface: interfaceRU,
          errors: errorsRU,
        },
        es: {
          interface: interfaceES,
          errors: errorsES,
        },
        de: {
          interface: interfaceDE,
          errors: errorsDE,
        },
        it: {
          interface: interfaceIT,
          errors: errorsIT,
        },
        pt: {
          interface: interfacePT,
          errors: errorsPT,
        },
      },
      fallbackLng: 'en',
      ns: ['interface', 'errors'],
      defaultNS: 'interface',
      debug: false,
      interpolation: {
        escapeValue: false, // not needed for react!!
      },

      // react i18next special options
      react: {
        useSuspense: false,
        wait: true,
        bindI18n: 'languageChanged loaded',
        bindStore: 'added removed',
        nsMode: 'default',
      },

      initImmediate: true,
    });

  return i18n;
};

export default configureI18n;
