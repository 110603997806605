import { APP_VERSION } from '../config';

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return {};
    }
    const parsed = JSON.parse(serializedState);

    if (parsed.settings && parsed.settings.app_version !== APP_VERSION) {
      return {};
    }

    return parsed;
  } catch (err) {
    return {};
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // TODO: Logging
  }
};
