import { HOST } from '../../config';
import { apiGet } from '../../api';

export const REQUEST_GET_SUPPORT_BY_ID = 'REQUEST_GET_SUPPORT_BY_ID';
export const RECEIVE_GET_SUPPORT_BY_ID = 'RECEIVE_GET_SUPPORT_BY_ID';
export const ERROR_GET_SUPPORT_BY_ID = 'ERROR_GET_SUPPORT_BY_ID';

const requestGetSupportById = (uuidUser) => ({
  type: REQUEST_GET_SUPPORT_BY_ID,
  uuidUser,
});

const receiveGetSupportById = (data) => ({
  type: RECEIVE_GET_SUPPORT_BY_ID,
  data,
});

const errorGetSupportById = (err) => ({
  type: ERROR_GET_SUPPORT_BY_ID,
  err,
});

export const fetchGetSupportById = (uuidUser) => (dispatch, getState) => {
  const uuidCompany = getState().session.selectedCompany;
  dispatch(requestGetSupportById(uuidUser));
  return apiGet({
    dispatch,
    url: `${HOST}/chat/user/${uuidCompany}/${uuidUser}`,
    tokens: getState().session.tokenData,
    callback: (res) => {
      if (res) {
        dispatch(receiveGetSupportById(res));
      }
    },
    error: (err) => {
      dispatch(errorGetSupportById(err));
    },
  });
};
