import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AppAccordion from '../../../../../components/Accordion/AppAccordion';
import SelectionUserChip from './SelectionUserChip';
import SelectionDepartmentOrUserActions from './SelectionDepartmentOrUserActions';
import { ReactComponent as UsersIcon } from '../../../../../assets/img/users.svg';
import { ReactComponent as UserIcon } from '../../../../../assets/img/user.svg';

function transformData(data) {
  const result = [];

  // eslint-disable-next-line guard-for-in
  for (const companyId in data) {
    // eslint-disable-next-line guard-for-in
    for (const departmentId in data[companyId]) {
      const department = data[companyId][departmentId];

      const transformedDepartment = {
        companyId,
        departmentId,
        name: department.name,
        users: department.users,
      };
      result.push(transformedDepartment);
    }
  }

  return result;
}

function extractUniqueUsers(data) {
  const userMap = new Map();

  data.forEach((department) => {
    department.users.forEach((user) => {
      userMap.set(user.uuid, user);
    });
  });

  return Array.from(userMap.values());
}

const SelectionTargetEmployee = ({ targets, setTargets }) => {
  const { t } = useTranslation();

  const { includes: includesList, excludes: excludesList } = targets;

  const { departments: companies } = useSelector((state) => state.session);
  const departments = transformData(companies);
  const users = extractUniqueUsers(departments);

  const handleInclude = (uuid) => {
    setTargets((prevState) => {
      if (prevState.includes.includes(uuid)) {
        return prevState;
      }

      return {
        ...prevState,
        includes: [...prevState.includes, uuid],
      };
    });
  };

  const handleExclude = (uuid) => {
    setTargets((prevState) => {
      if (prevState.excludes.includes(uuid)) {
        return prevState;
      }

      return {
        ...prevState,
        excludes: [...prevState.excludes, uuid],
      };
    });
  };
  const handleSwap = (uuid, prevKey) => {
    const currentKey = prevKey === 'includes' ? 'excludes' : 'includes';

    setTargets((prevState) => {
      const filteredPrevKey = prevState[prevKey].filter((id) => id !== uuid);

      return {
        ...prevState,
        [prevKey]: filteredPrevKey,
        [currentKey]: [...prevState[currentKey], uuid],
      };
    });
  };
  const handleRemove = (uuid, key) => {
    setTargets((prevState) => {
      if (!prevState[key]?.includes(uuid)) {
        return prevState;
      }
      return {
        ...prevState,
        [key]: prevState[key].filter((id) => id !== uuid),
      };
    });
  };

  const uuidStatus = (uuid) => {
    if (includesList.includes(uuid)) {
      return 'includes';
    }
    if (excludesList.includes(uuid)) {
      return 'excludes';
    }

    return null;
  };

  const userOrCompanyName = (uuid) => {
    if (uuid.startsWith('e:')) {
      const currentUser = users.find((user) => uuid === user.uuid);

      return `${currentUser.name ?? ''} ${currentUser.surname ?? ''}`;
    }

    return departments.find((department) => department.departmentId === uuid)
      .name;
  };

  return (
    <Body>
      <div className="lists">
        <div className="companyList">
          {departments.map((department) => (
            <AppAccordion
              accordionClass="accordion"
              key={department.name}
              titleJSX={
                <div className="accordionTitle">
                  <UsersIcon fill="#18191B" />
                  <p>{department.name}</p>
                  <SelectionDepartmentOrUserActions
                    uuid={department.departmentId}
                    status={uuidStatus(department.departmentId)}
                    onInclude={handleInclude}
                    onExclude={handleExclude}
                    onSwap={handleSwap}
                    onRemove={handleRemove}
                  />
                </div>
              }
            >
              <div className="accordionBody">
                {department.users.map((user, index) => (
                  <AccordionBodyRow key={index}>
                    <UserIcon />
                    <p className="name">
                      {user.name} {user.surname}
                    </p>
                    <SelectionDepartmentOrUserActions
                      key={`${user.uuid}${index}`}
                      uuid={user.uuid}
                      status={uuidStatus(user.uuid)}
                      onInclude={handleInclude}
                      onExclude={handleExclude}
                      onSwap={handleSwap}
                      onRemove={handleRemove}
                    />
                  </AccordionBodyRow>
                ))}
              </div>
            </AppAccordion>
          ))}
        </div>

        <UsersList>
          <div className="wrapper">
            <h5 className="title">{t('routingPage.included')}</h5>
            <div className="list">
              {includesList.map((uuid) => (
                <SelectionUserChip
                  key={uuid}
                  label={userOrCompanyName(uuid)}
                  isDepartment={!uuid.startsWith('e:')}
                  isInclude
                  onDelete={() => handleRemove(uuid, 'includes')}
                />
              ))}
            </div>
          </div>
          <div className="wrapper">
            <h5 className="title">{t('routingPage.excluded')}</h5>
            <div className="list">
              {excludesList.map((uuid) => (
                <SelectionUserChip
                  key={uuid}
                  label={userOrCompanyName(uuid)}
                  isDepartment={!uuid.startsWith('e:')}
                  isInclude={false}
                  onDelete={() => handleRemove(uuid, 'excludes')}
                />
              ))}
            </div>
          </div>
        </UsersList>
      </div>
    </Body>
  );
};

SelectionTargetEmployee.propTypes = {
  targets: PropTypes.array.isRequired,
  setTargets: PropTypes.func.isRequired,
};
export default SelectionTargetEmployee;

const Body = styled.section`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;

  .lists {
    direction: rtl;

    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 4px;
    width: 100%;
    max-height: 400px;
    min-height: 400px;
    overflow: auto;
    padding-left: 6px;
    margin-left: 6px;

    .companyList {
      direction: ltr;
      order: 1;

      display: flex;
      flex-direction: column;
      row-gap: 6px;
    }

    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #dadce0;
      border-radius: 2px;
    }
  }

  .accordion {
    border: 1px solid #dadce0;

    .accordion__button {
      padding: 8px 12px 8px 20px;
    }
  }

  .accordionBody {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    padding: 12px 24px;
  }

  .accordionTitle {
    display: flex;
    align-items: center;
    width: 100%;
    column-gap: 8px;
  }
`;
const AccordionBodyRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
  padding: 4px 4px 4px 12px;
  background-color: #ffffff;
  border-radius: 6px;

  .name {
    font-size: 14px;
    line-height: 16px;
  }

  .actions {
    display: flex;
    align-items: center;
    column-gap: 2px;
  }
`;
const UsersList = styled.section`
  direction: ltr;

  position: sticky;
  inset: 0;
  width: 100%;
  max-height: 400px;
  display: grid;
  grid-template-rows: 1fr 1fr;
  row-gap: 4px;

  .wrapper {
    display: grid;
    grid-template-rows: max-content 1fr;
    background-color: #f4f6f8;
    border-radius: 14px;
    border: 1px solid #dadce0;
    overflow-y: auto;
    padding: 0 6px 6px 6px;

    .title {
      position: sticky;
      top: 0;
      z-index: 1;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      padding: 12px;
      background-color: #f4f6f8;
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      padding: 4px;
      column-gap: 8px;
      row-gap: 6px;
      border-radius: 8px;
      background-color: #ffffff;
      border: 1px solid #dadce0;
    }

    ::-webkit-scrollbar {
      width: 2px;
    }
    ::-webkit-scrollbar-track {
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: #dadce0;
      border-radius: 2px;
    }
  }
`;
