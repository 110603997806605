import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';

import {TextareaAutosize} from '@material-ui/core';
import {Close} from '@material-ui/icons';
import { Picker as EmojiPicker } from 'emoji-mart';
import {chatReplyMessage, setPostText, sndPostComment, sndStartDialog} from 'store/actions';
import 'emoji-mart/css/emoji-mart.css';
import { ReactComponent as SendIcon } from '../../../../../../../assets/img/send-button.svg';
import { ReactComponent as Reply } from '../../../../../../../assets/img/reply.svg';
import { ReactComponent as ReplyPersonally } from '../../../../../../../assets/img/reply_personaly.svg';
import {FormWrap, FormWrapper} from '../../../../ConversationDetails/components/ConversationForm/ConversationForm';


export default function PostInput ({
  post,
}) {
  const {t} = useTranslation();
  const dispatch = useDispatch();

  const reply = useSelector((state) => state.chat.reply);
  const inputText = useSelector((state) => state.post.inputText);
  const [cursorPosition, setPosition] = React.useState(0);
  const [showEmoji, setShowEmoji] = React.useState(false);

  const handleSaveText = (textMessage) => {
    dispatch(setPostText(textMessage));
  };

  const handleChange = (event) => {
    handleSaveText(event.target.value);
    event.preventDefault();
  };
  const handleClearReply = () => {
    dispatch(chatReplyMessage(null));
  }

  const handleSendComment = () => {
    const msg = {
      ids: post.ids,
      text: inputText,
    };
    if (reply) {
      const {
        comment: {
          ids: {
            uuidMessengerComment,
            uuidMessengerUser,
          }
        },
        username,
        personally,
      } = reply;
      if (personally) {
        const data = {
          cmd: 'send.text',
          uuidMessengerUser,
          uuidConnection: post.provider.uuid,
          uuidMessengerComment,
          name: username,
          status: '',
          data: {
            text: inputText,
          },
        };
        sndStartDialog(data)(dispatch);
      } else {
        dispatch(sndPostComment({
          ...msg,
          additional: {
            replyMsgId: uuidMessengerComment,
          },
          to: post.provider.messenger === 'telegram' ? post.additional.uuidMessengerChannel : uuidMessengerComment,
          isReply: true,
        }));
      }
    } else {
      dispatch(sndPostComment({
        ...msg,
        additional: {
          replyMsgId: post.ids.uuidMessengerPost,
        },
        to: post.provider.messenger === 'telegram' ? post.additional.uuidMessengerChannel : post.ids.uuidMessengerPost,
        isReply: false,
      }));
    }
    handleClearReply();
  }

  const handleKeyDown = (event) => {
    setPosition(event.target.selectionStart);

    if (event.key === 'Enter') {
      if (event.ctrlKey || event.metaKey || event.shiftKey) {
        handleSaveText(inputText);
      } else {
        event.preventDefault();
        handleSendComment();
      }
    }
    // if ((e.ctrlKey || e.metaKey) && (e.keyCode == 13 || e.keyCode == 10)) {}
  };
  const handleClickCursor = (event) => {
    setPosition(event.target.selectionStart);
  };

  const handleToggleEmoji = () => {
    setShowEmoji(!showEmoji);
  };
  const handleEmojiClick = ({ native }) => {
    handleSaveText(
      inputText.slice(0, cursorPosition)
        + native
        + inputText.slice(cursorPosition, inputText.length),
    );
    setPosition(cursorPosition + native.length);
  };

  React.useEffect(() => {
    const clickEventHandler = (event) => {
      if (showEmoji) {
        const specifiedElement = document.getElementsByClassName(
          'emoji-mart',
        )[0];
        const isClickInside = specifiedElement.contains(event.target);
        if (!isClickInside) {
          setShowEmoji(false);
        }
      }
    };

    const keyDownEventHandler = (event) => {
      if (showEmoji) {
        if (event.key === 'Escape') {
          setShowEmoji(false);
        }
      }
    };

    window.addEventListener('mousedown', clickEventHandler, false);
    window.addEventListener('touchstart', clickEventHandler, false);
    window.addEventListener('keydown', keyDownEventHandler, false);
    return () => {
      window.removeEventListener('mousedown', clickEventHandler, false);
      window.removeEventListener('touchstart', clickEventHandler, false);
      window.removeEventListener('keydown', keyDownEventHandler, false);
    };
  }, [showEmoji]);

  return (
    <FormWrap>
      {reply &&
        <div className='attach_preview reply_preview'>
          <div className='attach_info'>
            {!reply.personally ?
              <Reply className='reply_icon' fill="red"/>:
              <ReplyPersonally className='reply_icon' fill="red"/>
            }
            <div className='reply' style={{cursor: 'pointer'}}>
              <div className='reply_username'>
                {reply.username}
              </div>
              <div className='reply_content'>
                {reply.content}
              </div>
            </div>
          </div>
          <Close className='attach_close_icon' onClick={handleClearReply}/>
        </div>
      }
      <View showEmoji={showEmoji}>
        <TextareaAutosize
          onKeyDown={handleKeyDown}
          rowsMax={10}
          className="message-text-input"
          value={inputText}
          placeholder={t('chatConversationForm.inputPlaceholder')}
          onClick={handleClickCursor}
          onChange={handleChange}
        />
        <div className="message-add-emoji" onClick={handleToggleEmoji} />
        <div className="message-send-button" onClick={handleSendComment}>
          <SendIcon/>
        </div>
        <EmojiPicker
          showPreview={false}
          set="emojione"
          onSelect={handleEmojiClick}
        />
      </View>
    </FormWrap>
  );
}

const View = styled(FormWrapper)`
  .message-text-input {
    font-size: 14px;
    margin-left: 20px;
    max-height: 340px;
    display: block;
    outline: none;
    flex-grow: 1;
    border: none;
    padding-right: 10px;
    margin-top: 1px;
    resize: none;
    padding-top: 4px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: ${(props) => props.outTextLimit && '#FF0000'};
  }
`;

PostInput.propTypes = {
  post: PropTypes.object.isRequired,
};

PostInput.defaultProps = {
};