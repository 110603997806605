export function getDateRange(calendarDateRange) {
  function transformDate(date) {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
  }
  return {
    from: Array.isArray(calendarDateRange.dateBefore)
      ? transformDate(calendarDateRange.dateBefore[0])
      : transformDate(calendarDateRange.dateBefore),
    to: transformDate(calendarDateRange.dateAfter),
  };
}