import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffOutlined from '@material-ui/icons/VisibilityOffOutlined';

import Input from 'components/Input';


const Wrapper = styled.div`
    label {
        font-size: 15px;
        color: #727887;
        margin-bottom: 10px;
    }
    .input {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: solid 1px #eeeff2;
        background-color: #f6f8fc;
        padding-right: 11px;
    }
    input {
        border: none;
        padding-right: 5px;
    }
    .hide-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #C2C6CE;
    }
`;

const SecretInput = (props) => {
  const {
    handleToggle,
    show,
    id,
    value,
    label,
    ...rest
  } = props;

  return (
    <Wrapper>
      {label ? <label htmlFor={id}>{label}</label> : null}
      <div className="input">
        <Input
          type={show ? 'text' : 'password'}
          id={id}
          value={value}
          {...rest} />
        <div className="hide-icon" onClick={handleToggle}>
          {show ?
            <VisibilityOutlinedIcon /> :
            <VisibilityOffOutlined />}
        </div>
      </div>
    </Wrapper>
  );
};

SecretInput.propTypes = {
  id: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  show: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};

SecretInput.defaultProps = {
  id: '',
  value: '',
  placeholder: '',
  label: '',
  onChange: () => false,
};

export default SecretInput;
