import React from 'react';
import Chip from '@mui/material/Chip';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from '../../../../../assets/img/cross-rounded.svg';
import { ReactComponent as UsersIcon } from '../../../../../assets/img/users.svg';
import { ReactComponent as UserCheckIcon } from '../../../../../assets/img/user-checkmark.svg';
import { ReactComponent as UserCrossIcon } from '../../../../../assets/img/user-cross.svg';

const SelectionUserChip = ({
  label,
  onDelete,
  isDepartment,
  isInclude,
  ...rest
}) => {
  const currentMainColor = isInclude ? '#006AE4' : '#FF3B30';
  const currentSecondaryColor = isInclude
    ? 'rgba(0, 106, 228, 0.05)'
    : 'rgba(246, 13, 55, 0.1)';
  const iconStyles = {
    width: 16,
    height: 16,
    fill: currentMainColor,
  };
  const CurrentIcon = isDepartment ? (
    <UsersIcon style={iconStyles} />
  ) : isInclude ? (
    <UserCheckIcon style={iconStyles} />
  ) : (
    <UserCrossIcon style={iconStyles} />
  );

  return (
    <Chip
      {...rest}
      sx={{
        height: '22px',
        backgroundColor: currentSecondaryColor,
        borderRadius: '6px',
        columnGap: '6px',
        '& .MuiChip-label': {
          padding: '0 10px 0 6px',
        },
      }}
      label={<p style={{ color: currentMainColor }}>{label}</p>}
      avatar={CurrentIcon}
      deleteIcon={<CrossIcon style={{ width: 16, height: 16 }} />}
      onDelete={onDelete}
    />
  );
};
SelectionUserChip.propTypes = {
  label: PropTypes.string.isRequired,
  isDepartment: PropTypes.bool.isRequired,
  isInclude: PropTypes.bool.isRequired,
  onDelete: PropTypes.func.isRequired,
};
export default SelectionUserChip;
