import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  inStopList: false,
  inSpamList: false,
  changePasswordSuccess: null
};

const personReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.SET_USER_IN_STOP_LIST:
      return update(state, {
        inStopList: { $set: action.payload },
      });
    case actionTypes.SET_USER_IN_SPAM_LIST:
      return update(state, {
        inSpamList: { $set: action.payload },
      });
    case actionTypes.SET_CHANGE_PASSWORD_RESULT:
      return update(state, {
        changePasswordSuccess: { $set: action.payload },
      });
    default: {
      return state;
    }
  }
};

export default personReducer;