import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import {
  Grid,
  Typography,
  Tooltip,
  Chip,
  IconButton,
  Popover,
  makeStyles,
} from '@material-ui/core';
import uuidGen from 'uuid';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import {
  AssignmentReturn as AssignmentReturnIcon,
  // Undo as UndoIcon,
} from '@material-ui/icons';
import _ from 'lodash';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, Textarea, Button } from 'components';
import { DepartmentsList } from 'views/Chat/components/ConversationDetails/components/ConversationToolbar/components';
import DocumentContent from 'views/Chat/components/ConversationDetails/components/ConversationMessage/components/Document';
import { getFileTypeEnum } from 'utils/getFileType';
import { postUploadFile } from 'store/actions';

const useStyles = makeStyles({
  employee: {
    backgroundColor: '#e7c1ff6e',

    cursor: 'pointer',
  },
  department: {
    backgroundColor: '#8df77aab',

    cursor: 'pointer',
  },
  me: {
    backgroundColor: '#ff9494c7',

    cursor: 'pointer',
  },
});

const TaskFormGridWrapper = styled.div`
  position: relative;
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px;
  `}
  ${(props) => `
  opacity:${props.status ? 0.6 : 1.0}
  `}
 ${(props) => (props.isCreateForm
    ? `background-color: #eeeff2;
  box-shadow: none;
  border-radius: 6px;
  padding: 10px;`
    : '')}
    padding:20px;

  input {
    width: 100%;
  }
  textarea {
    width: 100%;
  }
`;

const GridDropzoneWrapper = styled(Grid)`
  outline: none;
`;

const TaskForm = ({
  task,
  selectedCompany,
  currentDialog,
  departments,
  onSubmit,
  onCancel,
  isCreateForm,
  submitButtonText,
}) => {
  const [value, setValue] = React.useState({
    ...task,
    ids: { uuidDialog: currentDialog, uuidCompany: selectedCompany },
  });
  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorTransferMenu, setAnchorTransferMenu] = React.useState(null);
  const transferOpen = Boolean(anchorTransferMenu);
  const handleTransfer = (event) => {
    setAnchorTransferMenu(event.currentTarget);
  };

  const { user } = useSelector((state) => state.session);

  const getAssigneeName = () => {
    if (value.assignee) {
      const assigneeDepartments = Object.values(
        _.get(departments, selectedCompany, {}),
      );
      if (value.assignee.type === 'employee') {
        // Get personal name
        if (value.assignee.uuid === user.uuid) {
          return user.name;
        }
        // TODO: get users from store or api

        const usersInCompany = assigneeDepartments
          .map(({ users }) => users)
          .flat();

        const finded = usersInCompany.find(
          ({ uuid }) => uuid === value.assignee.uuid,
        );
        if (!finded) return '';
        return `${finded.surname} ${finded.name}`;
      }

      if (value.assignee.type === 'department') {
        return _.get(
          departments,
          `${selectedCompany}.${value.assignee.uuid}.name`,
          '',
        );
      }
    }

    return '';
  };
  const handleTransferClose = () => {
    setAnchorTransferMenu(null);
  };

  const handleSubmit = () => {
    onSubmit(value);
  };
  const handleCancel = () => {
    onCancel();
  };
  const handleDepartmentAssigneeClick = (uuidAssignee, assigneeType) => {
    setValue({
      ...value,
      ids: {
        uuidCompany: selectedCompany,
        uuidDialog: currentDialog,
      },
      assignee: {
        uuid: uuidAssignee,
        type: assigneeType === 'personal' ? 'employee' : 'department',
      },
    });

    handleTransferClose();
  };

  const onDrop = React.useCallback((acceptedFiles) => {
    Promise.all(
      acceptedFiles.map((file) => dispatch(postUploadFile(file)).then((fileData) => ({
        src: file,
        uuid: uuidGen.v4(),
        title: fileData.originName,
        type: getFileTypeEnum(file.name),
        author: user.name,
        url: fileData.url,
      }))),
    ).then((files) => {
      setValue({
        ...value,
        attachments: files,
      });
    });
  }, []);

  const { getRootProps, isDragActive } = useDropzone({ onDrop });

  const transferId = transferOpen ? 'transfer-popover' : undefined;
  const memorizedItems = React.useMemo(
    () => value.attachments
      && value.attachments.length > 0
      && value.attachments
        .filter((file) => file.src && file.src.name)
        .map((item) => (
          <Grid item xs={12} key={item.uuid}>
            <DocumentContent
              key={item.uuid}
              fileName={_.get(item, 'src.name')}
              src={item.src}
              size={1000000}
              useShortName
            />
          </Grid>
        )),
    [value.attachments],
  );
  const handleUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      dispatch(postUploadFile(file)).then((fileData) => {
        setValue({
          ...value,
          attachments: [
            {
              src: file,
              uuid: uuidGen.v4(),
              title: fileData.originName,
              type: getFileTypeEnum(file.name),
              author: user.name,
              url: fileData.url,
            },
          ],
        });
      });
    }
  };
  return (
    <TaskFormGridWrapper isCreateForm={isCreateForm}>
      <GridDropzoneWrapper
        container
        spacing={3}
        direction="column"
        {...getRootProps()}
      >
        <Grid item xs={12}>
          <Input
            label={t('tasksTool.form.title')}
            value={value.title}
            onChange={(f) => {
              setValue({ ...value, title: f.target.value });
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <Textarea
            label={t('tasksTool.form.description')}
            onChange={(e) => setValue({ ...value, description: e.target.value })}
            value={value.description}
          />
        </Grid>
        <Grid item xs={12}>
          <Typography component="span" variant="body1">
            {t('tasksTool.form.fileToDownload')}:
            <input
              type="file"
              style={{ display: 'none' }}
              id="file-attach"
              onChange={handleUpload}
            />
            <label htmlFor="file-attach">
              <IconButton color="primary" component="span">
                <AttachFileIcon />
              </IconButton>
            </label>
          </Typography>
          <br />
          <Grid container spacing={2}>
            {memorizedItems}
          </Grid>
          <br />
          {isDragActive && (
            <Typography component="span" variant="body2">
              Dropzone
            </Typography>
          )}
        </Grid>

        <Grid
          item
          xs={12}
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Typography component="span">
              {t('tasksTool.form.assignee')}
              {getAssigneeName().length > 0 && (
                <Chip
                  classes={{
                    root:
                      classes[
                        value.assignee
                          ? user.uuid === value.assignee.uuid
                            ? 'me'
                            : value.assignee.type
                          : 'me'
                      ],
                  }}
                  label={`${getAssigneeName()}`}
                />
              )}
            </Typography>
          </Grid>
          <Grid item>
            <Tooltip title={t('tasksTool.form.assignee')}>
              <IconButton
                color="primary"
                aria-describedby={transferId}
                onClick={handleTransfer}
              >
                <AssignmentReturnIcon />
              </IconButton>
            </Tooltip>
          </Grid>

          <Popover
            id={transferId}
            open={transferOpen}
            anchorEl={anchorTransferMenu}
            onClose={handleTransferClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <DepartmentsList
              departments={departments}
              selectedCompany={selectedCompany}
              handleDepartmentAssigneeClick={handleDepartmentAssigneeClick}
            />
          </Popover>
        </Grid>
        <br />
        <Grid
          item
          spacing={3}
          container
          direction="row"
          alignItems="center"
          justify="space-between"
        >
          <Grid item>
            <Button type="button" className="primary" onClick={handleSubmit}>
              {submitButtonText}
            </Button>
          </Grid>
          <Grid item>
            <Button type="button" className="secondary" onClick={handleCancel}>
              {t('common.cancel')}
            </Button>
          </Grid>
        </Grid>
      </GridDropzoneWrapper>
    </TaskFormGridWrapper>
  );
};
TaskForm.propTypes = {
  task: PropTypes.shape({
    status: PropTypes.string,
    description: PropTypes.string,
    assignee: PropTypes.shape({
      uuid: PropTypes.string,
      type: PropTypes.string,
    }),
    title: PropTypes.string,
    ids: PropTypes.shape({
      uuidDialog: PropTypes.string,
      uuidCompany: PropTypes.string,
    }),
    chain: PropTypes.shape({
      prev: PropTypes.string,
      next: PropTypes.string,
    }),
    attachments: PropTypes.array,
    date: PropTypes.shape({ created: PropTypes.number }),
  }),
  isCreateForm: PropTypes.bool,
  departments: PropTypes.object.isRequired,
  selectedCompany: PropTypes.string.isRequired,
  currentDialog: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitButtonText: PropTypes.string.isRequired,
};
TaskForm.defaultProps = {
  isCreateForm: false,
  task: {
    status: 'todo',
    description: '',
    assignee: null,
    title: '',
    date: { created: 1 },
    attachments: [],
  },
};
export default TaskForm;
