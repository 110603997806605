import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 25px;
  label {
    display: block;
    margin-bottom: 10px;
    color: #7d8392;

    font-size: 16px;
  }
  .react-select__control {
    border-radius: 3px;
    border: solid 1px #eeeff2;
    background-color: #f6f8fc;
    color: #3f434c;
  }
`;

const FieldWrapper = (props) => {
  const {
    id, label, style, labelStyle, children,
  } = props;
  return (
    <Wrapper style={style}>
      <label htmlFor={id} style={labelStyle}>
        {label}
      </label>
      {children}
    </Wrapper>
  );
};

FieldWrapper.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  style: PropTypes.object,
  labelStyle: PropTypes.object,
  children: PropTypes.any.isRequired,
};

FieldWrapper.defaultProps = {
  label: '',
  style: {},
  labelStyle: {},
};

export default FieldWrapper;
