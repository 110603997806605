import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import img from './spinner.svg';

const Wrapper = styled.div`
    width: 50px;
    height: 50px;
    background-image: url(${img});
    background-repeat: no-repeat;
    background-size: 100%;
`;

const Spinner = (props) => {
  const { show } = props;
  if (!show) return null;
  return (
    <Wrapper />
  );
};

Spinner.propTypes = {
  show: PropTypes.bool,
};

Spinner.defaultProps = {
  show: false,
};

export default Spinner;
