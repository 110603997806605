import { ReactComponent as CheckmarkIcon } from '../../assets/img/checkmark.svg';
import styled from 'styled-components';

const AppCheckbox = ({ label, onChange, checked = false, ...rest }) => {
  return (
    <StyledCheckbox>
      <input
        {...rest}
        type="checkbox"
        className="realCheckbox"
        checked={checked}
        onChange={onChange}
      />
      <span className={`customCheckbox${checked ? ' active' : ''}`}>
        {checked && <CheckmarkIcon />}
      </span>
      {label}
    </StyledCheckbox>
  );
};

export default AppCheckbox;

const StyledCheckbox = styled.label`
  display: flex;
  align-items: center;
  width: fit-content;
  cursor: pointer;

  .realCheckbox {
    width: 1px;
    height: 1px;
    position: fixed;
    top: -1px;
    left: -1px;
    opacity: 0;
    z-index: -1;
  }

  .customCheckbox {
    display: grid;
    place-items: center;
    width: 24px;
    height: 24px;
    border: 1px solid #dadce0;
    border-radius: 6px;
    background-color: #ffffff;

    &.active {
      background-color: #006ae4;
      border: 1px solid #006ae4;
      transition: border 0.4s ease, background-color 0.4s ease;
    }

    svg path {
      fill: #ffffff;
    }
  }
`;
