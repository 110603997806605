import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DeleteIcon } from '../../../../../../assets/img/trash-outlined.svg';
import { ReactComponent as CopyIcon } from '../../../../../../assets/img/copy.svg';
import { ReactComponent as EditIcon } from '../../../../../../assets/img/edit.svg';
import HintButton from '../../../../../../components/HintButton/HintButton';
import {
  changeRoutingScript,
  createRoutingScript,
  deleteRoutingScript,
  hideModal,
  showModal,
} from '../../../../../../store/actions';
import AppSwitcher from '../../../../../../components/AppSwitcher/AppSwitcher';

const RoutingScriptAccordionTitle = ({ script, isDisabled }) => {
  const { t } = useTranslation();

  const scriptAdditional = script?.additional || {};
  const { title, isScriptOn } = scriptAdditional;

  const dispatch = useDispatch();

  const [isOn, setIsOn] = useState(isScriptOn ?? false);
  const handleSwitchToggle = (event) => {
    event.stopPropagation();

    setIsOn((prev) => !prev);

    dispatch(
      changeRoutingScript(
        { additional: { ...scriptAdditional, isScriptOn: !isOn } },
        script.uuid,
      ),
    );
  };

  const handleEditClick = (event) => {
    event.stopPropagation();

    dispatch(
      showModal('CreateRoutingScriptDialog', { changingScript: script }),
    );
  };
  const handleCopyClick = (event) => {
    event.stopPropagation();

    const copiedScript = structuredClone(script);
    delete copiedScript.uuid;

    dispatch(createRoutingScript(copiedScript));
  };
  const handleScriptDelete = (event) => {
    event.stopPropagation();

    dispatch(
      showModal('ConfirmRemoveDialog', {
        title: `${t('routingPage.deleteScript')}?`,
        text: t('routingPage.deleteScriptSubText'),
        onConfirm: () => {
          dispatch(deleteRoutingScript(script.uuid)).then(() =>
            dispatch(hideModal()),
          );
        },
      }),
    );
  };

  return (
    <AccordionTitle className="accordion-title">
      <h4 className="text">{title}</h4>
      <span className="actions">
        <AppSwitcher
          className="switcher small"
          size="small"
          isOn={isOn}
          isDisabled={isDisabled}
          onChange={handleSwitchToggle}
        />
        <HintButton
          tooltipText={t('routingPage.changeTitleAndDescription')}
          disabled={isDisabled}
          onClick={handleEditClick}
        >
          <EditIcon className="edit" />
        </HintButton>
        <HintButton
          tooltipText={t('routingPage.cloneScript')}
          disabled={isDisabled}
          onClick={handleCopyClick}
        >
          <CopyIcon className="copy" />
        </HintButton>
        <HintButton
          tooltipText={t('routingPage.deleteScript')}
          disabled={isDisabled}
          onClick={handleScriptDelete}
        >
          <DeleteIcon className="trash" />
        </HintButton>
      </span>
    </AccordionTitle>
  );
};

RoutingScriptAccordionTitle.propTypes = {
  script: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
};

export default RoutingScriptAccordionTitle;

const AccordionTitle = styled.span`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
  row-gap: 8px;
  width: 100%;

  .text {
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
  }

  .actions {
    display: flex;
    align-items: center;
    column-gap: 8px;
    margin-right: 16px;
    margin: 0 16px 0 auto;

    .switcher {
      margin-right: 24px;
    }
  }

  .trash path {
    fill: #ff3b30;
  }
`;
