import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  data: [],
  isFetching: true,
  createdAttachedFile: null,
  attachedFile: null,
};

const templateReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_POST_TEMPLATE:
    case actionTypes.REQUEST_DELETE_TEMPLATE:
    case actionTypes.REQUEST_UPDATE_TEMPLATE:
      return update(state, {
        isFetching: { $set: true },
      });
    case actionTypes.RECEIVE_TEMPLATE_LIST:
      return update(state, {
        isFetching: { $set: false },
        data: { $set: [...action.data] },
      });
    case actionTypes.RECEIVE_DELETE_TEMPLATE:
      return update(state, {
        isFetching: { $set: false },
        data: {

          $set: [...state.data.filter(({ uuid }) => uuid !== action.uuid)],
        },
      });

    case actionTypes.RECEIVE_UPDATE_TEMPLATE: {
      const template = state.data.find(({ uuid }) => uuid === action.payload.uuid);
      if (typeof action.payload.template === 'object') {
        template.template = action.payload.template.text;
        template.title = action.payload.template.title;
        template.file = action.payload.template.file;
      } else {
        template.template = action.payload.template;
        template.file = null;
      }
      return update(state, {
        isFetching: { $set: false },
        data: {
          $set: [...state.data.filter(({ uuid }) => uuid !== action.payload.uuid), template],
        },
      });
    }

    case actionTypes.RECEIVE_CREATE_TEMPLATE_ATTACHMENT: {
      return update(state, {
        createdAttachedFile: { $set: action.data },
      });
    }

    case actionTypes.CLEAR_CREATE_TEMPLATE_ATTACHMENT: {
      return update(state, {
        createdAttachedFile: { $set: null },
      });
    }

    case actionTypes.RECEIVE_TEMPLATE_ATTACHMENT: {
      return update(state, {
        attachedFile: { $set: action.data },
      });
    }

    case actionTypes.CLEAR_TEMPLATE_ATTACHMENT: {
      return update(state, {
        attachedFile: { $set: null },
      });
    }

    case actionTypes.ERROR_TEMPLATE_LIST:
      return update(state, {
        error: { $set: action.err },
      });

    default: {
      return state;
    }
  }
};
export default templateReducer;
