import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import { LinearProgress } from '@material-ui/core';

const Auth = (props) => {
  const { children } = props;
  return (
    <main>
      <Suspense fallback={<LinearProgress />}>
        {children}
      </Suspense>
    </main>
  );
};

Auth.propTypes = {
  children: PropTypes.array.isRequired,
};

export default Auth;
