import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import DatePicker, { registerLocale } from 'react-datepicker';
import ru from 'date-fns/locale/ru';
import es from 'date-fns/locale/es';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import de from 'date-fns/locale/de';
import it from 'date-fns/locale/it';
import pt from 'date-fns/locale/pt';

registerLocale('ru', ru);
registerLocale('es', es);
registerLocale('de', de);
registerLocale('it', it);
registerLocale('pt', pt);

const Wrapper = styled.div`
  .react-datepicker-wrapper,
  .react-datepicker__input-container {
    width: 100%;
  }
  input {
    position: relative;
    font-size: 15px;
    color: #26282d;
    border-radius: 3px;
    border: solid 1px #eeeff2;
    background-color: #f6f8fc;
    background-repeat: no-repeat;
    background-position: 12px 10px;
    padding: 9px 18px 9px 35px;
    background-image: url(/img/calendar.svg);
    cursor: pointer;
    margin-left: 0;
    color: #26282d;
    width: 100%;
    ::placeholder,
    ::-webkit-input-placeholder {
      color: #26282d;
    }
    :-ms-input-placeholder {
      color: #26282d;
    }
  }
  .react-datepicker-popper {
    transform: translate3d(30px, 39px, 0px) !important;
    box-shadow: 0 18px 42px 0 rgba(114, 120, 135, 0.1),
      0 4px 16px 0 rgba(0, 0, 0, 0.15);
  }
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^='bottom']
    .react-datepicker__triangle::before {
    border-bottom-color: #fff;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #484c56;
  }
  .react-datepicker__navigation--next {
    border-left-color: #484c56;
  }
  .react-datepicker__header {
    background-color: #fff;
    border-bottom: none;
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    font-size: 15px;
    font-weight: 500;
    color: #484c56;
  }
  .react-datepicker__day-names {
    background-color: #f6f8fc;
    margin-top: 10px;
  }
  .react-datepicker__day-name {
    color: #7d8392;
    text-transform: uppercase;
    font-size: 12px;
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--selected {
    border-radius: 50%;
    background-color: #006ae4 !important;
  }
  .react-datepicker__day:hover {
    border-radius: 50%;
  }
  .react-datepicker {
    border: none;
  }
`;

const TextField = (props) => {
  const { i18n } = useTranslation();
  const {
    id, value, onChange, placeholder,
  } = props;

  const handleChange = (date) => {
    onChange(moment(date).unix());
  };

  const currentDate = value ? new Date(value * 1000) : null;

  return (
    <Wrapper>
      <DatePicker
        id={id}
        className="date-input"
        locale={i18n.language}
        placeholderText={placeholder}
        selected={currentDate}
        showTimeSelect
        showTimeSelectOnly
        timeFormat="HH:mm"
        timeIntervals={1}
        timeCaption="Время"
        dateFormat="HH:mm"
        onChange={handleChange}
      />
    </Wrapper>
  );
};

TextField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

TextField.defaultProps = {
  placeholder: 'Значение поля',
};

export default TextField;
