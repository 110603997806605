import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { Lottie } from '@crello/react-lottie';
import { Emojione } from 'react-emoji-render';
import { useTheme } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const StickerContent = ({
  src,
  caption,
  loading = false,
  lottie = false,
  json = '{}',
}) => {
  const theme = useTheme();

  const Picture = styled.img`
    margin-top: ${theme.spacing(2)};
    height: auto;
    width: 200px;
    max-width: 100%;
  `;

  return React.useMemo(
    () => (
      <Typography color="inherit" variant="body1" component="span">
        {lottie && !loading && (
          <Lottie
            config={{
              loop: true,
              animationData: JSON.parse(json),
              renderer: 'canvas',
            }}
            height={200}
            width={200}
          />
        )}
        {loading && (
          <>
            <Typography color="inherit" variant="caption">
              Loading Sticker...
            </Typography>
          </>
        )}
        {src && <Picture alt="Attachment" src={src} />}
        {caption && <Emojione size={64} svg text={caption} />}
      </Typography>
    ),
    [loading, src, json],
  );
};

StickerContent.propTypes = {
  src: PropTypes.string,
  caption: PropTypes.string,
  loading: PropTypes.bool,
  lottie: PropTypes.bool,
  json: PropTypes.string,
};

StickerContent.defaultProps = {
  src: '',
  caption: '',
  loading: false,
  lottie: false,
  json: '{}',
};

export default StickerContent;
