import { Box, Typography } from '@mui/material';
import AppSwitcher from '../../../../../components/AppSwitcher/AppSwitcher';
import AppButton from '../../../../../components/AppButton/AppButton';
import { ReactComponent as VolumeOnIcon } from '../../../../../assets/img/volume-on.svg';
import { ReactComponent as Clock } from '../../../../../assets/img/clock.svg';
import { useTranslation } from 'react-i18next';
import { SettingsCardStyled } from '../SettingsNotifications';
import { useMemo, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../../assets/img/close.svg';
import { ReactComponent as VolumeOffIcon } from '../../../../../assets/img/volume-off.svg';
import TimerDialog from './TimerDialog';
import { setSoundState } from '../../../../../store/actions';
import { useDispatch, useSelector } from 'react-redux';

const MutedSoundInfo = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // @ts-expect-error
  const { sound } = useSelector((state) => state.settings);

  const time = useMemo(() => {
    const hours = Math.floor(sound.offTimeMinutes / 60).toString();
    const minutes = (sound.offTimeMinutes % 60).toString();

    return { hour: hours, minute: minutes };
  }, [sound.offTimeMinutes]);

  const handleTimeOffRemove = () => {
    dispatch(
      setSoundState({
        ...sound,
        offTimeMinutes: null,
        offTimeInitiatedDate: null,
      }),
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Box
        sx={{
          background: '#FF3B301A',
          borderRadius: 1,
          p: '12px',
          display: 'flex',
          justifyItems: 'center',
          alignItems: 'center',
          gap: 1,
          flex: 1,
        }}
      >
        <VolumeOffIcon width={16} height={16} stroke="red" />
        <span style={{ fontSize: '12px', fontWeight: 400, color: 'red' }}>
          {t('notification.soundMutedAlert', {
            hour: time.hour,
            min: time.minute,
          })}
        </span>
      </Box>
      <AppButton
        type="secondary"
        style={{ padding: '12px' }}
        onClick={handleTimeOffRemove}
      >
        <CloseIcon />
      </AppButton>
    </Box>
  );
};

const SettingsSoundCard = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // @ts-expect-error
  const { offTimeMinutes } = useSelector((state) => state.settings.sound);
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const {
    sound,
    // @ts-expect-error
  } = useSelector((state) => state.settings);

  const handleSoundToggle = () => {
    dispatch(
      setSoundState({
        ...sound,
        isOn: !sound.isOn,
        offTimeMinutes: null,
        offTimeInitiatedDate: null,
      }),
    );
  };

  return (
    <>
      <SettingsCardStyled>
        <Box className="header-container">
          <VolumeOnIcon />
          <AppSwitcher onChange={handleSoundToggle} isOn={sound.isOn} size="" />
        </Box>

        <Typography className="card-title">
          {t('notification.notificationSoundTitle')}
        </Typography>

        <Typography className="card-text">
          {t('notification.notificationSoundText')}
        </Typography>

        {/* {sound.isOn || (!sound.isOn && !offTimeMinutes) ? (
          <AppButton
            style={{ width: '100%', justifyContent: 'center' }}
            type="secondary"
            withIcon
            onClick={handleClickOpen}
          >
            <Clock /> {t('notification.notificationSoundBtn')}
          </AppButton>
        ) : (
          <MutedSoundInfo />
        )} */}
      </SettingsCardStyled>

      {open && <TimerDialog open={open} handleClose={handleClose} />}
    </>
  );
};

export default SettingsSoundCard;
