import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import _ from 'lodash';
import { getName } from 'utils/personeHelper';

export const ConversationListItemWrapper = styled.div`
  color: #263238;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: rgb(235,238,243);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  font-size: 14px;
  cursor: pointer;
  .conversation {
    cursor: pointer;
    display: flex;
    position: relative;
    padding-bottom: 3px;
  }
  .archive {
    height: 20px;
  }
  .banned {
    background-color: #1e1e1e8a;
    color: #fff;
    height: 20px;
  }
  .conversation-avatar {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #ebeef3;
    .user-avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      background-color: transparent;
      border: 2px solid #fff;
    }
    .conversation-messenger-badge {
      position: absolute;
      width: 18px;
      height: 18px;
      top: 0px;
      left: -3px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-position: center center;
      border: 2px solid #fff;
      &.telegram {
        background-color: #0088cc;
        background-image: url(/img/messengers/badges/telegram.svg);
      }
      &.telegramOriginal {
        background-color: #0088cc;
        background-image: url(/img/messengers/badges/telegram.svg);
      }
      &.whatsapp {
        background-color: #25d366;
        background-image: url(/img/messengers/badges/whatsapp.svg);
      }
      &.waba {
        background-color: #25d366;
        background-image: url(/img/messengers/badges/whatsapp.svg);
      }
      &.facebook {
        background-color: #3b5998;
        background-image: url(/img/messengers/badges/facebook.svg);
      }
      &.vk {
        background-color: #45668e;
        background-image: url(/img/messengers/badges/vk.svg);
      }
      &.viber {
        background-color: #fff;
        background-image: url(/img/messengers/badges/viber.svg);
        background-size: cover;
      }
      &.i2crm {
        background-color: #fff;
        background-image: url(/img/messengers/badges/i2crm.svg);
        background-size: cover;
      }
      &.instagram {
        background-color: #fff;
        background-image: url(/img/messengers/badges/instagram.svg);
        background-size: cover;
      }
      &.instagramOriginal {
        background-color: #fff;
        background-image: url(/img/messengers/badges/instagram.svg);
        background-size: cover;
      }
    }
    .conversation-messenger-info {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      border: 2px solid #fff;
      width: 18px;
      height: 18px;
      bottom: 0px;
      right: -3px;
      border-radius: 11px;
      background-repeat: no-repeat;
      background-position: center center;
      font-size: 8px;
      color: #fff;
      background-color: #006ae4;
    }
  }

  .conversation-preview {
    overflow: hidden;
    margin-left: 10px;
    width: 150px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    .conversation-name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 15px;
      font-weight: bold;
      color: #26282d;
    }
    .conversation-text {
      max-height: 32px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 13px;
      line-height: 1.23;
      color: #727887;
    }
  }

  .conversation-time {
    font-size: 13px;
    text-align: right;
    color: #727887;
  }

  .conversation-due-task {
    position: absolute;
    right: 0px;
    font-size: 18px;
  }

  .conversation-notification {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
    margin-top: 9px;
    border-radius: 50%;
    opacity: 0;
    &.conversation-unread {
      background-color: #f4ab00;
      opacity: 1;
    }
    &.conversation-newassign {
      background-color: red;
      opacity: 1;
    }
  }

  .closed-badge {
    position: absolute;
    bottom: 0;
    right: 4px;
  }

  &.closed {
    background: #f5f5f5;
    opacity: 0.8;
  }

  &.active {
    box-shadow: inset 4px 0px 0px #006ae4;
    background-color: #ebeef3;
  }
  &.active-assigned {
    box-shadow: inset 4px 0px 0px #006ae4;
    background-color: #ef44448a;
    .conversation-messenger-badge {
      border: 1px solid #fff;
    }
    .conversation-name {
      color: #fff;
    }
    .conversation-text {
      color: rgba(255, 255, 255, 0.6);
    }
    .conversation-time {
      color: rgba(255, 255, 255, 0.6);
    }
    .conversation-unread {
      background-color: #fff;
      color: #000;
    }
  }
  .tag-container {
    margin-top: 3px;
    display: flex;
    flex-wrap: wrap;
    row-gap: 3px;
    column-gap: 1px;
    max-height: 72px;
    overflow-y: auto;
    width: 100%;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background: #D9D9D9; 
      border-radius: 4px;
    }
  }
  .tag {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 26px;
    font-size: 13px;
    line-height: 18px;
  }
`;

const Wrapper = styled.div`
  display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 16px;
    column-gap: 10px;
    > div {
        display: flex;
        font-size: 14px;
        cursor: pointer;
        .search-message-avatar {
            margin-right: 16px;
            width: 50px;
            height: 50px;
            display: flex;
            overflow: hidden;
            position: relative;
            font-size: 1.25rem;
            align-items: center;
            flex-shrink: 0;
            line-height: 1;
            user-select: none;
            border-radius: 50%;
            justify-content: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-image: url(/img/avatar-default.jpg);
            > div {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: cover;
            }
        }
        .search-message-wrapper {
            color: #263238;
            padding: 8px 16px;
            border-radius: 4px;
            background-color: rgb(235, 238, 243);
            .search-message-persone {
                font-weight: 600;
                line-height: 20px;
            }
            .search-message-content {
                margin-top: 8px;
                white-space: pre-wrap;
                line-height: 21px;
                letter-spacing: -0.05px;
            }
        }
    }
`;

const DialogItem = (props) => {
  const {
    dialog,
    handleOpenDialog,
  } = props;
  const { countUnread } = dialog.additional || { countUnread: 0 };
  const groupInfo = React.useMemo(
    () => _.get(dialog, 'additional.group'),
    [dialog.additional],
  );
  const getLastMessageDate = () => {
    const {
      date: { lastMessage: ts },
    } = dialog;
    if (moment(ts).isSame(new Date(), 'day')) {
      return moment(ts).format('HH:mm');
    }
    return moment(ts).format('DD.MM.YY');
  };
  const getDialogAvatar = () => _.get(groupInfo, 'avatar.0.fileUrl', '/img/group.png');

  const time = getLastMessageDate();

  return (
    <div>
      <ConversationListItemWrapper onClick={() => handleOpenDialog(dialog)}>
        <div className='conversation'>
          <div className="conversation-avatar">
            <div
              className={`conversation-messenger-badge ${dialog.provider.messenger}`}
            />
            <div
              className="user-avatar"
              style={{ backgroundImage: `url(${getDialogAvatar()})` }}
            />
            {countUnread > 0 && (
              <div className="conversation-messenger-info">
                <span>
                  {' '}
                  {countUnread > 100 ? '•••' : countUnread}
                </span>
              </div>
            )}
          </div>
          <div className="conversation-preview">
            <div className="conversation-name">{_.get(groupInfo, 'title', getName(dialog.persone))}</div>
            <div className="conversation-time">{time}</div>
          </div>
        </div>
      </ConversationListItemWrapper>
    </div>
  );
};

DialogItem.propTypes = {
  dialog: PropTypes.object.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
};

const DialogsTab = (props) => {
  const {
    dialogs,
    handleOpenDialog,
  } = props;

  return (
    <Wrapper>
      {dialogs.map((dialog) => (
        <DialogItem key={dialog.uuid} dialog={dialog} handleOpenDialog={handleOpenDialog} />
      ))}
    </Wrapper>
  );
};

DialogsTab.propTypes = {
  dialogs: PropTypes.array.isRequired,
  handleOpenDialog: PropTypes.func.isRequired,
};

export default DialogsTab;
