import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import SelectDayMonthInputs from './SelectDayMonthInputs';
import {
  CustomAutocomplete,
  CustomInput,
} from '../../../../../components/AppMultipleAutocomplete/AppMultipleAutocomplete';
import { ReactComponent as ChevronIcon } from '../../../../../assets/img/chevron-bordered.svg';

function transformData(data) {
  const result = [];

  // eslint-disable-next-line guard-for-in
  for (const companyId in data) {
    // eslint-disable-next-line guard-for-in
    for (const departmentId in data[companyId]) {
      const department = data[companyId][departmentId];

      const transformedDepartment = {
        companyId,
        departmentId,
        name: department.name,
        users: department.users,
      };
      result.push(transformedDepartment);
    }
  }

  return result;
}

const TargetDepartmentsSchedule = ({
  orCondition,
  onKeyChange,
  onValueChange,
}) => {
  const { departments: companies } = useSelector((state) => state.session);
  const departments = transformData(companies);
  const currentValue =
    departments?.find(
      (d) => d.departmentId === orCondition.schedule?.departmentUuid,
    )?.name ?? '';

  return (
    <>
      <CustomAutocomplete
        value={currentValue}
        disablePortal
        options={departments}
        getOptionLabel={(option) => option.name ?? option}
        sx={{ maxWidth: '200px', width: '100%' }}
        popupIcon={<ChevronIcon />}
        renderInput={(params) => <CustomInput {...params} label="Отдел" />}
        onChange={(event, value) =>
          onValueChange('departmentUuid', value.departmentId)
        }
      />

      <SelectDayMonthInputs
        orCondition={orCondition}
        onKeyChange={onKeyChange}
        onValueChange={onValueChange}
      />
    </>
  );
};
TargetDepartmentsSchedule.propTypes = {
  orCondition: PropTypes.object,
  onKeyChange: PropTypes.func,
  onValueChange: PropTypes.func,
};
TargetDepartmentsSchedule.defaultProps = {
  orCondition: {},
  onKeyChange: null,
  onValueChange: null,
};
export default TargetDepartmentsSchedule;
