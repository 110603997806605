import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from '../../assets/img/chevron.svg';

const AppAccordion = ({
  title,
  titleJSX,
  isDefaultOpen,
  accordionClass,
  children,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(isDefaultOpen ?? false);

  const handleAccordionToggle = () => setIsOpen((prev) => !prev);

  return (
    <StyledAccordion className={`accordion${isOpen ? ' open' : ''}`} {...rest}>
      <button
        className="accordion__button"
        type="button"
        onClick={handleAccordionToggle}
      >
        {titleJSX || <span className="accordion__button-title">{title}</span>}

        <ChevronIcon
          className={`accordion__button-icon ${isOpen ? 'rotate' : ''}`}
        />
      </button>

      <div className={`accordion__body ${isOpen ? 'open' : ''}`}>
        <div className="accordion__body-inner">{children}</div>
      </div>
    </StyledAccordion>
  );
};

AppAccordion.propTypes = {
  title: PropTypes.string,
  titleJSX: PropTypes.node,
  isDefaultOpen: PropTypes.bool,
  accordionClass: PropTypes.string,
  children: PropTypes.node.isRequired,
};
AppAccordion.defaultProps = {
  title: '',
  titleJSX: null,
  isDefaultOpen: false,
  accordionClass: '',
};

export default AppAccordion;

const StyledAccordion = styled.div`
  background-color: #ffffff;
  border-radius: 6px;
  width: 100%;
  overflow: hidden;

  .accordion {
    &__button {
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: 8px;
      background-color: inherit;
      font-size: 14px;
      line-height: 16px;
      font-family: inherit;
      padding: 8px 10px 8px 20px;
      width: 100%;
      outline: none;
      border: none;
      cursor: pointer;

      &-title {
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &-icon {
        flex: none;
        transition: transform 0.3s ease;

        &.rotate {
          transform: rotate(180deg);
        }
      }
    }

    &__body {
      display: grid;
      grid-template-rows: 0fr;
      transition: grid-template-rows 0.3s ease;
      background-color: #f4f6f8;

      &.open {
        grid-template-rows: 1fr;
      }

      &-inner {
        overflow: hidden;
      }
    }
  }
`;
