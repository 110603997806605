import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  getSearchMessages: {
    isFetching: false,
    error: null,
  },
  getSearchPersones: {
    isFetching: false,
    error: null,
  },
  searchModeIsOn: false,
  currentDialog: null,
  currentMessageId: null,
};

const search = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_MODE_STATE:
      return update(state, {
        searchModeIsOn: { $set: action.searchModeState },
      });

    case actionTypes.CLOSE_SEARCH_MODE:
      return update(state, { $set: initialState });

    case actionTypes.SET_CURRENT_SEARCH_DIALOG:
      return update(state, {
        currentDialog: { $set: action.dialog },
      });

    case actionTypes.SET_CURRENT_MESSAGE_ID:
      return update(state, {
        currentMessageId: { $set: action.uuid },
      });

    case actionTypes.CHAT_UPDATE_DIALOG_HISTORY: {
      return update(state, {
        currentDialog: {
          $apply: (dialog) => {
            if (dialog && dialog.uuid === action.payload.rows[0].ids.uuidDialog) {
              let history = action.payload.rows;
              if (dialog.historyPagination) {
                const uuidSet = new Set(dialog.history.map(msg => msg.uuid));
                const uniqueRows = action.payload.rows.filter(({ uuid }) => !uuidSet.has(uuid));
                history = [...uniqueRows, ...dialog.history];
              }
              return {
                ...dialog,
                history,
                historyPagination: action.payload.pagination,
              }
            }
            return dialog;
          },
        },
      });
    }

    case actionTypes.REQUEST_SEARCH_MESSAGES:
      return update(state, {
        getSearchMessages: {
          isFetching: { $set: true },
          error: { $set: null },
        },
      });

    case actionTypes.RECEIVE_SEARCH_MESSAGES:
      return update(state, {
        getSearchMessages: {
          isFetching: { $set: false },
        },
      });

    case actionTypes.ERROR_SEARCH_MESSAGES:
      return update(state, {
        getSearchMessages: {
          isFetching: { $set: false },
          error: { $set: action.err },
        },
      });

    default:
      return state;
  }
};

export default search;
