import { set } from 'idb-keyval';
import {
  apiDelete, apiGet, apiPost, apiPut,
} from '../../api';
import { HOST } from '../../config';
import { getUser } from '../../utils/userHelper';

export const TEMPLATE_SET_LOADING = 'TEMPLATE_SET_LOADING';
export const REQUEST_TEMPLATE_LIST = 'REQUEST_TEMPLATE_LIST';
export const RECEIVE_TEMPLATE_LIST = 'RECEIVE_TEMPLATE_LIST';
export const ERROR_TEMPLATE_LIST = 'ERROR_TEMPLATE_LIST';

export const REQUEST_POST_TEMPLATE = 'REQUEST_POST_TEMPLATE';
export const RECEIVE_POST_TEMPLATE = 'RECEIVE_POST_TEMPLATE';
export const ERROR_POST_TEMPLATE = 'ERROR_POST_TEMPLATE';

export const REQUEST_DELETE_TEMPLATE = 'REQUEST_DELETE_TEMPLATE';
export const RECEIVE_DELETE_TEMPLATE = 'RECEIVE_DELETE_TEMPLATE';
export const ERROR_DELETE_TEMPLATE = 'ERROR_DELETE_TEMPLATE';

export const REQUEST_UPDATE_TEMPLATE = 'REQUEST_UPDATE_TEMPLATE';
export const RECEIVE_UPDATE_TEMPLATE = 'RECEIVE_UPDATE_TEMPLATE';
export const ERROR_UPDATE_TEMPLATE = 'ERROR_UPDATE_TEMPLATE';

export const RECEIVE_CREATE_TEMPLATE_ATTACHMENT = 'RECEIVE_CREATE_TEMPLATE_ATTACHMENT';
export const CLEAR_CREATE_TEMPLATE_ATTACHMENT = 'CLEAR_CREATE_TEMPLATE_ATTACHMENT';

export const RECEIVE_TEMPLATE_ATTACHMENT = 'RECEIVE_TEMPLATE_ATTACHMENT';
export const CLEAR_TEMPLATE_ATTACHMENT = 'CLEAR_TEMPLATE_ATTACHMENT';

const requestGetTemplates = () => ({
  type: REQUEST_TEMPLATE_LIST,
});

export const receivePostCreateTemplateAttachment = (data) => ({
  type: RECEIVE_CREATE_TEMPLATE_ATTACHMENT,
  data,
});

export const clearPostCreateTemplateAttachment = () => ({
  type: CLEAR_CREATE_TEMPLATE_ATTACHMENT,
});

export const receivePostTemplateAttachment = (data) => ({
  type: RECEIVE_TEMPLATE_ATTACHMENT,
  data,
});

export const clearPostTemplateAttachment = () => ({
  type: CLEAR_TEMPLATE_ATTACHMENT,
});

const receiveGetTemplates = (data) => ({
  type: RECEIVE_TEMPLATE_LIST,
  data,
});

const errorGetTemplates = (err) => ({
  type: ERROR_TEMPLATE_LIST,
  err,
});

export const requestDeleteTemplate = () => ({
  type: REQUEST_DELETE_TEMPLATE,
});

export const receiveDeleteTemplate = (uuid) => ({
  type: RECEIVE_DELETE_TEMPLATE,
  uuid,
});

export const errorDeleteTemplate = (err) => ({
  type: ERROR_DELETE_TEMPLATE,
  err,
});

export const requestUpdateTemplate = () => ({
  type: REQUEST_UPDATE_TEMPLATE,
});

export const receiveUpdateTemplate = (payload) => ({
  type: RECEIVE_UPDATE_TEMPLATE,
  payload,
});
export const errorUpdateTemplate = (err) => ({
  type: ERROR_UPDATE_TEMPLATE,
  err,
});

export const errorPostTemplate = (err) => ({
  type: ERROR_POST_TEMPLATE,
  err,
});

export const requestPostTemplate = () => ({
  type: REQUEST_POST_TEMPLATE,
});

export const receivePostTemplate = (payload) => ({
  type: RECEIVE_POST_TEMPLATE,
  payload,
});

export const deleteTemplate = (template) => (dispatch, getState) => {
  const { uuid } = template;
  const uuidCompany = getState().session.selectedCompany;

  dispatch(requestDeleteTemplate());
  return apiDelete({
    dispatch,
    url: `${HOST}/chat/template/${uuidCompany}/${uuid}`,
    tokens: getState().session.tokenData,
    callback: () => {
      dispatch(receiveDeleteTemplate(uuid));
    },
    error: (err) => {
      dispatch(errorDeleteTemplate(err));
    },
  });
};

export const postTemplateAttachmentThroughBody = (fileName, file) => async (
  dispatch,
  getState,
) => apiPost({
  dispatch,
  url: `${HOST}/chat/upload/v2/${getState().session.selectedCompany}/${fileName}`,
  formData: file,
  tokens: getState().session.tokenData,
  callback: (res) => {
    dispatch(receivePostTemplateAttachment(res));
  },
});

export const postTemplateCreateAttachmentThroughBody = (fileName, file) => async (
  dispatch,
  getState,
) => apiPost({
  dispatch,
  url: `${HOST}/chat/upload/v2/${getState().session.selectedCompany}/${fileName}`,
  formData: file,
  tokens: getState().session.tokenData,
  callback: (res) => {
    dispatch(receivePostCreateTemplateAttachment(res));
  },
});

export const updateTemplate = (data) => (dispatch, getState) => {
  const { uuid, template } = data;
  const uuidCompany = getState().session.selectedCompany;

  dispatch(requestUpdateTemplate());
  return apiPut({
    dispatch,
    body: { template },
    url: `${HOST}/chat/template/${uuidCompany}/${uuid}`,
    tokens: getState().session.tokenData,
    callback: () => {
      dispatch(receiveUpdateTemplate({
        uuid,
        template,
      }));
    },
    error: (err) => {
      dispatch(errorUpdateTemplate(err));
    },
  });
};

export const fetchUser = ({ uuidCompany, uuid, tokenData }) => new Promise((resolve, reject) => apiGet({
  url: `${HOST}/chat/user/${uuidCompany}/${uuid}`,
  tokens: tokenData,
  callback: (userData) => {
    resolve(userData);
  },
  error: (err) => reject(err),
}));

export const fetchTemplates = () => (dispatch, getState) => {
  const uuidCompany = getState().session.selectedCompany;

  dispatch(requestGetTemplates());
  return new Promise((resolve, reject) => apiGet({
    dispatch,
    url: `${HOST}/chat/template/${uuidCompany}`,
    tokens: getState().session.tokenData,
    callback: async (res) => {
      const templates = await Promise.all(
        res.map((template) => new Promise((inResolve, inReject) => {
            getUser(template.ids.uuidUser, (user) => inResolve({
              ...template,
              user,
            }), () => {
              fetchUser({
                uuidCompany,
                uuid: template.ids.uuidUser,
                tokenData: getState().session.tokenData,
              })
                .then((user) => {
                  if (user) {
                    set(`user:${user.uuid}`, user);
                    inResolve(({
                      ...template,
                      user,
                    }));
                  } else {
                    inResolve(({ ...template }));
                  }
                })
                .catch((err) => inReject(err));
            });
        })),
      );
      dispatch(receiveGetTemplates(templates));
      resolve();
    },
    error: (err) => {
      dispatch(errorGetTemplates(err));
      reject(err);
    },
  }));
};

export const createTemplate = (template) => (dispatch, getState) => {
  const uuidCompany = getState().session.selectedCompany;

  dispatch(requestPostTemplate());

  return new Promise((resolve, reject) => {
    apiPost({
      body: { template },
      url: `${HOST}/chat/template/${uuidCompany}`,
      tokens: getState().session.tokenData,
      callback: async (res) => {
        await dispatch(receivePostTemplate(res));
        await dispatch(fetchTemplates());
        resolve();
      },
      error: (err) => {
        dispatch(errorPostTemplate(err));
        reject(err);
      },
    });
  });
};
