import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { clearNewReply } from 'store/actions';

import PostComment from '../PostComment';

export default function Replies({ replies, isExpand }) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [expand, setExpand] = React.useState(false);
  React.useEffect(() => {
    if (isExpand) {
      setExpand(true);
      dispatch(clearNewReply());
    }
  }, [isExpand]);
  return (
    <View>
      <div className="count">
        {`${t('post.answers')}: ${replies.length}`}
        <div className="expand">
          {expand ? (
            <ExpandLess onClick={() => setExpand(false)} />
          ) : (
            <ExpandMore onClick={() => setExpand(true)} />
          )}
        </div>
      </div>
      {expand && (
        <div className="replies">
          {replies.map((reply, index) => (
            <PostComment key={index} comment={reply} />
          ))}
        </div>
      )}
    </View>
  );
}

const View = styled.div`
  .count {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 12px;
    line-height: 17px;
    color: #a6a6a6;
    padding-bottom: 15px;
  }
  .expand {
    cursor: pointer;
    font-size: 12px;
  }
  .replies {
    border-left: 1px solid rgba(0, 0, 0, 0.1);
    padding-left: 20px;
  }
`;

Replies.propTypes = {
  replies: PropTypes.array.isRequired,
  isExpand: PropTypes.bool,
};

Replies.defaultProps = {
  isExpand: false,
};
