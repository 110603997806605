import React from 'react';
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import Drawer from '@material-ui/core/Drawer';
import ForumIcon from '@material-ui/icons/Forum';
import { makeStyles } from '@material-ui/styles';

import { showModal } from '../../../../store/actions/modalActions';

const useStyles = (open) => makeStyles(() => ({
  root: {
    width: 70,
  },
  paper: {
    overflow: 'hidden',
    width: 70,
    backgroundColor: '#f6f8fc',
    marginRight: open ? 333 : 0,
    transition: 'margin-right 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  },
}));

const MenuItem = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 70px;
    text-align: center;
    font-size: 12px;
    color: #000;
    cursor: pointer;
    svg path {
      fill: #A0A8BB;
    }
    &:hover,
    &.active {
        background-color: #eeeff2; 
        svg path {
          fill: #006ae4;
        }
    }
    span {
      margin-top: 7px;
    }
`;

const RightBar = (props) => {
  const {
    open,
    menuItems,
    currentItem,
    handleMenuItemClick,
  } = props;

  const classes = useStyles(open)();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const handleStartDialog = () => {
    dispatch(showModal('StartDialog'));
  };

  return (
    <>
      <Drawer
        open
        variant="permanent"
        anchor="right"
        classes={classes}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.id}
            className={currentItem.id === item.id && open ? 'active' : ''}
            onClick={() => handleMenuItemClick(item)}
          >
            <item.icon />
            <span>{item.title}</span>
          </MenuItem>
        ))}
        <MenuItem
          key="start-dialog"
          onClick={() => handleStartDialog()}
        >
          <ForumIcon />
          <span>{t('startDialog.title')}</span>
        </MenuItem>
      </Drawer>
    </>
  );
};

RightBar.propTypes = {
  open: PropTypes.bool,
  menuItems: PropTypes.array,
  currentItem: PropTypes.object.isRequired,
  handleMenuItemClick: PropTypes.func,
};

RightBar.defaultProps = {
  open: false,
  menuItems: [],
  handleMenuItemClick: () => false,
};

export default RightBar;
