import * as calendarTypes from '../actions/calendarActions';

const initialState = {
  calendarIsOpen: false,
  mainDate: {
    dateAfter: new Date(),
    dateBefore: new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 1),
    ),
  },
  calendarDate: {
    dateAfter: new Date(),
    dateBefore: new Date(
      new Date().setDate(new Date().getDate() - new Date().getDay() + 1),
    ),
  },
};

const calendarReducer = (state = initialState, action) => {
  switch (action.type) {
    case calendarTypes.CLOSE_CALENDAR:
      return {
        ...state,
        calendarIsOpen: false,
      };

    case calendarTypes.TOGGLE_CALENDAR:
      return {
        ...state,
        calendarIsOpen: !state.calendarIsOpen,
      };

    case calendarTypes.CHANGE_CALENDAR_DATE_AFTER:
      if (Array.isArray(state.calendarDate.dateBefore)) {
        const tempArr = state.calendarDate.dateBefore;
        tempArr[1] = action.payload;
        return {
          ...state,
          calendarDate: {
            ...state.calendarDate,
            dateAfter: action.payload,
            dateBefore: tempArr,
          },
        };
      }
      return {
        ...state,
        calendarDate: { ...state.calendarDate, dateAfter: action.payload },
      };

    case calendarTypes.CHANGE_CALENDAR_DATE_BEFORE:
      if (Array.isArray(action.payload)) {
        return {
          ...state,
          calendarDate: {
            ...state.calendarDate,
            dateAfter: action.payload[1],
            dateBefore: action.payload,
          },
        };
      }
      return {
        ...state,
        calendarDate: { ...state.calendarDate, dateBefore: action.payload },
      };

    case calendarTypes.SET_MAIN_DATE:
      return {
        ...state,
        mainDate: state.calendarDate,
      };

    default:
      return state;
  }
};

export default calendarReducer;
