import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { Select as MuiSelect } from '@mui/material';
import PropTypes from 'prop-types';
import InputBase from '@mui/material/InputBase';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronIcon } from '../../assets/img/chevron.svg';

const CustomInput = styled(InputBase)(({ _, customInputStyles }) => ({
  '& .MuiSelect-select': {
    maxHeight: '46px',
    padding: '12px 16px',
    borderRadius: '8px',
    border: '1px solid #DADCE0',
    fontSize: '14px',
    lineHeight: '20px',
  },

  '& .MuiInputBase-input': {
    ...customInputStyles,
  },
  '& .MuiSelect-select:focus': {
    backgroundColor: '#ffffff',
  },
}));
const CustomMenuItem = styled(MenuItem)(() => ({
  fontSize: '14px',
}));

const Select = ({
  label,
  options,
  defaultValue,
  isFullWidth,
  customInputStyles,
  onChange,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <FormControl size="small" fullWidth={isFullWidth} {...rest}>
      <MuiSelect
        input={<CustomInput customInputStyles={customInputStyles} />}
        label={label}
        defaultValue={defaultValue}
        IconComponent={(props) => (
          <ChevronIcon
            style={{ height: '100%', top: 0, right: '16px' }}
            {...props}
          />
        )}
        MenuProps={{
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left',
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          getContentAnchorEl: null,
        }}
        onChange={onChange}
      >
        {options.map((option) => (
          <CustomMenuItem key={option.label} value={option}>
            {t(option.label)}
          </CustomMenuItem>
        ))}
      </MuiSelect>
    </FormControl>
  );
};

Select.propTypes = {
  isFullWidth: PropTypes.bool,
  defaultValue: PropTypes.object,
  customInputStyles: PropTypes.object,
  label: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};
Select.defaultProps = {
  isFullWidth: false,
  defaultValue: {},
  customInputStyles: {},
};

export default Select;
