import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { TASK_SIZE } from 'config';
import { Spinner, AddButton } from 'components';
import {
  postCreateTask, postDeleteTask, postUpdateTask, fetchTaskListDialog
} from 'store/actions';
import * as selector from 'store/selectors';
import { TaskForm, TaskItem } from './components';

const ToolTitle = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: #373b42;
  border-bottom: 2px solid #eeeff2;
  padding-bottom: 23px;
  padding-left: 20px;
  margin-top: 18px;
  margin-bottom: 10px;
`;

// const SpinnerWrapper = styled.div`
//   width: 100%;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TaskList = styled(Grid)`
`;

const TaskPagination = styled(Pagination)`
  .MuiPagination-ul {
    justify-content: space-between;
    padding: 5px;
    padding-top: 8px;
  }
`;

const TasksTool = () => {
  const [loading, setLoading] = React.useState(true);
  const [createFormActive, setCreateFormActive] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(1);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dialogUuid = useSelector(selector.selectCurrentDialog);
  const departments = useSelector(selector.selectCurrentDepartmentMemorized);
  const selectedCompany = useSelector(selector.selectCurrentCompanyMemorized);
  const tasks = useSelector(selector.selectDialogTaskListSelector);
  const allTasks = useSelector(selector.selectTaskListSelector);
  const taskTotalCount = useSelector(selector.selectDialogTaskTotalCountSelector);

  const handleTaskCreate = (task) => {
    dispatch(postCreateTask(task));
    setCreateFormActive(false);
  };
  const handleTaskUpdateStatus = (task, status) => {
    dispatch(postUpdateTask(
      {
        ...task,
        status: status === 'todo' ? 'done' : 'todo',
      },

    ));
  };
  const handleTaskUpdate = (task, status) => {
    if (status) {
      handleTaskUpdateStatus(task, status);
    } else {
      dispatch(postUpdateTask(task));
    }
  };

  const handleTaskDelete = (task) => {
    dispatch(postDeleteTask(task));
  };

  const handlePageChange = (event, newPage) => {
    setLoading(true);
    dispatch(fetchTaskListDialog(dialogUuid, newPage));
    setCurrentPage(newPage);
    setLoading(false);
  };

  React.useEffect(() => {
    setLoading(true);
    dispatch(fetchTaskListDialog(dialogUuid, 1));
    setLoading(false);
  }, [dialogUuid, allTasks]);

  const prepareTasks = (allTaskList) => {
    const newTasksList = allTaskList
      .map(item => ({ ...item, due: item.date.due && item.date.created >= item.date.due }))
      .sort((first, second) => (first.due === second.due) ? 0 : first.due ? -1 : 1);
    return newTasksList;
  }

  const tasksList = tasks && prepareTasks(tasks).map((task) => (
    <Grid item xs={12} key={task.uuid}>
      <TaskItem
        task={task}
        onUpdate={handleTaskUpdate}
        onDelete={handleTaskDelete}
        renderForm={(taskFormItem, setEditState) => (
          <TaskForm
            task={taskFormItem}
            departments={departments}
            selectedCompany={selectedCompany}
            currentDialog={dialogUuid}
            submitButtonText={t('common.edit')}
            onSubmit={(value) => {
              setEditState(false);
              handleTaskUpdate(value);
            }}
            onCancel={() => setEditState(false)}
          />
        )}
      />
    </Grid>
  ));
  return (
    <>
      <ToolTitle>{t('toolsBar.tasks')}</ToolTitle>

      <Grid container>
        <Grid item xs={12}>
          <AddButton
            text={t('tasksTool.create')}
            // onClick={handleAddNewButton}
            onClick={() => setCreateFormActive(true)}
            style={{
              margin: '15px 0 0 15px',
              paddingBottom: '15px',
              borderBottom: '1px solid #eeeff2',
              fontSize: '15px',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TaskPagination
            page={currentPage}
            onChange={handlePageChange}
            showFirstButton
            showLastButton
            color="secondary"
            count={Math.ceil(taskTotalCount / TASK_SIZE)} />
        </Grid>
        {createFormActive && (
          <Grid item>
            <TaskForm
              isCreateForm
              departments={departments}
              selectedCompany={selectedCompany}
              currentDialog={dialogUuid}
              submitButtonText={t('common.save')}
              onSubmit={handleTaskCreate}
              onCancel={() => setCreateFormActive(false)}
            />
          </Grid>
        )}

        <SpinnerWrapper>
          <Spinner show={loading} />
        </SpinnerWrapper>
        <TaskList container>
          {tasksList}
        </TaskList>
      </Grid >
    </>
  );
};

export default TasksTool;
