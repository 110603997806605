/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { Button, TextField, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import MuiAlert from '@material-ui/lab/Alert';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Field, Form } from 'react-final-form';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { fetchSignIn, errorSignIn, fetchSingInByCode } from '../../../../store/actions';

const useStyles = makeStyles((theme) => ({
  root: {},
  fields: {
    margin: theme.spacing(-1),
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'right',
    '& > *': {
      flexGrow: 1,
      margin: theme.spacing(1),
    },
  },
  submitButton: {
    marginTop: theme.spacing(2),
    width: '100%',
    textTransform: 'none',
    color: theme.palette.common.white,
    height: '50px',
  },
}));

const StyledTextField = styled(TextField)`
  .MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-size: 16px;
    margin-bottom: 20px;
    transform: translateY(-25px);
    background-color: transparent;
  }
  .MuiOutlinedInput-input {
    font-size: 16px;
  }
  .PrivateNotchedOutline-legendNotched-7 {
    max-width: 0;
  }
`;

const LoginForm = (props) => {
  const { className, ...rest } = props;
  const errorLogin = useSelector((state) => _.get(state, 'session.signIn.error', null));

  const { t } = useTranslation();
  const classes = useStyles();
  const dispatch = useDispatch();

  // React.useEffect(() => {
  //   if (error && error.status === 401) {
  //   }
  // }, [error]);

  const submitForm = async (values) => {
    const userData = {
      ...values,
      grantBy: 'password',
      secret: 'Cluw1tBw94',
    };
    dispatch(fetchSignIn(userData)).then(() => {
      document.location.reload();
    });
  };

  return (
    <Form
      onSubmit={submitForm}
      initialValues={{ email: '', password: '' }}
      render={({ handleSubmit, error, invalid }) => (
        <form
          {...rest}
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit}
        >
          {errorLogin && (
            <>
              <MuiAlert severity="error">
                <Typography variant="subtitle2">
                  {t('errors:auth.authInvalid')}
                </Typography>
              </MuiAlert>
              <br />
              <br />
            </>
          )}
          <br />
          <div className={classes.fields}>
            <Field name="email">
              {({ input, meta }) => (
                <StyledTextField
                  error={meta.error !== undefined}
                  fullWidth
                  helperText={meta.error}
                  label={t('loginPage.formEmailLabel')}
                  {...input}
                  variant="outlined"
                />
              )}
            </Field>

            <br />
            <br />
            <Field name="password">
              {({ input, meta }) => (
                <StyledTextField
                  error={meta.error !== undefined}
                  fullWidth
                  helperText={meta.error}
                  label={t('loginPage.formPasswordLabel')}
                  {...input}
                  type="password"
                  variant="outlined"
                />
              )}
            </Field>
          </div>

          <br />
          <Link to={{ pathname: "https://connect.intellectdialog.com/user/recovery" }} target="_blank">
            {t('loginPage.forgotPassword')}
          </Link>
          <br />
          <Button
            className={classes.submitButton}
            color="secondary"
            disabled={invalid}
            size="large"
            type="submit"
            variant="contained"
          >
            {t('loginPage.btnSignin')}
          </Button>
        </form>
      )}
    />
  );
};

LoginForm.propTypes = {
  className: PropTypes.string,
};

LoginForm.defaultProps = {
  className: '',
};

export default LoginForm;
