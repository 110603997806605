import React, { useEffect } from 'react';
import styled from 'styled-components';
import _ from 'lodash';

import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Switch, Tooltip } from '@material-ui/core';
import { KeyboardReturn } from '@material-ui/icons';
import {
  clearPostChatAttachment,
  getAiData,
  makeAiSync,
  setAiMessage,
  setChatMessage,
} from 'store/actions';

import { Spinner } from 'components';

import { ReactComponent as BetaIcon } from '../../../../../assets/img/beta.svg';

export default function AiTool() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.session);
  const isDeveloper = _.get(user, 'group', '') === 'developer';

  const {
    currentDialog,
    selectedCompany,
    user: { companies },
  } = useSelector((state) => state.session);
  const company = companies.find(({ uuid }) => uuid === selectedCompany);
  const { error, loading, sync, answers, message } = useSelector(
    (state) => state.ai,
  );
  const dialog = useSelector((state) =>
    state.chat.dialogs.find((x) => x.uuid === currentDialog),
  );
  useEffect(() => {
    if (dialog) {
      const lastMessage = dialog.history[dialog.history.length - 1];
      if (
        lastMessage.content.type === 'message.text' &&
        lastMessage.type === 'in'
      ) {
        const lastMessageText = _.get(lastMessage, 'content.data.text');
        if (lastMessageText) {
          dispatch(setAiMessage(lastMessageText));
        }
      }
    }
  }, [dialog]);
  useEffect(() => {
    if (message) {
      dispatch(
        getAiData({
          uuidCompany: selectedCompany,
          question: message,
        }),
      );
    }
  }, [message]);

  const handleSwitch = () => {
    if (!sync && company && isDeveloper) {
      dispatch(
        makeAiSync({
          uuidCompany: company.uuid,
          nameCompany: company.name,
        }),
      );
    }
  };

  const handleInsert = (text) => {
    dispatch(setChatMessage(text));
    dispatch(clearPostChatAttachment());
  };

  const getContent = () => {
    if (sync) {
      if (dialog) {
        if (message) {
          return (
            <>
              <dvi className="answers">
                <div className="question">{message}</div>
                {answers && answers.length > 0
                  ? answers.map(({ answer }, index) => (
                      <div key={index} className="answer">
                        {answer}
                        <div
                          className="send-icon"
                          onClick={() => handleInsert(answer)}
                        >
                          <KeyboardReturn />
                        </div>
                      </div>
                    ))
                  : t('ai.lolSkill')}
              </dvi>
            </>
          );
        }
        return <div className="center">Выберите текст</div>;
      }
      return <div className="center">Выберите диалог</div>;
    }
    return !sync ? <div className="center">{t('ai.notAllowed')}</div> : null;
  };

  return (
    <>
      <Header>
        <div className="header">
          <div className="title">
            {t('toolsBar.ai')}
            {!sync && isDeveloper && (
              <Switcher
                onChange={handleSwitch}
                checked={sync}
                success={!sync && isDeveloper}
              />
            )}
          </div>
          <Tooltip title={t('ai.beta')}>
            <div className="beta">
              <BetaIcon />
            </div>
          </Tooltip>
        </div>
        <div className="subtitle">
          {sync
            ? t('ai.inwork')
            : isDeveloper
            ? `${t('ai.hello')}. ${t('ai.switchForTry')}`
            : t('ai.hello')}
        </div>
      </Header>
      <Content container>
        {error ? (
          <div className="center">{t('errors:ai.any')}</div>
        ) : loading || !company ? (
          <div className="center">
            <Spinner show />
          </div>
        ) : (
          getContent()
        )}
      </Content>
    </>
  );
}

const Switcher = styled(Switch)`
  .MuiButtonBase-root {
    ${(props) => !props.success && 'cursor: not-allowed'};
  }
`;

const Content = styled.div`
  overflow: hidden;
  overflow-y: auto;
  padding: 15px;
  padding-top: 0px;
  height: 100%;
  .question {
    word-wrap: break-word;
    justify-content: space-between;
    gap: 12px;
    padding: 12px 15px;
    background: #e3deff;
    border-radius: 8px;
  }
  .send-icon {
    margin-top: 12px;
    cursor: pointer;
    width: 15px;
    display: flex;
    width: 100%;
    justify-content: right;
  }
  .answer {
    justify-content: space-between;
    gap: 12px;
    padding: 12px 15px;
    background: #f6f8fc;
    border-radius: 8px;
  }
  .answers {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  .center {
    height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    color: #a0a8bb;
    text-align: center;
  }
  .body {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`;

const Header = styled.div`
  padding-bottom: 23px;
  padding-left: 20px;
  margin-top: 18px;
  margin-bottom: 10px;
  border-bottom: 2px solid #eeeff2;
  .beta {
    width: 24px;
    height: 24px;
    background: #e6e7e8;
    border-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: help;
  }
  .header {
    display: flex;
    align-items: center;
    gap: 7px;
  }
  .title {
    font-size: 19px;
    font-weight: bold;
    color: #373b42;
  }
  .subtitle {
    padding-top: 4px;
    font-size: 12px;
    line-height: 17px;
    color: #727887;
  }
`;
