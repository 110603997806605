import _ from 'lodash';
import uuidGenerator from 'uuid';
import { getPersone } from 'utils/personeHelper';
import i18n from '../../i18n';
import { HOST, DEFAULT_DIALOG_MESSAGES_PAGE_SIZE } from '../../config';
import { apiDelete, apiGet, apiPost } from '../../api';
import { notify, notifyNewMessage } from '../../utils/notify';
import { play as soundNotify } from '../../utils/soundNotify';
import { wsSndMessage } from './wsActions';
import { updateDepartments } from './sessionActions';
import { updateFile } from './filesActions';
import {
  setPosts,
  newPostComment,
  setCommentReplyPersonal,
  addPost,
  updatePostHistory,
  setPersonalReply,
  setCommentsLoading,
} from './postActions';
import { setPostMode } from './settingsActions';
import { checkAiSync, setAiAnswers, setAiError, setAiSync } from './aiActions';

export const SORT_DIALOGS = 'SORT_DIALOGS';
export const RESET_SORTED = 'RESET_SORTED';
export const SET_FORWARD_STATUS = 'SET_FORWARD_STATUS';

export const CHAT_MESSAGE = 'CHAT_MESSAGE';
export const CHAT_MESSAGE_STATUS = 'CHAT_MESSAGE_STATUS';

export const CHAT_RCV_DIALOGS = 'CHAT_RCV_DIALOGS';
export const CHAT_RCV_DIALOG = 'CHAT_RCV_DIALOG';
export const CHAT_RCV_EVENT = 'CHAT_RCV_EVENT';
export const CHAT_SET_DIALOG_LOADING = 'CHAT_SET_DIALOG_LOADING';
export const CHAT_SEARCH_SET_DIALOG_LOADING = 'CHAT_SEARCH_SET_DIALOG_LOADING';
export const CHAT_CHANGE_COPMANY = 'CHAT_CHANGE_COPMANY';
export const CHAT_DIALOG_UNREAD = 'CHAT_DIALOG_UNREAD';
export const CHAT_DIALOG_READ = 'CHAT_DIALOG_READ';

export const CHAT_CLEAR_NEW_MESSAGES_STATE = 'CHAT_CLEAR_NEW_MESSAGES_STATE';

export const CHAT_UPDATE_DIALOG_HISTORY = 'CHAT_UPDATE_DIALOG_HISTORY';
export const CHAT_SET_MESSAGES_GET_LOADING_STATE =
  'CHAT_SET_MESSAGES_GET_LOADING_STATE';

export const SET_CHAT_MESSAGE = 'SET_CHAT_MESSAGE';
export const SET_CHAT_MESSAGES_COUNT = 'SET_CHAT_MESSAGES_COUNT';
export const CHAT_ADD_PERSONE_TAG = 'CHAT_ADD_PERSONE_TAG';
export const CHAT_DEL_PERSONE_TAG = 'CHAT_DEL_PERSONE_TAG';
export const CHAT_ADD_PERSONE_INFO = 'CHAT_ADD_PERSONE_INFO';
export const CHAT_UPDATE_PERSONE_INFO = 'CHAT_UPDATE_PERSONE_INFO';
export const CHAT_DEL_PERSONE_INFO = 'CHAT_DEL_PERSONE_INFO';

export const ARCHIVE_DIALOG = 'CHAT_ARCHIVE_DIALOG';

export const ADD_NEW_ASSIGN_DIALOG = 'ADD_NEW_ASSIGN_DIALOG';
export const REMOVE_NEW_ASSIGN_DIALOG = 'REMOVE_NEW_ASSIGN_DIALOG';

export const REQUEST_COMPANY_PROVIDERS = 'REQUEST_COMPANY_PROVIDERS';
export const RECEIVE_COMPANY_PROVIDERS = 'RECEIVE_COMPANY_PROVIDERS';
export const ERROR_COMPANY_PROVIDERS = 'ERROR_COMPANY_PROVIDERS';
export const SET_COMPANY_PROVIDERS = 'SET_COMPANY_PROVIDERS';

export const CHAT_OPEN_DIALOG = 'CHAT_OPEN_DIALOG';

export const RECEIVE_CHAT_ATTACHMENT = 'RECEIVE_CHAT_ATTACHMENT';
export const CLEAR_CHAT_ATTACHMENT = 'CLEAR_CHAT_ATTACHMENT';

export const CHAT_ASSIGNEE_REQUEST = 'CHAT_ASSIGNEE_REQUEST';
export const CHAT_ASSIGNEE_REQUEST_SUCCESS = 'CHAT_ASSIGNEE_REQUEST_SUCCESS';
export const CHAT_ASSIGNEE_REQUEST_FAILURE = 'CHAT_ASSIGNEE_REQUEST_FAILURE';

export const CHAT_ASSIGNEE_SET = 'CHAT_ASSIGNEE_SET';
export const CHAT_TASK_SET = 'CHAT_TASK_SET';
export const UPDATE_STATISTICS = 'UPDATE_STATISTICS';

export const CHAT_DEBUG_ENABLE = 'CHAT_DEBUG_ENABLE';
export const CHAT_DEBUG_DISABLE = 'CHAT_DEBUG_DISABLE';

export const CHAT_UPLOAD_FILE = 'CHAT_UPLOAD_FILE';
export const CHAT_UPLOAD_FILE_REMOVE = 'CHAT_UPLOAD_FILE_REMOVE';
export const CHAT_UPLOAD_FILE_ADD = 'CHAT_UPLOAD_FILE_ADD';

export const CHAT_UPLOAD_FILE_CLEAR = 'CHAT_UPLOAD_FILE_CLEAR';

export const CHAT_UPLOAD_REQUEST = 'CHAT_UPLOAD_REQUEST';
export const CHAT_UPLOAD_COMPLETE = 'CHAT_UPLOAD_COMPLETE';

export const CHAT_GET_PERSONE = 'CHAT_GET_PERSONE';

export const CHAT_GET_TAGS = 'CHAT_GET_TAGS';
export const CHAT_GET_PERSON_TAGS = 'CHAT_GET_PERSON_TAGS';
export const CHAT_UPDATE_DIALOG_TAGS = 'CHAT_UPDATE_DIALOG_TAGS';
export const DELETE_TAG = 'DELETE_TAG';

export const CHAT_REPLY_MESSAGE = 'CHAT_REPLY_MESSAGE';
export const CHAT_FORWARD_MESSAGE = 'CHAT_FORWARD_MESSAGE';

export const STATISTICS_LOADING_SET = 'STATISTICS_LOADING_SET';
export const STATISTICS_LOADING_UNSET = 'STATISTICS_LOADING_UNSET';
export const STATISTICS_SUBSCRIBED = 'STATISTICS_SUBSCRIBED';

export const ADD_SELECTED_MSG = 'ADD_SELECTED_MSG';
export const CLEAR_SELECTED_MSG = 'CLEAR_SELECTED_MSG';

export const SEARCH_DIALOGS = 'SEARCH_DIALOGS';
export const CHAT_SET_FORWARD_DIALOG_LOADING =
  'CHAT_SET_FORWARD_DIALOG_LOADING';
export const CHAT_SET_FORWARD_DIALOG = 'CHAT_SET_FORWARD_DIALOG';

export const SET_SEARCH_DIALOGS = 'SET_SEARCH_DIALOGS';
export const RESET_SEARCH_DIALOGS = 'RESET_SEARCH_DIALOGS';

export const SET_TYPING = 'SET_TYPING';
export const OPEN_DIALOG_OPTIONS = 'OPEN_DIALOG_OPTIONS';

export const PIN_DIALOG = 'PIN_DIALOG';
export const UNPIN_DIALOG = 'UNPIN_DIALOG';

export const SET_AMO_BASE_URL = 'SET_AMO_BASE_URL';
export const UPDATE_PERSONES_NAME = 'UPDATE_PERSONES_NAME';

export const UPDATE_DIALOG_TITLE = 'UPDATE_DIALOG_TITLE';
export const LOADING_UPDATE_DIALOG_TITLE = 'LOADING_UPDATE_DIALOG_TITLE';

export const DELETE_DIALOG = 'DELETE_DIALOG';

export const DIALOG_TASK_LIST_HAS_TODO_SET = 'DIALOG_TASK_LIST_HAS_TODO_SET';

export const REMOVE_DIALOG = 'REMOVE_DIALOG';

export const SET_COUNT_STAT = 'SET_COUNT_STAT';
export const CLEAR_COUNT_STAT = 'CLEAR_COUNT_STAT';

export const SET_USER_SETTINGS = 'SET_USER_SETTINGS';

export const UPDATE_DIALOG_ADDITIONAL = 'UPDATE_DIALOG_ADDITIONAL';

export const updateDialogAdditional = (payload) => ({
  type: UPDATE_DIALOG_ADDITIONAL,
  payload,
});

export const setUserSettings = (payload) => ({
  type: SET_USER_SETTINGS,
  payload,
});

export const clearCountStat = () => ({
  type: CLEAR_COUNT_STAT,
});

export const setCountStat = (payload) => ({
  type: SET_COUNT_STAT,
  payload,
});

export const dialogTaskListHasTodoSet = (payload) => ({
  type: DIALOG_TASK_LIST_HAS_TODO_SET,
  payload,
});

export const deleteTag = (payload) => ({
  type: DELETE_TAG,
  payload,
});

export const removeDialog = (payload) => ({
  type: REMOVE_DIALOG,
  payload,
});

export const loadingUpdateDialogTitle = (payload) => ({
  type: LOADING_UPDATE_DIALOG_TITLE,
  payload,
});

export const deleteDialog = (payload) => ({
  type: DELETE_DIALOG,
  payload,
});

export const updateDialogTitle = (payload) => ({
  type: UPDATE_DIALOG_TITLE,
  payload,
});

export const setAmoBaseUrl = (payload) => ({
  type: SET_AMO_BASE_URL,
  payload,
});

export const updatePersoneName = (payload) => ({
  type: CHAT_UPDATE_PERSONE_INFO,
  payload,
});

export const addPersoneName = (payload) => ({
  type: CHAT_ADD_PERSONE_INFO,
  payload,
});

export const updatePersonesName = (payload) => ({
  type: UPDATE_PERSONES_NAME,
  payload,
});

export const setPinDialog = (payload) => ({
  type: PIN_DIALOG,
  payload,
});

export const setUnpinDialog = (payload) => ({
  type: UNPIN_DIALOG,
  payload,
});

export const setOpenDialogOptions = (payload) => ({
  type: OPEN_DIALOG_OPTIONS,
  payload,
});

export const setTyping = (payload) => ({
  type: SET_TYPING,
  payload,
});

export const setSearchDialogs = (text) => ({
  type: SET_SEARCH_DIALOGS,
  payload: text,
});

export const resetSearchdDialogs = () => ({
  type: RESET_SEARCH_DIALOGS,
  payload: null,
});

export const setSearchedDialogs = (payload) => ({
  type: SEARCH_DIALOGS,
  payload,
});

export const sortDialogs = () => ({
  type: SORT_DIALOGS,
});

export const resetSorted = () => ({
  type: RESET_SORTED,
});

export const statisticsSubscribeHandler = () => ({
  type: STATISTICS_SUBSCRIBED,
});

export const setStatisticsLoading = (payload) => ({
  type: STATISTICS_LOADING_SET,
  payload,
});

export const unsetStatisticsLoading = () => ({
  type: STATISTICS_LOADING_UNSET,
});

export const chatReplyMessage = (payload) => ({
  type: CHAT_REPLY_MESSAGE,
  payload,
});

export const chatUploadRequest = (uuidDialog) => ({
  type: CHAT_UPLOAD_REQUEST,
  payload: { uuidDialog },
});
export const chatUploadComplete = (uuidDialog) => ({
  type: CHAT_UPLOAD_COMPLETE,
  payload: { uuidDialog },
});

export const chatSetUploadFile = (uuidDialog, files) => ({
  type: CHAT_UPLOAD_FILE,
  payload: { uuidDialog, files },
});

export const chatUploadFileRemove = (uuidDialog, uuidFile) => ({
  type: CHAT_UPLOAD_FILE_REMOVE,
  payload: { uuidDialog, uuidFile },
});

export const chatUploadFileClear = (uuidDialog) => ({
  type: CHAT_UPLOAD_FILE_CLEAR,
  payload: { uuidDialog },
});

export const chatUploadFile = (files) => (dispatch, getState) => {
  const mappedFiles = files.map((file) => ({
    src: file,
    uuid: uuidGenerator.v4(),
  }));
  const selectedDialog = _.get(getState(), 'session.currenDialog');
  dispatch(chatSetUploadFile(selectedDialog, mappedFiles));
};

export const chatAssigneeRequestSuccess = () => (dispatch) =>
  dispatch({
    type: CHAT_ASSIGNEE_REQUEST_SUCCESS,
  });

export const chatAssigneeRequestFailure = (error) => (dispatch) =>
  dispatch({
    type: CHAT_ASSIGNEE_REQUEST_FAILURE,
    error,
  });

export const chatAssigneeRequest = () => (dispatch) =>
  dispatch({
    type: CHAT_ASSIGNEE_REQUEST,
  });
export const savePersoneByUuid = (persone) => ({
  type: CHAT_GET_PERSONE,
  payload: { persone },
});

export const setAllTags = (payload) => ({
  type: CHAT_GET_TAGS,
  payload,
});
export const setAllPersonTags = (payload) => ({
  type: CHAT_GET_PERSON_TAGS,
  payload,
});

export const updateDialogTags = (payload) => ({
  type: CHAT_UPDATE_DIALOG_TAGS,
  payload,
});

export const fetchAllTags = () => (dispatch, getState) =>
  apiGet({
    url: `${HOST}/chat/history/tags/${getState().session.selectedCompany}`,
    tokens: getState().session.tokenData,
    callback: (data) => {
      dispatch(setAllTags(data));
    },
  });
export const fetchAllPersonTags = () => (dispatch, getState) =>
  apiGet({
    url: `${HOST}/chat/history/persone/tags/${
      getState().session.selectedCompany
    }`,
    tokens: getState().session.tokenData,
    callback: (data) => {
      dispatch(setAllPersonTags(data));
    },
  });
export const removeDialogTag =
  (uuidDialog, title, created) => (dispatch, getState) =>
    apiDelete({
      dispatch,
      url: `${HOST}/chat/history/dialogs/${uuidDialog}/remove-tag`,
      tokens: getState().session.tokenData,
      body: { title, created },
      callback: ({ success, dialog }) => {
        if (success) {
          dispatch(fetchAllTags());
          dispatch(updateDialogTags(dialog));
        }
      },
    });

export const addDialogTag =
  ({ uuidDialog, tag }) =>
  (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/history/dialogs/${uuidDialog}/${
        getState().session.selectedCompany
      }/add-tag`,
      tokens: getState().session.tokenData,
      body: { title: tag },
      callback: ({ success, dialog }) => {
        if (success) {
          dispatch(fetchAllTags());
          dispatch(updateDialogTags(dialog));
        }
      },
    });

export const fetchUserByUuid =
  ({ uuidUser, uuidCompany }) =>
  (dispatch, getState) =>
    apiGet({
      dispatch,
      url: `${HOST}/chat/${uuidCompany}/${uuidUser}`,
      tokens: getState().session.tokenData,
      callback: (persone) => {
        dispatch(savePersoneByUuid(persone));
      },
    });

export const setChatMsgCount = (payload) => ({
  type: SET_CHAT_MESSAGES_COUNT,
  payload,
});

export const fetchPersoneByUuid = (uuidPersone) => (dispatch, getState) =>
  apiGet({
    dispatch,
    url: `${HOST}/chat/persone/${uuidPersone}`,
    tokens: getState().session.tokenData,
    callback: (persone) => {
      dispatch(savePersoneByUuid(persone));
    },
    error: () => {
      fetchUserByUuid({
        uuidUser: uuidPersone,
        uuidCompany: getState().session.selectedCompany,
      });
    },
  });

export const receiveInWorkEvent = (payload) => async (dispatch, getState) => {
  const state = getState();

  const {
    ids: { uuidDialog, uuidCompany },
  } = payload;

  if (state.session.selectedCompany === uuidCompany) {
    const currentDialog = state.chat.dialogs.find(
      ({ uuid }) => uuid === uuidDialog,
    );

    if (!currentDialog) {
      return;
    }

    dispatch(updateDialogAdditional(payload));
  }
};

export const updateChatDialogs = (payload) => async (dispatch, getState) => {
  const { session } = getState();
  for (let i = 0; i < payload.rows.length; i += 1) {
    const dialog = _.get(payload, `rows.${i}`);
    if (_.get(dialog, 'type') === 'group') {
      if (dialog.participants.length > 0) {
        for (let j = 0; j < dialog.participants.length; j += 1) {
          getPersone(dialog.participants[j]).then((user) => {
            if (user === null) {
              dispatch(fetchPersoneByUuid(dialog.participants[j]));
            }
          });
        }
      }
    }
  }
  dispatch({
    type: CHAT_RCV_DIALOGS,
    payload,
    session,
  });
};

export const addChatDialog = (payload) => (dispatch) => {
  dispatch({
    type: CHAT_RCV_DIALOG,
    payload,
  });
};

export const changeCompany = (payload) => (dispatch) =>
  dispatch({
    type: CHAT_CHANGE_COPMANY,
    payload,
  });

export const setDialogsLoading =
  ({ loading }) =>
  (dispatch) =>
    dispatch({
      type: CHAT_SET_DIALOG_LOADING,
      payload: { loading },
    });

export const setForwardDialogsLoading = (payload) => (dispatch) =>
  dispatch({
    type: CHAT_SET_FORWARD_DIALOG_LOADING,
    payload,
  });

export const setForwardDialogs = (payload) => (dispatch) =>
  dispatch({
    type: CHAT_SET_FORWARD_DIALOG,
    payload,
  });
export const setSearchDialogsLoading = (payload) => ({
  type: CHAT_SEARCH_SET_DIALOG_LOADING,
  payload,
});

export const setAssignee = (payload) => ({
  type: CHAT_ASSIGNEE_SET,
  payload,
});

export const setTasks = (payload) => ({
  type: CHAT_TASK_SET,
  payload,
});

export const chatDebugEnable = (payload) => ({
  type: CHAT_DEBUG_ENABLE,
  payload,
});
export const chatDebugDisable = (payload) => ({
  type: CHAT_DEBUG_DISABLE,
  payload,
});

export const getChatDialog = (payload) => (dispatch) => {
  dispatch(setDialogsLoading({ loading: true }));
  dispatch(
    wsSndMessage({
      action: 'chat.dialog.get',
      data: payload,
    }),
  );
};

export const receiveAssigneeEvent = (data) => (dispatch, getState) => {
  const state = getState();
  if (
    state.session.user.uuid === _.get(data, 'content.data.params.toUuid') ||
    (state.session.user.settings &&
      state.session.user.settings.departments &&
      state.session.user.settings.departments.includes(
        _.get(data, 'content.data.params.toUuid'),
      ))
  ) {
    if (state.settings.notificationIsOn) {
      const loc = i18n(getState().settings.language);
      const eventType = _.get(data, 'content.data.type');

      const key = `dialog.${data.content.type}.${
        eventType === 'assignee' ? 'assignee.default' : eventType
      }`;
      notify(loc.t(key, _.get(data, 'content.data.params')));
    }
    if (state.settings.sound.isOn) {
      soundNotify();
    }
    // dispatch(addNewAssignDialog({ uuid: payload.uuidDialog }));
  }

  dispatch(setAssignee(data));
};

export const receiveTaskEvent = (data) => (dispatch, getState) => {
  const state = getState();
  if (
    state.session.user.uuid === _.get(data, 'content.data.params.uuid') ||
    (state.session.user.settings &&
      state.session.user.settings.departments &&
      state.session.user.settings.departments.includes(
        _.get(data, 'content.data.params.uuid'),
      ))
  ) {
    if (state.settings.notificationIsOn) {
      const eventType = _.get(data, 'content.data.type');

      const key = `dialog.${data.content.type}.${
        eventType === 'assignee' ? 'assignee.default' : eventType
      }`;
      if (_.get(data, 'content.data.params.uuidUser') === 'bot') {
        _.set(data, 'content.data.params.userName', 'bot');
      }
      notify(i18n.t(key, _.get(data, 'content.data.params')));
    }
    if (state.settings.sound.isOn) {
      soundNotify();
    }
    // dispatch(addNewAssignDialog({ uuid: payload.uuidDialog }));
  }

  // getChatDialog({
  //   uuidDialog: _.get(data, 'ids.uuidDialog'),
  //   sort: 'desc',
  //   historySize: DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
  // })(dispatch);

  dispatch(setTasks(data));
};

export const posponeDialog =
  ({ uuid, datetime }) =>
  (dispatch) => {
    dispatch(
      wsSndMessage({
        action: 'chat.dialog.postpone',
        data: {
          uuid,
          datetime,
        },
      }),
    );
  };

export const unposponeDialog =
  ({ uuid }) =>
  (dispatch) => {
    dispatch(
      wsSndMessage({
        action: 'chat.dialog.postpone.cancel',
        data: {
          uuid,
        },
      }),
    );
  };

export const getChatDialogs = (payload) => (dispatch) => {
  dispatch(setDialogsLoading({ loading: true }));
  dispatch(
    wsSndMessage({
      action: 'chat.dialogs.get',
      data: payload,
    }),
  );
};

export const getDialogsCount = (uuid) => (dispatch) => {
  dispatch(
    wsSndMessage({
      action: 'chat.dialogs.count',
      data: {
        uuid,
      },
    }),
  );
};

export const updateChatDialogTitle =
  ({ dialog }) =>
  (dispatch) => {
    dispatch(loadingUpdateDialogTitle(true));
    dispatch(
      wsSndMessage({
        action: 'chat.dialog.update.title',
        data: {
          dialog,
        },
      }),
    );
  };

export const getForwardDialogs =
  ({ uuidCompany, providerName, uuidConnection, search = '', page, size }) =>
  (dispatch) => {
    dispatch(setForwardDialogsLoading(true));
    dispatch(
      wsSndMessage({
        action: 'chat.forward.dialogs.get',
        data: {
          search,
          uuidCompany,
          providerName,
          uuidConnection,
          page,
          size,
        },
      }),
    );
  };
export const searchChatDialogs = (payload) => (dispatch, getState) => {
  dispatch(setSearchDialogsLoading(true));
  dispatch(
    wsSndMessage({
      action: 'chat.dialogs.search',
      data: {
        ...payload,
        uuidCompany: getState().session.selectedCompany,
      },
    }),
  );
};

export const subscribeDeleteDialogEvents = () => (dispatch, getState) => {
  const {
    session: {
      user: { companies },
    },
  } = getState();

  wsSndMessage({
    action: 'dialog.delete.subscribe',
    data: {
      companies: companies.map((o) => o.uuid),
    },
  })(dispatch);
};

export const subscribeEvents = (payload) => (dispatch) => {
  wsSndMessage({
    action: 'chat.events.subscribe',
    data: payload,
  })(dispatch);
  dispatch(statisticsSubscribeHandler());
  dispatch(subscribeDeleteDialogEvents());
};

export const subscribeCrmEvents = () => (dispatch) =>
  wsSndMessage({
    action: 'chat.events.subscribe.crm',
    data: {
      fields: ['unreadCount'],
    },
  })(dispatch);

export const subscribeOrganizationsEvents = () => (dispatch, getState) => {
  const {
    session: {
      user: { companies },
    },
  } = getState();
  dispatch(
    subscribeEvents({
      companies: companies.map((o) => o.uuid),
    }),
  );
  /*
  dispatch(
    subscribeCrmEvents({
      field: ['unreadCount'],
    })
  )
  */
};

export const setDialogRead = (payload) => (dispatch) => {
  wsSndMessage({
    action: 'chat.dialogs.mark.read',
    data: {
      uuid: payload.uuid,
    },
  })(dispatch);
  dispatch({
    type: CHAT_DIALOG_READ,
    payload,
  });
};

export const setDialogUnread = (payload) => (dispatch) => {
  dispatch({
    type: CHAT_DIALOG_UNREAD,
    payload,
  });
};

export const updateStatistics = (payload) => ({
  type: UPDATE_STATISTICS,
  payload,
});

export const setMessageStatus = (payload) => (dispatch) => {
  dispatch({
    type: CHAT_MESSAGE_STATUS,
    payload,
  });
};

export const sndChatMessage = (payload) => (dispatch) => {
  const { text, uuidDialog, reply, parseMode } = payload;
  wsSndMessage({
    action: 'chat.message.send',
    data: {
      cmd: 'send.text',
      data: {
        text,
      },
      reply,
      parseMode,
      uuidDialog,
    },
  })(dispatch);
};

export const sndChatAudio = (payload) => (dispatch) => {
  const { fileUrl, uuidDialog, title, size, caption, reply, parseMode } =
    payload;
  const msg = {
    action: 'chat.message.send',
    data: {
      cmd: 'send.audio',
      data: {
        files: [
          {
            fileUrl,
            storage: 'link',
            title,
            size,
          },
        ],
        caption,
      },
      uuidDialog,
      reply,
      parseMode,
    },
  };
  if (caption) {
    msg.data.caption = caption;
  }
  wsSndMessage(msg)(dispatch);
};

export const sndChatVideo = (payload) => (dispatch) => {
  const { fileUrl, uuidDialog, title, size, caption, reply, parseMode } =
    payload;
  const msg = {
    action: 'chat.message.send',
    data: {
      cmd: 'send.video',
      data: {
        files: [
          {
            fileUrl,
            storage: 'link',
            title,
            size,
          },
        ],
        caption,
      },
      uuidDialog,
      reply,
      parseMode,
    },
  };
  if (caption) {
    msg.data.caption = caption;
  }
  wsSndMessage(msg)(dispatch);
};

export const sndChatFile = (payload) => (dispatch) => {
  const { fileUrl, uuidDialog, title, size, caption, reply, parseMode } =
    payload;
  const msg = {
    action: 'chat.message.send',
    data: {
      cmd: 'send.file',
      data: {
        files: [
          {
            fileUrl,
            storage: 'link',
            title,
            size,
          },
        ],
        caption,
      },
      reply,
      parseMode,
      uuidDialog,
    },
  };
  wsSndMessage(msg)(dispatch);
};

export const sndChatImage = (payload) => (dispatch) => {
  const { fileUrl, uuidDialog, caption, reply, parseMode } = payload;
  const data = {
    cmd: 'send.image',
    data: {
      caption,
      sizes: [
        {
          storage: 'link',
          fileUrl,
        },
      ],
    },
    uuidDialog,
  };
  if (reply) data.reply = reply;
  if (parseMode) data.parseMode = parseMode;
  wsSndMessage({
    action: 'chat.message.send',
    data,
  })(dispatch);
};

export const receivePostChatAttachment = (data) => ({
  type: RECEIVE_CHAT_ATTACHMENT,
  data,
});

export const clearPostChatAttachment = () => ({
  type: CLEAR_CHAT_ATTACHMENT,
});

export const postChatAttachment =
  (file, uuidCompany) => async (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/upload/${uuidCompany}`,
      formData: file,
      tokens: getState().session.tokenData,
      callback: (res) => {
        dispatch(receivePostChatAttachment(res));
      },
    });
export const postChatAttachmentThroughBody =
  (fileName, file, uuidCompany) => async (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/upload/v2/${uuidCompany}/${fileName}`,
      formData: file,
      tokens: getState().session.tokenData,
      callback: (res) => {
        dispatch(receivePostChatAttachment(res));
      },
    });
export const uploadChatAttachment = (fileUrl) => async (dispatch, getState) =>
  apiPost({
    dispatch,
    url: `${HOST}/chat/upload/url/${getState().session.selectedCompany}`,
    tokens: getState().session.tokenData,
    body: {
      url: fileUrl,
    },
    callback: (data) => {
      const a = document.createElement('a');
      a.href = data;
      a.download = 'image.png';
      a.click();
    },
  });
export const postChatAttachmentThunk =
  (files, uuiCompany) => async (dispatch, getState) => {
    const selectedDialog = _.get(getState(), 'session.currenDialog');
    dispatch(chatUploadRequest(selectedDialog));
    await Promise.all([
      files.map((file) => {
        const formData = new FormData();
        formData.append('file', file.src);
        return postChatAttachment(formData, uuiCompany)(
          dispatch,
          getState,
        ).then(() => {
          setTimeout(() => {
            dispatch(chatUploadFileRemove(selectedDialog, file.uuid));
          }, 1000);
        });
      }),
    ]).then(() => {
      dispatch(chatUploadComplete(selectedDialog));
    });
  };

export const sndStartDialog = (payload) => (dispatch) =>
  wsSndMessage({
    action: 'chat.message.sendFirst',
    data: payload,
  })(dispatch);

export const closeDialog = (payload) => (dispatch) => {
  const { uuid } = payload;
  wsSndMessage({
    action: 'chat.dialogs.close',
    data: {
      uuid,
    },
  })(dispatch);
  dispatch({
    type: ARCHIVE_DIALOG,
    payload,
  });
};

export const reopenDialog = (payload) => (dispatch) => {
  const { uuid } = payload;
  wsSndMessage({
    action: 'chat.dialogs.open',
    data: {
      uuid,
    },
  })(dispatch);
};

export const forwardMessage =
  ({ additional, ids }) =>
  (dispatch) => {
    wsSndMessage({
      action: 'chat.message.forward',
      data: {
        additional,
        ids,
      },
    })(dispatch);
  };

export const deleteMessage = (payload) => (dispatch) => {
  const { providerName, messageIds, ids } = payload;
  wsSndMessage({
    action: 'chat.message.delete',
    data: {
      providerName,
      messageIds,
      ids,
    },
  })(dispatch);
};

export const newChatMessageStatus = (payload) => (dispatch, getState) => {
  const state = getState();
  const {
    ids: { uuidCompany },
  } = payload;
  const {
    session: { selectedCompany },
  } = state;
  if (selectedCompany !== uuidCompany) return;
  setMessageStatus(payload)(dispatch);
};

export const newChatMessage =
  (payload, begin = false) =>
  (dispatch, getState) => {
    const state = getState();
    const {
      ids: { uuidDialog },
    } = payload;
    if (
      !state.settings.customFilter.inSpamList &&
      _.get(payload, 'additional.inSpamList', false)
    ) {
      return;
    }
    if (state.session.selectedCompany === payload.ids.uuidCompany) {
      const currentDialog = state.chat.dialogs.find(
        ({ uuid }) => uuid === payload.ids.uuidDialog,
      );

      if (
        currentDialog &&
        !_.get(currentDialog, 'additional.postpone', false) &&
        !_.get(state, 'settings.chatNotifications', []).includes(
          currentDialog.uuid,
        ) &&
        !_.get(payload, 'additional.inSpamList', false) &&
        _.get(payload, 'type', 'out') === 'in'
      ) {
        notifyNewMessage({
          payload,
          currentDialog,
          soundIsOn: state.settings.sound.isOn,
          notificationIsOn: state.settings.notificationIsOn,
        });
      }

      if (!currentDialog) {
        if (_.get(payload, 'type', 'in') !== 'out' || begin) {
          getChatDialog({
            uuidDialog: payload.ids.uuidDialog,
            sort: 'desc',
            historySize: DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
          })(dispatch);
        }
      } else {
        const checkDouble = currentDialog.history.find(
          (m) => m.uuid === payload.uuid,
        );
        if (!checkDouble) {
          if (
            state.session.currentDialog !== uuidDialog &&
            _.get(payload, 'type', 'out') === 'in'
          ) {
            setDialogUnread({
              uuid: uuidDialog,
              count: '++',
            })(dispatch);
          }
          dispatch({
            type: CHAT_MESSAGE,
            payload,
          });
        }
      }
    }
  };

export const setChatMessageForwardStatus = (payload) => ({
  type: SET_FORWARD_STATUS,
  payload,
});

export const chatClearNewMessagesState = (uuidDialog) => ({
  type: CHAT_CLEAR_NEW_MESSAGES_STATE,
  uuidDialog,
});

export const addNewAssignDialog = (payload) => ({
  type: ADD_NEW_ASSIGN_DIALOG,
  payload,
});

export const removeNewAssignDialog = (payload) => ({
  type: REMOVE_NEW_ASSIGN_DIALOG,
  payload,
});

export const updateDialogHistory = (payload) => (dispatch) =>
  dispatch({
    type: CHAT_UPDATE_DIALOG_HISTORY,
    payload,
  });

export const clearSelectedMsg = (payload) => ({
  type: CLEAR_SELECTED_MSG,
  payload,
});

export const addSelectedMsg = (payload) => ({
  type: ADD_SELECTED_MSG,
  payload,
});

export const setMessagesGetLoadingState = (isLoading) => ({
  type: CHAT_SET_MESSAGES_GET_LOADING_STATE,
  isLoading,
});

export const openDialog = (uuidDialog) => ({
  type: CHAT_OPEN_DIALOG,
  uuidDialog,
});

export const rcvEvent = (payload) => (dispatch, getState) => {
  const { uuid, action, success = true, data } = payload;
  if (!success) {
    if (action.startsWith('chat.ai')) {
      if (
        !(
          action === 'chat.ai.check' && _.get(payload, 'errors.code', 0) === 404
        )
      ) {
        dispatch(setAiError(true));
      }
    }
    if (action === 'chat.dialog.update.title') {
      dispatch(loadingUpdateDialogTitle(false));
    }
    dispatch(setCommentsLoading(false));
    return;
  }
  let chatAction = null;

  switch (action) {
    case 'chat.event':
      if (_.get(data, 'type') === 'sys') {
        switch (_.get(data, 'content.data.type', '0.0').split('.')[0]) {
          case 'inwork':
            chatAction = () => receiveInWorkEvent(data);
            break;
          case 'task':
            chatAction = () => receiveTaskEvent(data);
            break;
          default:
            chatAction = () => receiveAssigneeEvent(data);
            break;
        }
      } else {
        chatAction = () => newChatMessage(data);
      }
      break;
    case 'chat.message.status':
      chatAction = () => newChatMessageStatus(data);
      break;
    case 'chat.message.forward':
      dispatch(setChatMessageForwardStatus(data.status));
      break;
    case 'chat.message.send':
      chatAction = () => newChatMessage(data);
      break;
    case 'chat.message.send.file':
      chatAction = () => newChatMessage(data);
      break;
    case 'dialog.delete':
      dispatch(deleteDialog(data));
      break;
    case 'chat.message.send.image':
      chatAction = () => newChatMessage(data);
      break;
    case 'chat.comments.get':
      if (data.rows && data.rows.length) {
        chatAction = () => updatePostHistory(data);
      }
      break;
    case 'chat.messages.get':
      dispatch(setMessagesGetLoadingState(false));
      if (data.rows && data.rows.length) {
        chatAction = () => updateDialogHistory(data);
      }
      break;
    case 'chat.message.sendFirst':
      if (data) {
        if (data.uuidMessengerComment) {
          dispatch(
            setPersonalReply({
              uuidMessengerComment: data.uuidMessengerComment,
            }),
          );
          dispatch(setPostMode(false));
        }
        chatAction = () => newChatMessage(data, true);
        dispatch(openDialog(data.ids.uuidDialog));
      }
      break;
    case 'chat.forward.dialogs.get': {
      dispatch(setForwardDialogsLoading(false));
      dispatch(setForwardDialogs(data));
      break;
    }
    case 'chat.dialog.postpone':
      dispatch(removeDialog(data));
      break;
    case 'chat.dialog.postpone.cancel':
      if (_.has(getState().settings.customFilter, 'postponed')) {
        dispatch(removeDialog(data));
      }
      break;
    case 'chat.dialogs.get':
      chatAction = () => updateChatDialogs(data);
      break;
    case 'chat.dialogs.search':
      if (getState().chat.searchedDialogsLoading) {
        dispatch(setSearchedDialogs(data));
      }
      break;
    case 'post.comment.markPersonal':
      chatAction = () => setCommentReplyPersonal(data);
      break;
    case 'chat.typing':
      dispatch(setTyping(data));
      break;
    case 'chat.post.get':
      chatAction = () => addPost(data);
      break;
    case 'chat.posts.get':
      chatAction = () => setPosts(data);
      break;
    case 'post.comment.send':
      chatAction = () => newPostComment(data);
      break;
    case 'post.event':
      switch (_.get(data, 'type')) {
        case 'sent':
          chatAction = () => newPostComment(data);
          break;
        default:
          break;
      }
      break;
    case 'stat.event.calculated':
      if (data.stat) {
        dispatch(updateStatistics(data.stat));
      }
      break;
    case 'stat.event.last':
      if (+data?.count > 1) {
        dispatch(setStatisticsLoading(data.count));
      }
      break;
    case 'chat.stat.get':
      if (data.stat) {
        dispatch(updateStatistics(data.stat));
      }
      dispatch(unsetStatisticsLoading());
      break;
    case 'chat.ai.sync':
      dispatch(setAiSync(true));
      break;
    case 'chat.ai.check':
      dispatch(setAiSync(data));
      break;
    case 'chat.ai.answers':
      dispatch(setAiAnswers(data.answers));
      break;
    case 'chat.dialog.update.title': {
      if (data) {
        dispatch(updateDialogTitle(data));
      } else {
        dispatch(loadingUpdateDialogTitle(false));
      }
      break;
    }
    case 'chat.dialogs.close':
      if (!getState().settings.customFilter.closed) {
        dispatch(
          removeDialog({
            uuid: data.uuid,
          }),
        );
      }
      break;
    case 'chat.dialogs.open':
      if (getState().settings.customFilter.closed) {
        dispatch(
          removeDialog({
            uuid: data.uuid,
          }),
        );
      }
      break;
    case 'chat.dialog.get':
      // if (!getState().settings.customFilter.postponed && _.get(data, 'additional.postpone', false)) {
      //   return;
      // }
      chatAction = () => addChatDialog(data);
      break;
    case 'chat.dialogs.count':
      dispatch(setCountStat(data));
      break;
    case 'chat.message.file.get':
      chatAction = () =>
        updateFile({
          uuid,
          data,
        });
      break;
    case 'chat.departments.get':
      chatAction = () => updateDepartments(data);
      break;
    // case 'chat.event.system.event.assignee':
    // chatAction = () => receiveAssigneeEvent(data);
    // break;
    // case 'chat.event.dialog.assign':
    // chatAction = () => receiveAssign(data);
    // break;
    default:
      break;
  }

  if (chatAction) {
    chatAction()(dispatch, getState);
  }
};

export const addNewPersonTag = (payload) => (dispatch) => {
  const { uuid, tag } = payload;
  wsSndMessage({
    action: 'chat.persone.tag.add',
    data: {
      uuid,
      tag,
    },
  })(dispatch);
  dispatch({
    type: CHAT_ADD_PERSONE_TAG,
    payload,
  });
};

export const removePersonTag = (payload) => (dispatch) => {
  const { uuid, tag } = payload;
  wsSndMessage({
    action: 'chat.persone.tag.remove',
    data: {
      uuid,
      tag,
    },
  })(dispatch);
  dispatch({
    type: CHAT_DEL_PERSONE_TAG,
    payload,
  });
};

export const addNewPersonInfo = (payload) => (dispatch) => {
  const { uuid, field, value, type } = payload;
  wsSndMessage({
    action: 'chat.persone.add.info',
    data: {
      uuid,
      field,
      value,
      type,
    },
  })(dispatch);
  dispatch({
    type: CHAT_ADD_PERSONE_INFO,
    payload,
  });
};

export const updatePersonInfo = (payload) => (dispatch) => {
  const { uuid, field, value, type } = payload;
  wsSndMessage({
    action: 'chat.persone.add.info',
    data: {
      uuid,
      field,
      value,
      type,
    },
  })(dispatch);
  dispatch({
    type: CHAT_UPDATE_PERSONE_INFO,
    payload,
  });
};

export const getAiAnswers = (payload) => (dispatch) => {
  const { uuidCompany, question } = payload;
  dispatch(
    checkAiSync({
      mainLoading: true,
    }),
  );
  wsSndMessage({
    action: 'chat.dialog.getAi',
    data: {
      uuidCompany,
      question,
    },
  })(dispatch);
};

export const sendTypingEvent = (payload) => (dispatch) => {
  const { type, uuidCompany, uuidDialog, name, uuidUser } = payload;

  wsSndMessage({
    action: 'chat.message.typing',
    data: {
      name,
      uuidUser,
      type,
      uuidCompany,
      uuidDialog,
    },
  })(dispatch);
};

/**
 * get chat messages
 * @param {{uuidDialog:string,page:number,size:number}} payload
 */
export const getChatMessages = (payload) => (dispatch) => {
  const {
    uuidDialog,
    page = 1,
    size = DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
    first = false,
  } = payload;

  dispatch(setMessagesGetLoadingState(true));

  wsSndMessage({
    action: 'chat.messages.get',
    data: {
      uuidDialog,
      page,
      size,
      first,
    },
  })(dispatch);
};

const requestGetCompanyProviders = () => ({
  type: REQUEST_COMPANY_PROVIDERS,
});

const receiveGetCompanyProviders = (data) => ({
  type: RECEIVE_COMPANY_PROVIDERS,
  data,
});

const errorGetCompanyProviders = (err) => ({
  type: ERROR_COMPANY_PROVIDERS,
  err,
});

export const setCompanyProviders = (payload) => ({
  type: SET_COMPANY_PROVIDERS,
  payload,
});

export const setChatMessage = (message) => ({
  type: SET_CHAT_MESSAGE,
  message,
});

export const fetchCompanyProviders =
  ({ uuidCompany }) =>
  (dispatch, getState) => {
    dispatch(requestGetCompanyProviders());
    return apiGet({
      dispatch,
      url: `${HOST}/chat/providers/${uuidCompany}`,
      tokens: getState().session.tokenData,
      callback: (res) => {
        dispatch(receiveGetCompanyProviders(res));
        dispatch(setCompanyProviders(res.rows));
      },
      error: (err) => {
        dispatch(errorGetCompanyProviders(err));
      },
    });
  };

export const postDialogAssignee =
  ({ payload, uuidDialog }) =>
  (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/history/dialogs/${uuidDialog}/assign`,
      tokens: getState().session.tokenData,
      body: payload,
      callback: () => {
        dispatch(chatAssigneeRequestSuccess());
      },
      error: (err) => {
        dispatch(chatAssigneeRequestFailure(err));
      },
    });
export const postDialogAssigneeRelease =
  ({ uuidDialog }) =>
  (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/history/dialogs/${uuidDialog}/assign/release`,
      tokens: getState().session.tokenData,
      callback: () => {
        dispatch(chatAssigneeRequestSuccess());
      },
      error: (err) => {
        dispatch(chatAssigneeRequestFailure(err));
      },
    });

export const inWorkDialogAdd =
  ({ user, dialog }) =>
  (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/history/dialog/inWork`,
      tokens: getState().session.tokenData,
      body: {
        dialog,
        user,
      },
    });

export const inWorkDialogClose =
  ({ user, dialog }) =>
  (dispatch, getState) =>
    apiDelete({
      dispatch,
      url: `${HOST}/chat/history/dialog/inWork`,
      tokens: getState().session.tokenData,
      body: {
        dialog,
        user,
      },
    });

export const removeCompanyTag =
  ({ title, uuidCompany }) =>
  (dispatch, getState) =>
    apiDelete({
      dispatch,
      url: `${HOST}/chat/history/tags`,
      tokens: getState().session.tokenData,
      body: {
        title,
        uuidCompany,
      },
      callback: (result) => {
        if (result) {
          dispatch(
            deleteTag({
              title,
              uuidCompany,
            }),
          );
        }
      },
    });

export const setWorkTime =
  ({ timezone, schedule }) =>
  (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/user/settings`,
      tokens: getState().session.tokenData,
      body: {
        timezone,
        schedule,
      },
      callback: (res) => {
        setUserSettings(res.data);
      },
    });
