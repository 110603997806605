import { apiPost } from "api";
import { HOST } from "config";

export const TAG_FILTER_SET_DATE = 'TAG_FILTER_SET_DATE';
export const TAG_FILTER_SET_ASSIGNED = 'TAG_FILTER_SET_ASSIGNED';
export const TAG_FILTER_SET_PROVIDER = 'TAG_FILTER_SET_PROVIDER';
export const TAG_STAT_GET_DIALOGS = 'TAG_STAT_GET_DIALOGS';
export const TAG_STAT_SEARCH = 'TAG_STAT_SEARCH';
export const TAG_FILTER_SET_COMPANY = 'TAG_FILTER_SET_COMPANY';

export const setTagFilterDate = (payload) => ({
  type: TAG_FILTER_SET_DATE,
  payload
});

export const setTagFilterAssigned = (payload) => ({
  type: TAG_FILTER_SET_ASSIGNED,
  payload
});

export const setTagFilterProvider = (payload) => ({
  type: TAG_FILTER_SET_PROVIDER,
  payload
});

export const setSelectedCompany = (payload) => ({
  type: TAG_FILTER_SET_COMPANY,
  payload
});

export const setDialogs = (payload) => ({
  type: TAG_STAT_GET_DIALOGS,
  payload
});

export const setSearchedSubstring = (payload) => ({
  type: TAG_STAT_SEARCH,
  payload
});

export const setChatDialogs = ({ uuidCompany, dateRange, fields, filter = {} }) => (
  dispatch,
  getState,
) => apiPost({
  dispatch,
  url: `${HOST}/chat/history/${uuidCompany}/dialogs`,
  tokens: getState().session.tokenData,
  body: { dateRange, fields, filter },
  callback: (dialogs) => {
    dispatch(setDialogs(dialogs));
  },
});