import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import UnlinkChannel from './UnlinkChannel';
import AddChannel from './AddChannel';
import AddNewField from './AddNewField';
import Search from './Search';
import StartDialog from './StartDialog';
import ChangePasswordDialog from './ChangePasswordDialog';
import SuccessPasswordChangeDialog from './SuccessPasswordChangeDialog';
import { hideModal } from '../../store/actions/modalActions';
import CrmDialog from './CrmDialog';
import TagDialog from './TagDialog';
import ConfirmDialog from './ConfirmDialog';
import ForwardModal from './ForwardModal';
import CreateRoutingScriptDialog from './Settings/CreateRoutingScriptDialog';
import ConfirmRemoveDialog from './ConfirmRemoveDialog';
import SelectDialogConditionDialog from './Settings/SelectDialogConditionDialog/SelectDialogConditionDialog';
import SelectVisibilityConditionDialog from './Settings/SelectVisbilityConditionDialog/SelectVisibilityConditionDialog';
import SelectTargetConditionDialog from './Settings/SelectTargetConditionDialog/SelectTargetConditionDialog';
import StartTrackerDialog from '../Modal/StartTrackerDialog';
import FinishTrackerDialog from '../Modal/FinishTrackerDialog';

const Modal = () => {
  const dispatch = useDispatch();
  const [currentModal, setCurrentModal] = useState(null);
  const modal = useSelector((state) => state.modal);
  const { show, type, data } = modal;

  // show = true;
  // type = 'Search';
  // data = {query:'test test test'};

  const closeCallback = (callback) => {
    dispatch(hideModal());
    if (callback) callback();
  };

  useEffect(() => {
    let result = null;
    switch (type) {
      case 'UnlinkChannel':
        result = (
          <UnlinkChannel
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'AddChannel':
        result = (
          <AddChannel
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'AddNewField':
        result = (
          <AddNewField
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'Search':
        result = (
          <Search data={data} onClose={(callback) => closeCallback(callback)} />
        );
        break;
      case 'CRM':
        result = (
          <CrmDialog
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'TagDialog':
        result = (
          <TagDialog
            uuidDialog={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'ForwardModal': {
        const { providerName, msgID, fromID, uuidConnection } = data;
        result = (
          <ForwardModal
            providerName={providerName}
            uuidConnection={uuidConnection}
            msgID={msgID}
            fromID={fromID}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      }
      case 'StartDialog':
        result = (
          <StartDialog
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'ConfirmDialog':
        result = (
          <ConfirmDialog
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'ChangePasswordDialog':
        result = (
          <ChangePasswordDialog
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'SuccessPasswordChangeDialog':
        result = (
          <SuccessPasswordChangeDialog
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'CreateRoutingScriptDialog':
        result = (
          <CreateRoutingScriptDialog
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'StartTimeTracker':
        result = (
          <StartTrackerDialog onClose={(callback) => closeCallback(callback)} />
        );
        break;
      case 'ConfirmRemoveDialog':
        result = (
          <ConfirmRemoveDialog
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'SelectDialogConditionDialog':
        result = (
          <SelectDialogConditionDialog
            scriptIndex={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'SelectTargetConditionDialog':
        result = (
          <SelectTargetConditionDialog
            data={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'SelectVisibilityConditionDialog':
        result = (
          <SelectVisibilityConditionDialog
            scriptIndex={data}
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      case 'FinishTimeTracker':
        result = (
          <FinishTrackerDialog
            onClose={(callback) => closeCallback(callback)}
          />
        );
        break;
      default:
        break;
    }
    setCurrentModal(result);
  }, [show]);

  return currentModal;
};

export default Modal;
