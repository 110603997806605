import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { List } from '@material-ui/core';
import { ArrowDownwardRounded } from '@material-ui/icons';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import { resetSorted, sortDialogs } from 'store/actions';

import { Spinner } from '../../../../components';
import { ConversationListItem } from './components';
import ContextMenu from './components/ContextMenu/ContextMenu';

export const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    overflowY: 'auto',
    position: 'relative',
    borderRight: '2px solid #eaeaea !important',
  },
  searchInput: {
    flexGrow: 1,
  },
  sort: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  doneIcon: {
    color: '#A6A6A6',
    width: '13px',
  },
  sortIcon: {
    color: '#A6A6A6',
    width: '13px',
    '&:hover': {
      color: '#A6A6A6',
    },
    cursor: 'pointer',
  }
}));

const ConversationList = (props) => {
  const {
    conversations,
    className,
    page,
    size,
    count,
    onLoadMore,
    ...rest
  } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const dialogsLoading = useSelector((state) => state.chat.dialogsLoading);
  const sorted = useSelector((state) => state.chat.sorted);
  const [contextMenu, setContextMenu] = useState(null);
  const dialogsPagination = useSelector(
    (state) => state.chat.dialogsPagination,
  );
  const currentDialog = useSelector((state) => state.session.currentDialog);

  const searchState = useSelector((state) => state.search);

  const selectedProviders = useSelector(
    (state) => state.settings.selectedProviders,
  );
  const disabledProviders = useSelector(
    (state) => state.settings.disabledProviders || [],
  );

  const getData = () => {
    const sortedData = conversations
      // .filter(({ type }) => (isFilterByGroup ? type === 'group' : true))
      .filter(({ ids }) => {
        if (disabledProviders.includes(ids.uuidConnection)) {
          return false;
        }
        if (selectedProviders.length > 0) {
          if (!selectedProviders.includes(ids.uuidConnection)) {
            return false;
          }
        }
        return true;
      })
      .slice();
    // .sort(sortingFunction);
    if (searchState.searchModeIsOn && searchState.currentDialog) {
      const findSearchIndexInDialogs = conversations.findIndex(
        (c) => c.uuid === searchState.currentDialog.uuid,
      );
      if (findSearchIndexInDialogs !== -1) {
        sortedData.splice(findSearchIndexInDialogs, 1);
      }
      return [searchState.currentDialog, ...sortedData];
    }
    return sortedData;
  };

  useEffect(() => {
    if (sorted) {
      dispatch(resetSorted());
    }
  }, [sorted]);

  const handleListScroll = (event) => {
    const isScrolled = event.target.scrollHeight - Math.ceil(event.target.scrollTop)
      <= event.target.clientHeight;
    const havePages = dialogsPagination.count > dialogsPagination.page * dialogsPagination.size;

    if (isScrolled && havePages && !dialogsLoading) {
      onLoadMore();
    }
  };

  const handleOpenContextMenu = (event, conversation) => {
    event.preventDefault();
    setContextMenu({
      anchorEl: event.currentTarget,
      conversation,
    });
  }

  const handleCloseContextMenu = () => {
    setContextMenu(null);
  }

  const dialogs = getData();

  const handleSort = () => {
    if (dialogs && dialogs.length > 0) {
      let isOrderIncorrect = false;

      for (let i = 1; i < dialogs.length; i+=1) {
        if (dialogs[i].date.lastMessage > dialogs[i-1].date.lastMessage) {
          isOrderIncorrect = true;
          break;
        }
      }

      if (isOrderIncorrect) {
        dispatch(sortDialogs());
      }
    }
  }

  const empty = dialogs.length === 0;
  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
      onScroll={handleListScroll}
    >
      {!dialogsLoading && !empty &&
        <div className={classes.sort}>
          {!sorted && <ArrowDownwardRounded
              onClick={handleSort}
              className={classes.sortIcon} />}
        </div>}
      <List disablePadding>
        {dialogs.map((conversation, i) => (
          <ConversationListItem
            handleContextMenu={handleOpenContextMenu}
            isActive={conversation.uuid === currentDialog}
            conversation={conversation}
            divider={i < conversations.length - 1}
            key={conversation.uuid}
          />
        ))}
      </List>
      <ContextMenu
        item={contextMenu}
        handleClose={handleCloseContextMenu}/>
      {dialogsLoading ? (
        <SpinnerWrapper>
          <Spinner show />
        </SpinnerWrapper>
      ) : null}
    </div>
  );
};

ConversationList.propTypes = {
  className: PropTypes.string,
  conversations: PropTypes.array.isRequired,
  page: PropTypes.number,
  size: PropTypes.number,
  count: PropTypes.number,
  onLoadMore: PropTypes.func,
};

ConversationList.defaultProps = {
  className: '',
  page: 1,
  size: 1,
  count: 0,
  onLoadMore: () => false,
};

export default ConversationList;
