import { Typography, Grid } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from '../../components';
import { LoginForm } from './components';
import LanguageSwitcher from '../../components/LanguageSwitcher';

const PageWrapper = styled(Page)`
  display: flex;
  @media only screen and (max-width: 700px) {
    flex-direction: column;
  }
  flex-direction: row;

  height: 100vh;
`;

const PageBannerWrapper = styled.div`
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  width: 50%;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  ${(props) => (props.image
    ? `
  background: url(${props.image}) no-repeat
    center;
  padding: 100px;
  background-size: cover;
  position: relative;
  z-index: 0;
  
 
  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    content: '';
    background: #007bffcc;
    z-index: 1;
  }
  `
    : '')}
  & > img {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    height: 50px;
  }
`;

const ContentWrapper = styled.div`
  ${(props) => (props.theme.breakpoints.down('md') ? '' : 'width: 50%;')}

  z-index: 5;
  h1 {
    font-size: 3.75rem;
    font-weight: 500;
    line-height: 1;
    color: ${(props) => props.theme.palette.common.white};
    margin-bottom: 1rem;
  }
  p {
    font-size: 1.125rem;
    line-height: 1.6;
    color: ${(props) => props.theme.palette.common.white};
    span {
      border-bottom: 1px solid ${(props) => props.theme.palette.common.white};
    }
  }
`;

const ContentFormWrapper = styled.div`
  width: 60%;
  text-align: right;
  .link-span {
    color: #6c757d;
    text-align: center;
    font-size: 1rem;
  }
  h3 {
    font-size: 1.75rem;
  }
  a {
    font-family: 'Poppins', sans-serif;
  }
`;

const Bottom = styled.div`
  position: absolute;
  padding: 10px 25px;
  bottom: 0px;
  width: 100%;
  z-index: 1000;
`;

const Login = () => {
  const { t } = useTranslation();

  return (
    <PageWrapper title={t('loginPage.pageTitle')}>
      <>
        <PageBannerWrapper image="https://connect.intellectdialog.com/media/img/login-bg.jpg">
          <img
            src="https://connect.intellectdialog.com/media/img/07.png"
            alt="logo"
          />
          <ContentWrapper>
            <Grid container spacing={2} direction="column">
              <Grid item xs={12}>
                <Typography variant="h1" component="h1">
                  {t('loginPage.banner.welcome')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="p" component="p">
                  {t('loginPage.banner.description')}
                  {' '}
                  <span>{t('loginPage.banner.descriptionChat')}</span>
                  <br />
                  {t('loginPage.banner.descriptionPlatform')}
                  &nbsp; intellectdialog.com
                </Typography>
              </Grid>
            </Grid>
          </ContentWrapper>
          <Bottom>
            <LanguageSwitcher color="#fff" />
          </Bottom>
        </PageBannerWrapper>
        <PageBannerWrapper>
          <ContentFormWrapper>
            <Grid container direction="column" justify="center">
              <Grid container item alignItems="center" justify="space-between">
                <Grid item>
                  <Typography variant="h3">
                    {t('loginPage.formTitle')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Link
                    to={{ pathname: "https://connect.intellectdialog.com/user/login" }}
                    target="_blank"
                  >
                    Admin
                  </Link>
                  &nbsp;|&nbsp;
                  <Link>Chat</Link>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <br />
                <br />
                <LoginForm />
              </Grid>
              <Grid item xs={12}>
                <br />
                <Typography className="link-span">
                  {t('loginPage.btnSingup')}
                  &nbsp;

                  <Link to={{ pathname: "https://connect.intellectdialog.com/user/register" }} target="_blank">
                    {t('loginPage.linkRegister')}
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </ContentFormWrapper>
        </PageBannerWrapper>
      </>
    </PageWrapper>
  );
};

export default Login;
/*

 <Link
            align="center"
            color="secondary"
            component={RouterLink}
            onClick={() => {
              window.location.href = 'https://connect.intellectdialog.com/user/register';
              return null;
            }}
            to="/auth/signup"
            underline="always"
            variant="subtitle2"
          >
            {t('loginPage.btnSingup')}
          </Link>
             <LoginForm className={classes.loginForm} />
 */
