import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  error: false,
  loading: false,
  sync: false,
  answers: [],
  message: null,
}

const aiReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_AI_MESSAGE:
      return update(state, {
        message: { $set: payload },
      });
    case actionTypes.SET_AI_ANSWERS:
      return update(state, {
        answers: { $set: payload },
        loading: { $set: false },
      });
    case actionTypes.AI_SET_ERROR:
      return update(state, {
        error: { $set: payload },
      });
    case actionTypes.AI_SYNC_CHECK:
      return update(state, {
        sync: { $set: payload },
      });
    case actionTypes.AI_SET_LOADING:
      return update(state, {
        loading: { $set: payload },
      });
    default:
      return state;
  }
}

export default aiReducer;