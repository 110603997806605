import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Dialog, Grid } from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogActions from '@material-ui/core/DialogActions';
import * as templateActions from '../../../../../../store/actions/templateActions';
import * as chatActions from '../../../../../../store/actions/chatActions';
import { Button, Spinner } from '../../../../../../components';
import { CreateTemplateForm, TemplateMessage } from './components';
import TemplateSearchPanel from './components/TemplateSearchPanel';

const ToolTitle = styled.div`
  font-size: 19px;
  font-weight: bold;
  color: #373b42;
  border-bottom: 2px solid #eeeff2;
  padding-bottom: 23px;
  padding-left: 20px;
  margin-top: 18px;
  margin-bottom: 10px;
`;
const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TemplatesTool = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [templateState, setTemplateState] = useState(null);
  const dialogUuid = useSelector((state) => state.session.currentDialog);
  const isFetchingTemplate = useSelector((state) => state.templates.isFetching);
  const conversation = useSelector(
    (state) => state.chat.dialogs.find((d) => d.uuid === dialogUuid) || null,
  );
  const templates = useSelector((state) => state.templates.data);
  const [searchedTemplates, setSearchedTemplates] = useState([]);

  const [isShow, setShow] = useState(false);
  const handleRemoveTemplate = (payload) => {
    setShow(true);
    //
    setTemplateState(payload);
  };
  const handleSubmitDelete = () => {
    if (templateState)
      dispatch(
        templateActions.deleteTemplate({
          uuid: templateState.uuid,
        }),
      );
    setTemplateState(null);
    setShow(false);
  };
  const handleUpdate = (uuid, template) => {
    dispatch(
      templateActions.updateTemplate({
        uuid,
        template,
      }),
    );
  };
  const handleCloseModal = () => {
    setShow(false);
  };

  const handleCreate = (template) => {
    dispatch(templateActions.createTemplate(template));
  };

  const sndMessage = (msg) => chatActions.sndChatMessage(msg)(dispatch);

  const handleSendMessage = (template) => {
    if (conversation && conversation.persone) {
      const attachedFile = template.file;
      if (typeof template === 'object') {
        let data = {
          fileUrl: attachedFile.url,
          uuidDialog: conversation.uuid,
          caption: template.text,
        };
        switch (attachedFile.mimeType) {
          case 'image/jpeg':
          case 'image/png':
            chatActions.sndChatImage(data)(dispatch);
            break;
          default:
            data = {
              ...data,
              title: attachedFile.originName,
              size: attachedFile.size,
            };
            if (attachedFile.mimeType.startsWith('audio/')) {
              chatActions.sndChatAudio(data)(dispatch);
            } else {
              chatActions.sndChatFile(data)(dispatch);
            }
            break;
        }
      } else {
        sndMessage({
          text: template,
          uuidDialog: conversation.uuid,
        });
      }
    }
  };
  const handleForwardMessage = (template) => {
    if (typeof template === 'object') {
      dispatch(chatActions.receivePostChatAttachment(template.file));
      dispatch(chatActions.setChatMessage(template.text));
    } else {
      dispatch(chatActions.setChatMessage(template));
      dispatch(chatActions.clearPostChatAttachment());
    }
  };
  useEffect(() => {
    setSearchedTemplates(templates);
  }, [templates]);
  return (
    <>
      <ToolTitle>{t('toolsBar.templates')}</ToolTitle>

      <Grid container>
        <Grid item xs={12}>
          <CreateTemplateForm onCreate={handleCreate} />
        </Grid>
        <TemplateSearchPanel setSearchedTemplates={setSearchedTemplates} />
        <SpinnerWrapper>
          <Spinner show={isFetchingTemplate} />
        </SpinnerWrapper>
        {!isFetchingTemplate &&
          searchedTemplates.map(
            ({ uuid, template, title, ids, date, user, file }) => (
              <Grid item xs={12} key={uuid}>
                <TemplateMessage
                  avatar={user?.avatar}
                  uuid={uuid}
                  userName={user?.name || t('templatesTool.deletedUser')}
                  template={template}
                  title={title}
                  file={file}
                  date={date}
                  ids={ids}
                  onSendTemplate={handleSendMessage}
                  onForwardTemplate={handleForwardMessage}
                  onEdit={handleUpdate}
                  onDelete={handleRemoveTemplate}
                />
              </Grid>
            ),
          )}
      </Grid>
      <Dialog
        open={isShow}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t('templatesTool.deleteDialog')}
        </DialogTitle>
        <DialogActions>
          <Button
            type="button"
            className="primary"
            style={{ margin: '10px 10px 10px 0' }}
            onClick={handleSubmitDelete}
          >
            {t('common.delete')}
          </Button>

          <Button
            type="button"
            className="secondary"
            onClick={handleCloseModal}
          >
            {t('templatesTool.cancel')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default TemplatesTool;
