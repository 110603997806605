import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
  IconButton,
  Grid,
  Popover,
  Toolbar,
  Tooltip,
  Typography,
  Hidden,
} from '@material-ui/core';

import {
  KeyboardBackspace as KeyboardBackspaceIcon,
  Person,
  Forum,
  UpdateRounded,
  // Undo as UndoIcon,
} from '@material-ui/icons';
import { StatusBullet } from 'components';
import copy from 'copy-to-clipboard';
import { getName } from 'utils/personeHelper';
import {
  postDialogAssignee,
  closeDialog,
  chatDebugEnable,
  chatDebugDisable,
  updateChatDialogTitle,
} from 'store/actions/chatActions';
import { DepartmentsList } from './components';

import { ReactComponent as TransferIcon } from '../../../../../../assets/img/conversation/toolbar/transfer.svg';
import { ReactComponent as ArchiveIcon } from '../../../../../../assets/img/conversation/toolbar/archive.svg';
import PostponeMenu from './components/PostponeMenu';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  user: {
    flexShrink: 0,
    flexGrow: 1,
    '@media (max-width: 960px)': {
      maxWidth: '320px',
    },
    '@media (max-width: 490px)': {
      maxWidth: '200px',
    },
    '@media (max-width: 380px)': {
      maxWidth: '160px',
    },
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
  activity: {
    display: 'flex',
    alignItems: 'center',
  },
  statusBullet: {
    marginRight: theme.spacing(1),
  },
  search: {
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    flexBasis: 300,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 auto',
    },
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon,
  },
  searchInput: {
    flexGrow: 1,
  },
  provider: {
    paddingLeft: '4px',
    display: 'inline-flex',
    gap: '4px',
  },
  providerIcon: {
    height: '17px',
  },
  title: {
    display: 'flex',
    gap: '4px',
    alignItems: 'center',
  },
  titleName: {
    cursor: 'pointer',
    position: 'relative',
  },
  copyBlock: {
    position: 'absolute',
    fontSize: '12px',
    fontWeight: '400',
    lineHeight: '12px',
    margin: '0 10px',
    padding: '5px 8px',
    borderRadius: '5px',
    color: '#fff',
    backgroundColor: '#008000',
    left: '100%',
  },
  updateIconLoading: {
    fontSize: '15px',
    color: '#5295b6',
    animation: '$rotate 0.7s linear infinite',
  },
  '@keyframes rotate': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
  updateIcon: {
    fontSize: '15px',
    color: '#8b8b8b',
    cursor: 'pointer',
    '&:hover': {
      color: '#5295b6',
      fontSize: '17px',
    },
  },
  actions: {
    display: 'flex',
    gap: '18px',
    alignItems: 'center',
    '& > *': {
      cursor: 'pointer',
    },
    '@media (max-width: 380px)': {
      gap: '10px',
    },
  },
  conversationInfo: {
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    overflow: 'hidden',
    justifyContent: 'center',
    '& > *': {
      '@media (min-width: 320px)': {
        maxWidth: '160px',
      },
    },
  },
  devActions: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '18px',
    '& > *': {
      color: '#546e7a',
      cursor: 'pointer',
      fontSize: '14px',
    },
  },
  lastMessage: {
    fontSize: '10px',
    '@media (max-width: 600px)': {
      fontSize: '10px',
      lineHeight: '14px',
    },
  },
}));

const ConversationToolbar = (props) => {
  const { conversation, className, lastMessage, ...rest } = props;
  const [anchorTransferMenu, setAnchorTransferMenu] = useState(null);
  const departments = useSelector((state) => state.session.departments);
  const currentUser = useSelector((state) => state.session.user);
  const selectedCompany = useSelector((state) => state.session.selectedCompany);
  const currentDialog = useSelector((state) => state.session.currentDialog);
  const { messagesIsLoading, isDebug, dialogTitleLoading } = useSelector(
    (state) => state.chat,
  );
  const [selectedChat, setSelectedChat] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { search } = useLocation();
  const classes = useStyles();

  const handleArchive = () => {
    dispatch(closeDialog({ uuid: conversation.uuid }));
  };

  const handleTransfer = (event) => {
    setAnchorTransferMenu(event.currentTarget);
  };

  const handleTransferClose = () => {
    setAnchorTransferMenu(null);
  };
  const handleClickDebugPerson = () => {
    dispatch(
      !isDebug.persone
        ? chatDebugEnable({ debugPersone: true, ...isDebug })
        : chatDebugDisable({ debugPersone: false, ...isDebug }),
    );
  };

  const handleClickDebugDialog = () => {
    dispatch(
      !isDebug.dialog
        ? chatDebugEnable({ debugDialog: true, ...isDebug })
        : chatDebugDisable({ debugDialog: false, ...isDebug }),
    );
  };
  const handleDepartmentAssigneeClick = (uuidAssignee, assigneeType) => {
    const uuid = conversation.assignee && conversation.assignee.uuid;
    dispatch(
      postDialogAssignee({
        payload: {
          uuidCompany: selectedCompany,
          uuidAssignee:
            uuid === currentUser.uuid && uuid === uuidAssignee
              ? null
              : uuidAssignee,
          assigneeType:
            uuid === currentUser.uuid && uuid === uuidAssignee
              ? 'none'
              : assigneeType,
        },
        uuidDialog: currentDialog,
      }),
    );

    handleTransferClose();
  };

  const handleUpdateTitle = () => {
    if (conversation) {
      dispatch(
        updateChatDialogTitle({
          dialog: conversation,
        }),
      );
    }
  };

  const transferOpen = Boolean(anchorTransferMenu);
  const transferId = transferOpen ? 'transfer-popover' : undefined;
  const groupInfo = React.useMemo(
    () => _.get(conversation, 'additional.group'),
    [conversation.additional],
  );
  const memorizedName = _.get(
    groupInfo,
    'title',
    getName(conversation.persone),
  );
  const handleCopyClick = () => {
    copy(window.location.href);
    setSelectedChat(true);
    setTimeout(() => {
      setSelectedChat(false);
    }, 1000);
  };
  return (
    <Toolbar {...rest} className={clsx(classes.root, className)}>
      <Hidden mdUp>
        <Tooltip title={t('chatToolbar.back')}>
          <IconButton
            className={classes.backButton}
            component={RouterLink}
            edge="start"
            to={`/chat${search}`}
          >
            <KeyboardBackspaceIcon />
          </IconButton>
        </Tooltip>
      </Hidden>
      <div className={classes.user}>
        {!messagesIsLoading ? (
          <>
            <Grid container direction="row">
              <Grid item>
                <Typography
                  variant="h6"
                  component="span"
                  className={classes.title}
                >
                  <span className={classes.titleName} onClick={handleCopyClick}>
                    {memorizedName}
                    {selectedChat && <span className={classes.copyBlock}>
                      {t("dialog.toolbar.linkCopied")}
                    </span>}
                  </span>
                  {conversation &&
                    conversation.type === 'group' &&
                    (!dialogTitleLoading ? (
                      <Tooltip
                        title={t('chatToolbar.updateTitle')}
                        onClick={handleUpdateTitle}
                      >
                        <UpdateRounded className={classes.updateIcon} />
                      </Tooltip>
                    ) : (
                      <UpdateRounded className={classes.updateIconLoading} />
                    ))}
                </Typography>
                <div className={classes.activity}>
                  {conversation.persone.active ? (
                    <>
                      <StatusBullet
                        className={classes.statusBullet}
                        color="success"
                        size="small"
                      />
                      <Typography variant="body2">
                        {t('chatToolbar.activeNow')}
                      </Typography>
                    </>
                  ) : (
                    <Typography className={classes.lastMessage}>
                      {`${t('chatToolbar.lastMessage')} ${moment(
                        _.get(lastMessage, 'date.created', moment()),
                      ).fromNow()}`}
                      {conversation && conversation.provider && (
                        <div className={classes.provider}>
                          <span>•</span>
                          <img
                            className={classes.providerIcon}
                            alt=""
                            src={`/img/messengers/${conversation.provider.messenger}.svg`}
                          />
                          {conversation.provider.name}
                        </div>
                      )}
                    </Typography>
                  )}
                </div>
              </Grid>
            </Grid>
          </>
        ) : (
          <div>Загрузка...</div>
        )}
      </div>
      {conversation.status !== 'closed' ? (
        <div className={classes.actions}>
          <div style={{ marginLeft: 'auto', display: 'flex' }}>
            <Tooltip title={t('chatToolbar.transferDialog')}>
              <TransferIcon
                aria-describedby={transferId}
                onClick={handleTransfer}
              />
            </Tooltip>
            <Popover
              id={transferId}
              open={transferOpen}
              anchorEl={anchorTransferMenu}
              onClose={handleTransferClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <DepartmentsList
                departments={departments}
                selectedCompany={selectedCompany}
                handleDepartmentAssigneeClick={handleDepartmentAssigneeClick}
              />
            </Popover>
          </div>
          <Tooltip title={t('chatToolbar.archiveDialog')}>
            <ArchiveIcon onClick={handleArchive} />
          </Tooltip>
          <PostponeMenu uuid={conversation.uuid} />
        </div>
      ) : null}
      {currentUser.group === 'developer' && (
        <div className={classes.devActions}>
          <Tooltip onClick={handleClickDebugDialog} title="Отладка диалога">
            <Forum />
          </Tooltip>
          <Tooltip onClick={handleClickDebugPerson} title="Отладка персоны">
            <Person />
          </Tooltip>
        </div>
      )}
    </Toolbar>
  );
};
ConversationToolbar.propTypes = {
  className: PropTypes.string,
  conversation: PropTypes.object.isRequired,
  lastMessage: PropTypes.object.isRequired,
};

ConversationToolbar.defaultProps = {
  className: '',
};

export default ConversationToolbar;
