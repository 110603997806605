// import { HOST } from 'config';
import { apiPost } from '../../api';

export const START_WORKING_TIME = 'START_WORKING_TIME';
export const SET_START_WORKING_STATUS = 'SET_START_WORKING_STATUS';
export const SET_PAUSE_WORKING_STATUS = 'SET_PAUSE_WORKING_STATUS';
export const PAUSE_WORKING_TIME = 'PAUSE_WORKING_TIME';
export const UNPAUSE_WORKING_TIME = 'UNPAUSE_WORKING_TIME';
export const FINISH_WORKING_TIME = 'FINISH_WORKING_TIME';
export const CLEAR_WORKING_TIME = 'CLEAR_WORKING_TIME';
export const SET_WORKERS_STAT = 'SET_WORKERS_STAT';
export const SET_WORKTIME_TIMER = 'SET_WORKTIME_TIMER';
export const SET_BEGIN_STOPWATCH = 'SET_BEGIN_STOPWATCH';

export const setBeginStopwatch = (payload) => ({
  type: SET_BEGIN_STOPWATCH, payload
})
export const startWorkingTime = () => ({
  type: START_WORKING_TIME,
});
export const setStartWorkingStatus = (payload) => ({
  type: SET_START_WORKING_STATUS,
  payload
})
export const setPauseWorkingStatus = (payload) => ({
  type: SET_PAUSE_WORKING_STATUS,
  payload
})
export const pauseWorkingTime = () => ({
  type: PAUSE_WORKING_TIME,
});

export const unpauseWorkingTime = () => ({
  type: UNPAUSE_WORKING_TIME,
});

export const finishWorkingTime = () => ({
  type: FINISH_WORKING_TIME,
});
export const clearWorkingTime = () => ({
  type: CLEAR_WORKING_TIME,
});
const setWorkersStat = (payload) => ({
  type: SET_WORKERS_STAT,
  payload,
});
export const setWorkTimeTimer = () => ({
  type: SET_WORKTIME_TIMER
});
export const sendWorkingTime =
  (uuidUser, departmentId, name, providers) =>
  async (dispatch, getState) => {
    apiPost({
      dispatch,
      url: `http://localhost:3007/workTime`,
      tokens: getState().session.tokenData,
      body: {
        hours: getState().workTime.hours,
        uuidUser,
        departmentId,
        name,
        providers,
      },
      callback: (res) => {
        console.log(res);
      },
    });
  };
export const getWorkingTime =
  (uuidUser, from, to, provider, departmentId) =>
  async (dispatch, getState) => {
    apiPost({
      dispatch,
      url: `http://localhost:3007/workTime/hours`,
      tokens: getState().session.tokenData,
      body: {
        uuidUser,
        from,
        to,
        provider,
        departmentId,
      },
      callback: (res) => {
        dispatch(setWorkersStat(res));
      },
    });
  };
