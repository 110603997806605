import { Button } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import _ from 'lodash';
import { postDialogAssigneeRelease } from 'store/actions';

const StyledButton = styled(Button)`
  text-transform: none ;
  background-color: #008000 !important;
 
`;
function ConversationMessageButton({ uuidDialog }) {
  const dialog = useSelector((state) => state.chat.dialogs.find((x) => x.uuid === uuidDialog));

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSolveAssignee = () => {
    dispatch(postDialogAssigneeRelease({ uuidDialog }));
  };
  if (!_.get(dialog, 'assignee.uuid')) {
    return null;
  }

  return (
    <>
      <StyledButton
        variant="contained"
        color="primary"
        onClick={handleSolveAssignee}
      >
        {t('conversation.solve')}
      </StyledButton>
    </>
  );
}
ConversationMessageButton.propTypes = {
  uuidDialog: PropTypes.string.isRequired,
};
export default ConversationMessageButton;
