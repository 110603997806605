import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Switcher } from '../../../../../components';

const TargetEmployeeOnlineInputs = ({ orCondition, onKeyChange }) => {
  const value = Object.values(orCondition)[0];

  useEffect(() => {
    if (JSON.stringify(value) === '{}') {
      onKeyChange('online', true)
    }
  }, [])

  return (
    <div style={{ margin: 'auto 0' }}>
      <Switcher
        checked={value}
        onChange={() => onKeyChange('online', !value)}
      />
    </div>
  );
};
TargetEmployeeOnlineInputs.propTypes = {
  orCondition: PropTypes.object,
  onKeyChange: PropTypes.func,
};
TargetEmployeeOnlineInputs.defaultProps = {
  orCondition: {},
  onKeyChange: null,
};
export default TargetEmployeeOnlineInputs;
