export const CLOSE_CALENDAR = 'CLOSE_CALENDAR';
export const TOGGLE_CALENDAR = 'TOGGLE_CALENDAR';
export const CHANGE_CALENDAR_DATE_BEFORE = 'CHANGE_CALENDAR_DATE_BEFORE';
export const CHANGE_CALENDAR_DATE_AFTER = 'CHANGE_CALENDAR_DATE_AFTER';
export const SET_MAIN_DATE = 'SET_MAIN_DATE';

export const handleCloseCalendar = () => ({
  type: CLOSE_CALENDAR,
});

export const handleToggleCalendar = () => ({
  type: TOGGLE_CALENDAR,
});

export const handleChangeCalendarDateBefore = (date) => ({
  type: CHANGE_CALENDAR_DATE_BEFORE,
  payload: date,
});

export const handleChangeCalendarDateAfter = (date) => ({
  type: CHANGE_CALENDAR_DATE_AFTER,
  payload: date,
});

export const handleSetMainDate = () => ({
  type: SET_MAIN_DATE,
});
