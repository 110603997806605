export const APP_VERSION = '0.2.2.7';

export const HOST = process.env.REACT_APP_API_HOST;
export const WSHOST = process.env.REACT_APP_API_WS_HOST;

export const DEFAULT_LANGUAGE = 'ru';
export const DEFAULT_DIALOGS_PAGE_SIZE = 25;
export const DEFAULT_DIALOG_MESSAGES_PAGE_SIZE = 25;
export const DEFAULT_FORWARD_DIALOGS_PAGE_SIZE = 10;
export const FORBIDDEN_TAGS = ['stop', 'стоп'];
export const UNAUTH_MAIN_PAGE = '/auth/login';
export const PRIVATE_MAIN_PAGE = '/chat';
export const UNAUTH_PATHS = [
  '/auth/login',
];
export const PRIVATE_PATHS = [
  '/chat',
  '/chat/:uuid',
  '/tasks',
  '/stat/general',
  '/stat/operators',
  '/stat/providers',
  '/stat/quality',
  '/stat/tags',
  '/stat/worktime',
  '/settings',
];
export const PROVIDERS_CAN_START = ['whatsapp', 'waba', 'gupshup', 'telegramOriginal'];

/*
telegram - `${ids.uuidMessage.split(':')[0]}:${reply}` === ids.uuidMessage.split(':')[1]
whatsapp - ids.uuidMessage.includes(reply)
telegramOriginal - ids.uuidMessage === reply
vk - no correct
*/
export const PROVIDERS_CAN_REPLY = ['whatsapp', 'telegram', 'telegramOriginal', 'vk'];
export const PROVIDERS_CAN_ALL = ['whatsapp', 'telegramOriginal', 'telegram'];
export const PROVIDERS_CAN_TAG = ['telegram'];
export const TASK_SIZE = 10;