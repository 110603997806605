import React from 'react';
import styled from 'styled-components';
import Calendar from 'react-calendar';
import './CalendarModal.css';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { FlexDiv } from '../../views/Stat/components/FlexDiv';
import { ReactComponent as CalendarSvg } from '../../assets/img/calendar.svg';
import * as calendarActions from '../../store/actions/calendarActions';

export function dateTransform(date) {
  if (!date) return '';
  if (Array.isArray(date)) {
    const day = date[0].getDate();
    const month = date[0].getMonth() + 1;
    const year = date[0].getFullYear();
    return `${day}.${month}.${year}`;
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}

const CalendarModal = () => {
  const calendarCtx = useSelector((state) => state.calendar);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const calendarListBtnHandler = (dateA, dateB) => {
    dispatch(calendarActions.handleChangeCalendarDateAfter(dateA));
    dispatch(calendarActions.handleChangeCalendarDateBefore(dateB));
    dispatch(calendarActions.handleSetMainDate());
    dispatch(calendarActions.handleCloseCalendar());
  };

  const acceptHandler = () => {
    if (
      calendarCtx.calendarDate.dateBefore &&
      calendarCtx.calendarDate.dateAfter
    ) {
      dispatch(calendarActions.handleSetMainDate());
      dispatch(calendarActions.handleCloseCalendar());
    }
  };

  return (
    <StyledCalendarModal>
      <CalendarLeftSide>
        <li className="leftSide-item">
          <button
            type="button"
            onClick={() => calendarListBtnHandler(new Date(), new Date())}
          >
            {t('calendar.today')}
          </button>
        </li>
        <li className="leftSide-item">
          <button
            type="button"
            onClick={() =>
              calendarListBtnHandler(
                new Date(Date.now() - 86400000),
                new Date(Date.now() - 86400000),
              )
            }
          >
            {t('calendar.yesterday')}
          </button>
        </li>
        <li className="leftSide-item">
          <button
            type="button"
            onClick={() =>
              calendarListBtnHandler(
                new Date(),
                new Date(Date.now() - 604800000),
              )
            }
          >
            {t('calendar.7day')}
          </button>
        </li>
        <li className="leftSide-item">
          <button
            type="button"
            onClick={() =>
              calendarListBtnHandler(
                new Date(),
                new Date(new Date().setMonth(new Date().getMonth() - 1)),
              )
            }
          >
            {t('calendar.lastMonth1')}
          </button>
        </li>
        <li className="leftSide-item">
          <button
            type="button"
            onClick={() =>
              calendarListBtnHandler(
                new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + 1,
                  0,
                ),
                new Date(new Date().getFullYear(), new Date().getMonth(), 1),
              )
            }
          >
            {t('calendar.thisMonth')}
          </button>
        </li>
        <li className="leftSide-item">
          <button
            type="button"
            onClick={() =>
              calendarListBtnHandler(
                new Date(new Date().getFullYear(), new Date().getMonth(), 0),
                new Date(
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth(),
                    0,
                  ).getFullYear(),
                  new Date(
                    new Date().getFullYear(),
                    new Date().getMonth() - 1,
                    1,
                  ).getMonth(),
                  1,
                ),
              )
            }
          >
            {t('calendar.lastMonth2')}
          </button>
        </li>
        <li
          className={
            calendarCtx.calendarDate.dateBefore ||
            calendarCtx.calendarDate.dateAfter
              ? 'leftSide-item active'
              : 'leftSide-item'
          }
        >
          <button type="button">{t('calendar.ownPeriod')}</button>
        </li>
        <div className="calendar-btns">
          <button
            type="button"
            className="calendar-btn blue"
            onClick={acceptHandler}
          >
            {t('calendar.btn1')}
          </button>
          <button
            type="button"
            className="calendar-btn gray"
            onClick={() => {
              dispatch(calendarActions.handleCloseCalendar());
            }}
          >
            {t('calendar.btn2')}
          </button>
        </div>
      </CalendarLeftSide>
      <FlexDiv items="center" direction="column">
        <CalendarDate>
          <span>{t('calendar.from')}</span>
          <div className="calendar-date">
            <CalendarSvg />
            <span>{dateTransform(calendarCtx.calendarDate.dateBefore)}</span>
          </div>
        </CalendarDate>
        <Calendar
          onChange={(d) => {
            dispatch(calendarActions.handleChangeCalendarDateBefore(d));
          }}
          value={calendarCtx.calendarDate.dateBefore}
          selectRange
        />
      </FlexDiv>
      <FlexDiv items="center" direction="column">
        <CalendarDate>
          <span>{t('calendar.to')}</span>
          <div className="calendar-date">
            <CalendarSvg />
            <span>{dateTransform(calendarCtx.calendarDate.dateAfter)}</span>
          </div>
        </CalendarDate>
        <Calendar
          onChange={(d) => {
            dispatch(calendarActions.handleChangeCalendarDateAfter(d));
          }}
          value={calendarCtx.calendarDate.dateAfter}
        />
      </FlexDiv>
    </StyledCalendarModal>
  );
};

const StyledCalendarModal = styled.div`
  filter: drop-shadow(0px 12px 24px rgba(0, 0, 0, 0.12));
  background: #ffffff;
  border-radius: 8px;
  position: absolute;
  top: calc(100% + 18px);
  display: flex;
  column-gap: 14px;
  z-index: 1000;
  padding: 24px 24px 26px 24px;
`;

const CalendarLeftSide = styled.ul`
  display: flex;
  flex-direction: column;
  list-style-type: none;
  justify-content: space-between;
  .leftSide-item {
    padding: 5px 0 7px 8px;
    button {
      cursor: pointer;
      background: unset;
      font-weight: 400;
      font-size: 15px;
      line-height: 19px;
      color: #7f7f7f;
      border: 0;
    }
  }
  .leftSide-item.active {
    background: rgba(0, 106, 228, 0.1);
    border-radius: 6px;
    color: #006ae4;
  }
  .calendar-btns {
    margin-top: 12px;
    display: flex;
    gap: 4px;
    .calendar-btn {
      font-weight: 400;
      font-size: 14px;
      border-radius: 6px;
      cursor: pointer;
      line-height: 19px;
    }
    .calendar-btn.gray {
      border: 1px solid #a6a6a6;
      padding: 5px 24.75px 7px 24.75px;
      color: #7f7f7f;
      background: inherit;
    }
    .calendar-btn.blue {
      color: #f2f2f2;
      border: 0;
      padding: 5px 13.25px 7px 13.25px;
      background: #006ae4;
    }
  }
`;

const CalendarDate = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 14px;
  & > span {
    margin-left: 13px;
    margin-right: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #a6a6a6;
  }
  .calendar-date {
    span {
      margin-left: 8.3px;
    }
    display: flex;
    align-items: center;
    padding: 5px 47% 7px 10.3px;
    border: 1px solid #a4aabb;
    border-radius: 6px;
  }
`;

export default CalendarModal;
