import { Box, Stack, Typography, styled } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Select from 'components/Select/Select';
import AppButton from 'components/AppButton/AppButton';
import { ReactComponent as Close } from '../../../../../assets/img/close.svg';
import AppIconButton from 'components/AppIconButton/AppIconButton';
import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { HOUR_OPTIONS, MINUTES_OPTIONS } from './contants';
import { useDispatch, useSelector } from 'react-redux';
import { setSoundState } from '../../../../../store/actions';

const DialogStyled = styled(Dialog)(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: '20px',
    maxWidth: '480px',
    width: '100%',
    p: '3',
  },
  '& .MuiDialogContent-root': {},
}));

interface IDialogProps {
  handleClose: () => void;
  open: boolean;
}

const TimerDialog = ({ handleClose, open }: IDialogProps) => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  // @ts-expect-error
  const { sound } = useSelector((state) => state.settings);

  const [mutedTime, setMutedTime] = useState<{
    hour: string;
    minute: string;
  }>({ hour: '', minute: '' });

  const handleApprove = () => {
    const hours = parseInt(mutedTime.hour, 10) || 0;
    const minutes = parseInt(mutedTime.minute, 10) || 0;
    const totalMinutes = hours * 60 + minutes;

    dispatch(
      setSoundState({
        ...sound,
        isOn: false,
        offTimeMinutes: totalMinutes,
        offTimeInitiatedDate: Date.now(),
      }),
    );
  };

  return (
    <DialogStyled
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogContent>
        <AppIconButton
          onClick={handleClose}
          style={{ position: 'absolute', right: '20px', top: '20px' }}
        >
          <Close />
        </AppIconButton>

        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 500,
          }}
        >
          {translate('notification.notificationSoundModalTitle')}
        </Typography>

        <Box
          sx={{
            my: 2,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: 1,
          }}
        >
          <Box sx={{ flex: '50%' }}>
            <p
              style={{
                fontSize: '12px',
                fontWeight: 400,
                marginBottom: '4px',
              }}
            >
              {translate('notification.hour')}
            </p>
            <Select
              label={'Hour'}
              id="hour"
              required
              classNamePrefix="react-select"
              options={HOUR_OPTIONS}
              value={{ value: '1', label: '1' }}
              onChange={(e: any) => {
                setMutedTime((prev) => ({
                  ...prev,
                  hour: e.target.value.value,
                }));
              }}
              isFullWidth={true}
              defaultValue={undefined}
              customInputStyles={undefined}
            />
          </Box>
          <Box sx={{ flex: '50%' }}>
            <p
              style={{
                fontSize: '12px',
                fontWeight: 400,
                marginBottom: '4px',
              }}
            >
              {translate('notification.minutes')}
            </p>
            <Select
              label={'Minutes'}
              required
              classNamePrefix="react-select"
              id="provider"
              options={MINUTES_OPTIONS}
              value={{ value: '', label: '' }}
              onChange={(e: any) => {
                setMutedTime((prev) => ({
                  ...prev,
                  minute: e.target.value.value,
                }));
              }}
              isFullWidth={true}
              defaultValue={undefined}
              customInputStyles={undefined}
            />
          </Box>
        </Box>
        <Stack
          sx={{ justifyContent: 'flex-end', gap: 1, flexDirection: 'row' }}
        >
          <AppButton
            type="secondary"
            size=""
            withIcon={false}
            onClick={() => {
              setMutedTime({ hour: '', minute: '' });
              handleClose();
            }}
          >
            {translate('calendar.btn2')}
          </AppButton>
          <AppButton
            type="common"
            size=""
            withIcon={false}
            onClick={(e: any) => {
              e.stopPropagation();
              handleApprove();
              handleClose();
            }}
          >
            {translate('calendar.btn1')}
          </AppButton>
        </Stack>
      </DialogContent>
    </DialogStyled>
  );
};

export default TimerDialog;
