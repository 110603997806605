import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import MuiRootDialog from '../MuiRootDialog/MuiRootDialog';
import {
  changeRoutingScript,
  createRoutingScript,
  hideModal,
} from '../../../store/actions';
import SettingsInput from './components/SettingsInput';
import { EMPTY_ROUTING_SCRIPT } from './constants';

const CreateRoutingScriptDialog = ({ data: { changingScript } }) => {
  const { t } = useTranslation();

  const { title: prevTitle, comment: prevComment } =
    changingScript?.additional || {};

  const dispatch = useDispatch();
  const closeModal = () => dispatch(hideModal());

  const [title, setTitle] = useState(prevTitle ?? '');
  const [comment, setComment] = useState(prevComment ?? '');

  const handleTitleChange = (e) => setTitle(e.target.value);
  const handleCommentChange = (e) => setComment(e.target.value);

  const addNewScript = () => {
    const body = structuredClone(EMPTY_ROUTING_SCRIPT);
    body.additional = {
      ...body.additional,
      title,
      comment,
    };
    body.conditions.dialogs.forEach((subArr) => {
      subArr.forEach((obj) => {
        if (Object.keys(obj).includes('mainOption')) {
          // eslint-disable-next-line no-param-reassign
          delete obj.mainOption;
        }
      });
    });

    dispatch(createRoutingScript(body)).then(() => closeModal());
  };
  const changeScript = () => {
    const additional = {
      title,
      comment,
    };

    dispatch(changeRoutingScript({ additional }, changingScript.uuid)).then(
      () => closeModal(),
    );
  };

  const handleConfirm = () => {
    if (changingScript) {
      changeScript();
    } else {
      addNewScript();
    }
  };

  const isConfirmActionDisabled =
    title.length === 0 ||
    (changingScript && title === prevTitle && comment === prevComment);

  return (
    <MuiRootDialog
      PaperProps={{
        style: {
          maxWidth: 480,
          width: '100%',
        },
      }}
      title={
        changingScript
          ? t('routingPage.changeScript')
          : t('routingPage.createScript')
      }
      handleClose={closeModal}
      declineAction={closeModal}
      confirmAction={handleConfirm}
      isConfirmDisabled={isConfirmActionDisabled}
    >
      <Body>
        <SettingsInput
          required
          label={t('routingPage.name')}
          value={title}
          onChange={handleTitleChange}
        />
        <SettingsInput
          label={t('routingPage.comment')}
          multiline
          rows={3}
          value={comment}
          onChange={handleCommentChange}
        />
      </Body>
    </MuiRootDialog>
  );
};

CreateRoutingScriptDialog.propTypes = {
  data: PropTypes.object,
};
CreateRoutingScriptDialog.defaultProps = {
  data: {},
};

export default CreateRoutingScriptDialog;

const Body = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  row-gap: 16px;
  padding: 16px 0;
`;
