import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { Page } from 'components';
import { fetchCompanyProviders, setChatDialogs } from 'store/actions';
import { Wrap } from './StatPage';
import { FlexDiv } from './components/FlexDiv';
import Filters from './components/Filters';
import StatNav from './components/StatNav';
import { ReactComponent as FileDownloadSvg } from '../../assets/img/file_download.svg';

const fields = {
  page: 0,
  size: 10,
  fields: [
    {
      rows: ['uuid', 'tags', 'date'],
    },
  ],
};

const testData = [
  {
    id: 1,
    provider: 'asdasdasdasdfjhasdkfjhaksdfhakdhjsfkajdhsfkjadhsf',
    assessment: 'Спросить',
    operator: 'Алексей Indd',
    endData: '27 ноя. 2027 г. 27:27:27',
    comment: '629',
  },
  {
    id: 2,
    provider: 'asdasdasdasdfjhasdkfjhaksdfhakdhjsfkajdhsfkjadhsf',
    assessment: 'Спросить',
    operator: 'Алексей Indd',
    endData: '27 ноя. 2027 г. 27:27:27',
    comment: '629',
  },
  {
    id: 3,
    provider: 'asdasdasdasdfjhasdkfjhaksdfhakdhjsfkajdhsfkjadhsf',
    assessment: 'Спросить',
    operator: 'Алексей Indd',
    endData: '27 ноя. 2027 г. 27:27:27',
    comment: '629',
  },
];

const QualityPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uuidCompany = useSelector((state) => state.session.selectedCompany);
  const dateRange = useSelector((state) => state.stat.dateRange);
  const company = useSelector((state) => state.stat.company);
  const [selectedCompany, setSelectedCompany] = useState(
    company || uuidCompany,
  );

  const getDialogsRequest = (companyUuid) => {
    dispatch(
      setChatDialogs({
        uuidCompany: companyUuid,
        ...fields,
        filter: {
          dateRange,
        },
      }),
    );
  };

  useEffect(() => {
    if (company) {
      setSelectedCompany(company);
      getDialogsRequest(company);
      dispatch(fetchCompanyProviders({ uuidCompany: company }));
    }
  }, [company]);

  useEffect(() => {
    getDialogsRequest(selectedCompany);
  }, [dateRange]);

  const testDataAssessments = [
    {
      id: 1,
      title: 'Имя оценки',
      percent: 90,
      quantity: 12124,
      color: '#03A000',
    },
    {
      id: 2,
      title: 'Имя оценки',
      percent: 8,
      quantity: 12,
      color: '#3894FF',
    },
    {
      id: 3,
      title: 'Имя оценки',
      percent: 2,
      quantity: 2,
      color: '#F23131',
    },
  ];

  return (
    <Page>
      <StatNav />
      <Wrap>
        <Filters selectedCompany={selectedCompany} />
        <SectionTitle>{t('qualityPage.title')}</SectionTitle>
        <RatedDiv>
          <span>
            {t('qualityPage.assessmentFrom', {
              assessments: 303,
              totalAssessments: 2126,
            })}
          </span>
          <span className="delimiter">&bull;</span>
          <span>{t('qualityPage.assessmentPercent', { percent: 19.2 })}</span>
        </RatedDiv>
        <FlexDiv margin="32px 0 0 0" cGap="32px">
          {testDataAssessments.map((data) => (
            <AssessmentDiv key={data.id} color={data.color}>
              <div className="square" />
              <div>
                <div>{data.title}</div>
                <div>
                  <span className="percent">{data.percent}% </span>
                  {t('qualityPage.assessment', { quantity: data.quantity })}
                </div>
              </div>
            </AssessmentDiv>
          ))}
        </FlexDiv>
        <FlexDiv margin="18px 0 0 0" className="result">
          {testDataAssessments.map((data) => (
            <StyledResult
              percent={data.percent}
              color={data.color}
              key={data.id}
            />
          ))}
        </FlexDiv>
        <FlexDiv justify="space-between" items="center" margin="37px 0 0 0">
          <h6
            style={{
              color: '#000000',
              fontSize: '18px',
              lineHeight: '25px',
            }}
          >
            {t('qualityPage.questions')}
          </h6>
          <QuestionAssessment>
            <span>{t('qualityPage.btnsTitle')}</span>
            <div
              style={{
                border: '1px solid rgba(0, 0, 0, 0.12)',
                borderRadius: '4px',
              }}
            >
              <button type="button" className="assessment-btn">Оценка</button>
              <button type="button" className="assessment-btn">Оценка</button>
              <button type="button" className="assessment-btn active">Оценка</button>
            </div>
          </QuestionAssessment>
        </FlexDiv>
        <OperatorsList>
          <div className="list-header">
            <span>{t('qualityPage.listHeader.col1')}</span>
            <span>{t('qualityPage.listHeader.col2')}</span>
            <span>{t('qualityPage.listHeader.col3')}</span>
            <span>{t('qualityPage.listHeader.col4')}</span>
            <span>{t('qualityPage.listHeader.col5')}</span>
            <div className="svg">
              <FileDownloadSvg />
            </div>
          </div>
          {testData.map((data) => (
            <div className="row" key={data.id}>
              <span>{data.provider}</span>
              <span>{data.assessment}</span>
              <span>{data.operator}</span>
              <span>{data.endData}</span>
              <span>{data.comment}</span>
              <span className="link">{t('qualityPage.openDialog')}</span>
            </div>
          ))}
        </OperatorsList>
      </Wrap>
    </Page>
  );
};

const StyledResult = styled.div`
  width: ${(props) => props.percent}%;
  height: 5px;
  background: ${(props) => props.color};
`;

const SectionTitle = styled.h4`
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-top: 32px;
  margin-bottom: 8px;
  line-height: 25px;
`;

const RatedDiv = styled.div`
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: rgba(0, 0, 0, 0.87);
  }
  span.delimiter {
    margin: 0 8px;
  }
`;

const AssessmentDiv = styled.div`
  display: flex;
  align-items: center;
  column-gap: 10px;
  .square {
    width: 13px;
    height: 13px;
    border-radius: 4px;
    background: ${(props) => (props.color ? props.color : 'black')};
  }
  div {
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7f7f7f;
  }
  .percent {
    color: ${(props) => (props.color ? props.color : '')};
  }
`;

const QuestionAssessment = styled.div`
  display: flex;
  align-items: center;
  span {
    margin-right: 12px;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #7f7f7f;
  }
  .assessment-btn {
    padding: 8px 12px;
    cursor: pointer;
    font-weight: 400;
    border: 0;
    background: inherit;
    font-size: 14px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    line-height: 19px;
    color: #000000;
    &.active {
      background: #006ae4;
      color: #ffffff;
    }
  }
`;

const OperatorsList = styled.div`
  font-weight: 400;
  margin-top: 32px;
  span.link {
    cursor: pointer;
    font-size: 14px;
    line-height: 19px;
    text-align: right;
    text-decoration-line: underline;
    color: #006ae4 !important;
  }
  .row,
  .list-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr 2fr 1fr;
    grid-column-gap: 16px;
  }
  .row {
    padding: 11px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    & span:first-of-type {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span {
      color: #000000;
      font-size: 14px;
      line-height: 19px;
    }
  }

  .list-header {
    font-size: 12px;
    line-height: 17px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 11px 0;
    color: rgba(0, 0, 0, 0.5);
  }

  .svg {
    margin-left: auto;
    user-select: none;
  }
`;

export default QualityPage;
