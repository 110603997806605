/* eslint-disable no-unused-vars */
import _, { indexOf } from 'lodash';

export const GALLERY_OPEN = 'GALLERY_OPEN';
export const GALLERY_CLOSE = 'GALLERY_CLOSE';
export const GALLERY_SET_IMAGES = 'GALLERY_SET_IMAGES';
export const GALLERY_SET_IMAGE = 'GALLERY_SET_IMAGE';

export const gallerySetCurrent = (imageUuid) => ({
  type: GALLERY_SET_IMAGE,
  payload: imageUuid,
});

export const galleryClose = () => ({
  type: GALLERY_CLOSE,
});

const newTwItem = (value, { next, prev }) => ({ next, value, prev });

export const fetchChatImages = (imageMessage) => (dispatch, getState) => {
  const { dialogs } = getState().chat;
  const uuidDialog = _.get(imageMessage, 'ids.uuidDialog');
  const dialog = dialogs.find((x) => x.uuid === uuidDialog);
  if (dialog && dialog.history && dialog.history.length > 0) {
    const images = dialog.history
      .map((message, idx) => {
        switch (_.get(message, 'content.type')) {
          case 'message.image': {
            const size = _.get(message, 'content.data.sizes', []).sort(
              (left, right) => left.size - right.size,
            );
            if (size && size.length > 0) {
              return {
                ...size[0],
                idx,
                uuid: message.uuid,
                source: message,
              };
            }
            return null;
          }

          case 'message.file': {
            const size = _.get(message, 'content.data.files', [])
              .filter(
                (x) => x.mime && x.mime.length > 0 && x.mime.indexOf('image') !== -1,
              )
              .sort((left, right) => left.size - right.size);

            if (size && size.length > 0) {
              return {
                ...size[0],
                idx,
                uuid: message.uuid,
                source: message,
              };
            }
            return null;
          }

          default:
            return null;
        }
      })
      .filter((x) => x)
      .map((item, idx) => ({ ...item, idx }));
    dispatch({ type: GALLERY_SET_IMAGES, payload: images });
    if (imageMessage) {
      dispatch({ type: GALLERY_OPEN, payload: imageMessage.uuid });
    }
  }
};

export const fetchPreviewImages = (files) => (dispatch, getState) => {
};
