import { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import { TextField } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import Autocomplete from '@mui/material/Autocomplete';
import { useDispatch } from 'react-redux';
import {
  changeGMT,
  changeIsEditedStatus,
} from 'store/actions/myScheduleActions';

interface IOption {
  value: string;
  label: string;
}

const TimeZoneSelect = ({ timezone }: { timezone: string }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [timezones, setTimezones] = useState<IOption[]>([]);

  useEffect(() => {
    const fetchTimezones = async () => {
      try {
        const response = await fetch('http://worldtimeapi.org/api/timezone');
        if (!response.ok) {
          throw new Error('Failed to fetch timezones');
        }
        const data: string[] = await response.json();
        const timezoneObjects: IOption[] = data.map(
          (timezone: string, index: number) => ({
            value: timezone,
            label: formatTimezoneName(timezone),
          }),
        );
        setTimezones(timezoneObjects);
      } catch (error) {
        // console.error('Error fetching timezones:', error);
      }
    };

    void fetchTimezones();
  }, []);

  return (
    <Autocomplete
      disablePortal
      className={classes.autocomplete}
      options={timezones}
      value={
        timezone
          ? { value: timezone, label: formatTimezoneName(timezone) }
          : null
      }
      renderInput={(params) => <TextField {...params} />}
      onChange={(_, val) => {
        if (val?.value) {
          dispatch(changeGMT(val.value));
          dispatch(changeIsEditedStatus(true));
        }
      }}
      isOptionEqualToValue={(option, selected) =>
        option.value === selected.value
      }
    />
  );
};
export default TimeZoneSelect;

const useStyles = makeStyles({
  autocomplete: {
    '& .MuiAutocomplete-inputRoot': {
      height: '40px',
      borderRadius: '8px',
      padding: '10px 16px !important',
      marginBottom: '40px',
    },

    '& .MuiInputBase-input': {
      padding: '0 !important',
    },
    ' & .MuiOutlinedInput-root': {
      padding: 0,
    },
  },
});

export function formatTimezoneName(timezone: string) {
  const cityName = timezone
    .substring(timezone.lastIndexOf('/') + 1)
    .replace(/_/g, ' ');
  const currentTime = moment().tz(timezone).format('HH:mm');
  const offset = moment().tz(timezone).format('Z');

  return `GMT${offset} (${cityName}) / ${currentTime}`;
}
