import update from 'immutability-helper';
import { set } from 'idb-keyval';
import moment from 'moment';
import {
  POST_SET_LOADING,
  POST_SET_POSTS,
  POST_NEW_COMMENT,
  POST_SET_REPLY_PERSONAL,
  POST_ADD,
  POST_COMMENTS_SET_LOADING,
  POST_UPDATE_COMMENTS,
  SET_TEXT,
  POST_CLEAR_NEW_REPLY,
  POST_SET_STORAGE_FILES,
  SET_FILTER,
} from '../actions';

const initialState = {
  // postsProviders: [],
  posts: [],
  postsPagination: {},
  postsLoading: false,
  commentsLoading: false,
  inputText: '',
  newReply: {},
  storageFiles: {},
  filters: {
    provider: null,
    search: '',
  },
};

const chatReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case SET_FILTER: {
      return update(state, {
        filters: { $set: ({...state.filters, ...payload}) },
      });
    }
    case POST_UPDATE_COMMENTS: {
      return update(state, {
        posts: {
          $apply: (posts) => posts.map((post) => {
            if (action.payload.rows[0].ids.uuidMessengerPost === post.ids.uuidMessengerPost) {
              return {
                ...post,
                comments: post.commentsPagination
                  ? post.comments.concat(
                    payload.rows.filter(({uuid}) => !post.comments.map(c => c.uuid).includes(uuid)))
                  : payload.rows,
                commentsPagination: payload.pagination,
              };
            }
            return post;
          }),
        },
        commentsLoading:  { $set: false },
      });
    }

    case POST_SET_STORAGE_FILES: {
      return update(state, {
        storageFiles: { $set: payload },
      });
    }

    case POST_COMMENTS_SET_LOADING: {
      return update(state, {
        commentsLoading: { $set: payload },
      });
    }

    case SET_TEXT: {
      return update(state, {
        inputText: { $set: payload },
      });
    }

    case POST_SET_LOADING: {
      const { loading } = payload;
      return update(state, {
        postsLoading: { $set: loading },
      });
    }

    case POST_ADD: {
      let { posts } = state;
      const post = payload;
      // Cache for persones
      set(`persone:${post.persone.uuid}`, post.persone).then();

      if (posts.find((p) => p.ids.uuidMessengerPost === post.ids.uuidMessengerPost)) {
        posts.forEach((d, i) => {
          if (d.ids.uuidMessengerPost === post.ids.uuidMessengerPost) {
            posts[i] = post;
          }
        });
      } else {
        posts = [post, ...posts];
      }

      return update(state, {
        postsLoading: { $set: false },
        posts: { $set: posts },
      });
    }

    case POST_SET_POSTS: {
      const { rows = [], pagination = {} } = payload;
      let posts = rows;
      if (pagination.page !== 1) {
        posts = [...state.posts, ...rows]
      }
      return update(state, {
        posts: {
          $set: posts,
        },
        dialogsLoading: { $set: false },
        postsPagination: { $set: pagination },
        postsLoading: { $set: false },
        // postsProviders:{ $set: rows.map(({provider}) => provider) },
      });
    }

    case POST_SET_REPLY_PERSONAL: {
      return update(state, {
        posts: {
          $apply: (p) => p.map((post) => {
            if (post.ids.uuidMessengerPost !== payload.ids.uuidMessengerPost) return post;
            return {
              ...post,
              comments: post.comments.map((c) => {
                if (c.ids.uuidMessengerComment !== payload.ids.uuidMessengerComment) return c;
                return {
                  ...c,
                  additional: {
                    ...c.additional,
                    personalReply: true,
                  },
                };
              }),
              hasNew: true,
            };
          }),
        },
      });
    }

    case POST_CLEAR_NEW_REPLY: {
      return update(state, {
        newReply: {}
      });
    }

    case POST_NEW_COMMENT: {
      return update(state, {
        posts: {
          $apply: (p) => p.map((post) => {
            if (post.ids.uuidMessengerPost !== payload.ids.uuidMessengerPost) return post;
            if (payload.isReply) {
              return {
                ...post,
                comments: post.comments.map((c) => {
                  if (c.ids.uuidMessengerComment !== payload.ids.uuidMessengerParent) return c;
                  return {
                    ...c,
                    replies: [...c.replies || [], payload]
                  };
                }),
                date: {
                  ...post.date,
                  updated: moment().valueOf(),
                },
                hasNew: true,
              };
            }
            return {
              ...post,
              comments: [...post.comments, payload],
              date: {
                ...post.date,
                updated: moment().valueOf(),
              },
              hasNew: true,
            };
          }),
        },
        commentsLoading: { $set: false },
        newReply: { $set: payload},
      });
    }

    default: {
      return state;
    }
  }
};

export default chatReducer;
