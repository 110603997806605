import React from 'react';
import PropTypes from 'prop-types';
import { Emojione } from 'react-emoji-render';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
  },
}));

const ConversationForm = (props) => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div
      {...rest}
      className={clsx(classes.root, className)}
    >
      <Typography variant="subtitle2" align="center" display="block" style={{ width: '100%' }}>
        <Emojione svg text={t('chatConversationFromPlaceholder.description')} />
      </Typography>
    </div>
  );
};

ConversationForm.propTypes = {
  className: PropTypes.string,
};

ConversationForm.defaultProps = {
  className: '',
};

export default ConversationForm;
