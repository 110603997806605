import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

const HintButton = ({ tooltipText, onClick, children, ...rest }) => {
  return (
    <Tooltip title={tooltipText} placement="top">
      <IconButton sx={{ borderRadius: '4px' }} onClick={onClick} {...rest}>
        {children}
      </IconButton>
    </Tooltip>
  );
};

HintButton.propTypes = {
  tooltipText: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  rest: PropTypes.node,
};
HintButton.defaultProps = {
  tooltipText: '',
  onClick: null,
  children: null,
  rest: null,
};

export default HintButton;
