import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';

const StyledInput = styled(IMaskInput)`
    border-radius: 3px;
    border: solid 1px #eeeff2;
    background-color: #f6f8fc;
    font-size: 15px;
    color: #3f434c;
    padding: 10px;
    width: 100%;
    outline: none;
`;

const PhoneField = (props) => {
  const {
    id,
    value,
    onChange,
    placeholder,
  } = props;

  return (
    <StyledInput
      mask="+{7}(000)000-00-00"
      id={id}
      value={value}
      unmask
      onAccept={(phoneValue) => onChange(phoneValue)}
      placeholder={placeholder}
    />
  );
};

PhoneField.propTypes = {
  id: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

PhoneField.defaultProps = {
  placeholder: 'Значение поля',
};

export default PhoneField;
