import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import ModalWrapper, { ActionButton, CancelButton } from './layout';

const Wrapper = styled.div`
  font-size: 17px;
  line-height: 1.53;
  text-align: center;
  color: #31343a;
  font-size: 15px;
  .modal-buttons {
    margin: 0 auto;
    margin-top: 25px;
    margin-right: -25px;
    button:first-child{
      margin-right: 15px;
    }
  }
  @media (max-width: 480px){
    .modal-buttons {
      button {
        display: block;
        width: 100%;
        &:first-child{
          margin-right: 10;
          margin-bottom: 15px;
        }
      }

    }
  }
`;

const UnlinkChannel = (props) => {
  const { data, onClose } = props;

  const name = (data.persone.info.find((o) => o.key === 'name') || {}).val || 'Noname';

  const handleDelete = () => {

  };

  return (
    <ModalWrapper onClose={() => onClose()}>
      <Wrapper>
        <div>{`Вы уверены, что хотите отвязать ${data.channel.type}: ${data.value} от клиента ${name} ?`}</div>
        <div className="modal-buttons">
          <ActionButton onClick={handleDelete}>Удалить</ActionButton>
          <CancelButton onClick={() => onClose()}>Отмена</CancelButton>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
};

UnlinkChannel.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default UnlinkChannel;
