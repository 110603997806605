import axios from 'axios';

const loadFileAsBase64 = async (url) => {
  const response = await axios.get(url, { responseType: 'arraybuffer' });
  const file = btoa(
    new Uint8Array(response.data)
      .reduce((data, byte) => data + String.fromCharCode(byte), ''),
  );
  return `data:${response.headers['content-type'].toLowerCase()};base64,${file}`;
};

export default loadFileAsBase64;
