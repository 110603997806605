import PostForm from "./PostForm/PostForm";
import PostListPreview from "./PostPreview/PostListPreview";
import AccountList from "./Account/AccountList";
import PostPage from "./PostPage";

export {
  PostForm,
  PostListPreview,
  AccountList,
}

export default PostPage;