import { apiGet, apiPost } from 'api';
import { HOST } from 'config';
import { wsSndMessage } from './wsActions';
import { updateStatistics } from './chatActions';

export const STATISTICS_SET_TAGS = 'STATISTICS_SET_TAGS';
export const STATISTICS_SET_CURRENT_DIALOG_TAGS = 'STATISTICS_SET_CURRENT_DIALOG_TAGS';
// Filters
export const STATISTICS_SET_SELECTED_COMPANY = 'STATISTICS_SET_SELECTED_COMPANY';
export const STATISTICS_SET_SELECTED_PROVIDER = 'STATISTICS_SET_SELECTED_PROVIDER';
export const STATISTICS_SET_SELECTED_DEPARTMENT = 'STATISTICS_SET_SELECTED_DEPARTMENT';

export const setSelectedProviders = (payload) => ({
  type: STATISTICS_SET_SELECTED_PROVIDER,
  payload,
});

export const setSelectedDepartment = (payload) => ({
  type: STATISTICS_SET_SELECTED_DEPARTMENT,
  payload,
});

export const setCurrentDialogTags = (payload) => ({
  type: STATISTICS_SET_CURRENT_DIALOG_TAGS,
  payload,
});

export const setTags = (payload) => ({
  type: STATISTICS_SET_TAGS,
  payload,
});

export const setSelectedCompany = (payload) => ({
  type: STATISTICS_SET_SELECTED_COMPANY,
  payload,
});

export const getStatistics =
  ({ uuidCompany }) =>
  (dispatch) =>
    apiGet({
      dispatch,
      url: `${HOST}/chat/history/${uuidCompany}/fakestat`,
      callback: (res) => {
        dispatch(updateStatistics(res));
      },
    });

export const getStat = (payload) => (dispatch) => {
  const { uuidCompany, dateRange, uuidConnection, uuidAssigns } = payload;
  dispatch(
    wsSndMessage({
      action: 'chat.stat.get',
      data: { dateRange, uuidCompany, uuidConnection, uuidAssigns },
    }),
  );
};

export const fetchTagsStat = ({ uuidCompany, data }) => (dispatch, getState) =>
    apiPost({
      dispatch,
      url: `${HOST}/chat/history/stat/tags/${uuidCompany}`,
      tokens: getState().session.tokenData,
      body: data,
      callback: (tags) => {
        dispatch(setTags(tags));
      },
  });