import { Box, styled } from '@mui/material';
import Divider from '../../../../components/Divider';
import WeekdayRow from './components/WeekdayRow';
import { useTranslation } from 'react-i18next';
import TimeZoneSelect from './components/TimeZoneSelect';
import { useDispatch, useSelector } from 'react-redux';
import AppButton from 'components/AppButton/AppButton';
import {
  fetchMyScheduleTimes,
  updateMyScheduleTimes,
} from 'store/actions/myScheduleActions';
import { useEffect } from 'react';

const SettingsSchedule = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  // @ts-expect-error
  const { schedule, isEdited } = useSelector((state) => state.mySchedule);

  const weekdays = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  useEffect(() => {
    dispatch(fetchMyScheduleTimes());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledScheduleContainer>
      <Divider title={translate('schedules.timezone')} />

      <TimeZoneSelect timezone={schedule.gmt as string} />

      <Divider title={translate('schedules.workTime')} />

      <Box display="flex" flexDirection="column" gap={1}>
        {weekdays.map((day) => (
          <WeekdayRow
            key={day}
            weekday={day}
            times={schedule[day].times}
            status={schedule[day].state}
          />
        ))}
      </Box>
      {isEdited && (
        <Box textAlign="right" mt="20px">
          <AppButton
            type="common  "
            onClick={() => {
              if (schedule != null)
                dispatch(updateMyScheduleTimes({ schedule }));
            }}
          >
            {translate('templatesTool.apply')}
          </AppButton>
        </Box>
      )}
    </StyledScheduleContainer>
  );
};

export default SettingsSchedule;

const StyledScheduleContainer = styled(Box)({
  padding: 24,
  maxWidth: 640,
  margin: '0 auto',
  borderRadius: '16px',
  background: 'white',
  border: '1px solid rgb(236, 239, 242)',
});
