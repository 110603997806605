import update from 'immutability-helper';
import {
  WS_SET_STATUS,
  WS_SND_MESSAGE,
  WS_RCV_MESSAGE,
} from '../actions/wsActions';

const initialState = {
  connection: {
    status: 'disconnect',
    lastError: null,
  },
  responseStatus: {},
};

const ws = (state = initialState, action) => {
  switch (action.type) {
    case WS_SET_STATUS: {
      const { status, error: lastError } = action.payload;
      return update(state, {
        connection: { $set: { status, lastError } },
      });
    }

    case WS_SND_MESSAGE:
      return update(state, {
        responseStatus: { [action.payload.action]: { $set: action.payload.uuid } },
      });

    case WS_RCV_MESSAGE:
      return update(state, {
        responseStatus: { $unset: [action.payload.action] },
      });

    default:
      return state;
  }
};

export default ws;
