import styled from 'styled-components';
import PropTypes from 'prop-types';

import { msgListStyles } from 'views/Chat/components/ConversationDetails/components/ConversationMessages/ConversationMessages';

import PostComment from './components/PostComment';
import Post from './components/Post';

export default function PostCommentList({ post, handleScroll }) {
  const classes = msgListStyles();

  return (
    <View onScroll={handleScroll} className={classes.root}>
      <Post post={post} />
      <div className={classes.inner}>
        {post.comments.map((comment, index) => (
          <PostComment
            key={index}
            comment={comment}
            provider={post.provider}
            postAuthor={post.ids.uuidMessengerUser}
          />
        ))}
      </div>
    </View>
  );
}

const View = styled.div``;

PostCommentList.propTypes = {
  post: PropTypes.object.isRequired,
  handleScroll: PropTypes.func.isRequired,
};

PostCommentList.defaultProps = {};
