import { useTranslation } from 'react-i18next';
import React from 'react';
import { Card, CardContent, Grid, TextField } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  postTemplateCreateAttachmentThroughBody,
  clearPostCreateTemplateAttachment,
} from 'store/actions';
import { Button, Textarea } from '../../../../../../../../components';
import AddButton from '../../../../../../../../components/AddButton';
import AttachFileForm from '../AttachFileForm';

const CreateForm = styled.div`
  margin: 8px;
`;
const TemplateCard = styled(Card)`
  ${({ theme }) => `
    margin: ${theme.spacing(1)}px;
  `}
`;
const CreateTemplateForm = ({ onCreate }) => {
  const { t } = useTranslation();
  const currentRef = React.useRef();
  const dispatch = useDispatch();

  const attachedFile = useSelector(
    (state) => state.templates.createdAttachedFile,
  );

  const [isCreate, setCreate] = React.useState(false);
  const [value, setValue] = React.useState('');
  const [caption, setCaption] = React.useState('');

  const handleClearAttachment = () => {
    dispatch(clearPostCreateTemplateAttachment());
  };
  const handleCreate = () => {
    if (value.length > 0) {
      if (attachedFile) {
        onCreate({
          title: caption,
          text: value,
          file: attachedFile,
        });
      } else {
        onCreate({ title: caption, text: value });
      }
      handleClearAttachment();
      setCreate(false);
    }
  };
  const handleCancel = () => {
    setCreate(false);
    handleClearAttachment();
  };
  const handleAttach = (fileName, data) => {
    dispatch(postTemplateCreateAttachmentThroughBody(fileName, data));
  };
  React.useEffect(() => {
    function handleClickOutside(event) {
      if (currentRef.current && !currentRef.current.contains(event.target)) {
        handleClearAttachment();
        handleCancel();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [currentRef]);
  const formCreate = (
    <TemplateCard ref={currentRef}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField 
              onChange={(e) => setCaption(e.target.value)}
              fullWidth
              margin='dense'
              label={t("templatesTool.title")}
              variant='outlined'
              value={caption}
            />
            <Textarea
              onChange={(e) => setValue(e.target.value)}
              value={value}
              id="template_input"
            />
            <AttachFileForm
              onClear={handleClearAttachment}
              attachedFile={attachedFile}
              onAttach={handleAttach}
            />
          </Grid>
          <Grid item xs={12}>
            <div>
              <Button
                type="button"
                className="primary"
                style={{ margin: '10px 10px 10px 0' }}
                disabled={value.length < 1}
                onClick={handleCreate}
              >
                {t('templatesTool.save')}
              </Button>
              <Button
                type="button"
                className="secondary"
                onClick={handleCancel}
              >
                {t('templatesTool.cancel')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </CardContent>
    </TemplateCard>
  );
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <AddButton
            text={t('templatesTool.createTemplate')}
            // onClick={handleAddNewButton}
            onClick={() => setCreate(true)}
            style={{
              margin: '15px 0 0 15px',
              paddingBottom: '15px',
              borderBottom: '1px solid #eeeff2',
              fontSize: '15px',
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <CreateForm>{isCreate ? formCreate : ''}</CreateForm>
        </Grid>
      </Grid>
    </>
  );
};

CreateTemplateForm.propTypes = {
  onCreate: PropTypes.func,
};
CreateTemplateForm.defaultProps = {
  onCreate: () => {},
};
export default CreateTemplateForm;
