import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import {
  CustomAutocomplete,
  CustomInput,
} from '../../../../../components/AppMultipleAutocomplete/AppMultipleAutocomplete';
import { ReactComponent as ChevronIcon } from '../../../../../assets/img/chevron-bordered.svg';

function transformData(data) {
  const result = [];

  // eslint-disable-next-line guard-for-in
  for (const companyId in data) {
    // eslint-disable-next-line guard-for-in
    for (const departmentId in data[companyId]) {
      const department = data[companyId][departmentId];

      const transformedDepartment = {
        companyId,
        departmentId,
        name: department.name,
        users: department.users,
      };
      result.push(transformedDepartment);
    }
  }

  return result;
}

const TargetEmployeeDialogCountInputs = ({ orCondition, onValueChange }) => {
  const { departments: companies } = useSelector((state) => state.session);
  const departments = transformData(companies);
  const currentValue =
    departments?.find((d) => d.departmentId === orCondition.department?.value)
      ?.name ?? '';

  return (
    <Wrapper>
      <CustomAutocomplete
        value={currentValue}
        disablePortal
        options={departments}
        getOptionLabel={(option) => option.name ?? option}
        sx={{ maxWidth: '300px', width: '100%' }}
        popupIcon={<ChevronIcon />}
        renderInput={(params) => <CustomInput {...params} label="Отдел" />}
        onChange={(event, value) => onValueChange('value', value.departmentId)}
      />
    </Wrapper>
  );
};
TargetEmployeeDialogCountInputs.propTypes = {
  orCondition: PropTypes.object,
  onValueChange: PropTypes.func,
};
TargetEmployeeDialogCountInputs.defaultProps = {
  orCondition: {},
  onValueChange: null,
};
export default TargetEmployeeDialogCountInputs;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  column-gap: 10px;
  row-gap: 12px;
`;
