import React from 'react';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import PropTypes from 'prop-types';
import { ReactComponent as CrossIcon } from '../../assets/img/cross-bordered.svg';
import { ReactComponent as ChevronIcon } from '../../assets/img/chevron-bordered.svg';

export const CustomInput = styled(TextField)({
  minWidth: '100px',

  '& .MuiAutocomplete-input': {
    padding: '4px',
  },

  '& input': {
    maxHeight: '48px',
    paddingTop: '14px',
    paddingBottom: '14px',
  },

  '& label': {
    fontSize: '14px',
  },
  '& label.Mui-focused': {
    color: '#A0AAB4',
  },

  '& .MuiInput-underline:after': {
    borderBottomColor: '#B2BAC2',
  },
  '& .MuiOutlinedInput-root': {
    fontSize: '14px',
    borderRadius: 13,

    '& fieldset': {
      borderColor: '#E0E3E7',
    },
    '&:hover fieldset': {
      borderColor: '#B2BAC2',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#6F7E8C',
    },
  },
});
export const CustomAutocomplete = withStyles({
  '& .MuiInputBase-root': {
    padding: 0,
    backgroundColor: 'red',
  },
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: '5px',
    },
  },
})(Autocomplete);

const AppMultipleAutocomplete = ({
  options,
  value,
  defaultValue,
  inputLabel,
  inputPlaceholder,
  onChange,
  ...rest
}) => {
  return (
    <CustomAutocomplete
      {...rest}
      multiple
      fullWidth
      options={options}
      value={value}
      onChange={onChange}
      limitTags={10}
      defaultValue={defaultValue}
      filterSelectedOptions
      popupIcon={<ChevronIcon />}
      renderInput={(params) => (
        <CustomInput
          {...params}
          label={inputLabel}
          placeholder={inputPlaceholder}
        />
      )}
      renderTags={(values, getTagProps) =>
        values.map((option, index) => (
          <Chip
            sx={{
              backgroundColor: '#006AE4',
              maxHeight: '22px',
              color: '#ffffff',
            }}
            variant="contained"
            label={option}
            {...getTagProps({ index })}
            deleteIcon={<CrossIcon />}
          />
        ))
      }
      renderOption={(props, option, item) => (
        <li
          {...props}
          style={{
            fontSize: '14px',
            lineHeight: '16px',
            borderTop: item.index !== 0 ? '1px solid #DADCE0' : 'none',
            padding: '8px',
            margin: '0 8px',
          }}
        >
          {option}
        </li>
      )}
    />
  );
};

AppMultipleAutocomplete.propTypes = {
  options: PropTypes.array,
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  inputLabel: PropTypes.string,
  inputPlaceholder: PropTypes.string,
  onChange: PropTypes.node,
};
AppMultipleAutocomplete.defaultProps = {
  options: null,
  value: null,
  defaultValue: null,
  inputLabel: null,
  inputPlaceholder: null,
  onChange: null,
};

export default AppMultipleAutocomplete;
