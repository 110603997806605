import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Route } from 'react-router';
import { Page } from '../../components';
import TaskDetails from './components/TaskDetails/TaskDetails';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
  },
}));
export default function TaskPage() {
  const classes = useStyles();
  return (
    <Page
      className={clsx({
        [classes.root]: true,
      })}
      title="IntellectDialog | Tasks"
    >
      <Route exact path="/tasks" component={TaskDetails} />
    </Page>
  );
}
