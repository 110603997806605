import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  getSupportById: {
    isFetching: false,
    error: null,
  },
  data: [],
  userLoaded: [],
};

const supportReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.REQUEST_GET_SUPPORT_BY_ID:
      return update(state, {
        getSupportById: {
          isFetching: { $set: true },
          error: { $set: null },
        },
        userLoaded: { $push: [action.uuidUser] },
      });

    case actionTypes.RECEIVE_GET_SUPPORT_BY_ID:
      return update(state, {
        getSupportById: {
          isFetching: { $set: false },
        },
        data: { $push: [action.data] },
      });

    case actionTypes.ERROR_GET_SUPPORT_BY_ID:
      return update(state, {
        getSupportById: {
          isFetching: { $set: false },
          error: { $set: action.err },
        },
      });

    default: {
      return state;
    }
  }
};

export default supportReducer;
