import { createMuiTheme } from '@material-ui/core';

import palette from './common/palette';
// import darkPalette from './dark/palette';
import typography from './common/typography';
import overrides from './overrides';

/**
 * get dark mode
 * @param {boolean} mode
 */
const getMode = (mode) => (mode ? {} : palette);

const theme = (prefersDarkMode) => createMuiTheme({
  palette: {
    type: prefersDarkMode ? 'dark' : 'light',
    ...getMode(prefersDarkMode),
  },

  typography,
  overrides,
});

export default theme;
