import { wsSndMessage } from "./wsActions";

export const AI_SET_ERROR = 'AI_SET_ERROR';
export const AI_SYNC_CHECK = 'AI_SYNC_CHECK';
export const AI_SET_LOADING = 'AI_SET_LOADING';
export const SET_AI_ANSWERS = 'SET_AI_ANSWERS';
export const SET_AI_MESSAGE = 'SET_AI_MESSAGE';

export const setAiMessage = (payload) => ({
  type: SET_AI_MESSAGE,
  payload,
})

export const setAiAnswers = (payload) => ({
  type: SET_AI_ANSWERS,
  payload,
})

export const setAiError = (payload) => ({
  type: AI_SET_ERROR,
  payload,
})

export const setAiSync = (payload) => ({
  type: AI_SYNC_CHECK,
  payload,
})

export const setAiLoading = (payload) => ({
  type: AI_SET_LOADING,
  payload,
})

export const checkAiSync = (uuidCompany) => (dispatch) => {
  dispatch(
    wsSndMessage({
      action: 'chat.ai.check',
      data: {
        uuidCompany,
      },
    }),
  );
}

export const makeAiSync = ({
  uuidCompany,
  nameCompany
}) => (dispatch) => {
  dispatch(
    wsSndMessage({
      action: 'chat.ai.sync',
      data: {
        uuidCompany,
        nameCompany
      },
    }),
  );
}

export const getAiData = ({
  uuidCompany,
  question
}) => (dispatch) => {
  dispatch(setAiLoading(true));
  dispatch(
    wsSndMessage({
      action: 'chat.ai.answers',
      data: {
        uuidCompany,
        question,
      },
    }),
  );
}