import React from 'react';
import 'emoji-mart/css/emoji-mart.css';
import styled from 'styled-components';


import {useParams} from 'react-router';
import {useDispatch, useSelector} from 'react-redux';

import { LinearProgress } from '@material-ui/core';
import {DEFAULT_DIALOG_MESSAGES_PAGE_SIZE} from 'config';
import {getPostComments, setPostMode} from 'store/actions';

import PostCommentList from '../PostComments/PostCommentList';
import {ConversationMessagesWrapper, useConversationDetailsStyles} from '../../ConversationDetails/ConversationDetails';
import PostInput from './components/PostInput';


export default function PostForm () {
  const dispatch = useDispatch();
  const rightPanel = useSelector((state) => state.rightPanel);
  const postMode = useSelector((state) => state.settings.postMode);
  const classes = useConversationDetailsStyles(rightPanel);
  const routerParams = useParams();
  React.useEffect(() => {
    if (!postMode) {
      dispatch(setPostMode(true));
    }
  }, [])

  const post = useSelector(
    (state) => state.post.posts.find((d) => d.uuid === routerParams.uuid) || null,
  );
  const commentsLoading = useSelector((state) => state.post.commentsLoading);

  const getNewPosts = () => {
    if (!commentsLoading) {
      const reqObj = {
        uuidMessengerPost: post.ids.uuidMessengerPost,
      };
      const {commentsPagination} = post;
      if (commentsPagination) {
        const newPage = commentsPagination.page + 1;
        if (
          newPage * DEFAULT_DIALOG_MESSAGES_PAGE_SIZE
          - commentsPagination.count
          > DEFAULT_DIALOG_MESSAGES_PAGE_SIZE
        ) {
          return;
        }
        reqObj.page = newPage;
      }

      dispatch(getPostComments(reqObj));
    }
  };

  const handleScroll = (data) => {
    const {clientHeight, scrollTop, scrollHeight} = data.target;
    if (clientHeight + scrollTop >= scrollHeight - 5) {
      getNewPosts();
    }
  };

  return (
    <div className={post && classes.root}>
      {post && (
        <>
          <ConversationMessagesWrapper>
            <PostCommentList
              post={post}
              handleScroll={handleScroll}/>
          </ConversationMessagesWrapper>
          {commentsLoading && <Loading/>}
          <PostInput post={post}/>
        </>
      )}
    </div>
  );
}

const Loading = styled(LinearProgress)`
  .MuiLinearProgress-root {
    height: 2px; 
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: #c5c3c3;
  }
`;