import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import { fetchCompanyProviders, setChatDialogs } from 'store/actions';
import { Page } from 'components';
import { FlexDiv } from './components/FlexDiv';
import Filters from './components/Filters';
import { Wrap } from './StatPage';
import StatNav from './components/StatNav';

const fields = {
  page: 0,
  size: 10,
  fields: [
    {
      rows: ['uuid', 'tags', 'date'],
    },
  ],
};

ChartJS.register(ArcElement, Tooltip, Legend);

// doughnut
const textForGraph = '202021';

const data = {
  datasets: [
    {
      label: '# of Votes',
      data: [50, 5, 10, 100],
      backgroundColor: ['#D9D9D9', '#E4006D', '#00E440', '#006AE4'],
      borderWidth: 0,
    },
  ],
};

const plugins = [
  {
    beforeDraw: (chart) => {
      const { width, height, ctx } = chart;
      ctx.restore();
      const fontSize = (height / 160).toFixed(2);
      ctx.font = `${fontSize}em Helvetica`;
      ctx.fillStyle = '#7F7F7F';
      ctx.textBaseline = 'top';
      const text = textForGraph;
      const textX = Math.round((width - ctx.measureText(text).width) / 2);
      const textY = height / 2 - 3;
      ctx.fillText(text, textX, textY);
      ctx.save();
    },
  },
];
//

const ProvidersPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const uuidCompany = useSelector((state) => state.session.selectedCompany);
  const dateRange = useSelector((state) => state.stat.dateRange);
  const company = useSelector((state) => state.stat.company);
  const [selectedCompany, setSelectedCompany] = useState(
    company || uuidCompany,
  );

  const getDialogsRequest = (companyUuid) => {
    dispatch(
      setChatDialogs({
        uuidCompany: companyUuid,
        ...fields,
        filter: {
          dateRange,
        },
      }),
    );
  };

  useEffect(() => {
    if (company) {
      setSelectedCompany(company);
      getDialogsRequest(company);
      dispatch(fetchCompanyProviders({ uuidCompany: company }));
    }
  }, [company]);

  useEffect(() => {
    getDialogsRequest(selectedCompany);
  }, [dateRange]);

  const testData = [
    {
      channelName: 'Имя канала',
      id: 1,
      newQuestions: 1212,
      oldQuestions: 1212,
      solvedQuestions: 1212,
      peopleSolvedQuest: 1212,
      answerSpeed: t('time.timeMins', { min: 2, sec: 12 }),
    },
    {
      channelName: 'Имя канала',
      id: 2,
      newQuestions: 1212,
      oldQuestions: 1212,
      solvedQuestions: 1212,
      peopleSolvedQuest: 1212,
      answerSpeed: t('time.timeMins', { min: 2, sec: 12 }),
    },
    {
      channelName: 'Имя канала',
      id: 3,
      newQuestions: 1212,
      oldQuestions: 1212,
      solvedQuestions: 1212,
      peopleSolvedQuest: 1212,
      answerSpeed: t('time.timeMins', { min: 2, sec: 12 }),
    },
  ];
  return (
    <Page>
      <StatNav />
      <Wrap>
        <Filters selectedCompany={selectedCompany} />
        <SectionTitle>{t('providersPage.title')}</SectionTitle>
        <ProvidersGraph>
          <div
            style={{
              width: '238px',
              height: '238px',
              marginRight: '10px',
              position: 'relative',
            }}
          >
            <Doughnut
              plugins={plugins}
              options={{
                plugins: {
                  tooltip: {
                    enabled: false,
                  },
                },
              }}
              data={data}
            />
          </div>
          <div
            style={{
              dispay: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <FlexDiv direction="column" margin="0 0 18px 0">
              <ProvidersInfoText>
                <span>Имя</span>
                <span>2%</span>
              </ProvidersInfoText>
              <ProvidersInfoBar fill="90" />
            </FlexDiv>
            <FlexDiv direction="column" margin="0 0 18px 0">
              <ProvidersInfoText>
                <span>Имя</span>
                <span>2%</span>
              </ProvidersInfoText>
              <ProvidersInfoBar fill="90" />
            </FlexDiv>
            <FlexDiv direction="column" margin="0 0 18px 0">
              <ProvidersInfoText>
                <span>Имя</span>
                <span>2%</span>
              </ProvidersInfoText>
              <ProvidersInfoBar fill="90" />
            </FlexDiv>
            <FlexDiv direction="column" margin="0 0 18px 0">
              <ProvidersInfoText>
                <span>Имя</span>
                <span>2%</span>
              </ProvidersInfoText>
              <ProvidersInfoBar fill="90" />
            </FlexDiv>
          </div>
        </ProvidersGraph>
        <ProvidersList>
          <div className="list-header">
            <span>{t('providersPage.listHeader.col1')}</span>
            <span>{t('providersPage.listHeader.col2')}</span>
            <span>{t('providersPage.listHeader.col3')}</span>
            <span>{t('providersPage.listHeader.col4')}</span>
            <span>{t('providersPage.listHeader.col5')}</span>
            <span>{t('providersPage.listHeader.col6')}</span>
          </div>
          {testData.map((d) => (
            <div className="row" key={d.id}>
              <span>{d.channelName}</span>
              <span>{d.newQuestions}</span>
              <span>{d.oldQuestions}</span>
              <span>{d.solvedQuestions}</span>
              <span>{d.peopleSolvedQuest}</span>
              <span>{d.answerSpeed}</span>
            </div>
          ))}
        </ProvidersList>
      </Wrap>
    </Page>
  );
};

const ProvidersGraph = styled.div`
  display: flex;
`;

const SectionTitle = styled.h4`
  color: #000000;
  font-weight: bold;
  font-size: 18px;
  margin-top: 36px;
  margin-bottom: 32px;
  line-height: 25px;
`;

const ProvidersInfoText = styled.div`
  font-weight: 400;
  font-size: 14px;
  margin-bottom: 12px;
  line-height: 19px;
  color: #7f7f7f;
  display: flex;
  justify-content: space-between;
`;

const ProvidersInfoBar = styled.div`
  height: 5px;
  background: #e2e2e2;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    background: #006ae4;
    width: ${(props) => (props.fill ? props.fill : '')}%;
  }
`;

const ProvidersList = styled.div`
  font-weight: 400;
  margin-top: 32px;
  .row,
  .list-header {
    display: grid;
    grid-template-columns: repeat(6, minmax(0, 1fr));
    grid-column-gap: 16px;
  }
  .row {
    padding: 11px 0;
    & span:first-of-type {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    span {
      color: #000000;
      font-size: 14px;
      line-height: 19px;
    }
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  }

  .list-header {
    font-size: 12px;
    line-height: 17px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 11px 0;
    color: rgba(0, 0, 0, 0.5);
  }
`;

export default ProvidersPage;
