import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/styles';
import { useDispatch } from 'react-redux';
import { Forum } from '@material-ui/icons';
import { showModal } from 'store/actions';

const useStyles = makeStyles(() => ({
  root: {
    top: 'initial',
    bottom: 0,
    backgroundColor: '#f6f8fc',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    zIndex: 9999,
  },
}));

const MenuItem = styled.div`
    text-align: center;
    font-size: 12px;
    color: #000;
    cursor: pointer;
    margin: 6px 0;
    &:hover,
    &.active {
        color: #215fdc;
        svg path {
          fill: #006ae4;
        }
    }
`;

const BottomBar = (props) => {
  const {
    open,
    menuItems,
    currentItem,
    handleMenuItemClick,
  } = props;

  const classes = useStyles();
  const dispatch = useDispatch();

  const [startDialog, setStartDialog] = useState(false);

  const handleStartDialog = () => {
    dispatch(showModal('StartDialog', {
      isExpand: true,
    }));
  };

  return (
    <AppBar position="fixed" classes={classes}>
      {menuItems.map((item) => (
        <MenuItem
          key={item.id}
          className={currentItem.id === item.id && open ? 'active' : ''}
          onClick={() => {
            handleMenuItemClick(item);
            setStartDialog(false);
          }}
        >
          <item.icon />
        </MenuItem>
      ))}
      <MenuItem
        key="start-dialog"
        className={startDialog ? 'active' : ''}
        onClick={() => {
          handleStartDialog();
          setStartDialog(true);
        }}
      >
        <Forum />
      </MenuItem>
    </AppBar>
  );
};

BottomBar.propTypes = {
  open: PropTypes.bool,
  menuItems: PropTypes.array,
  currentItem: PropTypes.shape,
  handleMenuItemClick: PropTypes.func,
};

BottomBar.defaultProps = {
  open: false,
  menuItems: [],
  currentItem: {},
  handleMenuItemClick: () => false,
};

export default BottomBar;
