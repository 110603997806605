import { createSelector } from 'reselect';

const taskFetching = (state) => state.task.isFetching;

const taskList = (state) => state.task.tasks;

const selectTaskTotalCount = (state) => state.task.taskTotalCount;

const dialogTaskList = (state) => state.task.dialogTasks;

const selectDialogTaskTotalCount = (state) => state.task.dialogTaskTotalCount;

export const selectTaskListSelector = createSelector([taskFetching, taskList], (fetchingState, tasks) => tasks);

export const selectDialogTaskListSelector = createSelector([taskFetching, dialogTaskList], (fetchingState, dialogTasks) => dialogTasks);

export const selectTaskTotalCountSelector = createSelector(selectTaskTotalCount, (taskTotalCount) => taskTotalCount);

export const selectDialogTaskTotalCountSelector = createSelector(selectDialogTaskTotalCount, (dialogTaskTotalCount) => dialogTaskTotalCount);
