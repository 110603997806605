import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import successIcon from '../../assets/img/confirm.svg';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 24px;
  gap: 24px;
  align-items: center;
  justify-content: center;
  .icon {
    width: 161px;
    height: 161px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
  }
  .indicator {
    border-radius: 50%;
    background: #C5FFCE;
    position: absolute;
    width: 161px;
    height: 161px;
    animation: 0.35s linear success;
  }
  img {
    position: absolute;
    width: 91px;
    height: 91px;
  }
  @keyframes success {
    0% {
      width: 91px;
      height: 91px;
    }
    100% {
      width: 161px;
      height: 161px;
    }
  }
`;

const SuccessPasswordChangeDialog = () => {
  const { t: translate } = useTranslation();
  return (
    <Wrapper>
      <div className='icon'>
        <div className='indicator' />
        <img src={successIcon} alt='' />
      </div>
      {translate('changePasswordDialog.success')}
    </Wrapper>
  );
}

export default SuccessPasswordChangeDialog;