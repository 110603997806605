import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import { Dialog, Snackbar } from '@material-ui/core';

import { ConversationListItem } from 'views/Chat/components/ConversationList/components';
import { Alert, Spinner } from 'components';
import {
  clearSelectedMsg,
  forwardMessage,
  getForwardDialogs,
  setChatMessageForwardStatus,
  setCurrentDialog,
  setForwardDialogs,
} from 'store/actions';
import { DEFAULT_FORWARD_DIALOGS_PAGE_SIZE } from 'config';
import { ReactComponent as CrossIcon } from '../../assets/img/cross.svg';

export default function ForwardModal({
  onClose,
  msgID,
  fromID,
  providerName,
  uuidConnection,
}) {
  const { t } = useTranslation();
  const {
    forwardStatus,
    forwardDialogs,
    forwardLoading,
    forwardDialogsPagination,
  } = useSelector((state) => state.chat);
  const selecetedCompany = useSelector(
    (state) => state.session.selectedCompany,
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const [openAlert, setOpenAlert] = React.useState(false);
  const [forwardDialog, setForwardDialog] = React.useState();
  const [dialogs, setDialogs] = React.useState([]);
  const [page, setPage] = React.useState(1);
  const [search, setSearch] = React.useState('');
  const handleClose = () => {
    onClose();
  };
  React.useEffect(() => {
    if (forwardDialogs && forwardDialogs.length) {
      const uniques = forwardDialogs.filter(
        ({ uuid }) => !dialogs.find((find) => find.uuid === uuid),
      );
      setDialogs([...dialogs, ...uniques]);
    }
  }, [forwardDialogs]);
  React.useEffect(() => {
    dispatch(
      getForwardDialogs({
        uuidCompany: selecetedCompany,
        providerName,
        uuidConnection,
        page,
        size: DEFAULT_FORWARD_DIALOGS_PAGE_SIZE,
      }),
    );
    return () => dispatch(setForwardDialogs([]));
  }, []);
  React.useEffect(() => {
    if (forwardStatus) {
      dispatch(setChatMessageForwardStatus(null));
      switch (forwardStatus) {
        case 'sended': {
          if (forwardDialog) {
            dispatch(setCurrentDialog(forwardDialog.uuid));
            history.push(`/chat/${forwardDialog.uuid}`);
            dispatch(clearSelectedMsg());
            handleClose();
          }
          break;
        }
        default: {
          setOpenAlert(true);
          break;
        }
      }
    }
  }, [forwardStatus]);
  React.useEffect(() => {
    const searchTimeout = setTimeout(() => {
      setDialogs([]);
      setPage(1);
      dispatch(
        getForwardDialogs({
          uuidCompany: selecetedCompany,
          search,
          providerName,
          uuidConnection,
          page,
          size: DEFAULT_FORWARD_DIALOGS_PAGE_SIZE,
        }),
      );
    }, 500);

    return () => {
      clearTimeout(searchTimeout);
      dispatch(setForwardDialogs([]));
    };
  }, [search]);
  const handleForward = (dialog) => {
    setForwardDialog(dialog);
    let additional = {
      messageIds: msgID,
    };
    if (providerName === 'whatsapp') {
      additional = {
        ...additional,
        phone: dialog.ids.uuidMessengerUser || dialog.ids.uuidMessage,
        group: dialog.type === 'group',
      };
    } else {
      additional = {
        ...additional,
        from: fromID,
        to: dialog.ids.uuidMessengerUser || dialog.ids.uuidMessage,
      };
    }
    dispatch(
      forwardMessage({
        additional,
        ids: dialog.ids,
      }),
    );
  };
  const handleScroll = (event) => {
    if (forwardDialogsPagination) {
      const {
        target: { scrollHeight, scrollTop, clientHeight },
      } = event;
      const isScrolled = scrollHeight - Math.ceil(scrollTop) <= clientHeight;
      const havePages =
        forwardDialogsPagination.count >
        forwardDialogsPagination.page * DEFAULT_FORWARD_DIALOGS_PAGE_SIZE;

      if (isScrolled && havePages && !forwardLoading) {
        setPage(page + 1);
        dispatch(
          getForwardDialogs({
            uuidCompany: selecetedCompany,
            search,
            providerName,
            uuidConnection,
            page: page + 1,
            size: DEFAULT_FORWARD_DIALOGS_PAGE_SIZE,
          }),
        );
      }
    }
  };
  const handleInput = (event) => {
    setSearch(event.target.value);
  };
  return (
    <Modal
      open
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <div className="header">
        <CrossIcon className="close-icon" onClick={handleClose} />
        <input
          onChange={handleInput}
          placeholder={`${t('common.forward')} ...`}
        />
      </div>
      <div onScroll={handleScroll} className="body">
        {dialogs
          .filter(({ persone }) => persone && persone.info)
          .map((conversation, index) => (
            <div key={index} onClick={() => handleForward(conversation)}>
              <ConversationListItem conversation={conversation} mini />
            </div>
          ))}
      </div>
      {forwardLoading && (
        <div className="spinner">
          <Spinner show />
        </div>
      )}
      <Snackbar
        className="alert"
        open={openAlert}
        autoHideDuration={3000}
        onClose={() => setOpenAlert(false)}
      >
        <Alert
          className="alert-msg"
          onClose={() => setOpenAlert(false)}
          message={t('errors:msg.unforward')}
        />
      </Snackbar>
    </Modal>
  );
}

const Modal = styled(Dialog)`
  .spinner {
    background: #ffffff57;
    left: 0px;
    top: 0px;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .alert {
    bottom: 50px;
  }
  .alert-msg {
    background-color: #b6b8bb;
  }
  input {
    border: none;
    outline: none;
    widht: 100%;
  }
  .header {
    display: flex;
    padding: 23px 25px;
    gap: 30px;
    border-bottom: 1px solid #eeeff2;
  }
  .close-icon {
    cursor: pointer;
  }
  .body {
    position: relative;
    height: 100%;
    overflow-y: auto;
  }
  .list::-webkit-scrollbar {
    width: 4px;
  }
  .list::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 4px;
  }
  .list::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  .MuiDialog-scrollPaper {
    padding-bottom: 50px;
    align-items: initial;
  }
  .MuiDialog-paperWidthSm {
    width: 350px;
    max-height: 700px;
  }
`;

ForwardModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  msgID: PropTypes.array.isRequired,
  fromID: PropTypes.string.isRequired,
  providerName: PropTypes.string.isRequired,
  uuidConnection: PropTypes.string.isRequired,
};
