import { Typography } from '@mui/material';

interface IDivederProps {
  title?: string;
}
const Divider: React.FC<IDivederProps> = ({ title }) => {
  return (
    <div
      style={{
        position: 'relative',
        width: '100%',
        height: '1px',
        background: 'rgb(240, 240, 240)',
        margin: '16px 0',
      }}
    >
      {title && (
        <Typography
          style={{
            position: 'absolute',
            top: '-50%',
            left: '20px',
            transform: 'translate(0, -50%)',
            background: 'white',
            padding: '0 16px',
            fontSize: 12,
            fontWeight: 400,
          }}
        >
          {title}
        </Typography>
      )}
    </div>
  );
};

export default Divider;
