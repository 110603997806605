import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import ClearIcon from '@material-ui/icons/Clear';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CloseOutlined, FormatListNumbered } from '@material-ui/icons';
import { Badge } from '@material-ui/core';
import {
  addDialogTag,
  fetchAllTags,
  removeCompanyTag,
  showModal,
} from 'store/actions';

export default function DialogTags({ conversation }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentValue, setCurrentValue] = useState('');
  const { tags: thisTags = [], uuid: uuidDialog } = conversation;
  const tags = useSelector((state) => state.chat.tags);
  const [searchedTags, setSearchedTags] = useState([]);
  useEffect(() => {
    dispatch(fetchAllTags());
  }, []);
  useEffect(() => {
    setSearchedTags(
      tags.map((tag) =>
        thisTags.find(({ title }) => title === tag.title)
          ? { ...tag, isSelected: true }
          : { ...tag, isSelected: false },
      ),
    );
  }, [JSON.stringify(thisTags), JSON.stringify(tags)]);

  const [tagPanelOpen, setTagPanelOpen] = useState(false);

  const handleSelect = (tag) => {
    dispatch(addDialogTag({ uuidDialog, tag }));
  };

  const handleChange = (value) => {
    setCurrentValue(value);

    const selectedTags = tags
      .filter(({ title }) =>
        thisTags.find(({ title: thisTagsTitle }) => thisTagsTitle === title),
      )
      .map((old) => ({ ...old, isSelected: true }));

    const otherTags = tags
      .filter(
        ({ title }) =>
          !thisTags.find(({ title: thisTagsTitle }) => thisTagsTitle === title),
      )
      .map((old) => ({ ...old, isSelected: false }));

    setSearchedTags(
      [...selectedTags, ...otherTags].filter(({ title }) =>
        title.includes(value),
      ),
    );
  };

  const handleAdd = () => {
    if (currentValue !== '') {
      dispatch(addDialogTag({ uuidDialog, tag: currentValue }));
      setCurrentValue('');
    }
  };

  const handleOpenTagModal = () => {
    dispatch(showModal('TagDialog', uuidDialog));
  };

  const getTagCount = (title) => {
    const count = thisTags.filter((tag) => tag.title === title).length;
    return count > 9 ? '9+' : count;
  };

  const handleRemoveTag = (e, title) => {
    e.stopPropagation();
    dispatch(
      showModal('ConfirmDialog', {
        title: t('tagStat.deleteTag.title'),
        subtitle: t('tagStat.deleteTag.subtitle', { tag: title }),
        handleConfirm: () => {
          dispatch(
            removeCompanyTag({
              title,
              uuidCompany: conversation.ids.uuidCompany,
            }),
          );
        },
      }),
    );
  };

  return (
    <View>
      {false && (
        <ExistTagPanel>
          {searchedTags.map((tag) => (
            <div className="tag" key={tag.uuid}>
              {tag}
              <ClearIcon className="tag-remove-icon" />
            </div>
          ))}
        </ExistTagPanel>
      )}
      <TagPanel isOpen={tagPanelOpen}>
        {tags.length > 0 ? (
          searchedTags.map(({ title, isSelected }, index) => (
            <SelectedTag
              key={index}
              color="primary"
              badgeContent={getTagCount(title)}
            >
              <div
                key={title}
                onClick={() => handleSelect(title)}
                className={`tag ${isSelected && 'selected'}`}
              >
                <div>{title}</div>
                <div
                  onClick={(e) => handleRemoveTag(e, title)}
                  className="remove-icon-wrap"
                >
                  <CloseOutlined className="remove-icon" />
                </div>
              </div>
            </SelectedTag>
          ))
        ) : (
          <div className="empty_tag_list">Нет тегов</div>
        )}
      </TagPanel>
      <AddPanel>
        <div className="tag_search">
          <AddIcon className="icons" onClick={handleAdd} />
          <input
            value={currentValue}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                handleAdd();
              }
            }}
            onFocus={() => setTagPanelOpen(true)}
            onChange={({ target: { value } }) => handleChange(value)}
            placeholder={t('tagStat.addTag')}
            className="tag_input"
          />
        </div>
        <FormatListNumbered onClick={handleOpenTagModal} className="icons" />
        {tagPanelOpen && (
          <CloseIcon onClick={() => setTagPanelOpen(false)} className="icons" />
        )}
      </AddPanel>
    </View>
  );
}

DialogTags.propTypes = {
  conversation: PropTypes.object.isRequired,
};

const View = styled.div`
  position: relative;
  .remove-icon-wrap {
    padding-top: 4px;
    cursor: pointer;
  }
  .remove-icon {
    font-size: 14px;
    &:hover {
      color: red;
    }
  }
`;

const SelectedTag = styled(Badge)``;

const ExistTagPanel = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  row-gap: 5px;
  column-gap: 3px;
  padding: 10px;
  position: absolute;
  bottom: 36px;
  .tag {
    padding: 2px 4px;
    gap: 4px;
    height: 22px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 26px;
    font-size: 13px;
    line-height: 18px;
    cursor: default;
    gap: 2px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .tag-remove-icon {
    height: 12px;
    width: 12px;
    color: #a6a6a6;
    &:hover {
      color: #565656;
    }
  }
`;

const TagPanel = styled.div`
  border-top: 1px solid #eaeaea;
  max-height: 85px;
  overflow-y: scroll;
  padding: 10px 20px;
  row-gap: 10px;
  column-gap: 8px;
  display: flex;
  flex-wrap: wrap;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
  }
  &::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 4px;
  }
  .tag {
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 2px 4px;
    height: 22px;
    background: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 26px;
    font-size: 13px;
    line-height: 18px;
    cursor: default;
    gap: 2px;
    &:hover {
      background-color: #dde8ff;
    }
    display: flex;
    align-items: center;
  }
  .selected {
    background-color: #215fdc;
    border-color: #215fdc;
    color: #ffffff;
    &:hover {
      background-color: #215fdc;
    }
  }
  .empty_tag_list {
    color: #757575;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: lowercase;
    font-size: 14px;
    line-height: 14px;
  }
  background-color: #fff;
  width: 100%;
  position: absolute;
  bottom: 36px;
  transition: 0.3s;
  z-index: 0;
  transform: translateY(100%);
  ${(props) =>
    props.isOpen &&
    css`
      transform: translateY(0);
      transition: 0.5s;
    `}
`;

const AddPanel = styled.div`
  z-index: 1;
  height: 36px;
  background-color: #fff;
  position: relative;
  width: 100%;
  border-top: 1px solid #eaeaea;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
  padding: 8.5px 20px;
  .icons {
    color: #a6a6a6;
    cursor: pointer;
    &:hover {
      color: #565656;
    }
  }
  .tag_search {
    display: flex;
    align-items: center;
    gap: 20px;
    flex: 1;
  }
  .tag_input {
    width: 100%;
    border: none;
    outline: none;
    color: #757575;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
`;
