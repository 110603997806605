import update from 'immutability-helper';
import * as actionTypes from '../actions';

const initialState = {
  schedule: {
    gmt: '',
    monday: {
      times: [],
      state: true,
    },
    tuesday: {
      times: [],
      state: true,
    },
    wednesday: {
      times: [],
      state: true,
    },
    thursday: {
      times: [],
      state: true,
    },
    friday: {
      times: [],
      state: true,
    },
    saturday: {
      times: [],
      state: true,
    },
    sunday: {
      times: [],
      state: true,
    },
  },
  isEdited: false,
};

const myScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_MY_SCHEDULE:
      return update(state, {
        schedule: { $set: action.payload },
      });
    case actionTypes.ADD_TIME_TO_WEEKDAY:
      const { weekday, time } = action.payload;
      return update(state, {
        schedule: {
          [weekday]: {
            times: { $push: [time] },
          },
        },
      });
    case actionTypes.REMOVE_TIME_FROM_WEEKDAY:
      return update(state, {
        schedule: {
          [action.payload.weekday]: {
            times: { $splice: [[action.payload.index, 1]] },
          },
        },
      });
    case actionTypes.UPDATE_WEEKDAY_STATE:
      return update(state, {
        schedule: {
          [action.payload.weekday]: {
            state: { $set: action.payload.state },
          },
        },
      });
    case actionTypes.CHANGE_ISEDITED_STATUS:
      return update(state, {
        isEdited: { $set: action.payload },
      });
    case actionTypes.CHANGE_GMT:
      return update(state, {
        schedule: {
          gmt: { $set: action.payload },
        },
      });
    default:
      return state;
  }
};

export default myScheduleReducer;
