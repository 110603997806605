import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import DateRangeIcon from '@material-ui/icons/DateRange';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import _ from 'lodash';
import {
  Chip,
  makeStyles,
  Popover,
  Tooltip,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { PeopleOutlineOutlined } from '@material-ui/icons';
import moment from 'moment';
import { setAssigneeFilter, setFilterStateDate } from 'store/actions';
import { DepartmentsList } from '../../ConversationDetails/components/ConversationToolbar/components';
import TrackingTimeItem from './TrackingTimeItem';

const useStyles = makeStyles({
  employee: {
    backgroundColor: '#e7c1ff6e',
    height: 25,
    cursor: 'pointer',
  },
  department: {
    backgroundColor: '#8df77aab',
    height: 25,
    cursor: 'pointer',
  },
  me: {
    backgroundColor: '#ff9494c7',
    cursor: 'pointer',
    height: 25,
  },
});

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex: 2;
  align-items: center;
  height: 63px;
  padding-left: 18px;
`;

const StyledFilterItem = styled.div`
  margin-right: 18px;
  background-color: #F3F4F5;
  padding: 4px;
  padding-left: 9px;
  height: 40px;
  border-radius: 8px;
  color: #727887;
  position: relative;
  display: flex;
  align-items: center;
  gap: 6px;
  .date-filter {
    height: 30px;
    display: flex;
    align-items: center;
    gap: 6px;
    input {
      padding: 5px 8px 7px 8px;
      border-radius: 6px;
      width: 80px;
      cursor: pointer;
      margin-left: 0px;
      background-color: #E6E7E8;
      color: #26282d;
      border: none;
      font-size: 14px;
      outline: none !important;
      ::placeholder,
      ::-webkit-input-placeholder {
        color: #26282d;
      }
      :-ms-input-placeholder {
        color: #26282d;
      }
      &:hover {
        background-color: #DDDFE3;
      }
    }
    .react-datepicker-popper {
      transform: translate3d(-31px, 30px, 0px) !important;
      box-shadow: 0 18px 42px 0 rgba(114, 120, 135, 0.1),
        0 4px 16px 0 rgba(0, 0, 0, 0.15);
    }
    .react-datepicker-popper[data-placement^='bottom']
      .react-datepicker__triangle,
    .react-datepicker-popper[data-placement^='bottom']
      .react-datepicker__triangle::before {
      border-bottom-color: #fff;
    }
    .react-datepicker__navigation--previous {
      border-right-color: #484c56;
    }
    .react-datepicker__navigation--next {
      border-left-color: #484c56;
    }
    .react-datepicker__header {
      background-color: #fff;
      border-bottom: none;
    }
    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      font-size: 15px;
      font-weight: 500;
      color: #484c56;
    }
    .react-datepicker__day-names {
      background-color: #f6f8fc;
      margin-top: 10px;
    }
    .react-datepicker__day-name {
      color: #7d8392;
      text-transform: uppercase;
      font-size: 12px;
    }
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--selected {
      border-radius: 50%;
      background-color: #006ae4 !important;
    }
    .react-datepicker__day:hover {
      border-radius: 50%;
    }
    .react-datepicker {
      border: none;
    }
  }
  .clear-icon {
    background-color: #E6E7E8;
    height: 30px;
    width: 30px;
    padding: 5px;
    border-radius: 6px;
    cursor: pointer;
    &:hover {
      background-color: #DDDFE3;
    }
  }
  .by-assign-button {
    height: 30px;
    border-radius: 6px;
    &:hover {
      background-color: #DDDFE3;
    }
  }
`;

const DateTimeFilterItem = () => {
  const dispatch = useDispatch();
  const dateFilter = useSelector((state) => state.settings.dateFilter);
  const [dateRange, setDateRange] = useState({
    from: dateFilter.from ? new Date(dateFilter.from) : null,
    to: dateFilter.to ? new Date(dateFilter.to) : null,
  });
  const { t, i18n } = useTranslation();

  const onChange = (date, type) => {
    if (type === 'start') {
      setDateRange((prevDate) => ({
        ...prevDate,
        from: date,
      }));
    }
    if (type === 'end') {
      setDateRange((prevDate) => ({
        ...prevDate,
        to: date,
      }));
    }
  };

  useEffect(() => {
    dispatch(
      setFilterStateDate({
        from: dateRange.from ? moment(dateRange.from).toISOString() : null,
        to: dateRange.to ? moment(dateRange.to).toISOString() : null
      }),
    );
  }, [dateRange]);

  const handleClear = () => {
    setDateRange({
      from: null,
      to: null,
    });
    dispatch(setFilterStateDate({
      from: null,
      to: null,
    }));
  };
  return (
    <StyledFilterItem className="date-filter">
      <div className="date-filter">
        <DateRangeIcon color="inherit" fontSize="small" />
        <DatePicker
          className="date-filter"
          locale={i18n.language}
          placeholderText={t('searchPanel.dateFrom')}
          dateFormat="dd.MM.yyyy"
          maxDate={new Date()}
          selected={dateRange.from}
          startDate={dateRange.from}
          endDate={dateRange.to}
          selectsStart
          onChange={(date) => onChange(date, 'start')}
        />
        <p>-</p>
        <DatePicker
          className="date-filter"
          locale={i18n.language}
          placeholderText={t('searchPanel.dateTo')}
          dateFormat="dd.MM.yyyy"
          minDate={dateRange.from}
          maxDate={new Date()}
          selected={dateRange.to}
          startDate={dateRange.from}
          endDate={dateRange.to}
          selectsEnd
          onChange={(date) => onChange(date, 'end')}
        />
        <Tooltip
          className="clear-icon"
          title={t('common.clear')}>
          <HighlightOffIcon
            onClick={handleClear}
            color="inherit"
            fontSize="small"
          />
        </Tooltip>
      </div>
    </StyledFilterItem>
  );
};

const DepartmentFilterItem = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [anchorTransferMenu, setAnchorTransferMenu] = useState(null);

  const transferOpen = Boolean(anchorTransferMenu);
  const transferId = transferOpen ? 'transfer-popover' : undefined;

  const handleTransfer = (event) => {
    setAnchorTransferMenu(event.currentTarget);
  };

  const handleTransferClose = () => {
    setAnchorTransferMenu(null);
  };

  const handleClear = () => {
    dispatch(setAssigneeFilter({
      assignee: null,
      type: 'all',
    }));
  };
  // eslint-disable-next-line no-unused-vars
  const handleDepartmentAssigneeClick = (uuidAssignee, assigneeType) => {
    dispatch(
      setAssigneeFilter({
        assignee: uuidAssignee,
        type: assigneeType === 'personal' ? 'employee' : 'department',
      }),
    );
    handleTransferClose();
  };
  const departments = useSelector((state) => state.session.departments);
  const selectedCompany = useSelector((state) => state.session.selectedCompany);
  const user = useSelector((state) => state.session.user);
  const assigneeFilter = useSelector((state) => state.settings.assigneeFilter);
  const styles = useStyles();

  const getAssigneeName = (assignee) => {
    if (assignee) {
      const assigneeDepartments = Object.values(
        _.get(departments, selectedCompany, {}),
      );

      if (assignee.type === 'employee') {
        if (assignee.assignee === user.uuid) {
          return user.name;
        }

        const usersInCompany = assigneeDepartments
          .map(({ users }) => users)
          .flat();

        const finded = usersInCompany.find(
          ({ uuid }) => uuid === assignee.assignee,
        );
        if (!finded) return '';
        return `${finded.surname} ${finded.name}`;
      }

      if (assignee.type === 'department') {
        return _.get(
          departments,
          `${selectedCompany}.${assignee.assignee}.name`,
          '',
        );
      }
    }

    return t('searchPanel.byAssign');
  };

  return (
    <StyledFilterItem>
      <PeopleOutlineOutlined />
      <Chip
        classes={{
          root: styles[assigneeFilter.type],
        }}
        className="by-assign-button"
        label={`${getAssigneeName(assigneeFilter)}`}
        onClick={handleTransfer}
      />
      <Popover
        id={transferId}
        open={transferOpen}
        anchorEl={anchorTransferMenu}
        onClose={handleTransferClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <DepartmentsList
          departments={departments}
          selectedCompany={selectedCompany}
          captionsRoot='searchPanel.toolbar'
          handleDepartmentAssigneeClick={handleDepartmentAssigneeClick}
        />
      </Popover>
      <Tooltip title={t('common.clear')}>
        <HighlightOffIcon
          className="clear-icon"
          color="inherit"
          fontSize="small"
          onClick={handleClear} />
      </Tooltip>
    </StyledFilterItem>
  );
};

function SearchPanelFilters() {
  return (
    <Wrapper>
      <DateTimeFilterItem />
      <DepartmentFilterItem />
      <TrackingTimeItem />
    </Wrapper>
  );
}

export default SearchPanelFilters;
