import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';
import {
  clearWorkingTime,
  finishWorkingTime,
  pauseWorkingTime,
  sendWorkingTime,
} from 'store/actions';
import { differenceInSeconds } from 'date-fns';

import ModalWrapper, { ActionButton } from './layout';

const Wrapper = styled.div`
  .line {
    margin-top: 25px;
    label {
      display: block;
      margin-bottom: 10px;
      color: #7d8392;
    }
  }
  .modal-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 25px;
  }
  button {
    flex: 1;
  }
  .font {
    font-weight: 700;
  }
  .underline {
    text-transform: none;
    text-decoration: underline;
  }
`;

const FinishTrackerDialog = (props) => {
  const dispatch = useDispatch();

  const { onClose } = props;
  const workTime = useSelector((state) => state.workTime);
  const user = useSelector((state) => state.session.user);
  const session = useSelector((state) => state.session);
  const providers = useSelector((state) => state.chat.companyProviders);
  const calculateTimeDifference = (start, end) => {
    const startTime = new Date(start);
    let endTime;
    if (end) {
      endTime = new Date(end);
    } else {
      endTime = new Date();
    }
    return differenceInSeconds(endTime, startTime);
  };
  const getWorkingTime = () => {
    const totalSeconds = workTime?.hours?.reduce((acc, workItem) => {
      const diffInSec = calculateTimeDifference(
        workItem.work?.start,
        workItem.work?.end,
      );
      return acc + diffInSec;
    }, 0);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    return `${hours > 0 ? `${hours} ч,` : ''} ${
      minutes > 0 ? `${minutes} мин` : ''
    }`;
  };

  const handleConfirm = () => {
    dispatch(finishWorkingTime());
    dispatch(
      sendWorkingTime(
        user?.uuid,
        session?.selectedCompany,
        user?.name,
        providers,
      ),
    );
    dispatch(clearWorkingTime());
    onClose();
  };

  const handleCancel = () => {
    dispatch(pauseWorkingTime());
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <ModalWrapper
      modalStyle={{
        width: '380px',
        paddingRight: '30px',
      }}
      noButtonBg
      onClose={handleClose}
      title="Закончить рабочий день?"
    >
      <Wrapper>
        <div className="line">
          <Typography variant="subtitle1">
            Сегодня вы работали: {getWorkingTime()}
          </Typography>
        </div>
        <div className="modal-buttons">
          <ActionButton
            className="font"
            bgColor="#FF5F5F"
            onClick={handleConfirm}
            type="button"
            disabled={false}
          >
            Закончить рабочий день
          </ActionButton>
          <Button onClick={handleCancel}>
            <Typography className="underline">Поставить на паузу</Typography>
          </Button>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
};

FinishTrackerDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default FinishTrackerDialog;
