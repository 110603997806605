import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ProfileNav = () => {
  const { t } = useTranslation();
  const links = [
    {
      link: '../stat/general',
      text: t('profileNav.link1'),
    },
    // {
    //   link: '../stat/operators',
    //   text: t('profileNav.link2'),
    // },
    // {
    //   link: '../stat/providers',
    //   text: t('profileNav.link3'),
    // },
    // {
    //   link: '../stat/quality',
    //   text: t('profileNav.link4'),
    // },
    {
      link: '../stat/tags',
      text: t('profileNav.link5'),
    },
    {
      link: '../stat/worktime',
      text: t('profileNav.link6'),
    },
  ];

  return (
    <StyledProfileNav>
      <TitleText>{t('profileNav.title')}</TitleText>
      <StyledNavbar>
        <ul>
          {links.map((link) => (
            <li key={link.link}>
              <NavLink
                className={({ isActive }) => (isActive ? 'active' : '')}
                to={link.link}
              >
                {link.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </StyledNavbar>
    </StyledProfileNav>
  );
};

const StyledProfileNav = styled.div`
  background: #ecf1f7;
  width: 100%;
  padding: 32px 0 24px 40px;
`;

const TitleText = styled.h3`
  letter-spacing: -0.02em;
  color: #000000;
  font-weight: 400;
  font-size: 34px;
  line-height: 47px;
  margin-bottom: 18px;
`;

const StyledNavbar = styled.nav`
  background: #ffffff;
  border-radius: 10px;
  display: inline-block;
  padding: 8px;
  ul {
    display: flex;
    flex-wrap: wrap;
    list-style-type: none;
    gap: 8px;
    li {
      a {
        padding: 5px 14px 7px 14px;
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #222f44;
        text-decoration: none;
      }
      a.active {
        background: #006ae4;
        border-radius: 6px;
        color: white;
      }
    }
  }
`;

export default ProfileNav;
