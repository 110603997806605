import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';


export default function Confirm ({
  data,
  handleConfirm,
  handleClose,
}) {
  const {title, subtitle, input} = data;
  const [text, setText] = useState('');
  const handleChange = (event) => {
    if (input) {
      setText(event.target.value);
    }
  }

  return (
    <View>
      <div className="modal">
        <div className="header">
          <div className="title">{title}</div>
          {subtitle && <div className="subtitle">{subtitle}</div>}
          {input && <input onChange={handleChange} placeholder={input.text}/>}
        </div>
        <div className="footer">
          <div className="button confirm" onClick={() => {
            handleConfirm(text);
            handleClose();
          }}>
            Ok
          </div>
          <div className="button cancel" onClick={() => handleClose()}>
            Cancel
          </div>
        </div>
      </div>
    </View>
  );
}

Confirm.propTypes = {
  data: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
};

const View = styled.div`
  top: 0;
  left: 0;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  background-color: #403f3f3b;
  input {
    margin-top: 18px;
    font-size: 16px;
    outline: none;
    padding: 8px 12px;
    min-width: 350px;
    border: 1px solid #b0b3b7;
    gap: 5px;
    border-radius: 13px;
    width: 220px;
    &:focus {
      border-color: #202020;
    }
  }
  .modal {
    background-color: #fff;
    padding: 12px;
    border-radius: 4px;
  }
  .subtitle {
    padding-top: 8px;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #454545;
  }
  .footer {
    display: flex;
    padding-top: 32px;
    gap: 8px;
  }
  .button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 18px 14px;
    gap: 5px;
    border-radius: 13px;
    use-select: none;
    cursor: pointer;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
  }
  .confirm {
    background: #F23131;
    color: #FFFFFF;
  }
  .cancel {
    background: #E6E7E8;
    color: #202020;
  }
  .title {
    font-weight: 600;
    font-family: 'Helvetica';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #202020;
  }
`;