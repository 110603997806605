const textTransform = (data) => data

  .replaceAll('\*', '*')
  .replaceAll('\-', '-')
  .replaceAll('\<', '<')
  .replaceAll('\>', '>')
//   .replaceAll('\~', '~')
  .replaceAll('\\', '');
const textSendTransform = (data) => data
  .replace('#', '\\#')
  .replaceAll('<', '\\<')
  .replaceAll('>', '\\>')
  .replaceAll('-', '\\-')
//   .replaceAll('~', '\\~')
  .replaceAll('*', '\*')
  .replace('.', '\\.');

export default { textTransform, textSendTransform };
