/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import EditIcon from '@material-ui/icons/Edit';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

import {
  Checkbox,
  Chip,
  TableRow,
  TableHead,
  Grid,
  Typography,
  IconButton,
  Tooltip,
  TablePagination,
} from '@material-ui/core';
import styled from 'styled-components';
import moment from 'moment';
import {
  CheckCircle as CheckBoxIcon,
  RadioButtonUncheckedOutlined as RadioButtonUncheckedOutlinedIcon,
  Schedule,
} from '@material-ui/icons';
import _ from 'lodash';
import PropTypes from 'prop-types';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { TextField } from 'components/Fields';
import { DropdownMenu, Input } from 'components';
import { getChatDialog, setCurrentDialog } from 'store/actions';
import { DEFAULT_DIALOG_MESSAGES_PAGE_SIZE } from 'config';


const useStyles = makeStyles({
  employee: {
    marginRight: '5px',
    backgroundColor: '#e7c1ff6e',

    cursor: 'pointer',
  },
  department: {
    marginRight: '5px',
    backgroundColor: '#8df77aab',

    cursor: 'pointer',
  },
  me: {
    marginRight: '5px',
    backgroundColor: '#ff9494c7',
    cursor: 'pointer',
  },
});

const TableWrapper = styled(Table)`
`;

const TaskHeaderWrapper = styled.div`
  padding: 10px;
  a{
    display: flex;
    align-items: center;
  }
  .form-input {
    margin-top: 25px;
    input {
      width: 100%;
    }
    label {
      display: block;
      margin-bottom: 10px;
      color: #7d8392;
    }
    .react-select__control {
      border-radius: 3px;
      border: solid 1px #eeeff2;
      background-color: #f6f8fc;
      color: #3f434c;
    }
  }
`;

const PaginationTable = styled(TablePagination)`
  .MuiToolbar-root {
    padding-left: 16px !important;
    min-height: 42px !important;
    max-height: 42px !important;
  }
  .MuiTablePagination-root{
    flex-direction: row-reverse !important;
    display: flex;
    background-color: #000 !important;
  }
  .MuiTablePagination-toolbar{
    display: flex;
    flex: 1;
  }
  .MuiIconButton-root {
    padding: 8px;
  }
  .MuiTypography-body2 {
    font-size: 14px;
  }
  border-bottom: none !important;
`;

export default function TaskTable({
  tasks,
  taskTotalCount,
  currentPage,
  onChangePage,
  onChangePerPage,
  onSelectTask,
  getAssigneeName,
  departments,
  onDeleteTask,
  onUpdateStatus,
  getAssigneeDepartment,
  user,
}) {
  const [tasksPerPage, setTasksPerPage] = useState(10);
  const history = useHistory();
  const { search } = useLocation();
  const dispatch = useDispatch();
  const [filterParam, setFilter] = React.useState({
    department: null,
    assigneeFilter: null,
    status: null,
    dateFilter: null,
  });
  const classes = useStyles();
  const { t } = useTranslation();
  const departmentOptions = React.useMemo(
    () => departments.map((x) => ({ label: x.name, value: x })),
    [],
  );
  const departmentUsers = React.useMemo(
    () => [
      { label: t('taskPage.filters.my'), value: user },

      ...departments
        .map((x) => x.users)
        .flat()
        .map((x) => ({ label: `${x.surname} ${x.name}`, value: x })),
    ],
    [],
  );
  const getTasks = () => {
    const needTasks = tasks
      .filter((x) => {
        const conditionStatus = filterParam.status ? filterParam.status.value === x.status : true;
        const conditionDepartment = filterParam.department ? getAssigneeDepartment(x.assignee)
          === filterParam.department.label : true;
        const conditionDate = filterParam.dateFilter ? moment(filterParam.dateFilter).unix() < x.date.created : true;
        const conditionAssignee = filterParam.assigneeFilter && x.assignee ? x.assignee.uuid === filterParam.assigneeFilter.value.uuid : true;
        return conditionStatus && conditionDepartment && conditionAssignee && conditionDate;
      });
    const allTasks = needTasks
      .map((item) => ({ ...item, due: item.date.due && item.date.created >= item.date.due }))
      .sort((first, second) => (first.due === second.due) ? 0 : first.due ? -1 : 1)
    return allTasks;
  };
  const handleReturnDialog = (row) => {
    const dialogUuid = _.get(row, 'ids.uuidDialog');
    dispatch(setCurrentDialog(dialogUuid));
    getChatDialog({
      uuidDialog: dialogUuid,
      sort: 'desc',
      historySize: DEFAULT_DIALOG_MESSAGES_PAGE_SIZE,
    })(dispatch);
    history.push(`/chat/${dialogUuid}${search}`);
  }
  const handleChangeTasksPerPage = (newTasksPerPage) => {
    setTasksPerPage(newTasksPerPage);
    onChangePerPage(newTasksPerPage);
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={12} style={{ paddingBottom: '0px' }}>
        <TaskHeaderWrapper>
          <Grid container alignItems="flex-end" spacing={4}>
            <Grid item>
              <Typography variant="h4">{t('taskPage.title')}</Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} alignItems="flex-end">
            <Grid item xs={2}>
              <div className="form-input">
                <label htmlFor="provider">{t('taskPage.statusFilter')}</label>
                <Select
                  classNamePrefix="react-select"
                  id="provider"
                  options={[{ label: t('taskPage.statuses.atWork'), value: 'todo' }, {
                    label: t('taskPage.statuses.completed'),
                    value: 'done',
                  }]}
                  value={filterParam.status}
                  onChange={(p) => {
                    setFilter({ ...filterParam, status: p });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="form-input">
                <label htmlFor="provider">{t('taskPage.departmentFilter')}</label>
                <Select
                  classNamePrefix="react-select"
                  id="provider"
                  options={departmentOptions}
                  value={filterParam.department}
                  onChange={(p) => {
                    setFilter({ ...filterParam, department: p });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="form-input">
                <label htmlFor="provider">{t('taskPage.assigneeFilter')}</label>
                <Select
                  classNamePrefix="react-select"
                  id="provider"
                  options={departmentUsers}
                  value={filterParam.assigneeFilter}
                  onChange={(p) => {
                    setFilter({ ...filterParam, assigneeFilter: p });
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <div className="form-input">
                <label htmlFor="provider">{t('taskPage.dateFilter')}</label>
                <Input
                  classNamePrefix="react-select"
                  id="provider"
                  type="date"
                  value={filterParam.dateFilter}
                  onChange={(p) => {
                    setFilter({ ...filterParam, dateFilter: p.target.value });
                  }}
                />
              </div>
            </Grid>
            <Grid item>
              <br />
              <br />
              <br />
              <Tooltip title={t('taskPage.resetFilters')}>
                <IconButton onClick={() => {
                  setFilter({ department: null, assigneeFilter: null, status: null });
                }}
                >
                  <RotateLeftIcon fontSize="small" />
                </IconButton>
              </Tooltip>

            </Grid>
          </Grid>
        </TaskHeaderWrapper>
        <Grid item>
          <PaginationTable
            classes="table-pagination"
            nextIconButtonText={t('taskPage.nextPage')}
            backIconButtonText={t('taskPage.prevPage')}
            labelDisplayedRows={({ from, to, count }) => `${from}-${to} / ${count}`}
            onRowsPerPageChange={({ target: { value } }) => handleChangeTasksPerPage(value)}
            page={currentPage}
            count={taskTotalCount}
            rowsPerPage={tasksPerPage}
            labelRowsPerPage={t('taskPage.tasksPerPage')}
            onPageChange={onChangePage} />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableContainer>
          <TableWrapper aria-label="a dense table" size="small">
            <TableHead>
              <TableRow>
                <TableCell align="center">
                  <Tooltip title={t('taskPage.actions.addTask')}>
                    <IconButton
                      size="small"
                      onClick={() => {
                        onSelectTask({
                          title: t('taskPage.newTask'),
                          date: {
                            created: moment().unix(),
                          },
                        });
                      }}
                    >
                      <AddCircleOutlineIcon fontSize="default" />
                    </IconButton>
                  </Tooltip>

                </TableCell>
                <TableCell>{t('taskPage.header.title')}</TableCell>
                <TableCell>{t('taskPage.header.description')}</TableCell>
                <TableCell>{t('taskPage.header.assigneeDepartment')}</TableCell>
                <TableCell>{t('taskPage.header.assignee')}</TableCell>
                <TableCell>{t('taskPage.header.date')}</TableCell>
                <TableCell>{t('taskPage.header.deadline')}</TableCell>
                <TableCell>{t('taskPage.header.action')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {getTasks().map((row) => (
                <TableRow key={row.uuid}>
                  <TableCell component="th" scope="row" align="center">
                    <Checkbox
                      onClick={() => {
                        onUpdateStatus(row, row.status);
                      }}
                      checked={row.status === 'done'}
                      icon={
                        <RadioButtonUncheckedOutlinedIcon fontSize="default" />
                      }
                      checkedIcon={<CheckBoxIcon fontSize="default" />}
                      name="checkedI"
                    />
                  </TableCell>
                  <TableCell onClick={() => onSelectTask(row)}>{row.title}</TableCell>
                  <TableCell onClick={() => onSelectTask(row)}>{row.description}</TableCell>
                  <TableCell onClick={() => onSelectTask(row)}>
                    {getAssigneeDepartment(row.assignee) && (
                      <Chip
                        classes={{
                          root: classes.department,
                        }}
                        label={`${getAssigneeDepartment(row.assignee)}`}
                      />
                    )}
                  </TableCell>
                  <TableCell onClick={() => onSelectTask(row)}>
                    {row.assignee && (
                      <Chip
                        classes={{
                          root: classes[
                            row.assignee
                              ? user.uuid === row.assignee.uuid
                                ? 'me'
                                : row.assignee.type
                              : 'me'
                          ],
                        }}
                        label={`${getAssigneeName(row.assignee)}`}
                      />
                    )}
                  </TableCell>
                  <TableCell onClick={() => onSelectTask(row)}>
                    {moment
                      .unix(_.get(row, 'date.created', moment().unix()))
                      .format('DD.MM.YY HH:MM')}
                  </TableCell>
                  <TableCell onClick={() => onSelectTask(row)}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px', color: row.due && '#e53935' }}>
                      {_.get(row, 'date.due') ? moment
                        .unix(_.get(row, 'date.due'))
                        .format('DD.MM.YY HH:MM') : ''}
                      {row.due &&
                        <Tooltip title={t('task.due')}>
                          <Schedule color="error" />
                        </Tooltip>}
                    </div>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={t('taskPage.actions.delete')}>
                      <IconButton
                        onClick={() => {
                          onDeleteTask(row);
                        }}
                      >
                        <DeleteForeverIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={t('taskPage.actions.edit')}>
                      <IconButton
                        onClick={() => {
                          onSelectTask(row);
                        }}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                    </Tooltip>

                    {(row.ids.uuidDialog !== null && row.ids.uuidDialog !== undefined) &&
                      (<Tooltip title={t('taskPage.actions.followToDialog')}>
                        <IconButton onClick={() => {
                          handleReturnDialog(row);
                        }}
                        >
                          <KeyboardReturnIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>)}

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </TableWrapper>
        </TableContainer>
      </Grid >
    </Grid >
  );
}
TaskTable.propTypes = {
  tasks: PropTypes.array,
  taskTotalCount: PropTypes.number,
  currentPage: PropTypes.number.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePerPage: PropTypes.func.isRequired,
  getAssigneeName: PropTypes.func.isRequired,
  onSelectTask: PropTypes.func.isRequired,
  onUpdateStatus: PropTypes.func.isRequired,
  onDeleteTask: PropTypes.func.isRequired,
  departments: PropTypes.array.isRequired,
  getAssigneeDepartment: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};
TaskTable.defaultProps = {
  tasks: [],
  taskTotalCount: 0
};