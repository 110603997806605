export const HANDLE_OPEN_RIGHT_PANEL = 'HANDLE_OPEN_RIGHT_PANEL';
export const HANDLE_HIDE_RIGHT_PANEL = 'HANDLE_HIDE_RIGHT_PANEL';
export const HANDLE_CLOSE_RIGHT_PANEL = 'HANDLE_CLOSE_RIGHT_PANEL';

export const handleOpenRightPanel = (payload) => ({
  type: HANDLE_OPEN_RIGHT_PANEL,
  payload,
});

export const handleHideRightPanel = () => ({
  type: HANDLE_HIDE_RIGHT_PANEL,
});

export const handleCloseRightPanel = () => ({
  type: HANDLE_CLOSE_RIGHT_PANEL,
});