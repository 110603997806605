/**
 * All files type
 */
export const FILE_TYPES = [
  '3ds',
  'aac',
  'ai',
  'avi',
  'bmp',
  'cad',
  'cdr',
  'css',
  'dat',
  'dll',
  'dmg',
  'doc',
  'eps',
  'fla',
  'flv',
  'gif',
  'html',
  'indd',
  'iso',
  'jpg',
  'js',
  'midi',
  'mov',
  'mp3',
  'mpg',
  'pdf',
  'php',
  'png',
  'ppt',
  'ps',
  'psd',
  'raw',
  'sql',
  'svg',
  'tif',
  'txt',
  'wmv',
  'xls',
];
/**
 * File image types to file
 */
export const FILE_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'bmp'];

/**
 * get file type from name
 * ex: data.png => png
 * @param {string} fileName
 * @returns {string} file type
 */
export const getFileType = (fileName) => fileName.split('.').reverse()[0];

/**
 * get file type enum
 * @param {string} filename name of file
 * @returns {'img'|'file'} file enum
 */
export const getFileTypeEnum = (filename) => {
  const fileType = getFileType(filename);
  if (FILE_IMAGE_TYPES.includes(fileType)) {
    return 'image';
  }
  return 'file';
};

/**
 * @typedef FileType
 * @property {Blob} src
 * @property {string} name
 *
 */
