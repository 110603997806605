import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import SearchIcon from '@material-ui/icons/Search';
//  import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Autocomplete } from '@material-ui/lab';
import { InputAdornment, TextField } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { setSearchedSubstring } from 'store/actions';

/*
const getDateRangeText = (dateRange) => {
  let text = '49,3% за период ';
  if (dateRange.from && dateRange.to) {
    text += `${moment(dateRange.from).format('DD.MM.YYYY')} - ${moment(dateRange.to).format('DD.MM.YYYY')}`;
  } else if (dateRange.to) {
    text += `до ${moment(dateRange.to).format('DD.MM.YYYY')}`;
  } else if (dateRange.from) {
    text += `от ${moment(dateRange.from).format('DD.MM.YYYY')}`;
  } else {
    return '';
  }
  return text;
};
*/

export default function SearchBar({ data }) {
  const { t } = useTranslation()
  const dispatch = useDispatch();
  const handleSearch = (_, value) => {
    dispatch(setSearchedSubstring(value));
  }

  return (
    <View>
      <Desc>
        <div className="common">
          <div className="main">{data.total ? Number((100 * data.count / data.total).toFixed(1)) : 0}% {t('tagStat.markedDialogs')}</div>
          <div className='sub'>{data.count} {t('common.of')} {data.total} {t('tagStat.dialogs')}</div>
        </div>
      </Desc>
      <Search>
        <Autocomplete
          clearText=''
          freeSolo
          onInputChange={handleSearch}
          className='input'
          options={data.rows}
          getOptionLabel={({ title }) => title}
          renderInput={(params) =>
            <TextField {...params} placeholder={t('tagStat.searchByTags')} InputProps={{
              ...params.InputProps,
              startAdornment: (<InputAdornment position="start"> <SearchIcon className='search-icon' />
              </InputAdornment>),
              disableUnderline: true
            }} />} />
      </Search>
    </View>
  );
}

SearchBar.propTypes = {
  data: PropTypes.array.isRequired,
  dateRange: PropTypes.object.isRequired
};

const View = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
`;

const Search = styled.div`
  width: 225px;
  display: flex;
  gap: 8px;
  align-items: center;
  padding: 8px;
  height: 35px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  .input {
    width: 100%;
    outline: none;
    border: none;
    line-height: 19px;
    font-size: 14px;
  }
  .search-icon {
    color: #C4C4C5;
  }
  .MuiAutocomplete-popupIndicator {
    display: none;
  }
`;

const Desc = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  .common {
    display: flex;
    gap: 12px;
  }
  .main {
    line-height: 25px;
    font-size: 18px;
    font-weight: bold;
  }
  .sub {
    color: rgba(0, 0, 0, 0.5);
    line-height: 17px;
    font-size: 12px;
  }
  .other {
    color: rgba(0, 0, 0, 0.87);
    line-height: 19px;
    font-size: 14px;
  }
`;