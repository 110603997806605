import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';
import {Tooltip} from '@material-ui/core';


export default function TagTable({ data }) {
  const { t } = useTranslation();
  const searchedSubstring = useSelector((state) => state.stat.searchedSubstring);
  const [tableData, setTableData] = useState(data);
  const [sort, setSort] = useState(null);

  const sorting = () => {
    let rows = [];
    if (sort === 1) {
      rows = data.rows.sort((b, a) => a.count - b.count);
    }
    else if (sort === 0) {
      rows = data.rows.sort((a, b) => a.count - b.count);
    }
    else {
      return;
    }
    setTableData({ ...tableData, rows });
  }

  useEffect(() => {
    setTableData(data);
    sorting();
  }, [JSON.stringify(data)]);

  useEffect(() => {
    sorting();
  }, [sort]);
  return (
    <View>
      <Table>
        <HeaderCellText>{t('tagStat.tag')}</HeaderCellText>
        <HeaderCell>
          {t('tagStat.dialogsCount')}
          <div
            className='sort_icon'
            onClick={() => setSort(!sort ? 1 : 0)} >
            {sort ? <ArrowDropDown className='sort_icon' /> : <ArrowDropUp className='sort_icon' />}
          </div>
        </HeaderCell>
        <HeaderCell>% {t('tagStat.dialogsPercent')}</HeaderCell>
        {tableData.rows.filter(({ title }) => title.includes(searchedSubstring)).map(({ title, count }) => <>
          <Tooltip title={title}>
            <TextCell >{title}</TextCell>
          </Tooltip>
          <ValueCell>{count}</ValueCell>
          <ValueCell>{Number(((count / data.count) *100).toFixed(1))}</ValueCell>
        </>)}
      </Table>
    </View>
  );
}

TagTable.propTypes = {
  data: PropTypes.array.isRequired,
};


const View = styled.div`
  overflow-y: auto;
  overflow-x: hidden;
  max-height: calc(100vh - 350px);
`;

const Table = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const HeaderCell = styled.div`
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 12px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.5);
  padding: 12px 0px;
  position: sticky;
  top: 0;
  background-color: #fff;
  display: flex;
  align-items: center;
  .sort_icon {
    height: 17px;
    cursor: pointer;
  }
  justify-content: center;
`;

const HeaderCellText = styled(HeaderCell)`
  justify-content: start;
`;

const Cell = styled.div`
  font-size: 14px;
  line-height: 19px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  padding: 11px 0px;
`;

const TextCell = styled(Cell)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ValueCell = styled(Cell)`
  text-align: center;
`;