import React, { useEffect, useState } from 'react';
import AppAccordion from '../../../../components/Accordion/AppAccordion';
import { useDispatch, useSelector } from 'react-redux';
import HorizontalDivider from '../../../../layouts/Modal/Settings/components/HorizontalDivider';
import { ReactComponent as RoutingIcon } from '../../../../assets/img/routing.svg';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UsersIcon } from '../../../../assets/img/users.svg';
import { ReactComponent as UserIcon } from '../../../../assets/img/user.svg';
import styled from 'styled-components';
import AppButton from '../../../../components/AppButton/AppButton';
import { changeAccessToRoutingSettings } from '../../../../store/actions';
import AppCheckbox from '../../../../components/Checkbox/AppCheckbox';
import { PERMISSIONS } from '../../index';

const RoutingAccesses = () => {
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();

  const { company, accesses, departments, groupedUsersByDepartment } =
    useSelector((state) => state.settings.accessesToSettings);
  const { users } = company;

  const [currentUsers, setCurrentUsers] = useState([]);
  const readPermissionId = accesses.find(
    (access) => access.name === PERMISSIONS.ROUTING.READ,
  ).uuid;
  const writePermissionId = accesses.find(
    (access) => access.name === PERMISSIONS.ROUTING.WRITE,
  ).uuid;

  const updateUserPermissions = (userId, permissionId) => {
    const updatedUsers = structuredClone(currentUsers);
    const userIndex = updatedUsers.findIndex(
      (user) => user.people_id === userId,
    );

    if (userIndex !== -1) {
      const user = updatedUsers[userIndex];

      const existingPermissionIndex = user.permissions.indexOf(permissionId);

      // удаление
      if (existingPermissionIndex !== -1) {
        if (permissionId === readPermissionId) {
          updatedUsers[userIndex].permissions = [];
        } else {
          updatedUsers[userIndex].permissions.splice(
            existingPermissionIndex,
            1,
          );
        }
      } else {
        // добавление
        if (permissionId === writePermissionId) {
          updatedUsers[userIndex].permissions = [
            writePermissionId,
            readPermissionId,
          ];
        } else {
          updatedUsers[userIndex].permissions.push(permissionId);
        }
      }

      setCurrentUsers(updatedUsers);
    }
  };

  const isActivePermissions = (userId, permissionId) => {
    if (currentUsers.length === 0) {
      return null;
    }

    return currentUsers
      ?.find((user) => user.people_id === userId)
      .permissions.includes(permissionId);
  };

  const handleChangesSend = () => {
    dispatch(changeAccessToRoutingSettings({ users: [...currentUsers] }));
  };

  useEffect(() => {
    setCurrentUsers(
      users.map((user) => ({
        people_id: user.people_id,
        permissions: user.permissions,
      })),
    );
  }, [users]);

  return (
    <AppAccordion
      titleJSX={
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '8px',
            padding: '4px 0',
          }}
        >
          <p style={{ fontSize: '14px', lineHeight: '24px' }}>
            {translate('settingsDrawer.chatCenter')} /
          </p>
          <RoutingIcon style={{ fill: '#18191B' }} />
          <p style={{ fontSize: '14px', lineHeight: '24px' }}>
            {translate('settingsDrawer.routing')}
          </p>
        </div>
      }
      style={{ padding: 0 }}
    >
      <div style={{ padding: '24px' }}>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: '1fr minmax(60px, 120px) minmax(60px, 120px)',
            marginBottom: '12px',
          }}
        >
          <p style={{ fontSize: '12px', lineHeight: '16px' }}>Список</p>
          <p style={{ fontSize: '12px', lineHeight: '16px' }}>Чтение</p>
          <p style={{ fontSize: '12px', lineHeight: '16px' }}>Редактирование</p>
        </div>
        <HorizontalDivider style={{ marginBottom: '8px' }} />

        {departments?.map((department, index) => (
          <div key={department + index}>
            <AppAccordion
              titleJSX={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '4px',
                  }}
                >
                  <UsersIcon style={{ fill: '#006AE4' }} />
                  <p style={{ fontSize: '14px', lineHeight: '20px' }}>
                    {department}
                  </p>
                </div>
              }
              style={{ backgroundColor: 'inherit' }}
            >
              {groupedUsersByDepartment[department].map((user) => (
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns:
                      '1fr minmax(60px, 120px) minmax(60px, 120px)',
                    marginLeft: 20,
                    marginBottom: 8,
                  }}
                  key={user.people_name}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      columnGap: '4px',
                      paddingLeft: '24px',
                    }}
                  >
                    <UserIcon style={{ fill: '#006AE4' }} />
                    <p style={{ fontSize: '14px', lineHeight: '20px' }}>
                      {user.people_name}
                    </p>
                  </div>

                  <AppCheckbox
                    checked={isActivePermissions(
                      user.people_id,
                      readPermissionId,
                    )}
                    onChange={() =>
                      updateUserPermissions(user.people_id, readPermissionId)
                    }
                  />

                  <AppCheckbox
                    checked={isActivePermissions(
                      user.people_id,
                      writePermissionId,
                    )}
                    onChange={() =>
                      updateUserPermissions(user.people_id, writePermissionId)
                    }
                  />
                </div>
              ))}
            </AppAccordion>
          </div>
        ))}

        <AppButton
          style={{ marginTop: 12 }}
          size="small"
          onClick={handleChangesSend}
        >
          {translate('common.save')}
        </AppButton>
      </div>
    </AppAccordion>
  );
};

export default RoutingAccesses;

const Icon = styled.svg``;
