import React from 'react';
import {
  YMaps,
  Map as YandexMap,
  Placemark,
  FullscreenControl,
} from 'react-yandex-maps';
import PropTypes from 'prop-types';

function MapContent({ data }) {
  return (
    <YMaps>
      <div>
        <YandexMap
          defaultState={{
            restrictMapArea: true,
            center: [data.latitude, data.longitude],
            zoom: 12,
            controls: [],
          }}
        >
          <Placemark geometry={[data.latitude, data.longitude]} />
          <FullscreenControl />
        </YandexMap>
      </div>
    </YMaps>
  );
}
MapContent.propTypes = {
  data: PropTypes.object.isRequired,
};

export default MapContent;
