const days = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];
const months = ['january','february','march','april','may','june','july','august','september','october','november','december'];

export function workloadTranslate(str, key, t) {
  if (!str || !key) return '';
  let result;
  if (key === 'weekdays') {
    for (let i = 0; i < days.length; i += 1) {
      result = str.match(new RegExp(days[i], 'i'));
      if (result) {
        return str.replace(
          result,
          t(`graphInfo.weekdays.${result[0].toLowerCase()}`),
        );
      }
    }
  } else if (key === 'days') {
    for (let i = 0; i < months.length; i += 1) {
      result = str.match(new RegExp(months[i], 'i'));
      if (result) {
        return str.replace(
          result,
          t(`graphInfo.days.${result[0].toLowerCase()}`),
        );
      }
    }
  }
  return str;
}
