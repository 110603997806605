import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';

import { ReactComponent as CloseIcon } from '../../assets/img/close.svg';
import { ReactComponent as ExpandIcon } from '../../assets/img/expand.svg';
import { ReactComponent as CollapseIcon } from '../../assets/img/collapse.svg';


const Modal = styled.div`
  background-color: #403f3f3b;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 99999999999999;
  width: 100%;
  height: 100%;
  overflow: auto;
  ${ props => props.expand ? 
    css`
      padding: 50px 44px;
      padding-top: 0px;
    `:
    css`
      padding: 50px 200px;
    `
  };
  @media (max-width: 960px) {
    padding: 40px 24px;
    padding-top: 0px;
    .expand-icon {
      display: none;
    }
  }
  .error {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    color: rgba(0, 0, 0, 0.3);
  }
`;

const Iframe = styled.iframe`
  width: 100%;
  height: 100%;
  border: none;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  height: 44px;
`;

const Options = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  padding: 4px;
  gap: 10px;
  ${ props => props.expand && 'padding-right: 8px' };
`;

const Icon = styled.div`
  height: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    color: #313131;
  }
`;

const Body = styled.div`
  width: 100%;
  height: calc(100% - 44px);
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.1);
`;

export default function CrmDialog ({
  data,
  onClose,
}) {
  const [expand, setExpand] = React.useState(false);
  const handleExpand = () => {
    setExpand(!expand);
  };
  return (
    <Modal expand={expand}>
      <Header>
        <Options expand={expand}>
          <Icon className='expand-icon' onClick={() => handleExpand()}>
            {expand ?
              <CollapseIcon />:
              <ExpandIcon/>
            }
          </Icon>
          <Icon onClick={() => onClose()}>
            <CloseIcon/>
          </Icon>
        </Options>
      </Header>
      <Body>
        { data.link ? <Iframe rel="preload" title="..." src={data.link}/> :
          <div className='error'>No valid link</div>     
        }
      </Body>
    </Modal>
  );
};

CrmDialog.propTypes = {
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};