import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import styled from 'styled-components';
import _ from 'lodash';
import * as selector from 'store/selectors';
import { showModal } from '../../../../../store/actions/modalActions';

const Wrapper = styled.div`
    .tool-title {
        font-size: 19px;
        font-weight: bold;
        color: #373b42;
        border-bottom: 2px solid #eeeff2;
        padding-bottom: 23px;
        padding-left: 20px;
        margin-top: 18px;
        margin-bottom: 10px;
    }
    .contact-item {
        position: relative;
        border-bottom: 1px solid #eeeff2;
        padding: 15px 8px;
        margin: 0 15px;
        .contact-item-title {
            font-size: 15px;
            font-weight: bold;
            color: #31343a;
        }
        .contact-item-value {
            font-size: 15px;
            color: #31343a;
        }
        .contact-item-edit-button {
            position: absolute;
            top: 25px;
            right: 50px;
            width: 20px;
            height: 20px;
            background-image: url(/img/pencil.svg);
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
            display: none;
        }
        .contact-item-unlink-button {
            position: absolute;
            top: 25px;
            right: 15px;
            width: 20px;
            height: 20px;
            background-image: url(/img/unlink.svg);
            background-repeat: no-repeat;
            background-size: contain;
            cursor: pointer;
            display: none;
        }
        &:hover {
            background-color: #eeeff2;
            border-radius: 6px;
            .contact-item-edit-button,
            .contact-item-unlink-button {
                display: block;
            }
        }
    }
`;

const getContactItemValue = ({ type, data }) => {
  switch (type) {
    case 'telegram':
      return `t.me/${data.nick}`;
    case 'whatsapp':
      return `${data.id}`;
    case 'vk':
      return `vk.com/id${data.id}`;
    case 'viber':
      return `${data.nick}`;
    case 'facebook':
      return `facebook.com/${data.nick}`;
    default:
      return null;
  }
};

const getContactItemType = (dialog, type) => {
  let result = '';
  if (['gupshup', 'clickatell', 'waba'].includes(type)) {
    result = 'whatsapp';
  } else {
    result = type;
  }
  const providerName = _.get(dialog, 'provider.name');
  if (providerName) {
    result = `${result}: ${providerName}`;
  }
  return result;
}

const ChannelsTool = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const dialogs = useSelector((state) => state.chat.dialogs);
  const selectedDialog = useSelector(selector.selectCurrentDialog);
  if (!selectedDialog || !dialogs) return '';
  const dialog = dialogs && selectedDialog && dialogs.find((o) => o.uuid === selectedDialog);
  if (!dialog) return '';
  const { contacts } = dialog.persone;

  // const handleEditButton = (channel) => {

  // };

  const handleUnlinkButton = (channel, value) => {
    dispatch(showModal('UnlinkChannel', { channel, value, persone: dialog.persone }));
  };

  // const handleAddNewButton = () => {
  //   dispatch(showModal('AddChannel', {}));
  // };
  return (
    <Wrapper>
      <div className="tool-title">{t('toolsBar.channels')}</div>
      {contacts.map((c) => (
        <div className="contact-item" key={c.uuid}>
          <div className="contact-item-title">{getContactItemType(dialog, c.type)}</div>
          <div className="contact-item-value">{getContactItemValue(c)}</div>
          {/* <div className="contact-item-edit-button" onClick={() => handleEditButton(c)} /> */}
          <div className="contact-item-unlink-button" onClick={() => handleUnlinkButton(c, getContactItemValue(c))} />
        </div>
      ))}
      {/* <AddButton
        text={t('channelsTool.addNewChannel')}
        onClick={handleAddNewButton}
        style={{
          margin: '15px 0 0 15px',
          paddingBottom: '15px',
          borderBottom: '1px solid #eeeff2',
          fontSize: '15px',
        }}
      /> */}
    </Wrapper>
  );
};

export default ChannelsTool;
