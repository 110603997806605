import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const AppSwitcher = ({ isOn, size, isDisabled = false, onChange, ...rest }) => {
  return (
    <Switch className={`switch${size === 'small' ? ' small' : ''}`} {...rest}>
      <input
        type="checkbox"
        checked={isOn}
        disabled={isDisabled}
        onChange={onChange}
      />
      <span className="slider round" />
    </Switch>
  );
};

AppSwitcher.propTypes = {
  isOn: PropTypes.bool,
  size: PropTypes.string,
  isDisabled: PropTypes.bool,
  onChange: PropTypes.func,
};
AppSwitcher.defaultProps = {
  isOn: false,
  size: '',
  isDisabled: false,
  onChange: null,
};

export default AppSwitcher;

const Switch = styled.label`
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  width: 42px;
  height: 22px;

  input {
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .slider {
      background-color: #0ecc66;

      &:before {
        transform: translateX(20px);
      }
    }

    &:disabled {
      cursor: not-allowed;
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #eceff2;
    transition: transform 0.4s, background-color 0.4s;
    border-radius: 112px;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 1px;
      bottom: 1px;
      background-color: #ffffff;
      transition: transform 0.4s, background-color 0.4s;
      border-radius: 50%;
    }

    &.round {
      border-radius: 112px;

      &:before {
        border-radius: 50%;
      }
    }
  }

  &.small {
    width: 30px;
    height: 16px;

    input:checked + .slider:before {
      transform: translateX(14px);
    }

    .slider {
      border-radius: 16px;

      &:before {
        height: 14px;
        width: 14px;
        left: 1px;
        bottom: 1px;
      }

      &.round {
        border-radius: 16px;
      }
    }
  }
`;
