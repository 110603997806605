import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { fetchTagsStat } from 'store/actions/statisticsActions';

import { Page } from 'components';
import { Wrap } from './StatPage';
import StatNav from './components/StatNav'
import TagTable from './components/TagTable';
import SearchBar from './components/SearchBar';
import Filters from './components/Filters';
import {getDateRange} from './utils';


export default function TagsPage() {
  const dispatch = useDispatch();
  const initSelectedCompany = useSelector((state) => state.session.selectedCompany);
  const selectedCompany = useSelector((state) => state.statistics.selectedCompany) || initSelectedCompany;
  const selectedProviders = useSelector((state) => state.statistics.selectedProviders);
  const selectedDepartment = useSelector((state) => state.statistics.selectedDepartment);
  const calendarDateRange = useSelector((state) => state.calendar.mainDate);
  const tags = useSelector((state) => state.statistics.tags);
  useEffect(() => {
    let data = {
      dateRange: getDateRange(calendarDateRange),
      uuidAssigns: selectedDepartment.assignee,
    };
    if (selectedProviders) {
      data = {...data, uuidConnection: selectedProviders};
    };
    dispatch(fetchTagsStat({uuidCompany: selectedCompany, data}));
  }, [selectedCompany, calendarDateRange, selectedProviders, selectedDepartment])
  return (
    <Page
      title="IntellectDialog | Stat">
        <StatNav />
      <Wrap>
        <div>
          <div className='section filters'>
            <Filters
              selectedCompany={selectedCompany}
              dateRange={calendarDateRange} />
          </div>
          <div className='section'>
            <SearchBar data={tags} dateRange={calendarDateRange} />
          </div>
        </div>
        <div className='section'>
          <TagTable data={tags} />
        </div>
      </Wrap>
    </Page>
  );
}
