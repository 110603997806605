import React from 'react';
import PropTypes from 'prop-types';
import SelectDayMonthInputs from './SelectDayMonthInputs';

const TargetEmployeeScheduleInputs = ({
  orCondition,
  onKeyChange,
  onValueChange,
}) => {
  return (
    <>
      <SelectDayMonthInputs
        orCondition={orCondition}
        onKeyChange={onKeyChange}
        onValueChange={onValueChange}
      />
    </>
  );
};
TargetEmployeeScheduleInputs.propTypes = {
  orCondition: PropTypes.object,
  onKeyChange: PropTypes.func,
  onValueChange: PropTypes.func,
};
TargetEmployeeScheduleInputs.defaultProps = {
  orCondition: {},
  onKeyChange: null,
  onValueChange: null,
};
export default TargetEmployeeScheduleInputs;
