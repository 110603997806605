import React, { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import Select from 'react-select';

import ModalWrapper, { ActionButton, CancelButton } from './layout';

const Wrapper = styled.div`
    .type-toggler {
        margin-top: 25px;
        position: relative;
        background-color: #ccc;
        padding: 12px 17px;
        font-size: 15px;
        border-radius: 20px;
        height: 42px;
        > div {
            position: absolute;
            top: 0;
            width: 52%;
            height: 100%;
            padding: 12px 17px;
            border-radius: 20px;
            text-align: center;
            cursor: pointer;
            transition: all 0.3s ease-out;
            &:first-child {
                left: 0;
            }
            &:last-child {
                right: 0;
            }
            &.active {
                background: #006ae4;
                color: #fff;
            }
        }
    }
    .form-input {
        margin-top: 25px;
        label {
            display: block;
            margin-bottom: 10px;
            font-size: 15px;
            color: #7d8392;
        }
        .react-select__control {
            border-radius: 3px;
            border: solid 1px #eeeff2;
            background-color: #f6f8fc;
            font-size: 15px;
            color: #3f434c;
        }
        input {
            border-radius: 3px;
            border: solid 1px #eeeff2;
            background-color: #f6f8fc;
            font-size: 15px;
            color: #3f434c;
            padding: 10px;
            width: 100%;
            outline: none;
        }
    }
    .modal-buttons {
        margin-top: 25px;
        button:first-child{
            margin-right: 15px;
        }
    }
`;

const mockUsers = [
  { label: 'Василий', value: 'vasya' },
  { label: 'Геныч', value: 'gena' },
  { label: 'Оксанка', value: 'oksana' },
];

const mockChannels = [
  { label: 'facebook vasya', value: 'vasya' },
  { label: 'vk gena', value: 'gena' },
  { label: 'telegram oksana', value: 'oksana' },
];

const mockChannelTypes = [
  { label: 'Facebook', value: 'facebook' },
  { label: 'VK', value: 'vk' },
  { label: 'Telegram', value: 'telegram' },
  { label: 'Viber', value: 'viber' },
  { label: 'Whatsapp', value: 'whatsapp' },
];

const AddChannel = (props) => {
  const { onClose } = props;
  const [selectedType, selectType] = useState(0);
  const [selectedClient, selectClient] = useState(mockUsers[0]);
  const [selectedChannel, selectChannel] = useState(mockChannels[0]);
  const [selectedChannelType, selectChannelType] = useState(mockChannelTypes[0]);
  const [channelAddress, setChannelAddress] = useState('');

  const handleAdd = () => {

  };

  return (
    <ModalWrapper onClose={() => onClose()} title="Привязать дополнительный канал">
      <Wrapper>
        <div className="type-toggler">
          <div onClick={() => selectType(0)} className={`${selectedType === 0 ? 'active' : ''}`}>Существующий</div>
          <div onClick={() => selectType(1)} className={`${selectedType === 1 ? 'active' : ''}`}>Новый</div>
        </div>
        {selectedType === 0
          ? (
            <>
              <div className="form-input">
                <label htmlFor="selectedClient">Выберите клиента</label>
                <Select
                  classNamePrefix="react-select"
                  id="selectedClient"
                  options={mockUsers}
                  value={selectedClient}
                  onChange={selectClient}
                />
              </div>
              <div className="form-input">
                <label htmlFor="selectedChannel">Выберите канал</label>
                <Select
                  classNamePrefix="react-select"
                  id="selectedChannel"
                  options={mockChannels}
                  value={selectedChannel}
                  onChange={selectChannel}
                />
              </div>
            </>
          )
          : (
            <>
              <div className="form-input">
                <label htmlFor="selectedChannelType">Тип канала</label>
                <Select
                  classNamePrefix="react-select"
                  id="selectedChannelType"
                  options={mockChannelTypes}
                  value={selectedChannelType}
                  onChange={selectChannelType}
                />
              </div>
              <div className="form-input">
                <label htmlFor="channelAddress">Адрес/номер канала</label>
                <input
                  id="channelAddress"
                  value={channelAddress}
                  onChange={(e) => setChannelAddress(e.target.value)}
                  placeholder="Адрес/номер канала"
                />
              </div>
            </>
          )}
        <div className="modal-buttons">
          <ActionButton onClick={handleAdd}>Добавить</ActionButton>
          <CancelButton onClick={() => onClose()}>Отмена</CancelButton>
        </div>
      </Wrapper>
    </ModalWrapper>
  );
};

AddChannel.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default AddChannel;
