import React from 'react';
// import { useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { MenuItem, Select } from '@material-ui/core';
// import { NativeSelect, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
// import { useTranslation } from 'react-i18next';
import {setSelectedCompany, setSelectedDepartment, setSelectedProviders} from 'store/actions/statisticsActions';
import ProviderFilter from 'components/Filter/ProviderFilter';
import DepartmentFilter from 'components/Filter/DepartmentFilter';
import StatFilters from './StatFilters';
// import DateFilter from 'components/Filter/DateFilter';

// const useStyles = makeStyles(() => ({
//   listFilter: {
//     background: '#F3F4F5',
//     padding: '4px 4px 4px 10px',
//     borderRadius: '8px',
//   },
//   listFilterItem: {
//     textAlign: 'center',
//   },
// }));

export default function Filters({
  // dateRange = { from: null, to: null },
  selectedCompany,
}) {
  // const classes = useStyles();
  // const { t } = useTranslation();
  // const [listFilterItem, setListFilterItem] = useState('');
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const {selectedProviders, selectedDepartment} = useSelector((state) => state.statistics);
  // const [date, setDate] = useState(dateRange);
  // const handleSetToDate = (to) => {
  //   const newDateRange = { ...date, to };
  //   setDate(newDateRange);
  //   dispatch(setTagFilterDate(newDateRange));
  // }
  // const handleSetFromDate = (from) => {
  //   const newDateRange = { ...date, from };
  //   setDate(newDateRange);
  //   dispatch(setTagFilterDate(newDateRange));
  // }
  // const handleClearDate = () => {
  //   setDate({from: new Date(Date.now() - 604800000), to: new Date()});
  //   dispatch(setTagFilterDate({from: new Date(Date.now() - 604800000), to: new Date()}));
  // }
  const handleSelect = (event) => {
    dispatch(setSelectedCompany(event.target.value));
  };
  // const handlerFilterList = (event) => {
  //   setListFilterItem(event.target.value);
  // };
  const handleSelectProvider = (provider) => {
    dispatch(setSelectedProviders(provider));
  };
  const handleClearProvider = () => {
    dispatch(setSelectedProviders(null));
  };

  const handleSelectDepartment = (uuidAssignee, assigneeType) => {
    dispatch(setSelectedDepartment({assignee: uuidAssignee, type: assigneeType}));
  };
  const handleClearDepartment = () => {
    dispatch(setSelectedDepartment({assignee: null, type: 'all'}));
  };

  return (
    <View>
      <StatFilters />
      {/* <NativeSelect
        value={listFilterItem}
        onChange={handlerFilterList}
        className={`${classes.listFilter} listFilterFix`}
      >
        <option className={classes.listFilterItem} value="">
          {t('profileFilters.list')}
        </option>
        <option className={classes.listFilterItem} value="1">
          1
        </option>
        <option className={classes.listFilterItem} value="5">
          5
        </option>
        <option className={classes.listFilterItem} value="10">
          10
        </option>
        <option className={classes.listFilterItem} value="24">
          24
        </option>
      </NativeSelect> */}
      <div className="filter">
        <Select
          className="select"
          onChange={handleSelect}
          value={selectedCompany}
        >
          {_.get(user, 'companies', []).map(({ uuid, name }) => (
            <MenuItem key={uuid} value={uuid}>
              {name}
            </MenuItem>
          ))}
        </Select>
      </div>
      <DepartmentFilter
        department={selectedDepartment}
        handleClear={handleClearDepartment}
        handleSelect={handleSelectDepartment}
        />
      <ProviderFilter
        selected={selectedProviders}
        handleClear={handleClearProvider}
        handleSelect={handleSelectProvider}/>
    </View>
  );
}

Filters.propTypes = {
  // dateRange: PropTypes.object.isRequired,
  selectedCompany: PropTypes.string.isRequired,
};

const View = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 22px;
  .filter {
    background-color: #f3f4f5;
    padding: 4px 4px 4px 9px;
    border-radius: 8px;
  }
  select:focus {
    background: inherit;
  }
  .MuiInput-underline {
    &:after {
      content: '';
      border: 0;
    }
    &:before {
      content: '';
      border: 0;
    }
  }
  .listFilterFix {
    border: 0;
    &:before {
      content: '';
      border: unset !important;
    }
  }
  .select {
    &:before,
    &:after {
      border-bottom: none !important;
    }
    .MuiSelect-select:focus {
      background-color: transparent;
    }
  }
`;
