import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { useSwipeable } from 'react-swipeable';
import {useHistory} from 'react-router';
import PropTypes from 'prop-types';
import _ from 'lodash';

import Drawer from '@material-ui/core/Drawer';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles } from '@material-ui/styles';

import CancelIcon from '@material-ui/icons/Cancel';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { Badge, IconButton, Tooltip } from '@material-ui/core';

import { useLocation } from 'react-router-dom';
import AddButton from '../../../../components/AddButton';
import LeftBar from './LeftBar';
import * as sessionActions from '../../../../store/actions/sessionActions';
import * as statisticsActions from '../../../../store/actions/statisticsActions';
import {
  setSelectedProviders,
  setAssigneeFilter,
  setCustomFilter,
  setDialogStatusFilter,
  toggleFilterBlock,
  toggleProvidersBlock,
  toggleDisabledProvidersBlock,
  setDisabledProviders,
  setPostMode,
} from '../../../../store/actions/settingsActions';

const useStyles = (adaptive, isHidden) => makeStyles(() => ({
  root: {
    width: adaptive ? 'auto' : 220,
    display: isHidden ? 'none' : 'block',
  },
  paper: {
    display: 'block',
    width: adaptive ? '100%' : 220,
    padding: '0 0 24px 0',
    marginLeft: adaptive ? 0 : 76,
    backgroundColor: '#f6f8fc',
    borderRight: '2px solid #eaeaea',
  },
}));

const FilterStyledDrawer = styled(Drawer)`
  .MuiInput-root {
    width: 100%;
    max-width: 275px;
  }
  z-index: 999;
`;

const StyledList = styled(List)`
  .toggle-title {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    svg {
      color: #727887;
      margin-top: 2px;
    }
    .active-circle {
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #006ae4;
      margin-top: 2px;
      margin-left: -10px;
      margin-right: 5px;
    }
  }

  .MuiTypography-body2 {
    color: #000;
  }
  .MuiListSubheader-sticky {
    color: #000;
  }
  .MuiSvgIcon-root {
    color: #000;
  }
  .MuiButtonBase-root {
    border-radius: 6px;
    .MuiTypography-root {
      font-size: 15px;
    }
    &.Mui-selected,
    &:hover {
      background-color: #e7e9ef;
    }
    &.Mui-selected {
      svg path {
        fill: #006ae4;
      }
    }
  }
  .MuiListSubheader-root {
    position: relative;
    background-color: transparent !important;
    font-size: 15px;
    font-weight: bold;
    color: #727887;
  }
  > li {
    border-radius: 6px;
    &:hover {
      background-color: #e7e9ef;
    }
    &.selected {
      background-color: #006ae4;
      > div {
        color: #fff !important;
      }
    }
    &.selected-posts {
      background-color: #7700e4;
      > div {
        color: #fff !important;
      }
    }
  }
  .filter-item {
    flex: 1;
    position: relative;
    font-size: 15px;
    color: #26282d;
    cursor: pointer;
  }
`;

const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  .MuiSvgIcon-root {
    color: #215fdc;
  }
`;

const ProvidersWrapper = styled(StyledList)`
`;

const DepartamentSelect = styled(Select)`
  height: 67px;
  border-bottom: 2px solid #eaeaea;
  padding: 0 20px 0 24px;
  font-size: 17px;
  color: #000000;
  &:before,
  &:after {
    border-bottom: none !important;
  }
  .MuiSelect-select.MuiSelect-select {
    padding: 0 !important;
    background-color: transparent !important;
  }
  svg {
    right: 15px;
  }
`;

const AllPersonalTogglerWrapper = styled.div`
  margin: 5px auto 15px auto;
  display: flex;
  flex-direction: row;
  justify-content:space-between;
  background-color: #e7e9ef;
  font-size: 12px;
  border-radius: 20px;
  height: 40px;
  width: 180px;
  > div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    padding: 10px 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.1s ease-out;
    &:first-child {
      left: 0;
      border-radius: 20px 0px 0px 20px;
    }
    &:last-child {
      right: 0;
      padding: 0px 5px 0px 5px;
      border-radius: 0px 20px 20px 0px;
    }
    &.active {
      background: #006ae4;
      color: #fff;
    }
  }
`;

const GroupTitle = styled.div`
  font-size: 15px;
  font-weight: bold;
  color: ${props => props.color || 'rgb(114, 120, 135)'};
  padding-top: 5px;
  padding-bottom: 5px;
`;

const ProviderItem = ({
  icon,
  text,
  isSelected,
  isDisabled,
  handleClick,
  handleToggleVisibility,
  translate,
}) => {
  const Item = styled(ListItem)`
    padding-left: 8px !important;
    padding-right: 8px !important;
    gap: 4px;
    cursor: pointer;
`;

  const Icon = styled.img`
    height: 17px;
  `;

  const Text = styled.div`
    font-size: 15px;
    color: #26282d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;

  const VisibilityIcon = styled(IconButton)`
    padding: 0px !important;
    svg {
      height: 15px;
      width: 18px;
    }
    svg path {
      fill: #C2C6CE;
    }
    &:hover {
      background-color: transparent !important;
    }
    &:hover svg path {
      fill: #7f8289;
    }
  `;

  return (
    <Item
      className={isSelected && 'selected'}
      disabled={isDisabled}
      onClick={handleClick}>
      <VisibilityIcon
        size="smal"
        onClick={handleToggleVisibility}>
        <Tooltip title={translate(isDisabled ? 'common.show' : 'common.hide')}>
          {isDisabled ? <VisibilityOff /> : <Visibility />}
        </Tooltip>
      </VisibilityIcon>
      <Icon src={icon} />
      <Tooltip title={text}>
        <Text>{text}</Text>
      </Tooltip>
    </Item >
  );
}

ProviderItem.propTypes = {
  translate: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  isSelected: PropTypes.bool,
  isDisabled: PropTypes.bool,
  handleToggleVisibility: PropTypes.func.isRequired,
  handleClick: PropTypes.func
};

ProviderItem.defaultProps = {
  isSelected: false,
  isDisabled: false,
  handleClick: () => { }
};

const ProvidersList = ({
  title,
  translate,
  isOpen,
  isActive,
  providers,
  allowSelected,
  onSelectAll,
  onSelectPosts,
  onSelect,
  selected,
  handleToggle,
  disabled,
  postMode,
  handleToggleProviderVisibility
}) => (
  <ProvidersWrapper
    subheader={(
      <div className="toggle-title" onClick={handleToggle}>
        <GroupTitle>{title}</GroupTitle>
        {!isOpen && isActive ? <div className="active-circle" /> : null}
        {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </div>
    )}>
    {isOpen && (
      <>
        {allowSelected && (
          <ListItem
            onClick={onSelectAll}
            className={`${selected.length === 0 && !postMode ? 'selected' : ''}`}>
            <div className="filter-item">{translate('post.dialogs')}</div>
          </ListItem>
        )}
        {allowSelected && (
          <ListItem
            onClick={onSelectPosts}
            className={`${postMode ? 'selected selected-posts' : ''}`}>
            <div className="filter-item">{translate('post.posts')}</div>
          </ListItem>
        )}
        {providers.map((row) => (
          <ProviderItem
            translate={translate}
            isDisabled={disabled}
            isSelected={selected.includes(row.uuid)}
            icon={`/img/messengers/${row.provider}.svg`}
            text={row.name}
            handleClick={() => onSelect(row.uuid)}
            handleToggleVisibility={(e) => {
              e.stopPropagation();
              handleToggleProviderVisibility(row.uuid);
            }}
            key={row.uuid} />
        ))}
      </>)}
  </ProvidersWrapper>
);

ProvidersList.propTypes = {
  title: PropTypes.string.isRequired,
  translate: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  providers: PropTypes.array,
  onSelectAll: PropTypes.func,
  onSelectPosts: PropTypes.func,
  onSelect: PropTypes.func,
  selected: PropTypes.array,
  handleToggleProviderVisibility: PropTypes.func.isRequired,
  handleToggle: PropTypes.func,
  allowSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  postMode:  PropTypes.bool.isRequired,
};

ProvidersList.defaultProps = {
  providers: [],
  isOpen: true,
  isActive: false,
  allowSelected: false,
  onSelectAll: () => false,
  onSelectPosts: () => false,
  onSelect: () => false,
  handleToggle: () => false,
  disabled: false,
  selected: []
};

// const TagList = ({ tags, label, canAdd }) => (
//   <StyledList subheader={label ? <ListSubheader>{label}</ListSubheader> : null}>
//     {tags.map((tag, i) => (
//       <ListItem key={`tag-list-item${i}`} button dense>
//         <ListItemText primary={`#${tag}`} />
//       </ListItem>
//     ))}
//     {canAdd ? <AddButton style={{ fontSize: '12px' }} /> : null}
//   </StyledList>
// );

// TagList.propTypes = {
//   tags: PropTypes.array,
//   label: PropTypes.string,
//   canAdd: PropTypes.bool,
// };

// TagList.defaultProps = {
//   tags: [],
//   label: '',
//   canAdd: false,
// };

const FilterList = ({
  isOpen, isActive, filters, label, canAdd, clear, handleToggle,
}) => {
  const countStat = useSelector((state) => state.chat.countStat);

  const prevCountStat = useRef(countStat);

  const getFormattedCount = useCallback((key) => {
    const count = countStat[key];
    if (count > 999) {
      return `${(count/1000).toFixed(2).replace(/\.?0+$/, '')}K`;
    }
    return count;
  }, [prevCountStat?.current]);
  
  useEffect(() => {
    if (_.isEqual(prevCountStat.current, countStat)) {
      prevCountStat.current = countStat;
    }
  }, [JSON.stringify(countStat)]);

  const Count = styled(Badge)`
    margin-left: 5px;
    .MuiBadge-anchorOriginTopRightRectangle {
      transform: none;
    }
    .MuiBadge-badge {
      position: relative; 
    }
    .MuiBadge-colorSecondary {
      ${props => props.isActive && `
        background-color: #FFFFFF;
        color: #006ae4;
      }`
    }
  `; 

  return (
    <StyledList
      subheader={
        label ? (
          <div className="toggle-title" onClick={handleToggle}>
            <GroupTitle>{label}</GroupTitle>
            {!isOpen && isActive ? <div className="active-circle" /> : null}
            {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </div>
        ) : null
      }
    >
      {isOpen ? (
        <>
          {filters.map((filter, i) => (
            <ListItem
              key={`filter-list-item${i}`}
              onClick={() => (!filter.isActive ? filter.onSelect() : clear())}
              className={filter.isActive ? 'selected' : ''}
            >
              <div className="filter-item">{filter.title}</div>
              <Count
                isActive={filter.isActive}
                max={999}
                color="secondary"
                badgeContent={getFormattedCount(filter.key)}/>
            </ListItem>
          ))}
          {canAdd ? <AddButton style={{ fontSize: '12px' }} /> : null}
        </>
      ) : null}
    </StyledList>
  );
};

FilterList.propTypes = {
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
  filters: PropTypes.array,
  label: PropTypes.string,
  canAdd: PropTypes.bool,
  clear: PropTypes.func.isRequired,
  handleToggle: PropTypes.func,
};

FilterList.defaultProps = {
  isOpen: true,
  isActive: false,
  filters: [],
  label: '',
  canAdd: false,
  handleToggle: () => false,
};

const AllPersonalToggler = () => {
  const user = useSelector((state) => state.session.user);
  const departments = useSelector((state) => state.session.departments);
  const selectedCompany = useSelector((state) => state.session.selectedCompany);

  const { t } = useTranslation();
  const assigneeFilter = useSelector(
    (state) => state.settings.assigneeFilter,
  );

  const dispatch = useDispatch();
  return (
    <AllPersonalTogglerWrapper>
      <div
        onClick={() => dispatch(setAssigneeFilter({
          assignee: null,
          type: 'all',
        }))}
        className={`${assigneeFilter.type === 'all' ? 'active' : ''}`}
      >
        {t('filterBar.all')}
      </div>
      <div
        onClick={() => dispatch(setAssigneeFilter({
          assignee: [user.uuid],
          type: 'personal',
        }))}
        className={`${assigneeFilter.type === 'personal' ? 'active' : ''}`}
      >
        {t('filterBar.myPerson')}
      </div>
      <div
        onClick={() => dispatch(setAssigneeFilter({
          assignee: Object.keys(departments?.[selectedCompany]) || 'undifened',
          type: 'myDepartment',
        }))}
        className={`${assigneeFilter.type === 'myDepartment' ? 'active' : ''}`}
      >
        {t('filterBar.myDepartment')}
      </div>
    </AllPersonalTogglerWrapper>
  );
};

// eslint-disable-next-line no-unused-vars
const getFavouriteFilters = (translate, dispatch, customFilter, dialogStatusFilter) => [
  {
    key: 'unread',
    title: translate('filterBar.favoriteFilters.unread'),
    onSelect: () => {
      dispatch(setCustomFilter({ unread: 'unread' }));
      dispatch(setDialogStatusFilter('open'));
    },
    isActive: customFilter.unread,
  },
  {
    key: 'noAnswerForClient',
    title: translate('filterBar.favoriteFilters.noAnswerForClient'),
    onSelect: () => {
      dispatch(setCustomFilter({ noAnswerForClient: 'noAnswerForClient' }));
      dispatch(setDialogStatusFilter('open'));
    },
    isActive: customFilter.noAnswerForClient === 'noAnswerForClient',
  },
  {
    key: 'noAnswerForSupport',
    title: translate('filterBar.favoriteFilters.noAnswerForSupport'),
    onSelect: () => {
      dispatch(setCustomFilter({ noAnswerForSupport: 'noAnswerForSupport' }));
      dispatch(setDialogStatusFilter('open'));
    },
    isActive: customFilter.noAnswerForSupport === 'noAnswerForSupport',
  },
  {
    key: 'active',
    title: translate('filterBar.favoriteFilters.active'),
    onSelect: () => {
      dispatch(setCustomFilter({ active: 'active' }));
      dispatch(setDialogStatusFilter('open'));
    },
    isActive: customFilter.active === 'active',
  },
  {
    key: 'closed',
    title: translate('filterBar.favoriteFilters.closed'),
    onSelect: () => {
      dispatch(setCustomFilter({ closed: 'closed' }));
      dispatch(setDialogStatusFilter('closed'));
    },
    isActive: customFilter.closed === 'closed',
  },
  /*
  {
    title: translate('filterBar.favoriteFilters.spam'),
    onSelect: () => {
      dispatch(setCustomFilter({ inSpamList: 'inSpamList' }));
      dispatch(setDialogStatusFilter('open'));
    },
    isActive: customFilter.inSpamList === 'inSpamList',
  },
  */
  {
    title: translate('filterBar.favoriteFilters.mailing'),
    onSelect: () => {
      dispatch(setCustomFilter({ isMailing: 'isMailing' }));
      dispatch(setDialogStatusFilter('open'));
    },
    isActive: customFilter.isMailing === 'isMailing',
  },
  {
    title: translate('filterBar.favoriteFilters.postponed'),
    onSelect: () => {
      dispatch(setCustomFilter({ postponed: 'postponed' }));
      dispatch(setDialogStatusFilter('open'));
    },
    isActive: customFilter.postponed === 'postponed',
  },
];

const FilterBar = (props) => {
  const { adaptive, open, handleCloseFilterBar } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const isSettingsPage = location.pathname.includes('/settings');
  const classes = useStyles(adaptive, isSettingsPage)();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.session.user);
  const selectedCompany = useSelector((state) => state.session.selectedCompany);
  const providers = useSelector((state) => state.chat.companyProviders);
  //  const postsProviders = useSelector((state) => state.chat.postsProviders);
  const settings = useSelector((state) => state.settings);

  const {
    disabledProviders = [],
    selectedProviders = [],
    customFilter = {},
    dialogStatusFilter,
    filterBlockIsOpen,
    providersBlockIsOpen,
    disabledProvidersBlockIsOpen,
    postMode,
  } = settings;

  const swipeHandlers = useSwipeable({
    onSwiped: (eventData) => {
      if (eventData.dir === 'Left') {
        handleCloseFilterBar();
      }
    },
  });

  // const systemTags = ['срочно', 'несрочно'];
  // const myTags = ['нагрубили', 'сложна'];

  const isOpen = adaptive ? open : true;

  const handleChangeCompany = (e) => {
    const newCompany = e.target.value;
    dispatch(sessionActions.setCompany(newCompany));
    dispatch(statisticsActions.setSelectedCompany(newCompany));
    sessionActions.getDepartaments(newCompany)(dispatch);
    dispatch(setSelectedProviders([]));
    dispatch(sessionActions.setCurrentDialog(null));
  };

  const history = useHistory(); 

  const handleSelectAllProviders = () => {
    if (postMode) {
      dispatch(setPostMode(false));
      history.push(`/chat`);
    }
    dispatch(setSelectedProviders([]));
    dispatch(setDialogStatusFilter('open'));
  };

  const onSelectPosts = () => {
    if (!postMode) {
      dispatch(setPostMode(true));
      history.push(`/chat/post`);
    }
  }


  const handleSelectProvider = (uuid) => {
    if (!disabledProviders.find(disabled => disabled === uuid)) {
      let newSelectedProviders = selectedProviders.slice();
      const findIndex = newSelectedProviders.findIndex((p) => p === uuid);
      if (findIndex > -1) {
        handleSelectAllProviders();
      } else {
        newSelectedProviders = [uuid];
        dispatch(setSelectedProviders(newSelectedProviders));
      }
    }
  };
  const handleDisableProvider = (uuid) => {
    dispatch(setDisabledProviders([...disabledProviders, uuid]));
    dispatch(setSelectedProviders(selectedProviders.filter(selected => selected !== uuid)));
  }

  const handleEnableProvider = (uuid) => {
    dispatch(setDisabledProviders(disabledProviders.filter(disabled => disabled !== uuid)));
  }

  const handleClearFavouriteFilters = () => {
    dispatch(setCustomFilter({}));
    dispatch(setDialogStatusFilter('open'));
  };

  const handleToggleFilterBlock = () => {
    dispatch(toggleFilterBlock(!filterBlockIsOpen));
  };

  const handleToggleProvidersBlock = () => {
    dispatch(toggleProvidersBlock(!providersBlockIsOpen));
  };

  const handleDisabledToggleProvidersBlock = () => {
    dispatch(toggleDisabledProvidersBlock(!disabledProvidersBlockIsOpen));
  };

  const favouriteFilters = getFavouriteFilters(
    t,
    dispatch,
    customFilter,
    dialogStatusFilter,
  );
  const favouriteFiltersIsActive = Boolean(
    favouriteFilters.find((f) => f.isActive),
  );

  const memorizedCompanies = React.useMemo(
    () => _.get(user, 'companies', []).map(({ uuid, name }) => (
      <MenuItem key={uuid} value={uuid}>
        {name}
      </MenuItem>
    )),
    [user.companies],
  );
  React.useEffect(() => {
    if (selectedCompany) {
      //  dispatch(fetchUndreadMsgCount({ uuidCompany: selectedCompany }));
    }
  }, [selectedCompany])
  React.useEffect(() => {
    dispatch(sessionActions.fetchGetUserInfo());
    if (!user.selectedCompany) {
      dispatch(sessionActions.fetchUserCompanies());
    }
    if (selectedProviders.length === 0) {
      dispatch(setDialogStatusFilter('open'));
    }
  }, []);
  React.useEffect(() => {
    const companies = _.get(user, 'companies', []).map(({ uuid }) => uuid);
    if (companies.length && !companies.includes(selectedCompany)) {
      const newCompany = companies[0];
      dispatch(sessionActions.setCompany(newCompany));
      dispatch(statisticsActions.setSelectedCompany(newCompany));
      sessionActions.getDepartaments(newCompany)(dispatch);
      dispatch(setSelectedProviders([]));
      dispatch(sessionActions.setCurrentDialog(null));
    }
  }, []);
  return (
    <>
      {!adaptive ? <LeftBar open={isOpen} /> : null}
      <FilterStyledDrawer
        open={isOpen}
        variant="persistent"
        anchor="left"
        classes={classes}
        {...swipeHandlers}
      >
        {adaptive && (
          <CloseButton onClick={handleCloseFilterBar}>
            <CancelIcon />
          </CloseButton>
        )}
        <DepartamentSelect
          onChange={handleChangeCompany}
          inputProps={{
            name: 'organization',
            id: 'organization',
          }}
          displayEmpty
          value={selectedCompany}
        >
          {memorizedCompanies}
        </DepartamentSelect>
        <div style={{ padding: '0 13px' }}>
          <GroupTitle color="rgb(73 78 88)">
            {t('filterBar.title')}
          </GroupTitle>
          <AllPersonalToggler />
          <FilterList
            isOpen={filterBlockIsOpen}
            isActive={favouriteFiltersIsActive}
            label={t('filterBar.favoriteFilters.title')}
            filters={favouriteFilters}
            clear={handleClearFavouriteFilters}
            handleToggle={handleToggleFilterBlock}
          />
          {React.useMemo(() => {
            if (!Object.keys(providers).length) return null;
            return (
              <>
                <ProvidersList
                  title={t('filterBar.selectedMessengers')}
                  translate={t}
                  isOpen={providersBlockIsOpen}
                  allowSelected
                  postMode={postMode}
                  isActive={Boolean(selectedProviders.length)}
                  providers={
                    postMode ?
                    []:
                    providers.filter(({ uuid }) => !disabledProviders.includes(uuid))
                  }
                  onSelectAll={handleSelectAllProviders}
                  onSelectPosts={onSelectPosts}
                  onSelect={handleSelectProvider}
                  handleToggleProviderVisibility={handleDisableProvider}
                  selected={selectedProviders}
                  handleToggle={handleToggleProvidersBlock} />
                <ProvidersList
                  disabled
                  title={t('filterBar.disabledMessengers')}
                  translate={t}
                  isActive={Boolean(disabledProviders.length)}
                  isOpen={disabledProvidersBlockIsOpen}
                  providers={
                    postMode ?
                    []:
                    providers.filter(({ uuid }) => disabledProviders.includes(uuid))
                  }
                  handleToggleProviderVisibility={handleEnableProvider}
                  handleToggle={handleDisabledToggleProvidersBlock} />
              </>
            );
          }, [disabledProviders, postMode, providers, selectedProviders, providersBlockIsOpen, disabledProvidersBlockIsOpen, t])}
        </div>

        {/* <TagList label={t('filterBar.systemTags')} tags={systemTags} />
            <TagList label={t('filterBar.myTags')} tags={myTags} canAdd />
         */}
      </FilterStyledDrawer >
    </>
  );
};

FilterBar.propTypes = {
  adaptive: PropTypes.bool,
  open: PropTypes.bool,
  handleCloseFilterBar: PropTypes.func,
  handleChangeVisibility: PropTypes.func
};

FilterBar.defaultProps = {
  adaptive: false,
  open: false,
  handleCloseFilterBar: () => false,
  handleChangeVisibility: () => false
};

export default FilterBar;
