import React, {
  Fragment,

  useState,
} from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/styles';
import {
  Avatar,
  List,
  ListItem,
  ListItemText,
  Collapse,

} from '@material-ui/core';

import {
  AssignmentReturned as AssignmentReturnedIcon,
  // AssignmentInd as AssignmentIndIcon,

  // Undo as UndoIcon,
  Group as GroupIcon,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons';
import { getInitials, stringToColor } from 'utils/userHelper';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.white,
  },
  backButton: {
    marginRight: theme.spacing(2),
  },
  user: {
    flexShrink: 0,
    flexGrow: 1,
    '@media (max-width: 960px)': {
      maxWidth: '160px',
    },
  },
  avatarSmall: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1),
    padding: theme.spacing(1),
    fontSize: '1rem',
  },
  activity: {
    display: 'flex',
    alignItems: 'center',
  },
  statusBullet: {
    marginRight: theme.spacing(1),
  },
  search: {
    height: 42,
    padding: theme.spacing(0, 2),
    display: 'flex',
    alignItems: 'center',
    flexBasis: 300,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      flex: '1 1 auto',
    },
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.icon,
  },
  searchInput: {
    flexGrow: 1,
  },
}));

const ListStyled = styled(List)`
    .MuiListItem-root{
      cursor: pointer;
      &:hover{
        background-color: rgba(0, 0, 0, 0.08);
      }
    }
  `;

const getDepartmentUserName = (user) => {
  const { name, surname, lastname } = user;
  if (!name && !surname && !lastname) return 'noname';
  return `${name} ${surname} ${lastname}`;
};

const DepartmentsList = (props) => {
  const {
    departments,
    selectedCompany,
    captionsRoot,
    handleDepartmentAssigneeClick,
  } = props;
  const currentData = departments[selectedCompany];
  const [opened, setOpened] = useState({});
  const user = useSelector((state) => state.session.user);
  const classes = useStyles();
  const { t } = useTranslation();

  const handleClick = (key) => {
    const newObj = { ...opened };
    newObj[key] = !opened[key];
    setOpened(newObj);
  };

  const handleDepartmentClick = (e, departmentUuid) => {
    e.stopPropagation();
    handleDepartmentAssigneeClick(departmentUuid, 'group');
  };

  if (!currentData) return null;
  return (
    <ListStyled>
      <ListItem onClick={() => handleDepartmentAssigneeClick(user.uuid, 'personal')}>
        <Avatar
          className={classes.avatarSmall}
          style={{ backgroundColor: '#d84444' }}
        >
          <AssignmentReturnedIcon fontSize="small" />
        </Avatar>
        <ListItemText primary={t(`${captionsRoot}.assignee.toMe`)} />
      </ListItem>
      {Object.keys(currentData).map((key) => (
        <Fragment key={key}>
          <ListItem onClick={() => handleClick(key)} style={{ minWidth: '400px' }}>
            <Avatar
              className={classes.avatarSmall}
              style={{ backgroundColor: stringToColor(currentData[key].name) }}
            >
              <GroupIcon fontSize="small" />
            </Avatar>
            <ListItemText primary={currentData[key].name} secondary={t(`${captionsRoot}.assignee.departmentSubtitle`, { count: currentData[key].users.length })} />
            {opened[key] ? <ExpandLess /> : <ExpandMore />}
          </ListItem>
          <Collapse in={opened[key]} timeout="auto" unmountOnExit>
            <List>
              <ListItem onClick={(e) => handleDepartmentClick(e, key)}>
                <Avatar
                  className={classes.avatarSmall}
                  style={{ backgroundColor: stringToColor(currentData[key].name) }}
                >
                  <AssignmentReturnedIcon fontSize="small" />
                </Avatar>
                <ListItemText primary={t(`${captionsRoot}.assignee.toDepartament`)} />
              </ListItem>
              {currentData[key].users.map((userItem) => (
                <ListItem key={userItem.uuid} onClick={() => handleDepartmentAssigneeClick(userItem.uuid, 'personal')}>
                  <Avatar
                    className={classes.avatarSmall}
                    style={{ backgroundColor: stringToColor(getDepartmentUserName(userItem)) }}
                  >
                    {getInitials(getDepartmentUserName(userItem))}
                  </Avatar>
                  <ListItemText primary={getDepartmentUserName(userItem)} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </Fragment>
      ))}
    </ListStyled>
  );
};

DepartmentsList.propTypes = {
  departments: PropTypes.object,
  selectedCompany: PropTypes.string,
  captionsRoot: PropTypes.string,
  handleDepartmentAssigneeClick: PropTypes.func,
};

DepartmentsList.defaultProps = {
  departments: {},
  selectedCompany: '',
  captionsRoot: 'dialog.toolbar',
  handleDepartmentAssigneeClick: () => false,
};
export default DepartmentsList;
